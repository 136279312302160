import React, { useEffect, useState } from 'react';

import { splitArray } from '@netspresso/shared';

import { Pagination } from '../Pagination';
import { isNumInstanceType, NumInstanceType } from '../../../../../../lib';

interface IClassesTable {
  classNames: string[];
  numInstances: NumInstanceType[] | Record<string, number>;
}

export const ClassesTable: React.FC<IClassesTable> = ({ classNames, numInstances }) => {
  const [paginated, setPaginated] = useState<string[][]>([]);
  const [dataToRender, setDataToRender] = useState<string[]>([]);
  const [pageNum, setPageNum] = useState(0);

  useEffect(() => {
    setPaginated(splitArray(classNames));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classNames]);

  useEffect(() => {
    setDataToRender(paginated[pageNum]);
  }, [paginated, pageNum]);

  const getNumInstance = (item: string) => {
    return item
      ? Array.isArray(numInstances)
        ? numInstances.find((instance) => instance.class_name === item)
        : Object.prototype.hasOwnProperty.call(numInstances, item)
        ? numInstances[item].toString()
        : ''
      : '';
  };

  const renderClasses = (item: string, i: number) => {
    const instance = getNumInstance(item);

    return (
      <tr key={`className_${i}`}>
        <td className="h-7 px-2">{pageNum * 10 + i}</td>
        <td className="h-7 px-2">{item || ''}</td>
        {isNumInstanceType(instance) ? (
          <>
            <td className="h-7 pr-6 text-right">{instance.total}</td>
            <td className="h-7 pr-6 text-right">{instance.train}</td>
            <td className="h-7 pr-6 text-right">{instance.val}</td>
            <td className="h-7 pr-6 text-right">{instance.test}</td>
          </>
        ) : (
          <>
            <td className="h-7 pr-4 text-right">{instance || ''}</td>
            <td className="h-7 pr-4" />
            <td className="h-7 pr-4" />
            <td className="h-7 pr-4" />
          </>
        )}
      </tr>
    );
  };

  if (!numInstances || !classNames) {
    return <>N/A</>;
  }

  return (
    <section className="w-full w-2/3 pl-3">
      <table className="w-full border-b border-defaultGray mb-2 even">
        <thead className="text-left bg-bgblue text-sm text-white">
          <tr>
            <th className="px-2 h-6">Index</th>
            <th className="px-2 h-6">Class name</th>
            <th className="px-2 text-center h-6">Total object</th>
            <th className="px-2 text-center h-6">Training</th>
            <th className="px-2 text-center h-6">Validation</th>
            <th className="px-2 text-center h-6">Testing</th>
          </tr>
        </thead>
        <tbody className="text-darkGray">{dataToRender && dataToRender.map(renderClasses)}</tbody>
      </table>
      <Pagination total={paginated ? paginated.length : 0} pageNum={pageNum} setPageNum={setPageNum} />
    </section>
  );
};
