import { formatizeLatency } from '@netspresso/shared';

export const getMaxMetric = (valueList: string | string[] | number | number[] | (string | number | null)[]): string => {
  const filtered: number[] = [];

  if (Array.isArray(valueList)) {
    for (let i = 0; i < valueList.length; i++) {
      const item = valueList[i];

      if (typeof item === 'string' && !isNaN(parseFloat(`${item}`))) {
        filtered.push(parseFloat(`${item}`));
      } else if (typeof item === 'number' && !isNaN(item)) {
        filtered.push(item);
      }
    }

    return formatizeLatency(Math.max(...filtered));
  }

  return `${valueList}`;
};
