import { TASKS, Tasks } from '../types';

export const isTasks = (value: unknown): value is Tasks => {
  switch (value) {
    case TASKS.OBJECT_DETECTION:
    case TASKS.IMAGE_CLASSIFICATION:
    case TASKS.SEMANTIC_SEGMENTATION:
    case TASKS.INSTANCE_SEGMENTATION:
    case TASKS.PANOPTIC_SEGMENTATION:
    case TASKS.IMAGE_SEGMENTATION:
    case TASKS.OTHER:
      return true;
    default:
      return false;
  }
};
