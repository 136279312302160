import React, { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-hook-form';

import {
  FormHeaderLabel,
  HeaderText,
  InfoContainer,
  InputNumber,
  InputTextLabel,
  RadioButton,
  Select,
} from '@netspresso/components';
import {
  FOURB,
  JETPACK_46,
  JETPACK_501,
  NANO,
  NVIDIA_TAO,
  PLACEHOLDER_OUTPUT_BATCH_SIZE,
  RASPBERRY_PI,
  featureFlags,
} from '@netspresso/shared';

import {
  AGX_ORIN_VERSIONS,
  BASE_IMG_SIZE,
  DEVICE,
  FRAMEWORK_VERSIONS,
  IMAGE_PROCESSING_DETAIL,
  IMG_SIZE_HEIGHT,
  IMG_SIZE_WIDTH,
  INTELXEON_FRAMEWORK_VERSIONS,
  JETSON_FRAMEWORK_VERSIONS,
  JETSON_VERSIONS,
  NANO_VERSIONS,
  ONNX_TRT_VERSIONS,
  OUTPUT_BATCH_SIZE,
  OUTPUT_DATA_TYPE,
  OUTPUT_MODEL_TYPE,
  OUTPUT_MODEL_VERSION,
  PROJECT_TASK,
  RASPBERRY_PI_VERSIONS,
  RASP_FRAMEWORK_VERSIONS,
  TAO_FRAMEWORK_VERSIONS,
  XAVIER_NX_VERSIONS,
} from '../../../../constants';
import { useProjectsFormContext } from '../../../../context';
import {
  DEVICES,
  Devices,
  DevicesMap,
  FRAMEWORKS,
  Frameworks,
  OUTPUT_DATA_TYPES,
  OutputDataTypes,
  isDevices,
  isOutputDataTypes,
  isOutputModelTypes,
  isTargetDevice,
} from '../../../../lib';
import {
  isArmVirtualHardware,
  isIntelXeon,
  isJetson,
  isJetsonAgxOrin,
  isJetsonNano,
  isJetsonNx,
  isNotArmVirtualHardware,
  isNotIntelXeon,
  isNotObjectDetection,
  isObjectDetection,
  isRaspberryPi,
  isSemanticSegmentation,
} from '../../../../utils';

export const TargetDevices: React.FC = () => {
  const {
    projectsForm: { setValue, watch, control },
  } = useProjectsFormContext();
  const { errors } = useFormState({ control });

  const [targetDevice, setTargetDevice] = useState<Devices>(DEVICES.RASPBERRY_PI);
  const [jetsonVersion, setJetsonVersion] = useState(NANO);
  const [raspberryVersion, setRaspberryVersion] = useState(FOURB);
  const [framework, setFramework] = useState<Frameworks>(FRAMEWORKS.TENSORFLOW_LITE);
  const [swversion, setSWversion] = useState(JETPACK_46);
  const [outputDataType, setOutputDataType] = useState<OutputDataTypes>(OUTPUT_DATA_TYPES.FP16);
  const [batchSize, setBatchSize] = useState(1);

  const watched = watch();

  const getDevice = useCallback(
    (device: Devices): Devices => {
      if (isJetson(device)) {
        const jetsonDevice = `${device}-${jetsonVersion}`;

        if (isDevices(jetsonDevice)) {
          return jetsonDevice;
        }
      }

      if (isRaspberryPi(device)) {
        const raspberryDevice = `${device}${raspberryVersion}`;

        if (isDevices(raspberryDevice)) {
          return raspberryDevice;
        }
      }

      return device;
    },
    [jetsonVersion, raspberryVersion]
  );

  const assignDevice = useCallback((device: Devices) => {
    const convertedDevice = getDevice(device);

    if (isTargetDevice(convertedDevice)) {
      setValue(DEVICE, convertedDevice);

      if (isRaspberryPi(device) || isArmVirtualHardware(device)) {
        setFramework(FRAMEWORKS.TENSORFLOW_LITE);
        setValue(OUTPUT_MODEL_TYPE, FRAMEWORKS.TENSORFLOW_LITE);
        setValue(OUTPUT_BATCH_SIZE, 1, { shouldValidate: true, shouldDirty: true });

        if (isArmVirtualHardware(device)) {
          setOutputDataType(OUTPUT_DATA_TYPES.INT8);
          setValue(OUTPUT_DATA_TYPE, OUTPUT_DATA_TYPES.INT8);
        } else {
          setOutputDataType(OUTPUT_DATA_TYPES.FP16);
          setValue(OUTPUT_DATA_TYPE, OUTPUT_DATA_TYPES.FP16);
        }
      }

      if (isJetson(device)) {
        setFramework(FRAMEWORKS.TENSORRT);
        setValue(OUTPUT_MODEL_TYPE, FRAMEWORKS.TENSORRT);
        setOutputDataType(OUTPUT_DATA_TYPES.FP16);
        setValue(OUTPUT_DATA_TYPE, OUTPUT_DATA_TYPES.FP16);

        if (isSemanticSegmentation(watched[PROJECT_TASK])) {
          setValue(OUTPUT_BATCH_SIZE, 1, { shouldValidate: true, shouldDirty: true });
        }
      }

      if (isIntelXeon(device)) {
        setFramework(FRAMEWORKS.OPENVINO);
        setValue(OUTPUT_MODEL_TYPE, FRAMEWORKS.OPENVINO);
        setOutputDataType(OUTPUT_DATA_TYPES.FP16);
        setValue(OUTPUT_DATA_TYPE, OUTPUT_DATA_TYPES.FP16);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeviceChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    if (!isDevices(value)) {
      return;
    }

    setTargetDevice(value);
    assignDevice(targetDevice);

    if (isObjectDetection(watched[PROJECT_TASK])) {
      if (isArmVirtualHardware(targetDevice)) {
        setOutputData(OUTPUT_DATA_TYPES.INT8);
        setImageSizes(128);
        setValue(IMAGE_PROCESSING_DETAIL, {
          pre_processing: {
            padding: true,
            color_mode: 'bgr',
            normalize: true,
          },
        });
      } else {
        setOutputData(OUTPUT_DATA_TYPES.FP16);

        if (watched[IMG_SIZE_WIDTH] < 256 && watched[IMG_SIZE_WIDTH] !== 128) {
          setImageSizes(640);
        }
        setValue(IMAGE_PROCESSING_DETAIL, {});
      }
    }
  };

  const setImageSizes = (value: number) => {
    setValue(BASE_IMG_SIZE, [value, value]);
    setValue(IMG_SIZE_WIDTH, value);
    setValue(IMG_SIZE_HEIGHT, value);
  };

  const onOutputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    if (isOutputDataTypes(value)) {
      setOutputData(value);
    }
  };

  const setOutputData = (value: OutputDataTypes) => {
    setOutputDataType(value);
    setValue(OUTPUT_DATA_TYPE, value);
  };

  const jetsonTrtVersionOptions = (deviceVersion: Devices) => {
    if (isJetsonAgxOrin(deviceVersion)) {
      return AGX_ORIN_VERSIONS;
    }

    if (isJetsonNano(deviceVersion)) {
      return NANO_VERSIONS;
    }

    if (isJetsonNx(deviceVersion)) {
      return XAVIER_NX_VERSIONS;
    }

    return ONNX_TRT_VERSIONS;
  };

  const onJetsonVersionChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    setJetsonVersion(value);
    const jetsonDevice = `${DEVICES.JETSON}-${value}`;

    if (isTargetDevice(jetsonDevice)) {
      setValue(DEVICE, jetsonDevice);
    }

    if (value === 'AGX-Orin') {
      setValue(OUTPUT_MODEL_VERSION, JETPACK_501);
    } else {
      setValue(OUTPUT_MODEL_VERSION, JETPACK_46);
    }
  };

  const onRaspberryVersionChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    setRaspberryVersion(value);
    const raspberryDevice = `${DEVICES.RASPBERRY_PI}${value}`;

    if (isTargetDevice(raspberryDevice)) {
      setValue(DEVICE, raspberryDevice);
    }
  };

  const onFrameworkChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    if (isOutputModelTypes(value)) {
      setFramework(value);
      setValue(OUTPUT_MODEL_TYPE, value);
    }
  };

  const onSWVersionChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    setSWversion(value);
    setValue(OUTPUT_MODEL_VERSION, value);
  };

  const onBatchSizeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setBatchSize(parseFloat(value));
    setValue(OUTPUT_BATCH_SIZE, parseFloat(value), { shouldValidate: true, shouldDirty: true });
  };

  const isBatchDiabled = () => {
    return (
      watched[OUTPUT_MODEL_TYPE] === FRAMEWORKS.TENSORFLOW_LITE ||
      (isSemanticSegmentation(watched[PROJECT_TASK]) && isNotIntelXeon(watched[DEVICE]))
    );
  };

  const modifiedFrameworks = () => {
    if (isRaspberryPi(targetDevice) || isArmVirtualHardware(targetDevice)) {
      return RASP_FRAMEWORK_VERSIONS;
    }

    if (isJetson(targetDevice)) {
      if (featureFlags(NVIDIA_TAO) && isSemanticSegmentation(watched[PROJECT_TASK])) {
        return TAO_FRAMEWORK_VERSIONS;
      }

      return JETSON_FRAMEWORK_VERSIONS;
    }

    if (isIntelXeon(targetDevice)) {
      return INTELXEON_FRAMEWORK_VERSIONS;
    }

    return FRAMEWORK_VERSIONS;
  };

  useEffect(() => {
    if (watched[DEVICE] && watched[DEVICE].indexOf(DEVICES.JETSON) > -1) {
      setTargetDevice(DEVICES.JETSON);
      setJetsonVersion(watched[DEVICE].substring(7));
    }

    if (watched[DEVICE] && watched[DEVICE].indexOf(RASPBERRY_PI) > -1) {
      setTargetDevice(RASPBERRY_PI);
      setRaspberryVersion(watched[DEVICE].substring(11));
    }

    if (watched[DEVICE] && watched[DEVICE].indexOf(DEVICES.INTEL_XEON) > -1) {
      setTargetDevice(DEVICES.INTEL_XEON);
    }

    if (watched[OUTPUT_DATA_TYPE]) {
      setOutputDataType(watched[OUTPUT_DATA_TYPE]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSemanticSegmentation(watched[PROJECT_TASK])) {
      setTargetDevice(DEVICES.INTEL_XEON);
      setFramework(FRAMEWORKS.OPENVINO);
    } else {
      setTargetDevice(RASPBERRY_PI);
      setRaspberryVersion(FOURB);
      setFramework(FRAMEWORKS.TENSORFLOW_LITE);
      setBatchSize(1);
    }
    setOutputData(OUTPUT_DATA_TYPES.FP16);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watched[PROJECT_TASK]]);

  useEffect(() => {
    if (targetDevice) {
      assignDevice(targetDevice);
    }
  }, [targetDevice, assignDevice]);

  return (
    <>
      <HeaderText className="mb-4" type="formGroup">
        Target device
      </HeaderText>
      <section className="flex flex-row mb-4">
        <div className="w-[200px] mr-4">
          <FormHeaderLabel>Target device *</FormHeaderLabel>
          <div className="flex flex-col">
            <RadioButton
              groupName={DEVICE}
              value={DEVICES.JETSON}
              label={DevicesMap[DEVICES.JETSON]}
              classes="mb-3"
              isChecked={isJetson(targetDevice)}
              onChange={onDeviceChange}
            />
            <RadioButton
              groupName={DEVICE}
              value={DEVICES.RASPBERRY_PI}
              label={DevicesMap[DEVICES.RASPBERRY_PI]}
              classes="mb-3"
              isChecked={isRaspberryPi(targetDevice)}
              isDisabled={isSemanticSegmentation(watched[PROJECT_TASK])}
              onChange={onDeviceChange}
            />
            <RadioButton
              groupName={DEVICE}
              value={DEVICES.INTEL_XEON}
              label={DevicesMap[DEVICES.INTEL_XEON]}
              classes="mb-3"
              isChecked={isIntelXeon(targetDevice)}
              onChange={onDeviceChange}
            />
            <RadioButton
              groupName={DEVICE}
              value={DEVICES.ARM_VIRTUAL_HARDWARE_ETHOS_U_SERIES}
              label={DevicesMap[DEVICES.ARM_VIRTUAL_HARDWARE_ETHOS_U_SERIES]}
              classes="mb-3"
              isChecked={isArmVirtualHardware(targetDevice)}
              isDisabled={isNotObjectDetection(watched[PROJECT_TASK])}
              onChange={onDeviceChange}
            />
          </div>
        </div>
        <div className={`${isJetson(targetDevice) ? 'block' : 'hidden'} w-1/6 pt-6 -mt-1`}>
          <Select value={jetsonVersion} options={JETSON_VERSIONS} onChange={onJetsonVersionChange} />
        </div>
        <div className={`${isRaspberryPi(targetDevice) ? 'block' : 'hidden'} w-1/12 pt-13 -mt-1`}>
          <Select value={raspberryVersion} options={RASPBERRY_PI_VERSIONS} onChange={onRaspberryVersionChange} />
        </div>
      </section>
      <HeaderText className="mb-2" type="formSection">
        Output Format
      </HeaderText>
      <section className="flex flex-row mb-4">
        <div className="w-1/6 mr-6">
          <FormHeaderLabel>Framework *</FormHeaderLabel>
          <Select
            value={watched[OUTPUT_MODEL_TYPE] || framework}
            options={modifiedFrameworks()}
            onChange={onFrameworkChange}
          />
        </div>
        <div
          className={`w-1/6 ${(watched[OUTPUT_MODEL_TYPE] || framework) === FRAMEWORKS.TENSORRT ? 'block' : 'hidden'}`}
        >
          <FormHeaderLabel>SW version *</FormHeaderLabel>
          <Select
            value={watched[OUTPUT_MODEL_VERSION] || swversion}
            options={jetsonTrtVersionOptions(watched[DEVICE])}
            onChange={onSWVersionChange}
          />
        </div>
      </section>
      <section className="mb-4">
        <FormHeaderLabel>Output datatype *</FormHeaderLabel>
        <div>
          <RadioButton
            groupName={OUTPUT_DATA_TYPE}
            value={OUTPUT_DATA_TYPES.FP32}
            label={OUTPUT_DATA_TYPES.FP32.toUpperCase()}
            classes="mr-6"
            isChecked={outputDataType === OUTPUT_DATA_TYPES.FP32}
            onChange={onOutputChange}
            isDisabled={isArmVirtualHardware(targetDevice)}
          />
          <RadioButton
            groupName={OUTPUT_DATA_TYPE}
            value={OUTPUT_DATA_TYPES.FP16}
            label={OUTPUT_DATA_TYPES.FP16.toUpperCase()}
            classes="mr-6"
            isChecked={outputDataType === OUTPUT_DATA_TYPES.FP16}
            onChange={onOutputChange}
            isDisabled={isArmVirtualHardware(targetDevice)}
          />
          <RadioButton
            groupName={OUTPUT_DATA_TYPE}
            value={OUTPUT_DATA_TYPES.INT8}
            label={OUTPUT_DATA_TYPES.INT8.toUpperCase()}
            classes="mr-6"
            isChecked={outputDataType === OUTPUT_DATA_TYPES.INT8}
            onChange={onOutputChange}
            isDisabled={isNotArmVirtualHardware(targetDevice)}
          />
          <RadioButton
            groupName={OUTPUT_DATA_TYPE}
            value={OUTPUT_DATA_TYPES.INT4}
            label={OUTPUT_DATA_TYPES.INT4.toUpperCase()}
            isChecked={outputDataType === OUTPUT_DATA_TYPES.INT4}
            onChange={onOutputChange}
            isDisabled
          />
        </div>
      </section>
      <section className="mb-4">
        <InputTextLabel htmlFor={OUTPUT_BATCH_SIZE}>Inference batch size *</InputTextLabel>
        <InputNumber
          id={OUTPUT_BATCH_SIZE}
          placeholder={PLACEHOLDER_OUTPUT_BATCH_SIZE}
          error={errors[OUTPUT_BATCH_SIZE] ? errors[OUTPUT_BATCH_SIZE].message : ''}
          value={watched[OUTPUT_BATCH_SIZE] || batchSize}
          isDisable={isBatchDiabled()}
          width="w-1/6"
          onChange={onBatchSizeChange}
        />
      </section>
      <InfoContainer>
        <ul className="w-full list-disc flex flex-col ml-4">
          <li className="text-xs text-secondary leading-[18px]">Support range: 1~32.</li>
          <li className="text-xs text-secondary leading-[18px]">
            FRAMEWORKS.TENSORFLOW_LITE only supports batch size 1
          </li>
        </ul>
      </InfoContainer>
    </>
  );
};
