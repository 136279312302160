import React from 'react';

type FormHeaderLabelProps = {
  margin?: string;
  display?: string;
  color?: string;
};

export const FormHeaderLabel: React.FC<FormHeaderLabelProps & React.PropsWithChildren> = ({
  children,
  display = 'block',
  color = 'darkGray',
  margin = 'mb-2',
}) => {
  return <span className={`${display} font-semibold text-sm text-${color} ${margin}`}>{children}</span>;
};
