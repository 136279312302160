import React, { useMemo } from 'react';

import { DeviceNames, ProjectStatus } from '../../../../lib';
import {
  isBenchmarking,
  isBenchmarkingError,
  isCompleted,
  isConfigError,
  isConverting,
  isConvertingError,
  isSubmitted,
  isTraining,
  isTrainingError,
  isTrainingStopped,
} from '../../../../utils';

import { ProjectState, ProjectStatusContainer, ProjectStep } from '../ProjectStatusContainer';

type ProjectStatusDisplayerProps = {
  status: ProjectStatus;
  targetLatency: string;
  targetDevice: DeviceNames;
  onStopProject: React.MouseEventHandler<HTMLButtonElement>;
  onResumeProject: React.MouseEventHandler<HTMLButtonElement>;
};

export const ProjectStatusDisplayer: React.FC<ProjectStatusDisplayerProps> = ({
  status,
  targetLatency,
  targetDevice,
  onStopProject,
  onResumeProject,
}) => {
  const { currentStep, projectState, TitleComponent } = useMemo<{
    currentStep: ProjectStep | null;
    projectState: ProjectState | null;
    TitleComponent: JSX.Element | null;
  }>(() => {
    if (isSubmitted(status)) {
      return {
        currentStep: 'submitted',
        projectState: 'warn',
        TitleComponent: (
          <>
            <div className="flex items-center text-xs">
              The project is waiting to be started. Please click a button to select a training server.
            </div>
            <button
              type="button"
              className="tooltip inline-flex border-2 border-transparent hover:bg-neutralGray-500 active:bg-neutralGray-600 focus:border-2 focus:border-primary-500 rounded-full p-1 text-black"
              onClick={onResumeProject}
            >
              <span className="material-symbols-outlined text-xl/5">replay</span>
              <span className="tooltiptext text-xs font-sans font-semibold min-w-[70px] -ml-[35px]">Start Project</span>
            </button>
          </>
        ),
      };
    }

    if (isConfigError(status) || isTrainingError(status)) {
      return {
        currentStep: 'trainStop',
        projectState: 'error',
        TitleComponent: (
          <>
            <div className="flex items-center text-xs">
              {isConfigError(status) &&
                'Please try again later. The project is temporarily paused while setting up training environment.'}
              {isTrainingError(status) &&
                'Please try again later. The project is temporarily paused due to an unexpected error.'}
            </div>
            <button
              type="button"
              className="tooltip inline-flex border-2 border-transparent hover:bg-neutralGray-500 active:bg-neutralGray-600 focus:border-2 focus:border-primary-500 rounded-full p-1 text-black"
              onClick={onResumeProject}
            >
              <span className="material-symbols-outlined text-xl/5">replay</span>
              <span className="tooltiptext text-xs font-sans font-semibold min-w-[70px] -ml-[35px]">Try Again</span>
            </button>
          </>
        ),
      };
    }

    if (isTrainingStopped(status)) {
      return {
        currentStep: 'trainStop',
        projectState: 'warn',
        TitleComponent: (
          <>
            <div className="flex items-center text-xs">Project is stopped as you requested.</div>
            <button
              type="button"
              className="tooltip inline-flex border-2 border-transparent hover:bg-neutralGray-500 active:bg-neutralGray-600 focus:border-2 focus:border-primary-500 rounded-full p-1 text-black"
              onClick={onResumeProject}
            >
              <span className="material-symbols-outlined text-xl/5">replay</span>
              <span className="tooltiptext text-xs font-sans font-semibold min-w-[70px] -ml-[35px]">Try Again</span>
            </button>
          </>
        ),
      };
    }

    if (isTraining(status)) {
      return {
        currentStep: 'training',
        projectState: 'progress',
        TitleComponent: (
          <>
            <div className="flex items-center text-xs">
              Netspresso is training and optimizing model for target&nbsp;<strong>latency {targetLatency}ms</strong>
              &nbsp;on&nbsp;
              <strong>{targetDevice}.</strong>
            </div>
            <button
              type="button"
              className="tooltip inline-flex border-2 border-transparent hover:bg-neutralGray-500 active:bg-neutralGray-600 focus:border-2 focus:border-primary-500 rounded-full p-1 text-black"
              onClick={onStopProject}
            >
              <span className="material-symbols-outlined text-xl/5">stop_circle</span>
              <span className="tooltiptext text-xs font-sans font-semibold min-w-[70px] -ml-[35px]">Stop</span>
            </button>
          </>
        ),
      };
    }

    if (isConverting(status)) {
      return {
        currentStep: 'converting',
        projectState: 'progress',
        TitleComponent: (
          <p className="flex items-center text-xs">
            Netspresso is training and optimizing model for target&nbsp;<strong>latency {targetLatency}ms</strong>
            &nbsp;on&nbsp;
            <strong>{targetDevice}.</strong>
          </p>
        ),
      };
    }

    if (isConvertingError(status)) {
      return {
        currentStep: 'convertStop',
        projectState: 'error',
        TitleComponent: (
          <>
            <div className="flex items-center text-xs">
              Please try again later. The Project is temporarily paused due to an unexpected error.
            </div>
            <button
              type="button"
              className="tooltip inline-flex border-2 border-transparent hover:bg-neutralGray-500 active:bg-neutralGray-600 focus:border-2 focus:border-primary-500 rounded-full p-1 text-black"
              onClick={onResumeProject}
            >
              <span className="material-symbols-outlined text-xl/5">replay</span>
              <span className="tooltiptext text-xs font-sans font-semibold min-w-[70px] -ml-[35px]">Try Again</span>
            </button>
          </>
        ),
      };
    }

    if (isBenchmarking(status)) {
      return {
        currentStep: 'benchmarking',
        projectState: 'progress',
        TitleComponent: (
          <div className="flex items-center text-xs">
            Netspresso is training and optimizing model for target&nbsp;<strong>latency {targetLatency}ms</strong>
            &nbsp;on&nbsp;
            <strong>{targetDevice}.</strong>
          </div>
        ),
      };
    }

    if (isBenchmarkingError(status)) {
      return {
        currentStep: 'benchmarkStop',
        projectState: 'error',
        TitleComponent: (
          <>
            <div className="flex items-center text-xs">
              Please try again later. The Project is temporarily paused due to an unexpected error.
            </div>
            <button
              type="button"
              className="tooltip inline-flex border-2 border-transparent hover:bg-neutralGray-500 active:bg-neutralGray-600 focus:border-2 focus:border-primary-500 rounded-full p-1 text-black"
              onClick={onResumeProject}
            >
              <span className="material-symbols-outlined text-xl/5">replay</span>
              <span className="tooltiptext text-xs font-sans font-semibold min-w-[70px] -ml-[35px]">Try Again</span>
            </button>
          </>
        ),
      };
    }

    if (isCompleted(status)) {
      return {
        currentStep: 'benchmarkStop',
        projectState: 'completed',
        TitleComponent: (
          <div className="flex items-center text-xs">
            Netspresso completed training a model for &nbsp;<strong>latency {targetLatency}ms</strong>&nbsp;on&nbsp;
            <strong>{targetDevice}.</strong>
          </div>
        ),
      };
    }

    return {
      currentStep: null,
      projectState: null,
      TitleComponent: null,
    };
  }, [status, onResumeProject, onStopProject, targetDevice, targetLatency]);

  if (currentStep !== null && projectState !== null && TitleComponent !== null) {
    return (
      <ProjectStatusContainer projectState={projectState} projectStep={currentStep}>
        {TitleComponent}
      </ProjectStatusContainer>
    );
  }

  return null;
};
