import * as yup from 'yup';

import { FIELD_NAME, FormErrorMessages } from '@netspresso/shared';

import { PACKAGE_NAME, PYTHON_VERSION, MODEL_ID, FILE } from '../constants';

export const PackagingScheme = yup.object({
  [PACKAGE_NAME]: yup
    .string()
    .required(FormErrorMessages.isRequired)
    .min(2, FormErrorMessages.isNotMin(2))
    .max(20, FormErrorMessages.isNotMax(20))
    .matches(FIELD_NAME, FormErrorMessages.isWrongCharacters),
  [PYTHON_VERSION]: yup.string().oneOf(['py38', 'py39']),
  [MODEL_ID]: yup.string().required(FormErrorMessages.isRequired),
  [FILE]: yup.mixed().optional().nullable(),
});

export type PackagingType = yup.InferType<typeof PackagingScheme>;
export const PackagingDefaultValues: PackagingType = {
  [PACKAGE_NAME]: '',
  [PYTHON_VERSION]: 'py38',
  [MODEL_ID]: '',
  [FILE]: null,
};
