import { FRAMEWORKS, OutputModelTypes } from '../types';

export const isOutputModelTypes = (value: unknown): value is OutputModelTypes => {
  switch (value) {
    case FRAMEWORKS.TENSORRT:
    case FRAMEWORKS.TENSORFLOW_LITE:
    case FRAMEWORKS.ETLT:
    case FRAMEWORKS.OPENVINO:
    case FRAMEWORKS.ONNX:
      return true;
    default:
      return false;
  }
};
