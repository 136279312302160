import { Devices, DEVICES } from '../types';

export const isDevices = (value: unknown): value is Devices => {
  switch (value) {
    case DEVICES.RASPBERRY_PI:
    case DEVICES.RASPBERRY_PI_4B:
    case DEVICES.RASPBERRY_PI_3BPLUS:
    case DEVICES.RASPBERRY_PI_2:
    case DEVICES.RASPBERRY_PI_ZEROW:
    case DEVICES.JETSON:
    case DEVICES.JETSON_NANO:
    case DEVICES.JETSON_NX:
    case DEVICES.JETSON_TX2:
    case DEVICES.JETSON_XAVIER:
    case DEVICES.JETSON_AGX_ORIN:
    case DEVICES.INTEL_XEON:
    case DEVICES.ARM_VIRTUAL_HARDWARE_ETHOS_U_SERIES:
      return true;
    default:
      return false;
  }
};
