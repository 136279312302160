import { TaskNames } from '@netspresso/app/src/lib';
import { parseTask } from '@netspresso/app/src/utils';

export const NotificationMessages = {
  creditDeduct: '100 credits will be deducted to start the project.',
  startLaterTitle: 'Submit project and start later',
  startLaterMessage: 'You can start the project when the server is available.',
  startProjectTitle: 'Do you want to start the project?',
  startProjectMessage: (credit = 100) => `${credit} credits will be used.`,
  stopProjectTitle: 'Do you want to stop the project?',
  stopProjectMessage: 'Credits will not be returned, once the project is stopped.',
  notEnoughCredit: 'You need to have at least 50 credits to start the project.',
  creditWillBeGone: (credit = 100) => `${credit} credits will not be returned.\nAre you sure you want to cancel?`,
  resumeProjectMessage: (credit = 100) => `${credit} credits will be used.`,
  resumeProjectTitle: 'Do you want to resume your project?',
  deleteProjectTitle: 'Are you sure you want to delete?',
  creditWillBeReturned: '100 credits will be returned.\nDo you want to cancel?',
  dataUploadHeader: 'Upload dataset?',
  dataUpload: 'Recommendation: Please upload at least 1000 training dataset for model performance.',
  modelUploadHeader: 'Upload model?',
  compressConfirmMessage: 'Do you want to compress?',
  projectStatusMismatchTitle: 'The current status needs to be updated.',
  projectStatusMismatchMessage: 'Please confirm this message to update the status.',
  convertingIsNotAvailable: 'Server is already occupied.\nYou can convert the model when project is completed.',
  noOptionToSelectForTask: (task: TaskNames) => `No option to select for Dataset (${task})`,
};
