import React from 'react';

type InfoTextProps = {
  color?: 'success-active' | 'secondary' | 'warning' | 'danger';
  padding?: string;
  leading?: string;
};

export const InfoText: React.FC<InfoTextProps & React.PropsWithChildren> = ({
  children,
  padding,
  color = 'secondary',
  leading = 'leading-6',
}) => (
  <p className={`text-xs text-${color} ${leading} ${padding}`} data-test="error-msg">
    {children}
  </p>
);
