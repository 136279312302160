import { ObjectValues } from '@netspresso/shared';

export const TASKS = {
  OBJECT_DETECTION: 'object_detection',
  IMAGE_CLASSIFICATION: 'image_classification',
  SEMANTIC_SEGMENTATION: 'semantic_segmentation',
  INSTANCE_SEGMENTATION: 'instance_segmentation',
  PANOPTIC_SEGMENTATION: 'panoptic_segmentation',
  IMAGE_SEGMENTATION: 'image_segmentation',
  OTHER: 'other',
} as const;

export type Tasks = ObjectValues<typeof TASKS>;

export type TasksWithMetric = 'object_detection' | 'image_classification' | 'semantic_segmentation';

export const PROJECT_TASKS = [
  'object_detection',
  'image_classification',
  'semantic_segmentation',
  'image_segmentation',
] as const;

export type ProjectTaskType = (typeof PROJECT_TASKS)[number];

export const TASK_NAMES = [
  'Object Detection',
  'Image Classification',
  'Semantic Segmentation',
  'Instance Segmentation',
  'Panoptic Segmentation',
  'Image Segmentation',
  'Other',
] as const;

export type TaskNames = (typeof TASK_NAMES)[number];
