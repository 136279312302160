import QuickSearchIcon from '@netspresso/components/assets/icons/quick_search.svg';
import React from 'react';

export const QuickSearch: React.FC = () => {
  return (
    <>
      <div className="w-32 h-32 pt-2 pb-1 rounded-[64px] border-2 border-secondary flex flex-col items-center">
        <QuickSearchIcon />
        <h1 className="text-secondary text-center text-sm font-medium leading-4 mt-1">
          Quick
          <br />
          Search
        </h1>
      </div>
      <ul className="list-disc text-secondary mt-6 mb-5 text-m">
        <li>Good performance</li>
        <li>Fast search (1~3days)</li>
        <li>Get only 1 model</li>
      </ul>
      <p className="text-xs text-secondary mb-6">
        Train a model close to your objective based on the validated model architecture recommended by NetsPresso.
      </p>
    </>
  );
};
