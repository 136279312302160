import AdvancedCompressionIcon from '@netspresso/components/assets/icons/adv_compression.svg';
import React from 'react';

export const AdvancedMethod: React.FC = () => {
  return (
    <>
      <div className="w-32 h-32 pt-2 pb-1 rounded-[64px] border-2 border-secondary flex flex-col items-center">
        <AdvancedCompressionIcon />
        <h1 className="text-secondary text-center text-sm font-medium leading-4 mt-1">
          Advanced
          <br />
          Compression
        </h1>
      </div>
      <ul className="list-disc text-secondary mt-4 mb-8 px-6 text-m">
        <li>Model visualization</li>
        <li>Model profiling on the target device</li>
        <li>Structured Pruning and Filter Decomposition</li>
        <li>Compress each layer to achieve the best results</li>
      </ul>
    </>
  );
};
