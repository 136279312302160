import React from 'react';

import { OutlineButton, SubmitButton } from '@netspresso/components';

type PromptProps = {
  headerMessage?: string;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
  message?: string;
};

export const Prompt: React.FC<PromptProps> = ({ headerMessage, onClose, onSubmit, message = '' }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6">
      {headerMessage && <h1 className="text-secondary-800 text-xl font-title font-bold mb-4">{headerMessage}</h1>}
      {message && <p className="text-secondary-800 text-m mb-4">{message}</p>}
      <div className="flex justify-end pt-2">
        <OutlineButton className="mr-3" onClick={onClose}>
          Cancel
        </OutlineButton>
        <SubmitButton type="button" onClick={onSubmit}>
          Confirm
        </SubmitButton>
      </div>
    </div>
  );
};
