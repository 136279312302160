import { ObjectValues } from '@netspresso/shared';

export const MODEL_FORMATS = {
  H5: 'h5',
  SAVED_MODEL: 'saved_model',
  OPENVINO: 'openvino',
  ETLT: 'etlt',
  TENSORFLOW_LITE: 'tensorflow_lite',
  TENSORRT: 'tensorrt',
  PYTORCH: 'pytorch',
  GRAPHMODULE_PT: 'graphmodule_pt',
  ONNX: 'onnx',
} as const;

export const OUTPUT_MODEL_FILES = ['h5', 'zip', 'tflite', 'trt', 'pt', 'pt', 'onnx'] as const;

export type OutputModelFileNames = (typeof OUTPUT_MODEL_FILES)[number];

export type ModelFormats = ObjectValues<typeof MODEL_FORMATS>;

export const ModelFormatsMap: Record<ModelFormats, OutputModelFileNames> = {
  h5: 'h5',
  saved_model: 'zip',
  openvino: 'zip',
  etlt: 'zip',
  tensorflow_lite: 'tflite',
  tensorrt: 'trt',
  pytorch: 'pt',
  graphmodule_pt: 'pt',
  onnx: 'onnx',
};
