import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';

export const CookieConsent: React.FC = () => {
  const initGTM = () => {
    if (process.env.REACT_APP_GTM_ID) {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID || '',
      };

      TagManager.initialize(tagManagerArgs);
    }
  };

  useEffect(() => {
    initGTM();
  }, []);

  return <></>;
};
