import React from 'react';
import { useLoaderContext, useModalContext, useSideNavContext } from '../../../context';

export const Main: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [{ isLoading }] = useLoaderContext();
  const [{ showModal }] = useModalContext();
  const [{ isExpand }] = useSideNavContext();

  return (
    <main
      className={`np-container ${isExpand ? 'expand' : 'shrink'} ${
        isLoading || showModal ? 'overflow-hidden' : 'overflow-y-auto'
      }`}
    >
      {children}
    </main>
  );
};
