import { CardContainer, TaskTag } from '@netspresso/components';
import DeleteIcon from '@netspresso/components/assets/icons/delete.svg';
import NPLogo from '@netspresso/components/assets/logos/np_bw.svg';
import { apiClient, API_DATASET_V1, parseDate } from '@netspresso/shared';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LEVEL_DANGER, LEVEL_SUCCESS, Toast, useNotificationContext } from '../../../../components/Notifications';
import { Prompt } from '../../../../components/Prompt';
import { useDatasetsContext, useLoaderContext, useModalContext } from '../../../../context';
import { NumImages, Tasks } from '../../../../lib';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { parseTask } from '../../../../utils';

type DatasetCardProps = {
  datasetUid: string;
  datasetName: string;
  datasetTask: Tasks;
  createdAt?: string;
  classNames?: string[];
  numImages?: NumImages;
  isSample?: boolean;
};

export const DatasetCard: React.FC<DatasetCardProps> = ({
  datasetUid,
  datasetName,
  datasetTask,
  createdAt = '',
  classNames,
  numImages,
  isSample = false,
}) => {
  // const nameRef = useRef<HTMLHeadingElement>(null);

  // const [nameMargin, setNameMargin] = useState('mb-12');
  // const [sampleNameMargin, setSampleNameMargin] = useState('mb-18');
  const navigate = useNavigate();
  const [, dispatchModal] = useModalContext();
  const [, dispatchLoading] = useLoaderContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const [, dispatch] = useDatasetsContext();

  const reload = async () => {
    const res = await apiClient.get(API_DATASET_V1);

    dispatch({ type: 'ready', payload: res.data });
  };

  const onCreateProject: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    navigate(`/projects/create_a_new_project/dataset/${datasetUid}`);
  };

  const onClosePrompt: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const onSubmitPrompt: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();

    try {
      dispatchModal({ type: ModalActions.Hide });
      dispatchLoading({ type: LoaderActions.Show });
      const res = await apiClient.delete(`${API_DATASET_V1}/${datasetUid}`);

      if (res.status === 200) {
        reload();
        showToast(
          <Toast
            content="The dataset is successfully deleted."
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      showToast(
        <Toast
          content="Requesting to delete failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const onDeleteClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({
      type: ModalActions.Show,
      payload: <Prompt message="Are you sure you want to delete?" onClose={onClosePrompt} onSubmit={onSubmitPrompt} />,
    });
  };

  const isDisabledToSeeDetail = () => {
    return !classNames || !classNames.length;
  };

  return (
    <CardContainer key={datasetUid}>
      <div className="relative [h-392px] bg-white rounded-xl shadow mb-6 px-6 py-7" data-testid="dataset-card">
        <div className="flex items-center justify-between mb-5">
          <TaskTag>{parseTask(datasetTask)}</TaskTag>
          {isSample ? (
            <div className="flex flex-row items-center absolute top-6 right-6">
              <NPLogo />
              <span className="ml-1 text-darkGray text-xs select-none" data-testid="is-sample-data">
                Sample data
              </span>
            </div>
          ) : (
            <button type="button" onClick={onDeleteClick}>
              <DeleteIcon />
            </button>
          )}
        </div>
        <h1 className="text-neutralBlack-500 hover:text-neutralBlack-400 font-medium text-xl mb-5 whitespace-nowrap text-ellipsis overflow-hidden w-full">
          {isDisabledToSeeDetail() ? datasetName : <Link to={`/datasets/details/${datasetUid}`}>{datasetName}</Link>}
        </h1>
        {isSample ? null : (
          <p className="text-xs font-normal text-neutralBlack-300 mb-6 pb-6 border-b border-neutralGray-200">
            Created : {parseDate(createdAt)}
          </p>
        )}

        <section className="flex flex-col space-y-3 tracking-normal mb-6">
          <div>
            <span className="inline-block bg-neutralGray-200 border border-neutralGray-300 rounded-full drop-shadow-sm px-3 py-1.5">
              <div className="font-medium text-xs text-neutralBlack-500">
                Class
                <span className="text-primary-500 ml-1">
                  {classNames && classNames.length ? classNames.length : 'N/A'}
                </span>
              </div>
            </span>
          </div>
          <div className="flex space-x-3">
            <span className="inline-block bg-neutralGray-200 border border-neutralGray-300 rounded-full drop-shadow-sm px-3 py-1.5">
              <div className="font-medium text-xs text-neutralBlack-500">
                Total dataset
                <span className="text-primary-500 ml-1">
                  {numImages ? numImages.test + numImages.val + numImages.train : 'N/A'}
                </span>
              </div>
            </span>
            <span className="inline-block bg-neutralGray-200 border border-neutralGray-300 rounded-full drop-shadow-sm px-3 py-1.5">
              <div className="font-medium text-xs text-neutralBlack-500">
                Training
                <span className="text-primary-500 ml-1">{numImages ? numImages.train : 'N/A'}</span>
              </div>
            </span>
          </div>
          <div className="flex space-x-3">
            <span className="inline-block bg-neutralGray-200 border border-neutralGray-300 rounded-full drop-shadow-sm px-3 py-1.5">
              <div className="font-medium text-xs text-neutralBlack-500">
                Validation
                <span className="text-primary-500 ml-1">{numImages ? numImages.val : 'N/A'}</span>
              </div>
            </span>
            <span className="inline-block bg-neutralGray-200 border border-neutralGray-300 rounded-full drop-shadow-sm px-3 py-1.5">
              <div className="font-medium text-xs text-neutralBlack-500">
                Testing
                <span className="text-primary-500 ml-1">{numImages ? numImages.test : 'N/A'}</span>
              </div>
            </span>
          </div>
        </section>

        <button
          type="button"
          className="inline-flex items-center justify-center w-full border border-neutralBlack-500 rounded text-sm text-neutralBlack-500 active:text-neutralBlack-300 bg-white hover:bg-neutralGray-100 disabled:pointer-events-none disabled:text-neutralBlack-100 disabled:border-neutralBlack-100 py-2"
          data-testid="create-project-btn"
          onClick={onCreateProject}
        >
          New Project<span className="material-symbols-outlined text-base ml-2">arrow_right_alt</span>
        </button>
      </div>
    </CardContainer>
  );
};
