export const PLACEHOLDER_DATASET_NAME = 'Dataset name';
export const PLACEHOLDER_DATASET_MEMO = 'Simple explanation for this dataset';
export const PLACEHOLDER_PROJECT_NAME = 'Project name';
export const PLACEHOLDER_PROJECT_MEMO = 'Simple explanation for this project';
export const PLACEHOLDER_PACKAGE_NAME = 'Package name';
export const PLACEHOLDER_EVALUATION_METRIC = 'Evaluation metric';
export const PLACEHOLDER_METRIC_VALUE = 'Evaluation metric value';
export const PLACEHOLDER_EMAIL = 'Input your email';
export const PLACEHOLDER_MODEL_NAME = 'Model name';
export const PLACEHOLDER_MODEL_MEMO = 'Simple explanation for this model';
export const PLACEHOLDER_NEW_PASSWORD = 'Input new password';
export const PLACEHOLDER_CONFIRM_PASSWORD = 'Input your password again';
export const PLACEHOLDER_OUTPUT_BATCH_SIZE = 'Support range: 1~32';
export const PLACEHOLDER_TARGET_LATENCY = 'Support range: 10~5000';
export const PLACEHOLDER_AWS_S3 = 'https://s3.xxxx.aws.amazone.com/';
