import React, { useState, useEffect } from 'react';
import { useFormState } from 'react-hook-form';

import { InputTextLabel, TextArea, TextInput } from '@netspresso/components';
import { PLACEHOLDER_DATASET_NAME, PLACEHOLDER_DATASET_MEMO } from '@netspresso/shared';

import { useDatasetsFormContext, useAuthContext } from '../../../../../../context';
import { AUTH, DATASET_NAME, DESCRIPTION } from '../../../../../../constants';

export const DatasetSetting: React.FC = () => {
  const { user } = useAuthContext();
  const [datasetName, setDatasetName] = useState('');
  const [datasetMemo, setDatasetMemo] = useState('');
  const { setValue, control } = useDatasetsFormContext();
  const { errors } = useFormState({ control });

  useEffect(() => {
    if (user) {
      setValue(AUTH, user.authorities[1]);
    }
  }, [user, setValue]);

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setDatasetName(value);
    setValue(DATASET_NAME, value, { shouldValidate: true, shouldDirty: true });
  };

  const onMemoChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const { value } = event.target;

    setDatasetMemo(value);
    setValue(DESCRIPTION, value, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <>
      <section className="mb-3" data-test="dataset-name-input">
        <InputTextLabel htmlFor={DATASET_NAME}>Dataset name *</InputTextLabel>
        <TextInput
          id={DATASET_NAME}
          placeholder={PLACEHOLDER_DATASET_NAME}
          error={errors[DATASET_NAME] ? errors[DATASET_NAME].message : ''}
          value={datasetName}
          width="w-1/2"
          onChange={onNameChange}
        />
      </section>
      <section className="mb-8" data-test="dataset-memo-input">
        <InputTextLabel htmlFor={DESCRIPTION}>Memo</InputTextLabel>
        <TextArea
          id={DESCRIPTION}
          error={errors[DESCRIPTION]?.message}
          placeholder={PLACEHOLDER_DATASET_MEMO}
          value={datasetMemo}
          onChange={onMemoChange}
        />
      </section>
    </>
  );
};
