import React, { createContext, useContext } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ConvertModelScheme, ConvertModelsDefaultValues, ConvertModelType } from '../schemes';

export const ConvertModelFormContext = createContext<UseFormReturn<ConvertModelType> | null>(null);

export const ConvertModelFormContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const convertModelForm = useForm({
    defaultValues: ConvertModelsDefaultValues,
    mode: 'onBlur',
    resolver: yupResolver(ConvertModelScheme),
  });

  return <ConvertModelFormContext.Provider value={convertModelForm}>{children}</ConvertModelFormContext.Provider>;
};

export const useConvertModelFormContext = (): UseFormReturn<ConvertModelType> => {
  const context = useContext(ConvertModelFormContext);

  if (!context) {
    throw new Error('useConvertModelFormContext must be used within a ConvertModelFormContextProvider');
  }

  return context;
};
