import React from 'react';

type HyperParameterDisplayProps = {
  label: string;
  dataTestId?: string;
};

export const HyperParameterDisplay: React.FC<HyperParameterDisplayProps & React.PropsWithChildren> = ({
  label,
  children,
  dataTestId = 'hyper-parameter-display',
}) => {
  return (
    <li className="flex flex-row space-x-4 text-normal text-darkGray">
      <span className="w-[150px]">{label}</span>
      <span data-testid={dataTestId}>{children}</span>
    </li>
  );
};
