import { TaskNames, Tasks, TasksWithMetric } from '@netspresso/app/src/lib';

export const OBJECT_DETECTION = 'object_detection';
export const IMAGE_CLASSIFICATION = 'image_classification';
export const SEMANTIC_SEGMENTATION = 'semantic_segmentation';
export const INSTANCE_SEGMENTATION = 'instance_segmentation';
export const PANOPTIC_SEGMENTATION = 'panoptic_segmentation';
export const IMAGE_SEGMENTATION = 'image_segmentation';
export const OTHER = 'other';

export const TasksMetricKeys = {
  image_classification: 'valid_accuracy',
  object_detection: 'metrics/mAP_0.5',
  semantic_segmentation: 'valid_miou %',
};

export const TasksMetric = {
  image_classification: 'Top-1 Accuracy (%)',
  object_detection: 'mAP[0.5]',
  semantic_segmentation: 'mIoU (%)',
};

export const TasksMap: Record<Tasks, TaskNames> = {
  [OBJECT_DETECTION]: 'Object Detection',
  [IMAGE_CLASSIFICATION]: 'Image Classification',
  [SEMANTIC_SEGMENTATION]: 'Semantic Segmentation',
  [INSTANCE_SEGMENTATION]: 'Instance Segmentation',
  [PANOPTIC_SEGMENTATION]: 'Panoptic Segmentation',
  [IMAGE_SEGMENTATION]: 'Image Segmentation',
  [OTHER]: 'Other',
};

export const TasksMetricMap: Record<TasksWithMetric, string> = {
  [IMAGE_CLASSIFICATION]: 'valid_accuracy',
  [OBJECT_DETECTION]: 'metrics/mAP_0.5',
  [SEMANTIC_SEGMENTATION]: 'valid_miou %',
};
