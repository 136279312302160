import { OutlineButton, SubmitButton } from '@netspresso/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Forbidden: React.FC = () => {
  const navigate = useNavigate();

  const onClickBack: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    navigate(-1);
  };

  const onClickHome: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    window.location.assign(process.env.REACT_APP_PORTAL_URL as string);
  };

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-[518px] bg-white shadow-gt overflow-hidden rounded-lg pb-8">
        <div className="border-t border-gray-200 text-center pt-4">
          <h1 className="text-9xl font-bold font-title text-darkGray">
            4<span className="text-primary">0</span>3
          </h1>
          <p className="text-2xl mb-12 font-bold font-title font-medium">Access denied</p>
          <div className="flex justify-center mb-4">
            <OutlineButton className="mr-3" onClick={onClickBack}>
              Back
            </OutlineButton>
            <SubmitButton onClick={onClickHome}>Home</SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
};
