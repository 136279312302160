export const CONTACT_LINK = 'mailto:netspresso@nota.ai';
export const SURVEY_LINK = 'https://notaai.typeform.com/to/vtDDejt5';
export const MAIL_LINK = 'mailto:netspresso@nota.ai';
export const FORUM_LINK = 'https://docs.netspresso.ai/';
export const DOC_LINK = 'https://docs.netspresso.ai/';
export const DISCUSSION_LINK = 'https://github.com/Nota-NetsPresso/Discussion/discussions';
export const VALIDATOR_LINK = 'https://github.com/Nota-NetsPresso/NetsPresso-Model-Searcher-Dataset-Validator';
export const VALIDATOR_WINDOW_LINK =
  'https://modelsearch-validator.s3.us-east-2.amazonaws.com/dataset_validator(windows).zip';
export const VALIDATOR_INTEL_LINK =
  'https://modelsearch-validator.s3.us-east-2.amazonaws.com/dataset_validator(apple_intel).tgz';
export const VALIDATOR_M1_LINK =
  'https://modelsearch-validator.s3.us-east-2.amazonaws.com/dataset_validator(apple_silicon).tgz';
export const VALIDATOR_MAC_LINK =
  'https://modelsearch-validator.s3.us-east-2.amazonaws.com/dataset_validator(apple_universal).zip';
export const VALIDATOR_LINUX_LINK =
  'https://modelsearch-validator.s3.us-east-2.amazonaws.com/dataset_validator(ubuntu).zip';
export const VALIDATOR_LOCAL_DATASET_GUIDE_LINK =
  'https://github.com/Nota-NetsPresso/NetsPresso-Model-Searcher-Dataset-Validator';
export const RUNTIME_SAMPLE_LINK = 'https://github.com/Nota-NetsPresso/NetsPresso-Model-Launcher-Package';
export const SAMPLE_FILE_LINK = 'https://github.com/Nota-NetsPresso/NetsPresso-ModelSearch-Dataset-Validator';
export const DATA_YAML_LINK =
  'https://github.com/Nota-NetsPresso/NetsPresso-ModelSearch-Dataset-Validator/blob/main/data.yaml';
export const RUNTIME_LINK = 'https://github.com/Nota-NetsPresso/NetsPresso-ModelSearch-Runtime';
export const AWS_S3_LINK = 'https://aws.amazon.com/premiumsupport/knowledge-center/read-access-objects-s3-bucket';
export const PRIVACY_POLICY_LINK = 'https://nota.ai/en/policy.php';
export const CREDIT_LINK = 'https://docs.netspresso.ai/docs/credit';
export const MODEL_ZOO_LINK = 'https://github.com/Nota-NetsPresso/NetsPresso-Model-Compressor-ModelZoo';
