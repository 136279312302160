export const removeNull = (obj: Record<string, unknown>) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });

  return obj;
};
