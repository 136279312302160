/* eslint-disable camelcase */
import { Devices, OutputDataTypes, OutputModelTypes, ProjectTaskType } from '../../lib';
import { ProjectType } from '../../schemes';

type RecommendationPayload = {
  device: Devices;
  task: ProjectTaskType;
  img_size_width: number;
  img_size_height: number;
  img_size_channel: number;
  output_batch_size: number;
  output_model_type: OutputModelTypes;
  output_data_type?: OutputDataTypes;
  target_latency?: number;
};

export const convertToRecommendationPayload = ({
  device,
  task,
  base_img_size,
  output_batch_size,
  output_model_type,
  output_data_type,
  target_latency,
}: ProjectType): RecommendationPayload => {
  return {
    device,
    task,
    img_size_width: base_img_size[0],
    img_size_height: base_img_size[1],
    img_size_channel: 3,
    output_batch_size,
    output_model_type,
    output_data_type,
    target_latency,
  };
};
