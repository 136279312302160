import { FD_CP, FD_SVD, FD_TK, PR_GM, PR_ID, PR_L2, PR_NN } from '../../../constants';

export const COMPRESSION_METHODS = [PR_L2, PR_GM, PR_NN, PR_ID, FD_TK, FD_SVD, FD_CP] as const;

export const COMPRESSION_METHODS_WITH_RECOMMENDATIONS = [PR_L2, PR_GM, PR_NN, FD_TK, FD_SVD] as const;

export type CompressionMethod = (typeof COMPRESSION_METHODS)[number];

export type CompressionType = 'automaticCompression' | 'advancedCompression';

export type CompressionMethodWithRecommendation = (typeof COMPRESSION_METHODS_WITH_RECOMMENDATIONS)[number];

export const CompressionMethodsMap: Record<CompressionMethod, string> = {
  [PR_L2]: 'L2 Norm Pruning',
  [PR_GM]: 'GM Pruning',
  [PR_NN]: 'Nuclear Norm Pruning',
  [PR_ID]: 'Pruning By Index',
  [FD_TK]: 'Tucker Decomposition',
  [FD_SVD]: 'Singular Value Decomposition',
  [FD_CP]: 'CP Decomposition',
};
