import { CUTMIX_ALPHA, MIXUP, MIXUP_ALPHA, MIXUP_SWITCH_PROB } from '@netspresso/shared';
import React from 'react';
import { AUGMENTATION_DETAILS } from '../../../../../../constants';
import { parseHypPlaceholderCls, parseHypTooltip } from '../../../../../../utils';

import { HyperParameterInput } from '../../../HyperParameterInput';

export const ClassificationParams: React.FC = () => {
  return (
    <>
      <section className="flex flex-row">
        <HyperParameterInput
          paramName={MIXUP}
          groupName={AUGMENTATION_DETAILS}
          className="pr-9"
          tooltipMsg={parseHypTooltip(MIXUP)}
          placeholder={parseHypPlaceholderCls(MIXUP)}
        />
        <HyperParameterInput
          paramName={MIXUP_ALPHA}
          groupName={AUGMENTATION_DETAILS}
          className="px-9"
          tooltipMsg={parseHypTooltip(MIXUP_ALPHA)}
          placeholder={parseHypPlaceholderCls(MIXUP_ALPHA)}
        />
        <HyperParameterInput
          paramName={CUTMIX_ALPHA}
          groupName={AUGMENTATION_DETAILS}
          className="pl-9"
          tooltipMsg={parseHypTooltip(CUTMIX_ALPHA)}
          placeholder={parseHypPlaceholderCls(CUTMIX_ALPHA)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={MIXUP_SWITCH_PROB}
          groupName={AUGMENTATION_DETAILS}
          className="pr-9"
          tooltipMsg={parseHypTooltip(MIXUP_SWITCH_PROB)}
          placeholder={parseHypPlaceholderCls(MIXUP_SWITCH_PROB)}
        />
      </section>
    </>
  );
};
