import { ObjectValues } from '@netspresso/shared';

export const FRAMEWORKS = {
  TENSORRT: 'tensorrt',
  TENSORFLOW_LITE: 'tensorflow_lite',
  TENSORFLOW_TAO: 'tensorflow_tao',
  PYTORCH_TAO: 'pytorch_tao',
  ETLT: 'etlt',
  OPENVINO: 'openvino',
  TENSORFLOW_KERAS: 'tensorflow_keras',
  ONNX: 'onnx',
  PYTORCH: 'pytorch',
} as const;

export type Frameworks = ObjectValues<typeof FRAMEWORKS>;

export type OutputModelTypes = 'tensorrt' | 'tensorflow_lite' | 'onnx' | 'etlt' | 'openvino';

export type UploadableModelTypes =
  | 'tensorflow_keras'
  | 'pytorch'
  | 'onnx'
  | 'openvino'
  | 'tensorflow_lite'
  | 'tensorrt';

export type FrameworkNames =
  | 'TensorRT'
  | 'TensorFlow Lite'
  | 'NVIDIA-TAO'
  | 'NVIDIA-TAO'
  | 'NVIDIA-TAO'
  | 'OpenVINO'
  | 'TensorFlow-Keras'
  | 'ONNX'
  | 'Pytorch';

export const FrameworksMap: Record<Frameworks, FrameworkNames> = {
  [FRAMEWORKS.TENSORRT]: 'TensorRT',
  [FRAMEWORKS.TENSORFLOW_LITE]: 'TensorFlow Lite',
  [FRAMEWORKS.TENSORFLOW_TAO]: 'NVIDIA-TAO',
  [FRAMEWORKS.PYTORCH_TAO]: 'NVIDIA-TAO',
  [FRAMEWORKS.ETLT]: 'NVIDIA-TAO',
  [FRAMEWORKS.OPENVINO]: 'OpenVINO',
  [FRAMEWORKS.TENSORFLOW_KERAS]: 'TensorFlow-Keras',
  [FRAMEWORKS.ONNX]: 'ONNX',
  [FRAMEWORKS.PYTORCH]: 'Pytorch',
};
