import { ReactElement, useState } from 'react';

export type NotificactionType = {
  isVisible: boolean;
  showToast: (content: ReactElement) => void;
  hideToast: VoidFunction;
  onClickToastHandler: React.MouseEventHandler<HTMLButtonElement>;
  notifyContent: ReactElement | null;
};

export const useNotify = (): NotificactionType => {
  const [isVisible, setIsVisible] = useState(false);
  const [notifyContent, setNotifyContent] = useState<ReactElement | null>(null);

  const showToast = (content: ReactElement) => {
    if (content) {
      setIsVisible(true);
      setNotifyContent(content);
    }
  };

  const hideToast = () => {
    setIsVisible(false);
    setNotifyContent(null);
  };

  const onClickToastHandler: React.MouseEventHandler<HTMLButtonElement> = (_event) => {
    hideToast();
  };

  return { isVisible, showToast, hideToast, onClickToastHandler, notifyContent };
};
