import React, { ChangeEventHandler } from 'react';

type InputNumberProps = {
  id: string;
  width: string;
  className?: string;
  error?: string;
  isDisable?: boolean;
  placeholder?: string;
  padding?: string;
  min?: number;
  max?: number;
  value?: number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const InputNumber: React.FC<InputNumberProps> = ({
  className,
  id,
  width,
  min,
  max,
  value,
  padding = 'px-4 py-1',
  error = '',
  placeholder = '',
  isDisable = false,
  onChange,
}) => {
  return (
    <div className={width}>
      <input
        id={id}
        name={id}
        className={`${
          error ? 'border-2 border-danger focus:border-danger' : 'border border-defaultGray focus:border-secondary'
        } rounded w-full ${padding} placeholder-defaultGray-300 focus:outline-none disabled:bg-lineGray disabled:text-defaultGray ${className}`}
        type="number"
        placeholder={placeholder}
        value={value}
        min={min}
        max={max}
        onChange={onChange}
        disabled={isDisable}
      />
      <span className={`${error ? 'block' : 'hidden'} text-xs text-danger`}>{error}</span>
    </div>
  );
};
