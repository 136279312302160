import { InfoContainer, InfoText } from '@netspresso/components';
import React from 'react';
import { PR_NN } from '../../../../../../../../constants';
import { CompressionMethod, CompressModel } from '../../../../../../../../lib';
import { getInputLayerArrayFromModel, getModelShapeString } from '../../../../../../../../utils';

type MethodInfosProps = {
  method: CompressionMethod | '';
  baseModel: CompressModel | null;
};

export const MethodInfos: React.FC<MethodInfosProps> = ({ method, baseModel }) => {
  return (
    <InfoContainer className="mt-6">
      {method === PR_NN ? (
        <InfoText color="secondary" leading="leading-[18px]">
          This method requires a dataset(<strong>.npy file format with 15MB limited</strong>)<br />
          Model Input Shape:&nbsp;
          {baseModel
            ? `Numpy array with shape ${getModelShapeString(getInputLayerArrayFromModel(baseModel), 'N')}`
            : 'unknown'}
        </InfoText>
      ) : (
        <InfoText color="secondary">
          Using filter decomposition may reduce or increase the model size depending on your configuration.
        </InfoText>
      )}
    </InfoContainer>
  );
};
