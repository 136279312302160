import {
  ANCHOR_T,
  BOX,
  CLS,
  CLS_PW,
  FL_GAMMA,
  IOU_T,
  LR0,
  LRF,
  MOMENTUM,
  OBJ,
  OBJ_PW,
  WARMUP_BIAS_LR,
  WARMUP_EPOCHS,
  WARMUP_MOMENTUM,
  WEIGHT_DECAY,
} from '@netspresso/shared';
import { HYP_DETAIL } from '../../../../../../constants';
import { parseHypPlaceholderDet, parseHypTooltip } from '../../../../../../utils';
import { HyperParameterInput } from '../../../HyperParameterInput';

export const DetectionParams = () => {
  return (
    <>
      <section className="flex flex-row">
        <HyperParameterInput
          paramName={LR0}
          groupName={HYP_DETAIL}
          className="pr-9"
          tooltipMsg={parseHypTooltip(LR0)}
          placeholder={parseHypPlaceholderDet(LR0)}
        />
        <HyperParameterInput
          paramName={LRF}
          groupName={HYP_DETAIL}
          className="px-9"
          tooltipMsg={parseHypTooltip(LRF)}
          placeholder={parseHypPlaceholderDet(LRF)}
        />
        <HyperParameterInput
          paramName={MOMENTUM}
          groupName={HYP_DETAIL}
          className="pl-9"
          tooltipMsg={parseHypTooltip(MOMENTUM)}
          placeholder={parseHypPlaceholderDet(MOMENTUM)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={WEIGHT_DECAY}
          groupName={HYP_DETAIL}
          className="pr-9"
          tooltipMsg={parseHypTooltip(WEIGHT_DECAY)}
          placeholder={parseHypPlaceholderDet(WEIGHT_DECAY)}
        />
        <HyperParameterInput
          paramName={WARMUP_EPOCHS}
          groupName={HYP_DETAIL}
          className="px-9"
          tooltipMsg={parseHypTooltip(WARMUP_EPOCHS)}
          placeholder={parseHypPlaceholderDet(WARMUP_EPOCHS)}
        />
        <HyperParameterInput
          paramName={WARMUP_MOMENTUM}
          groupName={HYP_DETAIL}
          className="pl-9"
          tooltipMsg={parseHypTooltip(WARMUP_MOMENTUM)}
          placeholder={parseHypPlaceholderDet(WARMUP_MOMENTUM)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={WARMUP_BIAS_LR}
          groupName={HYP_DETAIL}
          className="pr-9"
          tooltipMsg={parseHypTooltip(WARMUP_BIAS_LR)}
          placeholder={parseHypPlaceholderDet(WARMUP_BIAS_LR)}
        />
        <HyperParameterInput
          paramName={BOX}
          groupName={HYP_DETAIL}
          className="px-9"
          tooltipMsg={parseHypTooltip(BOX)}
          placeholder={parseHypPlaceholderDet(BOX)}
        />
        <HyperParameterInput
          paramName={CLS}
          groupName={HYP_DETAIL}
          className="pl-9"
          tooltipMsg={parseHypTooltip(CLS)}
          placeholder={parseHypPlaceholderDet(CLS)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={CLS_PW}
          groupName={HYP_DETAIL}
          className="pr-9"
          tooltipMsg={parseHypTooltip(CLS_PW)}
          placeholder={parseHypPlaceholderDet(CLS_PW)}
        />
        <HyperParameterInput
          paramName={OBJ}
          groupName={HYP_DETAIL}
          className="px-9"
          tooltipMsg={parseHypTooltip(OBJ)}
          placeholder={parseHypPlaceholderDet(OBJ)}
        />
        <HyperParameterInput
          paramName={OBJ_PW}
          groupName={HYP_DETAIL}
          className="pl-9"
          tooltipMsg={parseHypTooltip(OBJ_PW)}
          placeholder={parseHypPlaceholderDet(OBJ_PW)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={IOU_T}
          groupName={HYP_DETAIL}
          className="pr-9"
          tooltipMsg={parseHypTooltip(IOU_T)}
          placeholder={parseHypPlaceholderDet(IOU_T)}
        />
        <HyperParameterInput
          paramName={ANCHOR_T}
          groupName={HYP_DETAIL}
          className="px-9"
          tooltipMsg={parseHypTooltip(ANCHOR_T)}
          placeholder={parseHypPlaceholderDet(ANCHOR_T)}
        />
        <HyperParameterInput
          paramName={FL_GAMMA}
          groupName={HYP_DETAIL}
          className="pl-9"
          tooltipMsg={parseHypTooltip(FL_GAMMA)}
          placeholder={parseHypPlaceholderDet(FL_GAMMA)}
        />
      </section>
    </>
  );
};
