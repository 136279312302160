import { FormHeaderLabel, HeaderText, InputDecimalNumber } from '@netspresso/components';
import { RECOMMENDATION_RATIO } from '../../../../../../constants';
import { useCompressFormContext } from '../../../../../../context';

export const CompressionRatio = () => {
  const {
    compressForm: { setValue, watch },
  } = useCompressFormContext();

  const watched = watch();

  const handleChangeRatio: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setValue(RECOMMENDATION_RATIO, parseFloat(value), { shouldDirty: true, shouldValidate: true });
  };

  const setRecommendationRatio = (value: string) => {
    setValue(RECOMMENDATION_RATIO, parseFloat(value), { shouldDirty: true, shouldValidate: true });
  };

  return (
    <>
      <HeaderText className="mb-4" type="formGroup">
        Compression options
      </HeaderText>
      <section className="mb-4">
        <FormHeaderLabel>Compression ratio *</FormHeaderLabel>
        <div className="flex flex-row">
          <input
            className="block w-1/3"
            type="range"
            min="0.01"
            step="0.01"
            max="1"
            value={watched[RECOMMENDATION_RATIO]}
            onChange={handleChangeRatio}
          />
          <InputDecimalNumber
            id={RECOMMENDATION_RATIO}
            margin="ml-2"
            min={0.01}
            max={1}
            value={`${watched[RECOMMENDATION_RATIO]}`}
            setValue={setRecommendationRatio}
          />
        </div>
      </section>
      <section className="flex items-start p-2 rounded bg-secondary-50 mb-6">
        <span className="material-icons mr-2 text-secondary">error_outline</span>
        <ul className="w-full list-disc flex flex-col ml-4">
          <li className="text-xs text-secondary leading-[18px]">0 &lt;= Ratio (float) &lt;= 1</li>
          <li className="text-xs text-secondary leading-[18px]">
            Compression ratio means the removal of corresponding amounts of the filters considering layer-wise
            importance (e.g. 0.2 removes 20% of the filters in a model).
          </li>
          <li className="text-xs text-secondary font-bold leading-[18px]">
            Compressed model needs to be retrained to recover the accuracy.
          </li>
        </ul>
      </section>
    </>
  );
};
