/* eslint-disable camelcase */
import React, { createContext, useContext, useState } from 'react';
import { Node } from 'react-flow-renderer';

export interface CompressNode extends Node {
  values: {
    name: string;
    layer_class_name: string;
    input_shape: string;
    output_shape: string;
  };
  data?: {
    label: React.ReactElement;
  };
  style?: {
    padding: number;
    border: string;
    width: number;
  };
  isAvailable?: boolean;
  animated?: boolean;
}

type NetworkLayerContextType = [CompressNode | null, React.Dispatch<React.SetStateAction<CompressNode | null>>];

export const NetworkLayerContext = createContext<NetworkLayerContextType | null>(null);

export const NetworkLayerContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [selectedLayer, setSelectedLayer] = useState<CompressNode | null>(null);

  return (
    <NetworkLayerContext.Provider value={[selectedLayer, setSelectedLayer]}>{children}</NetworkLayerContext.Provider>
  );
};

export const useNetworkLayerContext = () => {
  const context = useContext(NetworkLayerContext);

  if (!context) {
    throw new Error('useNetworkLayerContext must be used within a NetworkLayerContextProvider');
  }

  return context;
};
