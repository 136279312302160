import { HeaderText, SubmitButton } from '@netspresso/components';
import React from 'react';
import { CompressModel } from '../../../../../../lib';
import { downloadCSV } from '../../../../../../utils';
import { Magnification } from '../Magnification';

type ModelPerformanceProps = {
  baseModel: CompressModel;
  compressedModel: CompressModel;
};

export const ModelPerformance: React.FC<ModelPerformanceProps> = ({ baseModel, compressedModel }) => {
  const parseLatency = (latency: number) => {
    return latency > 0 ? `${latency} ms` : latency === 0 ? 'Measuring...' : latency === -1 ? 'Error' : '';
  };
  const onClickDownloadCSV: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (!baseModel || !compressedModel) {
      return;
    }

    downloadCSV(baseModel, compressedModel);
  };

  const baseModelTargetDeviceLatency = React.useMemo(() => {
    if (baseModel) {
      const targetDeviceInfo = baseModel.devices.find((device) => device.name === baseModel.target_device);

      if (targetDeviceInfo) {
        return targetDeviceInfo.total_latency;
      }
    }

    return 0;
  }, [baseModel]);

  const compressedModelTargetDeviceLatency = React.useMemo(() => {
    if (compressedModel) {
      const targetDeviceInfo = compressedModel.devices.find((device) => device.name === compressedModel.target_device);

      if (targetDeviceInfo) {
        return targetDeviceInfo.total_latency;
      }
    }

    return 0;
  }, [compressedModel]);

  return (
    <section className="w-full pt-8">
      <HeaderText className="mb-4">Model Performance</HeaderText>
      <section className="flex flex-row mb-4 pt-4 pl-[170px] relative">
        <div className="absolute left-0 top-[74px] w-[170px]">
          <div>Original</div>
          <div className="mt-4">Converted</div>
        </div>
        <div className="w-1/6 border-r border-lineGray mr-6">
          <div className="text-sm font-medium text-defaultGray">
            Evaluation Metric
            <br /> (mAP[0.5])
          </div>
          <div className="text-darkGray font-normal mt-4">
            {!!baseModel.metric.metric_value && baseModel.metric.metric_value}
          </div>
          <div className="text-darkGray font-normal mt-4">
            {!!compressedModel.metric.metric_value && compressedModel.metric.metric_value}
          </div>
        </div>
        <div className="w-1/6 border-r border-lineGray mr-6">
          <div className="text-sm font-medium text-defaultGray">
            Latency
            <br /> (ms)
          </div>
          <div className="text-darkGray font-normal mt-4">{parseLatency(baseModelTargetDeviceLatency)} </div>
          <div className="text-darkGray font-normal mt-4">
            {parseLatency(compressedModelTargetDeviceLatency)}{' '}
            {compressedModelTargetDeviceLatency > 0 && baseModelTargetDeviceLatency > 0 && (
              <Magnification>
                {(baseModelTargetDeviceLatency / compressedModelTargetDeviceLatency).toFixed(1)}
              </Magnification>
            )}
          </div>
        </div>
        <div className="w-1/6 border-r border-lineGray mr-6">
          <div className="text-sm font-medium text-defaultGray">
            Model size
            <br /> (MB)
          </div>
          <div className="text-darkGray font-normal mt-4">{baseModel.spec.model_size}</div>
          <div className="text-darkGray font-normal mt-4">
            {compressedModel.spec.model_size}{' '}
            <Magnification>{(baseModel.spec.model_size / compressedModel.spec.model_size).toFixed(1)}</Magnification>
          </div>
        </div>
        <div className="w-1/6 border-r border-lineGray mr-6">
          <div className="text-sm font-medium text-defaultGray">
            FLOPs
            <br /> (M)
          </div>
          <div className="text-darkGray font-normal mt-4">{baseModel.spec.flops.toLocaleString()}</div>
          <div className="text-darkGray font-normal mt-4">
            {compressedModel.spec.flops.toLocaleString()}{' '}
            <Magnification>{(baseModel.spec.flops / compressedModel.spec.flops).toFixed(1)}</Magnification>
          </div>
        </div>
        <div className="w-1/6 border-r border-lineGray mr-6">
          <div className="text-sm font-medium text-defaultGray">
            Trainable
            <br /> Parameters(M)
          </div>
          <div className="text-darkGray font-normal mt-4">{baseModel.spec.trainable_parameters.toLocaleString()}</div>
          <div className="text-darkGray font-normal mt-4">
            {compressedModel.spec.trainable_parameters.toLocaleString()}{' '}
            <Magnification>
              {(baseModel.spec.trainable_parameters / compressedModel.spec.trainable_parameters).toFixed(1)}
            </Magnification>
          </div>
        </div>
        <div className="w-1/6 mr-6">
          <div className="text-sm font-medium text-defaultGray">
            Non Trainable
            <br /> Parameters(M)
          </div>
          <div className="text-darkGray font-normal mt-4">
            {baseModel.spec.non_trainable_parameters.toLocaleString()}
          </div>
          <div className="text-darkGray font-normal mt-4">
            {compressedModel.spec.non_trainable_parameters.toLocaleString()}{' '}
            {!Number.isNaN(baseModel.spec.non_trainable_parameters / compressedModel.spec.non_trainable_parameters) && (
              <Magnification>
                {(baseModel.spec.non_trainable_parameters / compressedModel.spec.non_trainable_parameters).toFixed(1)}
              </Magnification>
            )}
          </div>
        </div>
      </section>
      <section className="flex justify-end">
        <SubmitButton type="button" onClick={onClickDownloadCSV}>
          Download CSV
        </SubmitButton>
      </section>
    </section>
  );
};
