/* eslint-disable camelcase */
import { Partial } from '@netspresso/shared';
import React from 'react';
import { isLookupTableTypes, Recommendation, Recommendations } from '../../../../lib';
import { parseModelSize, parseRecommendationsModel } from '../../../../utils';
import { ModelIcon } from '../ModelIcon';

type RecommendationCardProps = {
  className: string;
  lookupTable: Recommendation;
  selected: Partial<Recommendation>;
  onClick: (value: Recommendation) => void;
};

export const RecommendationCard: React.FC<RecommendationCardProps> = ({
  className,
  lookupTable,
  selected,
  onClick,
}) => {
  const { lookuptable_id, model_name, img_size_width, img_size_height, latency_s } = lookupTable;

  const isDisabled = () => {
    return selected && selected.lookuptable_id === lookuptable_id;
  };

  const onClickHandler: React.MouseEventHandler<HTMLButtonElement> = (_) => {
    if (!isDisabled()) {
      onClick(lookupTable);
    }
  };

  const displaySizeIfYolo = (modelName: Recommendations) => {
    const result = modelName.substring(0, 7);

    if (isLookupTableTypes(result)) {
      return parseModelSize(result);
    }

    return '';
  };

  return (
    <section role="menuitem" className={`w-[138px] ${className}`} tabIndex={0} onClick={onClickHandler}>
      <div
        className={`rounded-lg border transition ease-in-out duration-200 ${
          isDisabled()
            ? 'bg-secondary-50 border-primary pointer-events-none'
            : 'bg-white border-defaultGray cursor-pointer'
        } px-4 py-2 flex flex-col items-center hover:shadow`}
      >
        <h1 className="text-bgblue text-center font-title font-bold tracking-tight h-12">
          {model_name === 'UNet (+ Resnet)' ? (
            <>
              UNet
              <br />
              (+ ResNet)
            </>
          ) : (
            parseRecommendationsModel(model_name)
          )}
        </h1>
        <ModelIcon modelName={model_name} />
        <span className="text-center text-xs text-secondary">{displaySizeIfYolo(model_name)}</span>
        <span className={`text-center text-xs text-defaultGray ${model_name.indexOf('yolov5') < 0 ? 'mt-6' : 'mt-2'}`}>
          Latency (ms)
        </span>
        <div className="mb-1">
          <span className="text-center text-3xs text-darkGray mr-1">Estimation</span>
          <span className="text-center text-sm text-darkGray">{Math.round(latency_s * 1000000) / 1000}</span>
        </div>
        <span className="text-center text-xs text-defaultGray">Image size (px)</span>
        <span className="text-center text-darkGray">{`${img_size_width} x ${img_size_height}`}</span>
      </div>
    </section>
  );
};
