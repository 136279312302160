import { ADVANCED_SEARCH, RETRAINING } from '@netspresso/shared';
import React from 'react';
import { ProjectTypes } from '../../../../lib';

import { AdvancedSearch, ExistingModel, QuickSearch } from './components';

const displayExplain = (method: ProjectTypes) => {
  if (method === ADVANCED_SEARCH) {
    return <AdvancedSearch />;
  }

  if (method === RETRAINING) {
    return <ExistingModel />;
  }

  return <QuickSearch />;
};

type TrainingMethodCardProps = {
  method: ProjectTypes;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const TrainingMethodCard: React.FC<TrainingMethodCardProps> = ({ method, onClick }) => {
  return (
    <section className="w-[296px] mb-1">
      <div className="bg-white rounded-lg shadow px-6 py-8 flex flex-col items-center">
        {displayExplain(method)}
        <button
          type="button"
          className={`text-sm text-white disabled:text-defaultGray disabled:font-bold px-7 py-3 rounded-[23px] disabled:bg-white ${
            method !== RETRAINING ? 'bg-secondary hover:bg-secondary-active' : 'bg-emphasize hover:bg-emphasize-active'
          }`}
          disabled={method === ADVANCED_SEARCH}
          onClick={onClick}
        >
          {method !== ADVANCED_SEARCH ? 'New Project' : `Coming soon`}
        </button>
      </div>
    </section>
  );
};
