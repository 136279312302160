import { UserAgentType } from '../../lib';

/* eslint-disable camelcase */
export const parseUserServerStatus =
  ({
    availableResult,
    busyResult,
    disabledResult,
  }: {
    availableResult: string;
    busyResult: string;
    disabledResult: string;
  }) =>
  ({ status }: UserAgentType): string =>
    status === 'running' ? busyResult : status !== 'available' ? disabledResult : availableResult;
