import { PropsWithChildren, useMemo } from 'react';
import CompletedIcon from '@netspresso/components/assets/icons/circle_check.svg';
import ErrorIcon from '@netspresso/components/assets/icons/circle_error.svg';
import ProgressIcon from '@netspresso/components/assets/icons/circle_in_progress.svg';
import WarningIcon from '@netspresso/components/assets/icons/circle_warning.svg';
import RightArrow from '@netspresso/components/assets/icons/right_arrow.svg';

export type ProjectStep =
  | 'submitted'
  | 'trainStop'
  | 'training'
  | 'convertStop'
  | 'converting'
  | 'benchmarkStop'
  | 'benchmarking';

export type ProjectState = 'warn' | 'progress' | 'error' | 'completed';

// FIXME: Info Container와 통합이 되어야 함 가능하다면 Card Container도 함께
type ProjectStatusContainerProps = {
  projectState: ProjectState;
  className?: string;
  projectStep: ProjectStep;
};

export const ProjectStatusContainer = ({
  className = '',
  projectState,
  children,
  projectStep,
}: PropsWithChildren<ProjectStatusContainerProps>) => {
  const {
    containerBg,
    iconBg,
    contentColor,
    icon: Icon,
  } = useMemo(
    () =>
      ({
        warn: {
          containerBg: 'bg-notaYellow-50',
          iconBg: 'bg-notaYellow-100',
          contentColor: 'text-notaYellow-600',
          icon: WarningIcon,
        },
        progress: {
          containerBg: 'bg-primary-50',
          iconBg: 'bg-primary-100',
          contentColor: 'text-primary',
          icon: ProgressIcon,
        },
        error: {
          containerBg: 'bg-notaRed-50',
          iconBg: 'bg-notaRed-100',
          contentColor: 'text-notaRed-600',
          icon: ErrorIcon,
        },
        completed: {
          containerBg: 'bg-notaGreen-50',
          iconBg: 'bg-notaGreen-100',
          contentColor: 'text-notaGreen-500',
          icon: CompletedIcon,
        },
      }[projectState]),
    [projectState]
  );

  const { trainTextClass, firstArrowClass, convertTextClass, secondArrowClass, benchmarkTextClass } = useMemo(
    () =>
      ({
        submitted: {
          trainTextClass: 'text-neutralBlack-200',
          firstArrowClass: 'fill-neutralBlack-200',
          convertTextClass: 'text-neutralBlack-200',
          secondArrowClass: 'fill-neutralBlack-200',
          benchmarkTextClass: 'text-neutralBlack-200',
        },
        trainStop: {
          trainTextClass: 'text-neutralBlack-500 font-bold',
          firstArrowClass: 'fill-neutralBlack-500',
          convertTextClass: 'text-neutralBlack-200',
          secondArrowClass: 'fill-neutralBlack-200',
          benchmarkTextClass: 'text-neutralBlack-200',
        },
        training: {
          trainTextClass: 'text-neutralBlack-500 font-bold dynamic-ellipsis',
          firstArrowClass: 'fill-neutralBlack-500',
          convertTextClass: 'text-neutralBlack-200',
          secondArrowClass: 'fill-neutralBlack-200',
          benchmarkTextClass: 'text-neutralBlack-200',
        },
        convertStop: {
          trainTextClass: 'text-neutralBlack-500',
          firstArrowClass: 'fill-neutralBlack-500',
          convertTextClass: 'text-neutralBlack-500 font-bold',
          secondArrowClass: 'fill-neutralBlack-500',
          benchmarkTextClass: 'text-neutralBlack-200',
        },
        converting: {
          trainTextClass: 'text-neutralBlack-500',
          firstArrowClass: 'fill-neutralBlack-500',
          convertTextClass: 'text-neutralBlack-500 font-bold dynamic-ellipsis',
          secondArrowClass: 'fill-neutralBlack-500',
          benchmarkTextClass: 'text-neutralBlack-200',
        },
        benchmarkStop: {
          trainTextClass: 'text-neutralBlack-500',
          firstArrowClass: 'fill-neutralBlack-500',
          convertTextClass: 'text-neutralBlack-500',
          secondArrowClass: 'fill-neutralBlack-500',
          benchmarkTextClass: 'text-neutralBlack-500 font-bold',
        },
        benchmarking: {
          trainTextClass: 'text-neutralBlack-500',
          firstArrowClass: 'fill-neutralBlack-500',
          convertTextClass: 'text-neutralBlack-500',
          secondArrowClass: 'fill-neutralBlack-500',
          benchmarkTextClass: 'text-neutralBlack-500 font-bold dynamic-ellipsis',
        },
      }[projectStep]),
    [projectStep]
  );

  return (
    <div className={`flex gap-3 p-[30px] rounded-t-lg ${containerBg} ${className}`}>
      <div className={`inline-flex p-3 rounded-4xl items-center justify-center m-auto ${iconBg}`}>
        <Icon />
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex gap-3 items-center text-2xl">
          <span className={trainTextClass}>Train</span>
          <RightArrow className={firstArrowClass} />
          <span className={convertTextClass}>Convert</span>
          <RightArrow className={secondArrowClass} />
          <span className={benchmarkTextClass}>Benchmark</span>
        </div>
        <div className={`flex gap-2 items-center min-h-[32px] ${contentColor}`}>{children}</div>
      </div>
    </div>
  );
};
