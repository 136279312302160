/* eslint-disable camelcase */
import { AxiosError } from 'axios';
import { useState } from 'react';
import { AuthService, TokenService, UserService } from '../../services';

const deacivated = (user: UserType) => false;

export type UserType = {
  authorities: [number, number, number];
  created_time: string;
  credit: number;
  current_time: string;
  detail_data: {
    privacy_policy_agreement: number;
    marketing_agreement: number;
    personal_information_agreement: number;
    accessing_age_agreement: number;
    terms_of_service_agreement: number;
    first_name: string;
    last_name: string;
    company: string;
  };
  email: string;
  is_active: boolean | 0 | 1;
  is_admin: boolean | 0 | 1;
  is_reset: boolean | 0 | 1;
  last_login_time: string;
  region: string;
  user_id: string;
  username: string;
};

export type AuthType = {
  user: UserType;
  fetchUserData: (accessToken: string, refreshToken: string) => Promise<void>;
  refetchUserData: () => Promise<void>;
  login: (username: string, password: string) => Promise<void>;
  logout: VoidFunction;
  error: string;
  loading: boolean;
  isDeactivated: boolean;
};

export const useAuth = (): AuthType => {
  const [user, setUser] = useState(TokenService.getUser() || null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDeactivated, setIsDeactivated] = useState(false);

  const login = async (username: string, password: string) => {
    const payload = { username, password };

    setLoading(true);
    setIsDeactivated(false);

    try {
      const auth = await AuthService.login(payload);

      if (auth) {
        const { tokens } = auth.data;
        const token = tokens.access_token;

        TokenService.updateLocalAccessToken(token);

        const refreshToken = tokens.refresh_token;

        TokenService.updateLocalRefreshToken(refreshToken);

        const userData = await UserService.getUser();

        if (userData) {
          setError('');
          const fetchedUser = userData.data;

          if (deacivated(fetchedUser)) {
            TokenService.removeLocalTokens();
            setIsDeactivated(!!deacivated(fetchedUser));
          } else {
            TokenService.setUser(fetchedUser);
            setUser(fetchedUser);
          }
        }
      }
    } catch (err) {
      const httpError = err as AxiosError;

      setError(httpError.response?.data.detail);
    } finally {
      setLoading(false);
    }
  };

  const refetchUserData = async () => {
    const userData = await UserService.getUser();

    if (userData) {
      setError('');
      const fetchedUser = userData.data;

      if (deacivated(fetchedUser)) {
        TokenService.removeLocalTokens();
        setIsDeactivated(!!deacivated(fetchedUser));
      } else {
        TokenService.setUser(fetchedUser);
        setUser(fetchedUser);
      }
    }
  };

  const fetchUserData = async (accessToken: string, refreshToken: string) => {
    TokenService.updateLocalAccessToken(accessToken);
    TokenService.updateLocalRefreshToken(refreshToken);

    await refetchUserData();
  };

  const logout = () => {
    TokenService.removeUser();
    TokenService.removeLocalTokens();
    setUser(null);
    window.location.href = `${process.env.REACT_APP_PORTAL_URL}`;

    return null;
  };

  return { user, fetchUserData, refetchUserData, login, logout, error, loading, isDeactivated };
};
