import React from 'react';

type SubmitButtonProps = {
  className?: string;
  type?: 'submit' | 'button' | 'reset';
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const SubmitButton: React.FC<SubmitButtonProps & React.PropsWithChildren> = ({
  children,
  className,
  type = 'submit',
  disabled = false,
  ...restProps
}) => (
  <button
    data-test="confirm-stop-progressing-btn"
    type={type}
    className={`transition ease-in-out duration-200 text-sm text-white bg-secondary hover:bg-secondary-active disabled:bg-disabledGray px-7 py-2 rounded leading-[14px] ${className}`}
    disabled={disabled}
    {...restProps}
  >
    {children}
  </button>
);
