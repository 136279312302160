import { CROP_H, CROP_W, SLIDE_EVAL, STRIDE } from '@netspresso/shared';
import React from 'react';
import { INFERENCE_DETAIL } from '../../../../constants';
import { useProjectsFormContext } from '../../../../context';
import { ProjectType } from '../../../../schemes';
import { parseHypTooltip } from '../../../../utils';
import { HyperParameterInput } from '../HyperParameterInput';

export const InferenceForm: React.FC = () => {
  const {
    projectsForm: { watch },
  } = useProjectsFormContext();

  const watched = watch();

  const renderInferenceDetail = (formFields: ProjectType) => {
    const inferenceDetailField = formFields[INFERENCE_DETAIL];

    if (inferenceDetailField && inferenceDetailField.slide_evaluation) {
      return (
        <section className="flex flex-row mt-6">
          <HyperParameterInput
            inputType="select"
            paramName={STRIDE}
            groupName={INFERENCE_DETAIL}
            dataTestId={STRIDE}
            className="pr-9"
            options={[
              { value: 512, label: '512' },
              { value: 768, label: '768' },
            ]}
            tooltipMsg={parseHypTooltip(STRIDE)}
          />
          <HyperParameterInput
            inputType="select"
            paramName={CROP_W}
            groupName={INFERENCE_DETAIL}
            dataTestId={CROP_W}
            className="px-9"
            options={[
              { value: 768, label: '768' },
              { value: 1024, label: '1024' },
            ]}
            tooltipMsg={parseHypTooltip(CROP_W)}
          />
          <HyperParameterInput
            inputType="select"
            paramName={CROP_H}
            groupName={INFERENCE_DETAIL}
            dataTestId={CROP_H}
            className="pl-9"
            options={[
              { value: 768, label: '768' },
              { value: 1024, label: '1024' },
            ]}
            tooltipMsg={parseHypTooltip(CROP_H)}
          />
        </section>
      );
    }

    return null;
  };

  return (
    <>
      <section className="flex flex-row">
        <HyperParameterInput
          inputType="toggle"
          paramName={SLIDE_EVAL}
          groupName={INFERENCE_DETAIL}
          dataTestId={SLIDE_EVAL}
          className="pr-9"
          tooltipMsg={parseHypTooltip(SLIDE_EVAL)}
        />
      </section>
      {renderInferenceDetail(watched)}
    </>
  );
};
