/* eslint-disable camelcase */
import React from 'react';
import { parseNotaServerStatus } from '../../../../../../utils';
import { NotaServerType } from '../../../../../../lib';

type NotaServerProps = {
  notaServer: NotaServerType;
  selected: string;
  isDisabled: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

export const NotaServer: React.FC<NotaServerProps> = ({ notaServer, selected, isDisabled, onClick }) => {
  const statusText = parseNotaServerStatus({
    availableResult: 'Available',
    busyResult: 'Running',
    disabledResult: 'Not Available',
  });

  const serverStatusColor = parseNotaServerStatus({
    availableResult: 'text-green-600',
    busyResult: 'text-sub',
    disabledResult: 'text-danger',
  });

  const serverNumber = ({ used_num, remained_num }: NotaServerType) => {
    return `${remained_num}/${remained_num + used_num}`;
  };

  return (
    <div
      className={`${isDisabled ? 'bg-gray-100' : selected === 'nota_server' ? 'bg-secondary-50' : 'bg-white'} ${
        !isDisabled ? 'cursor-pointer hover:shadow' : ''
      } text-sm rounded-lg border border-gray-300 px-6 py-4 flex flex-row items-center justify-between`}
      onClick={!isDisabled ? onClick : undefined}
    >
      <div>
        <h1 className="font-title font-bold text-gray-900">NetsPresso Server</h1>
      </div>
      <div>
        <p className={`text-center font-semibold ${serverStatusColor(notaServer)}`}>{statusText(notaServer)}</p>
        <p className="text-right text-gray-500">{serverNumber(notaServer)}</p>
      </div>
    </div>
  );
};
