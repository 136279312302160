import QuickSearchIcon from '@netspresso/components/assets/icons/quicksearch.svg';
import RetrainingIcon from '@netspresso/components/assets/icons/retraining.svg';
import { QUICK_SEARCH } from '@netspresso/shared';
import React from 'react';
import { ProjectTypes } from '../../../../../../lib';

type ProjectIconsType = {
  projectType: ProjectTypes;
};

export const ProjectIcons: React.FC<ProjectIconsType> = ({ projectType }) => {
  const isQuickSearch = (type: ProjectTypes) => type === QUICK_SEARCH;

  return (
    <span
      className={`inline-flex items-center justify-center rounded-full drop-shadow-sm w-7 h-7 ${
        isQuickSearch(projectType)
          ? 'bg-primary-500 border border-primary-600'
          : 'bg-tertiary-500 border border-tertiary-600'
      } pb-px mr-2`}
    >
      {isQuickSearch(projectType) ? <QuickSearchIcon /> : <RetrainingIcon />}
    </span>
  );
};
