import React, { createContext, useContext, useReducer } from 'react';

import { ProjectsAction, projectsReducer, ProjectsState } from '../reducers';

type ProjectsContextType = [ProjectsState, React.Dispatch<ProjectsAction>];

export const ProjectsContext = createContext<ProjectsContextType | null>(null);

const initialState = { projects: [] };

export const ProjectsContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(projectsReducer, initialState);

  return <ProjectsContext.Provider value={[state, dispatch]}>{children}</ProjectsContext.Provider>;
};

export const useProjectsContext = (): ProjectsContextType => {
  const context = useContext(ProjectsContext);

  if (!context) {
    throw new Error('useProjectsContext must be used within a ProjectsContextProvider');
  }

  return context;
};
