import React, { createContext, useContext } from 'react';
import { NotificactionType, useNotify } from './useNotify';
import { Notifications } from './Notifications';

export const NotificationContext = createContext<NotificactionType | null>(null);

export const NotificationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isVisible, showToast, hideToast, notifyContent, onClickToastHandler } = useNotify();

  return (
    <NotificationContext.Provider value={{ isVisible, showToast, hideToast, notifyContent, onClickToastHandler }}>
      <Notifications />
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = (): NotificactionType => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotificationContext must be used within a NotificationProvider');
  }

  return context;
};
