import { fillArray } from './fillArray';

export const splitArray = <T>(list: T[], size = 10): T[][] => {
  const result = [];

  for (let i = 0; i < list.length; i += size) {
    result.push(fillArray<T>(list.slice(i, i + size)));
  }

  return result;
};
