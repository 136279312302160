import ExistingModelIcon from '@netspresso/components/assets/icons/existing_model.svg';
import React from 'react';

export const ExistingModel: React.FC = () => {
  return (
    <>
      <div className="w-32 h-32 pt-3 pb-1 rounded-[64px] border-2 border-emphasize flex flex-col items-center">
        <ExistingModelIcon />
        <h1 className="text-emphasize text-center text-sm font-medium mt-4">Retraining</h1>
      </div>
      <ul className="list-disc text-emphasize mt-6 mb-[50px] text-m">
        <li>For fine-tuning and transfer-learning</li>
        <li>Get only 1 model</li>
      </ul>
      <p className="text-xs text-emphasize mb-8">
        Retrain a model based on your previous model architectures in NetsPresso.
      </p>
    </>
  );
};
