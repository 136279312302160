import {
  FIELD_NAME,
  FormErrorMessages,
  IMAGE_CLASSIFICATION,
  INSTANCE_SEGMENTATION,
  OBJECT_DETECTION,
  OTHER,
  PANOPTIC_SEGMENTATION,
  SEMANTIC_SEGMENTATION,
} from '@netspresso/shared';
import * as yup from 'yup';
import {
  BATCH,
  CHANNEL,
  DESCRIPTION,
  DIMENSION,
  FILE,
  FILE_NAME,
  FRAMEWORK,
  INPUT_LAYERS,
  METRIC_UNIT,
  METRIC_VALUE,
  MODEL_NAME,
  TASK,
} from '../constants';
import { FRAMEWORKS, UploadableModelTypes } from '../lib';

const checkIfDimensionStringArray = (value: string | number[]) => {
  if (Array.isArray(value)) {
    return true;
  }

  const target = value.replaceAll(' ', '').split(',');

  return target.length > 0 && target[target.length - 1] !== '';
};

// type Dimensions = string | number[];

export const UploadModelScheme = yup.object({
  [MODEL_NAME]: yup
    .string()
    .required(FormErrorMessages.isRequired)
    .min(2, FormErrorMessages.isNotMin(2))
    .max(20, FormErrorMessages.isNotMax(20))
    .matches(FIELD_NAME, FormErrorMessages.isWrongCharacters),
  [DESCRIPTION]: yup.string().max(100, FormErrorMessages.isNotMax(100)),
  [TASK]: yup
    .string()
    .required(FormErrorMessages.isRequired)
    .oneOf([
      OBJECT_DETECTION,
      IMAGE_CLASSIFICATION,
      SEMANTIC_SEGMENTATION,
      INSTANCE_SEGMENTATION,
      PANOPTIC_SEGMENTATION,
      OTHER,
    ]),
  [FRAMEWORK]: yup.string<UploadableModelTypes>().required(FormErrorMessages.isRequired),
  [FILE]: yup.mixed().required(FormErrorMessages.isRequired).nullable(),
  [FILE_NAME]: yup.string().required(FormErrorMessages.isRequired),
  [METRIC_UNIT]: yup.string().required(FormErrorMessages.isRequired),
  [METRIC_VALUE]: yup.number().required(FormErrorMessages.isRequired).min(-10000).max(10000),
  [INPUT_LAYERS]: yup
    .object()
    .shape({
      [BATCH]: yup
        .number()
        .required(FormErrorMessages.isRequired)
        .min(1, FormErrorMessages.isNotMin(1))
        .max(100, FormErrorMessages.isNotMax(100)),
      [CHANNEL]: yup
        .number()
        .required(FormErrorMessages.isRequired)
        .min(1, FormErrorMessages.isNotMin(1))
        .max(100, FormErrorMessages.isNotMax(100)),
      [DIMENSION]: yup
        .mixed<string | number[]>()
        .required(FormErrorMessages.isRequired)
        .test('must-be-strings-array', FormErrorMessages.isWrongDimension, checkIfDimensionStringArray),
    })
    .required(),
});

export type UploadModelType = yup.InferType<typeof UploadModelScheme>;

export const UploadModelsDefaultValues: UploadModelType = {
  [MODEL_NAME]: '',
  [DESCRIPTION]: '',
  [TASK]: '',
  [FRAMEWORK]: FRAMEWORKS.ONNX,
  [FILE]: null,
  [FILE_NAME]: '',
  [METRIC_UNIT]: '',
  [METRIC_VALUE]: 0,
  [INPUT_LAYERS]: {
    [BATCH]: 1,
    [CHANNEL]: 3,
    [DIMENSION]: '',
  },
};
