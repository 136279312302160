import { OptionType, RadioButton, Select, ToggleSwitch } from '@netspresso/components';
import { CROP_H, CROP_W, NOT_NUMBER, NUMBER_WITH_DIGIT } from '@netspresso/shared';
import React, { useEffect, useRef, useState } from 'react';
import { FieldErrors, useFormState } from 'react-hook-form';
import { INFERENCE_DETAIL } from '../../../../constants';
import { useProjectsFormContext } from '../../../../context';
import { HyperParameterTooltips } from '../../../../lib';
import { ProjectType } from '../../../../schemes';

type HyperParameterInputProps = {
  paramName: HyperParameterTooltips | HyperParameterTooltips[];
  groupName: 'augmentation_details' | 'hyp_detail' | 'inference_detail';
  tooltipMsg: string;
  className: string;
  inputType?: 'number' | 'toggle' | 'select' | 'radio';
  options?: OptionType[];
  placeholder?: string;
  isDisabled?: boolean;
  dataTestId?: string;
};

export const HyperParameterInput: React.FC<HyperParameterInputProps> = ({
  paramName,
  groupName,
  tooltipMsg,
  className,
  inputType = 'number',
  options = [],
  placeholder = '',
  isDisabled = false,
  dataTestId = 'hyper-parameter-input',
}) => {
  const [toggleValue, setToggleValue] = useState(false);
  const {
    projectsForm: { setValue, watch, control },
  } = useProjectsFormContext();
  const { errors } = useFormState({ control });

  const watched = watch();

  const [paramValue, setParamValue] = useState(0);
  const [currentValue, setCurrentValue] = useState('');

  const [tooltipWidth, setTooltipWidth] = useState(0);
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (ref.current) {
      const tooltipTextWidth = ref.current.offsetWidth;

      setTooltipWidth(tooltipTextWidth / 2);
    }
  }, [tooltipMsg]);

  useEffect(() => {
    const groupProperty = watched[groupName];

    if (groupProperty && !Array.isArray(paramName)) {
      setCurrentValue(`${groupProperty[paramName]}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watched[groupName]]);

  const onParamChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setCurrentValue(value);
  };

  const onBlurParam = () => {
    let maybeNumber = currentValue.trim();

    maybeNumber = maybeNumber.replace(NOT_NUMBER, '').replace(',', '.').replace('--', '-');

    if (maybeNumber.length > 2) {
      maybeNumber = maybeNumber.charAt(0) + maybeNumber.substring(1).replace('-', '');
    }

    if (NUMBER_WITH_DIGIT.test(maybeNumber)) {
      updateInputNumberValue(maybeNumber);

      return;
    }

    const groupProperty = watched[groupName];

    if (groupProperty && !Array.isArray(paramName)) {
      updateInputNumberValue(`${groupProperty[paramName]}`);
    }
  };

  const updateInputNumberValue = (value: string) => {
    const parsedValue = parseFloat(value);

    setCurrentValue(`${parsedValue}`);
    setParamValue(parsedValue);
    setValue(`${groupName}.${paramName}`, parsedValue, { shouldValidate: true, shouldDirty: true });
  };

  const onToggleChange = (checked: boolean) => {
    setToggleValue(checked);
    setValue(`${groupName}.${paramName}`, checked, { shouldValidate: true, shouldDirty: true });
  };

  const onSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;
    const parsedValue = parseInt(value, 10);

    setParamValue(parsedValue);
    setValue(`${groupName}.${paramName}`, parsedValue, { shouldValidate: true, shouldDirty: true });

    if (groupName === INFERENCE_DETAIL) {
      if (paramName === CROP_H) {
        setValue(`${INFERENCE_DETAIL}.${CROP_W}`, parsedValue, { shouldValidate: true, shouldDirty: true });
      }

      if (paramName === CROP_W) {
        setValue(`${INFERENCE_DETAIL}.${CROP_H}`, parsedValue, { shouldValidate: true, shouldDirty: true });
      }
    }
  };

  const onRadioChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    const parsedValue = parseInt(value, 10);

    setParamValue(parsedValue);

    if (Array.isArray(paramName)) {
      paramName.forEach((item) => {
        setValue(`${groupName}.${item}`, parsedValue, { shouldValidate: true, shouldDirty: true });
      });
    } else {
      setValue(`${groupName}.${paramName}`, parsedValue, { shouldValidate: true, shouldDirty: true });
    }
  };

  const renderInputNumber = (formFields: ProjectType) => {
    const groupProperty = formFields[groupName];

    if (groupProperty && !Array.isArray(paramName)) {
      return (
        <input
          className={`block w-full rounded px-3 py-1 placeholder-disabledGray focus:outline-none focus:border-secondary disabled:bg-lineGray disabled:text-defaultGray ${getErrorClass(
            errors
          )}`}
          type="text"
          id={paramName}
          name={paramName}
          value={currentValue}
          placeholder={placeholder}
          disabled={isDisabled}
          onChange={onParamChange}
          onBlur={onBlurParam}
        />
      );
    }

    return false;
  };

  const renderToggle = (formFields: ProjectType) => {
    const groupProperty = formFields[groupName];

    if (groupProperty && !Array.isArray(paramName)) {
      return (
        <ToggleSwitch
          id={paramName}
          onToggle={onToggleChange}
          value={!!groupProperty[paramName] || toggleValue}
          dataTestId={dataTestId}
        />
      );
    }

    return false;
  };

  const renderSelect = (formFields: ProjectType) => {
    const groupProperty = formFields[groupName];

    if (groupProperty && !Array.isArray(paramName)) {
      return (
        <Select
          id={paramName}
          className="font-semibold mb-2"
          value={`${groupProperty[paramName]}` || paramValue.toString()}
          options={options}
          dataTestId={dataTestId}
          onChange={onSelectChange}
        />
      );
    }

    return false;
  };

  const renderRadio = (formFields: ProjectType) => {
    const groupProperty = formFields[groupName];

    if (groupProperty) {
      <div>
        {options.map((item, index) => {
          return (
            <RadioButton
              key={`${item.label}${item.value}`}
              groupName={Array.isArray(paramName) ? paramName.join(' / ') : paramName}
              value={item.value}
              label={item.label}
              classes={index + 1 !== options.length ? 'mr-6' : ''}
              isChecked={
                Array.isArray(paramName)
                  ? item.value === groupProperty[paramName[0]]
                  : item.value === groupProperty[paramName]
              }
              onChange={onRadioChange}
            />
          );
        })}
      </div>;
    }

    return false;
  };

  const getErrorClass = (fieldErrors: FieldErrors<ProjectType>) => {
    const groupError = fieldErrors[groupName];

    if (groupError && !Array.isArray(paramName) && groupError[paramName]) {
      return 'border-2 border-danger focus:border-danger';
    }

    return 'border border-defaultGray focus:border-secondary';
  };

  const renderErrorMessage = (fieldErrors: FieldErrors<ProjectType>) => {
    const groupError = fieldErrors[groupName];

    if (groupError && !Array.isArray(paramName)) {
      const paramError = groupError[paramName];

      if (paramError) {
        return (
          <span className={`${groupError[paramName] ? 'block' : 'hidden'} text-xs text-danger`}>
            {paramError.message}
          </span>
        );
      }
    }

    return false;
  };

  return (
    <section className={`w-1/3 ${className}`}>
      <div className="flex flex-row items-center">
        <label className="block font-semibold text-sm text-darkGray mb-1 mr-2" htmlFor="target_latency">
          {Array.isArray(paramName) ? paramName.join(' / ') : paramName}
        </label>
        <div className="material-symbols-outlined text-xl text-gray-300 cursor-pointer pb-1 tooltip">
          help
          <span
            ref={ref}
            className="tooltiptext text-xs font-sans font-semibold"
            style={{ marginLeft: `-${tooltipWidth}px` }}
          >
            {tooltipMsg}
          </span>
        </div>
      </div>
      {inputType === 'number' && renderInputNumber(watched)}
      {inputType === 'toggle' && renderToggle(watched)}
      {inputType === 'select' && renderSelect(watched)}
      {inputType === 'radio' && renderRadio(watched)}
      {renderErrorMessage(errors)}
    </section>
  );
};
