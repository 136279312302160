import { HeaderText, LabeledValueDisplay } from '@netspresso/components';
import { getLocalTimeString } from '@netspresso/shared';
import React from 'react';
import { Compressed, CompressModel } from '../../../../../../lib';
import { parseCompressionMethod, parseDevices, parseFramework } from '../../../../../../utils';

type CompressionInfoProps = {
  compression: Compressed;
  compressedModel: CompressModel;
};

export const CompressionInfo: React.FC<CompressionInfoProps> = ({ compression, compressedModel }) => {
  return (
    <section className="w-full">
      <HeaderText className="mb-4">Compression Info</HeaderText>
      <div className="flex flex-row">
        <LabeledValueDisplay label="Framework" className="w-1/4">
          {parseFramework(compressedModel.framework)}
        </LabeledValueDisplay>
        <LabeledValueDisplay label="Created" className="w-1/4">
          {compressedModel ? getLocalTimeString(compressedModel.created_time) : 'time default'}
        </LabeledValueDisplay>
        <LabeledValueDisplay label="Compression Method" className="w-1/4">
          {parseCompressionMethod(compression.compression_method)}
        </LabeledValueDisplay>
        <LabeledValueDisplay label="Latency Profiling Target" className="w-1/4">
          {parseDevices(compressedModel.target_device)}
        </LabeledValueDisplay>
      </div>
    </section>
  );
};
