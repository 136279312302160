import React from 'react';
import { useNavigate } from 'react-router-dom';

export type PackagablePanelProps = {
  modelId: string;
};

export const PackagablePanel: React.FC<PackagablePanelProps> = ({ modelId }) => {
  const navigate = useNavigate();

  const onClickPackage: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    navigate(`/package/model/${modelId}`);
  };

  return (
    <>
      <li>
        <button
          type="button"
          className="px-4 py-2 text-left text-secondary w-full disabled:text-lineGray hover:text-secondary border-t border-lineGray"
          onClick={onClickPackage}
        >
          New package
        </button>
      </li>
      <li>
        <div className="px-4 py-2 text-left text-lineGray w-full select-none">See existing packages</div>
      </li>
    </>
  );
};
