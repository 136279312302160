import { MODEL_ZOO_LINK } from '@netspresso/shared';
import React from 'react';

export const ModelPyTorch: React.FC = () => {
  return (
    <section className="flex items-start p-2 rounded bg-secondary-50 mb-6">
      <span className="material-icons mr-2 text-secondary">error_outline</span>
      <div className="text-m text-secondary">
        <h1 className="font-medium text-secondary-700">PyTorch GraphModule model</h1>
        <h2 className="mt-2">Model Compressor</h2>
        <ul className="w-full list-disc ml-4">
          <li className="text-xs text-secondary leading-[18px]">Supported version: Pytorch version &gt;= 1.11.x.</li>
          <li className="text-xs text-secondary leading-[18px]">
            If a model is defined in Pytorch, it should be converted into the GraphModule before being uploaded.
          </li>
          <li className="text-xs text-secondary leading-[18px]">
            The model must contain not only the status dictionary but also the structure of the model (do not use
            state_dict).
          </li>
          <li className="text-xs text-secondary leading-[18px]">
            How-to-guide for the conversion is at the{' '}
            <a href={MODEL_ZOO_LINK} className="underline" target="_blank" role="menuitem" rel="noreferrer">
              ModelZoo-torch
            </a>
            .
          </li>
        </ul>
        <h2 className="mt-2">Model Launcher</h2>
        <ul className="w-full list-disc ml-4">
          <li className="text-xs text-secondary leading-[18px]">
            PyTorch GraphModule to TensorRT, TensorFlow Lite, OpenVINO converting will be available soon
          </li>
        </ul>
      </div>
    </section>
  );
};
