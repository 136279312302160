import React, { createContext, useContext, useReducer } from 'react';
import { DatasetsAction, DatasetsState, datasetsReducer } from '../reducers';

type DatasetsContextType = [DatasetsState, React.Dispatch<DatasetsAction>];

export const DatasetsContext = createContext<DatasetsContextType | null>(null);

const initialState = { datasets: [] };

export const DatasetsContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(datasetsReducer, initialState);

  return <DatasetsContext.Provider value={[state, dispatch]}>{children}</DatasetsContext.Provider>;
};

export const useDatasetsContext = (): DatasetsContextType => {
  const context = useContext(DatasetsContext);

  if (!context) {
    throw new Error('useDatasetsContext must be used within a DatasetsContextProvider');
  }

  return context;
};
