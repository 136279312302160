import React, { useState } from 'react';
import { useFormState } from 'react-hook-form';

import { InputTextLabel, TextArea, TextInput } from '@netspresso/components';
import { PLACEHOLDER_MODEL_NAME, PLACEHOLDER_MODEL_MEMO } from '@netspresso/shared';

import { useUploadModelFormContext } from '../../../../../../context';
import { DESCRIPTION, MODEL_NAME } from '../../../../../../constants';

export const ModelSetting: React.FC = () => {
  const [modelName, setModelName] = useState('');
  const [modelMemo, setModelMemo] = useState('');
  const { setValue, control } = useUploadModelFormContext();
  const { errors } = useFormState({ control });

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setModelName(value);
    setValue(MODEL_NAME, value, { shouldValidate: true, shouldDirty: true });
  };

  const onMemoChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const { value } = event.target;

    setModelMemo(value);
    setValue(DESCRIPTION, value, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <>
      <section className="mb-3" data-test="dataset-name-input">
        <InputTextLabel htmlFor={MODEL_NAME}>Model name *</InputTextLabel>
        <TextInput
          id={MODEL_NAME}
          placeholder={PLACEHOLDER_MODEL_NAME}
          error={errors[MODEL_NAME] ? errors[MODEL_NAME].message : ''}
          value={modelName}
          width="w-1/2"
          onChange={onNameChange}
        />
      </section>
      <section className="mb-8" data-test="dataset-memo-input">
        <InputTextLabel htmlFor={DESCRIPTION}>Memo</InputTextLabel>
        <TextArea
          id={DESCRIPTION}
          error={errors[DESCRIPTION] ? errors[DESCRIPTION].message : ''}
          placeholder={PLACEHOLDER_MODEL_MEMO}
          value={modelMemo}
          onChange={onMemoChange}
        />
      </section>
    </>
  );
};
