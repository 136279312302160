export enum SideNavActions {
  Show = 'show_nav',
  Hide = 'hide_nav',
  Expand = 'expand_nav',
  Shrink = 'shrink_nav',
}

export type SideNavAction = {
  type: SideNavActions;
  state: SideNavState;
};

export type SideNavState = {
  isOpen: boolean;
  isExpand: boolean;
};

export const sideNavReducer = (_state: SideNavState, action: SideNavAction): SideNavState => {
  switch (action.type) {
    case SideNavActions.Show:
      return { isOpen: true, isExpand: action.state.isExpand };
    case SideNavActions.Hide:
      return { isOpen: false, isExpand: action.state.isExpand };
    case SideNavActions.Expand:
      return { isOpen: action.state.isOpen, isExpand: true };
    case SideNavActions.Shrink:
      return { isOpen: action.state.isOpen, isExpand: false };
    default:
      throw new Error(`Unhandled action :${action.type}`);
  }
};
