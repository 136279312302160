import { apiClient, API_MODEL_V1 } from '@netspresso/shared';
import { Model } from '../../lib';

export const ModelService = (() => {
  const getModels = () => {
    return apiClient.get<Model[]>(API_MODEL_V1);
  };

  const getModel = (modelId: string) => {
    return apiClient.get<Model>(`${API_MODEL_V1}/${modelId}`);
  };

  const createModel = (payload: FormData) => {
    return apiClient.post(API_MODEL_V1, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  const downloadModel = (modelId: string) => {
    return apiClient.get(`${API_MODEL_V1}/${modelId}/download`);
  };

  return {
    downloadModel,
    getModels,
    getModel,
    createModel,
  };
})();
