import { MAIL_LINK } from '@netspresso/shared';
import React from 'react';

type ResultContentProps = {
  isRetrainable?: boolean;
};

export const ResultContent: React.FC<ResultContentProps> = ({ isRetrainable }) => {
  return (
    <>
      {isRetrainable ? (
        <p>
          Compressed model needs to be retrained to recover the accuracy. Since the model is created with NetsPresso,
          you can create a project to retrain the model immediately.
        </p>
      ) : (
        <p>
          We recommend you retrain the model to recover the accuracy.
          <br />
          Please see our{' '}
          <a
            className="inline-block underline text-secondary hover:text-secondary-active"
            target="_blank"
            rel="noreferrer"
            href="https://github.com/Nota-NetsPresso/NetsPresso-Model-Compressor-ModelZoo/tree/main/best_practices"
          >
            Best Practices
          </a>{' '}
          to see the retraining process. Please use the same dataset that was used to train the original model.
          <br />
          If you have any troubles with retraining, please leave a feedback on our Github Discussion or contact to{' '}
          <a className="inline-block underline text-secondary hover:text-secondary-active" href={MAIL_LINK}>
            netspresso@nota.ai
          </a>
          .
        </p>
      )}
    </>
  );
};
