import { AvailableLayer } from '../../lib';
import { getIndexListFromLayerInput } from '../networkLayer';

export const getNewLayers = (layers: AvailableLayer[], layerName: string, columnName: string, value: string) => {
  return layers.map((layer) => {
    if (layer.name === layerName) {
      if (['inRank'].includes(columnName)) {
        return { ...layer, values: [parseFloat(value), layer.values[1]] };
      }

      if (['outRank'].includes(columnName)) {
        return { ...layer, values: [layer.values[0], parseFloat(value)] };
      }

      if (['channels'].includes(columnName)) {
        return {
          ...layer,
          values: getIndexListFromLayerInput(`${value}`),
          text: value,
        };
      }

      return { ...layer, values: [parseFloat(value)] };
    }

    return { ...layer };
  });
};
