import React from 'react';
import { OptionType } from '../../lib/types';

interface SelectProps {
  options: OptionType[];
  value: string;
  id?: string;
  label?: string;
  className?: string;
  isDisabled?: boolean;
  labelProp?: string;
  margin?: string;
  width?: string;
  valueProp?: keyof OptionType;
  dataTestId?: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}

export const Select: React.FC<SelectProps> = ({
  id,
  className,
  label,
  value,
  onChange,
  margin = '',
  width = 'w-full',
  valueProp = 'value',
  labelProp = 'label',
  dataTestId = 'select',
  options = [],
  isDisabled = false,
}) => {
  return (
    <>
      {label ? <span className={`block text-sm text-darkGray ${className}`}>{label}</span> : null}
      <select
        className={`border border-defaultGray text-darkGray placeholder-disabledGray rounded px-3 py-1 disabled:bg-lineGray disabled:text-componentGray focus:outline-none focus:border-secondary ${width} ${margin}`}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        data-testid={dataTestId}
      >
        {options.length > 0 ? (
          options.map((item) => {
            return (
              <option key={item[valueProp]} value={item[valueProp]} disabled={item.isDisabled}>
                {item[labelProp]}
              </option>
            );
          })
        ) : (
          <option value="">No option to select</option>
        )}
      </select>
    </>
  );
};
