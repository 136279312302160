import React from 'react';

type MethodRadioProps = {
  groupName?: string;
  label: string;
  value: string | number;
  classes?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  isChecked?: boolean;
  isDisabled?: boolean;
};

export const MethodRadio: React.FC<MethodRadioProps> = ({
  groupName,
  label,
  value,
  classes,
  onChange,
  isChecked = false,
  isDisabled = false,
}) => {
  const id = `${groupName}-${value}`.replace(/ /g, '_');

  return (
    <label className={`relative radio-method ${classes}`} htmlFor={id}>
      <input
        type="radio"
        id={id}
        name={groupName}
        value={value}
        disabled={isDisabled}
        checked={isChecked}
        onChange={onChange}
      />
      <span>{label}</span>
    </label>
  );
};
