/* eslint-disable react/prop-types */
import React from 'react';
import { CompressNode, useNetworkLayerContext } from '../../../../context/NetworkLayerContext';
import { AvailableLayer, CompressModel } from '../../../../lib';
import { getModelShapeString } from '../../../../utils';
import { LatencyGuage } from '../LatencyGuage';

type NetworkGraphNodeProps = {
  model: CompressModel;
  layers: AvailableLayer[] | null;
  onClickLayerCheckbox: React.ChangeEventHandler<HTMLInputElement>;
  element: CompressNode;
  maxLatency: number;
  disabled: boolean;
};

// FIXME: opacity class 적용 안됨
export const NetworkGraphNode: React.FC<NetworkGraphNodeProps> = ({
  model,
  layers,
  element,
  onClickLayerCheckbox,
  maxLatency,
  disabled,
}) => {
  const [, setSelectedLayer] = useNetworkLayerContext();

  const showLatencyGauge = (
    availableLayers: AvailableLayer[] | null,
    compressNode: CompressNode,
    compressModel: CompressModel
  ) => {
    if (availableLayers) {
      const selectedLayer = availableLayers.find((layer) => layer.name === compressNode.values.name);

      if (selectedLayer) {
        const selectedLatency = selectedLayer.latency.find(
          (latency) => latency.device_name === compressModel.target_device
        );

        if (selectedLatency) {
          return <LatencyGuage latency={selectedLatency.value} max={maxLatency} />;
        }
      }
    }

    return <></>;
  };

  const handleClickNode: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (element.isAvailable) {
      document.getElementById(`table-layer-${element.values.name}`)?.scrollIntoView(true);
    }
    setSelectedLayer(element);
  };

  const drawModelShape = (inputShape: string) => {
    return (
      <div className="p-1 text-center text-xs border-b border-gray-300">
        {`${getModelShapeString(inputShape, 'N')}`}
      </div>
    );
  };

  return (
    <div
      className={`text-sm border border-gray-300 rounded overflow-hidden cursor-pointer ${
        !element.isAvailable && 'opacity-30'
      }`}
      role="button"
      tabIndex={-1}
      onClick={handleClickNode}
    >
      {element.values.input_shape && drawModelShape(element.values.input_shape)}
      <div className="p-1">
        <div className="text-center">
          {element.isAvailable ? (
            <label
              className="flex items-center justify-center gap-1"
              htmlFor={`network-checkbox${element.values.name}`}
            >
              <input
                type="checkbox"
                id={`network-checkbox${element.values.name}`}
                name={`${element.values.name}`}
                data-type="network"
                data-layer-name={element.values.name}
                checked={layers?.find((layer) => layer.name === element.values.name)?.use}
                onChange={onClickLayerCheckbox}
                disabled={disabled}
              />
              <b>{`${element.values.name}`}</b>
            </label>
          ) : (
            <b>{`${element.values.name}`}</b>
          )}
        </div>
        {showLatencyGauge(layers, element, model)}
      </div>
      {element.values.output_shape && (
        <div className="p-1 text-center text-xs border-t border-gray-300">
          {`${getModelShapeString(element.values.output_shape, 'N')}`}
        </div>
      )}
    </div>
  );
};
