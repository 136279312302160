/* eslint-disable camelcase */
import {
  HeaderText,
  InfoButton,
  InfoContainer,
  InfoText,
  LabeledPerformanceDisplay,
  LabeledValueDisplay,
  Panel,
  PanelColumn,
  PanelContent,
  PanelHeader,
} from '@netspresso/components';
import { API_PROJECT_V1, formatizeLatency, TAO } from '@netspresso/shared';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoaderContext, useModalContext, useSaveModelFormContext } from '../../../../context';
import {
  FRAMEWORKS,
  isDevicesWithCpuInfo,
  isDevicesWithGpuInfo,
  Model,
  Project,
  ProjectTaskType,
} from '../../../../lib';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { ModelService } from '../../../../services';
import {
  getMaxMetric,
  isImageClassification,
  isObjectDetection,
  isSemanticSegmentation,
  parseCPU,
  parseDevices,
  parseGPU,
  useFetch,
  useGTM,
} from '../../../../utils';
import { SaveModel } from '../../components/SaveModel';

export const ProjectPerformance: React.FC = () => {
  const navigate = useNavigate();
  const { reset } = useSaveModelFormContext();
  const [, dispatchLoading] = useLoaderContext();
  const [, dispatchModal] = useModalContext();
  const { projectUid } = useParams();
  const [projectName, setProjectName] = useState('');
  const [projectNote, setProjectNote] = useState('');
  const [projectTask, setProjectTask] = useState<ProjectTaskType>();
  const [projectDevice, setProjectDevice] = useState('');
  const [outputModel, setOutputModel] = useState('');
  const [cpu, setCPU] = useState('');
  const [gpu, setGPU] = useState('');
  const [targetLatency, setTargetLatency] = useState('0');
  const [accuracy5, setAccuracy5] = useState('0');
  const [accuracy, setAccuracy] = useState('0');
  const [accuracyPrecision, setAccuracyPrecision] = useState('0');
  const [accuracyRecall, setAccuracyRecall] = useState('0');
  const [accuracyCls, setAccuracyCls] = useState('0');
  const [accuracyMIoU, setAccuracyMIoU] = useState('0');
  const [accuracyPix, setAccuracyPix] = useState('0');
  const [accuracyF1, setAccuracyF1] = useState('0');
  const [modelSize, setModelSize] = useState(0);
  const [footprintGPU, setFootprintGPU] = useState(0);
  const [footprintCPU, setFootprintCPU] = useState(0);
  const [powerConsuption, setPowerConsumption] = useState(0);
  const [ramSize, setRamSize] = useState(0);
  const [pythonVersion, setPythonVersion] = useState('');
  const [numpyVersion, setNumpyVersion] = useState('');
  const [openvinoVersion, setOpenVinoVersion] = useState('');
  const [tensorrtVersion, setTensorrtVersion] = useState('');
  const [jetpackVersion, setJetpackVersion] = useState('');
  const [tfliteVersion, setTfliteVersion] = useState('');
  const [isSelected] = useState(0);
  const [resultModelId, setResultModelId] = useState('');
  const [resultModel, setResultModel] = useState<Model>();
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { setPageToDataLayer } = useGTM();

  const { data: projectData, loading: projectLoading, error } = useFetch<Project>(`${API_PROJECT_V1}/${projectUid}`);

  if (error) {
    navigate('/not_found', { replace: true });
  }

  useEffect(() => {
    setPageToDataLayer('Project Performance');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatchLoading({ type: projectLoading || isLoading ? LoaderActions.Show : LoaderActions.Hide });
  }, [projectLoading, isLoading, dispatchLoading]);

  useEffect(() => {
    if (!projectData) {
      return;
    }

    const { project_name, note, task, device, trials, output_model_type } = projectData;

    setProjectName(project_name);

    if (note) {
      setProjectNote(note);
    }
    setProjectDevice(parseDevices(device));
    setProjectTask(task);
    setOutputModel(output_model_type);

    if (isDevicesWithCpuInfo(device)) {
      setCPU(parseCPU(device) || 'N/A');
    }

    if (isDevicesWithGpuInfo(device)) {
      setGPU(parseGPU(device) || 'N/A');
    }

    const { result_model_id } = trials[0];

    setResultModelId(result_model_id);
  }, [projectData]);

  const fetchResultModel = async (modelId: string) => {
    try {
      const res = await ModelService.getModel(modelId);

      if (res.status === 200) {
        setResultModel(res.data);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);

      const httpError = err as AxiosError;

      if (httpError.response?.status === 403 || httpError.response?.status === 500) {
        navigate('/forbidden');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (resultModelId) {
      setIsLoading(true);
      fetchResultModel(resultModelId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultModelId]);

  useEffect(() => {
    if (resultModel) {
      const { performance_data, dataset_id, measure_data, model_status_data, model_detail_data } = resultModel;

      const performanceData = performance_data[dataset_id];

      setIsVisible(model_status_data.is_visible);
      setModelSize(model_detail_data.model_size || 0);

      if (performanceData && projectTask) {
        if (isObjectDetection(projectTask)) {
          setAccuracy5(getMaxMetric(performanceData['metrics/mAP_0.5'] || 0));
          setAccuracy(getMaxMetric(performanceData['metrics/mAP_0.5:0.95'] || 0));
          setAccuracyPrecision(getMaxMetric(performanceData['metrics/precision'] || 0));
          setAccuracyRecall(getMaxMetric(performanceData['metrics/recall'] || 0));
        } else if (isImageClassification(projectTask)) {
          setAccuracyCls(getMaxMetric(performanceData.valid_accuracy));
        } else if (isSemanticSegmentation(projectTask) && outputModel === TAO) {
          setAccuracyMIoU(formatizeLatency(performanceData['Mean IOU'] || 0));
          setAccuracyF1(formatizeLatency(performanceData['F1 score'] || 0));
          setAccuracyPrecision(formatizeLatency(performanceData['Average precision'] || 0));
          setAccuracyRecall(formatizeLatency(performanceData['Average recall'] || 0));
        } else {
          setAccuracyMIoU(getMaxMetric(performanceData['valid_miou %']));
          setAccuracyPix(getMaxMetric(performanceData['valid_pixAcc %']));
        }
      }

      if (measure_data.length < 1) {
        return;
      }

      const measureData = measure_data[0];
      const measureMetric = measureData.measure_metric;

      if (measureMetric) {
        setTargetLatency((measureMetric.latency * 1000).toFixed(2));

        setFootprintGPU(measureMetric.memory_footprint_mb_gpu || 0);
        setFootprintCPU(measureMetric.memory_footprint_mb_cpu || 0);
        setPowerConsumption(measureMetric.power_consumption || 0);
      }

      const deviceSpec = measureData.device_spec;

      setProjectDevice(parseDevices(deviceSpec.device_name));
      const versions = deviceSpec.installed_software_versions;

      if (versions.numpy) {
        setNumpyVersion(versions.numpy[0]);
      }

      if (versions.python) {
        setPythonVersion(versions.python);
      }

      if (versions['openvino-runtime']) {
        setOpenVinoVersion(versions['openvino-runtime'][0]);
      }

      if (versions.tensorrt) {
        setTensorrtVersion(versions.tensorrt[0]);
      }

      if (versions.jetpack) {
        setJetpackVersion(versions.jetpack[0]);
      }

      if (versions.tflite) {
        setTfliteVersion(versions.tflite[0]);
      }
      setRamSize(Math.round((deviceSpec.ram_size / 1024) * 100) / 100);
    }
  }, [resultModel, projectTask, outputModel]);

  const onSaveModel: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    reset();
    dispatchModal({ type: ModalActions.Show, payload: <SaveModel modelId={resultModelId} /> });
  };

  return (
    <Panel>
      <PanelHeader className="pl-6 mb-4">{projectName}</PanelHeader>
      {projectNote && <p className="text-m text-darkGray font-normal pl-6 mb-4">{projectNote}</p>}
      <section className="bg-white rounded-lg shadow mb-6 p-6">
        {!isVisible && (
          <InfoContainer>
            <>
              <InfoText>
                Save this model to &quot;Models&quot;. You can download the files on &quot;Models&quot;.
              </InfoText>
              <InfoButton onClick={onSaveModel} disabled={!!isSelected}>
                Save Model
              </InfoButton>
            </>
          </InfoContainer>
        )}
        {projectTask && (
          <section className={`${!isVisible ? 'pt-8' : ''}`}>
            <HeaderText>Model performance</HeaderText>
            {isObjectDetection(projectTask) && (
              <section className="flex flex-row mb-4 pt-4">
                <LabeledPerformanceDisplay className="mb-2" dataTestId="accuracy5" value={accuracy5 || 'N/A'}>
                  Accuracy
                  <br />
                  (mAP 0.5)
                </LabeledPerformanceDisplay>
                <LabeledPerformanceDisplay className="mb-2" value={accuracy || 'N/A'}>
                  Accuracy
                  <br />
                  (mAP 0.5:0.95)
                </LabeledPerformanceDisplay>
                <LabeledPerformanceDisplay className="mb-2" value={accuracyPrecision || 'N/A'}>
                  Accuracy
                  <br />
                  (Precision)
                </LabeledPerformanceDisplay>
                <LabeledPerformanceDisplay className="mb-2" isLast value={accuracyRecall || 'N/A'}>
                  Accuracy
                  <br />
                  (Recall)
                </LabeledPerformanceDisplay>
              </section>
            )}
            {isImageClassification(projectTask) && (
              <section className="flex flex-row mb-4 pt-4">
                <LabeledPerformanceDisplay className="mb-2" value={accuracyCls}>
                  Accuracy
                  <br />
                  (Top-1 Accuracy (%))
                </LabeledPerformanceDisplay>
              </section>
            )}
            {isSemanticSegmentation(projectTask) ? (
              outputModel === TAO ? (
                <section className="flex flex-row mb-4 pt-4">
                  <LabeledPerformanceDisplay className="mb-2" value={accuracyMIoU}>
                    Accuracy
                    <br />
                    (mIoU)
                  </LabeledPerformanceDisplay>
                  <LabeledPerformanceDisplay className="mb-2" value={accuracyF1}>
                    Accuracy
                    <br />
                    (F1 Score)
                  </LabeledPerformanceDisplay>
                  <LabeledPerformanceDisplay className="mb-2" value={accuracyPrecision || 'N/A'}>
                    Accuracy
                    <br />
                    (Precision)
                  </LabeledPerformanceDisplay>
                  <LabeledPerformanceDisplay className="mb-2" isLast value={accuracyRecall || 'N/A'}>
                    Accuracy
                    <br />
                    (Recall)
                  </LabeledPerformanceDisplay>
                </section>
              ) : (
                <section className="flex flex-row mb-4 pt-4">
                  <LabeledPerformanceDisplay className="mb-2" value={accuracyMIoU}>
                    Accuracy
                    <br />
                    (mIoU %)
                  </LabeledPerformanceDisplay>
                  <LabeledPerformanceDisplay className="mb-2" value={accuracyPix}>
                    Accuracy
                    <br />
                    (pixAcc %)
                  </LabeledPerformanceDisplay>
                </section>
              )
            ) : null}
            <section className="flex flex-row">
              <LabeledPerformanceDisplay className="mb-1" value={targetLatency || 'N/A'}>
                Latency
                <br />
                (ms)
              </LabeledPerformanceDisplay>
              <LabeledPerformanceDisplay className="mb-1" value={modelSize ? modelSize.toFixed(2) : 'N/A'}>
                Model size
                <br />
                (MB)
              </LabeledPerformanceDisplay>
              <LabeledPerformanceDisplay className="mb-1" value={footprintGPU ? footprintGPU.toFixed(2) : 'N/A'}>
                Memory footprint
                <br />
                GPU (MB)
              </LabeledPerformanceDisplay>
              <LabeledPerformanceDisplay className="mb-1" value={footprintCPU ? footprintCPU.toFixed(2) : 'N/A'}>
                Memory footprint
                <br />
                CPU (MB)
              </LabeledPerformanceDisplay>
              <LabeledPerformanceDisplay
                className="mb-1"
                isLast
                value={powerConsuption > 0 ? powerConsuption.toFixed(2) : 'N/A'}
              >
                Power
                <br />
                consumption (Wh)
              </LabeledPerformanceDisplay>
            </section>
          </section>
        )}
        <section className="pt-8">
          <HeaderText>Device setting info</HeaderText>
          <PanelContent className="pt-4 flex-row">
            <PanelColumn>
              <LabeledValueDisplay label="Device">{projectDevice}</LabeledValueDisplay>
              <LabeledValueDisplay label="Library" className="pt-4">
                {`Python ${pythonVersion}`}
                <br />
                {`NumPy ${numpyVersion}`}
                <br />
                {outputModel === FRAMEWORKS.TENSORFLOW_LITE ? (
                  <>{`TFLite ${tfliteVersion}`}</>
                ) : outputModel === FRAMEWORKS.TENSORRT ? (
                  <>
                    {`JetPack ${jetpackVersion}`}
                    <br />
                    {`TensorRT ${tensorrtVersion}`}
                  </>
                ) : (
                  openvinoVersion && <>{`OpenVINO ${openvinoVersion}`}</>
                )}
              </LabeledValueDisplay>
            </PanelColumn>
            <PanelColumn>
              <LabeledValueDisplay label="CPU">{cpu}</LabeledValueDisplay>
              <LabeledValueDisplay label="GPU" className="pt-4">
                {gpu}
              </LabeledValueDisplay>
            </PanelColumn>
            <PanelColumn>
              <LabeledValueDisplay label="RAM">{ramSize} GB</LabeledValueDisplay>
              <LabeledValueDisplay label="Power mode">
                {projectDevice.indexOf('Jetson') > -1 ? 'Max' : 'N/A'}
              </LabeledValueDisplay>
            </PanelColumn>
          </PanelContent>
        </section>
      </section>
    </Panel>
  );
};
