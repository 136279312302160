import React, { createContext, useContext } from 'react';
import { useAuth, AuthType } from './hooks';

export const AuthContext = createContext<AuthType | null>(null);

export const AuthContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuthContext = (): AuthType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }

  return context;
};
