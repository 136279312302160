import React from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

type ResultBarChartProps = {
  name: string;
  originalModel: number;
  compressedModel: number;
  compressedLabel: string;
  hasLegend?: boolean;
};

export const ResultBarChart: React.FC<ResultBarChartProps> = ({
  name,
  originalModel,
  compressedModel,
  compressedLabel,
  hasLegend = false,
}) => {
  return (
    <div className="w-[426px] h-[190px]">
      <ResponsiveContainer width="100%" height={190}>
        <BarChart
          data={[
            {
              name,
              'original model': originalModel,
              'compressed model': compressedModel,
              'compressed model label': compressedLabel,
            },
          ]}
          margin={{ top: 30 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {hasLegend && <Legend />}
          <Bar dataKey="original model" fill="#8884d8" isAnimationActive={false}>
            <LabelList dataKey="original model" position="top" />
          </Bar>
          <Bar dataKey="compressed model" fill="#82ca9d" isAnimationActive={false}>
            <LabelList dataKey="compressed model label" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
