import { InfoContainer, InfoText, MainActionButton, ModelHeadTableRow, TableHeader } from '@netspresso/components';
import { API_CONVERT_V1, API_MODEL_V1, JSONValue, RUNTIME_LINK } from '@netspresso/shared';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Prompt } from '../../components';
import { EmptyMessage } from '../../components/EmptyMessage';
import { REFRESH_INTERVAL } from '../../constants';
import { useLoaderContext, useModalContext, useModelsContext, useUploadModelFormContext } from '../../context';
import { Model } from '../../lib';
import { LoaderActions, ModalActions } from '../../reducers';
import { ModelService } from '../../services';
import { parseJsonWithNaN, useFetch, useGTM } from '../../utils';
import { ParentProject, Searchbar } from './components';

export const Models: React.FC = () => {
  const { reset } = useUploadModelFormContext();
  const [, dispatchModal] = useModalContext();
  const [query, setQuery] = useState('');
  const [models, dispatch] = useModelsContext();
  const { setPageToDataLayer } = useGTM();
  const [filteredModel, setFilteredModel] = useState<Model[]>([]);
  const [isConverting, setIsConverting] = useState(false);
  const [, dispatchLoading] = useLoaderContext();
  const navigate = useNavigate();

  const { data, loading } = useFetch<JSONValue>(API_MODEL_V1);
  const {
    data: availabilityData,
    loading: availabilityLoading,
    error,
  } = useFetch<{ availability: boolean }>(`${API_CONVERT_V1}/availability`);

  if (error) {
    navigate('/internal_error', { replace: true });
  }

  useEffect(() => {
    dispatchLoading({ type: loading || availabilityLoading ? LoaderActions.Show : LoaderActions.Hide });
  }, [loading, availabilityLoading, dispatchLoading]);

  useEffect(() => {
    if (availabilityData) {
      setIsConverting(!availabilityData?.availability);
    }
  }, [availabilityData]);

  useEffect(() => {
    const dispatchFetchedData = () => {
      if (data) {
        dispatch({ type: 'ready', payload: parseJsonWithNaN(data) });
      }
    };

    if (process.env.NODE_ENV !== 'test') {
      dispatchFetchedData();
      const refreshModel = setInterval(async () => {
        const res = await ModelService.getModels();

        dispatch({ type: 'ready', payload: parseJsonWithNaN(res.data as JSONValue) });
      }, REFRESH_INTERVAL);

      return () => clearInterval(refreshModel);
    }

    return () => {
      dispatchLoading({ type: LoaderActions.Hide });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data]);

  useEffect(() => {
    setPageToDataLayer('Models');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderModels = (item: Model) => {
    return (
      <ParentProject
        key={item.model_id}
        createdAt={item.created_time}
        modelId={item.model_id}
        datasetId={item.dataset_id}
        originFrom={item.origin_from}
        projectName={item.display_name}
        modelStatusData={item.model_status_data}
        performanceData={item.performance_data}
        modelFormat={item.model_format}
        framework={item.framework}
        task={item.task}
        models={item.children}
        isConverting={isConverting}
        onDelete={onClickDelete}
      />
    );
  };

  const onClickDelete =
    (onSubmitPrompt: React.MouseEventHandler<HTMLButtonElement>): React.MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      event.stopPropagation();
      dispatchModal({
        type: ModalActions.Show,
        payload: (
          <Prompt message="Are you sure you want to delete?" onClose={onClosePrompt} onSubmit={onSubmitPrompt} />
        ),
      });
    };

  const onClosePrompt: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const onClickConfirm = () => {
    if (query) {
      setFilteredModel(models.filter((item) => item.display_name.indexOf(query) > -1));
    } else {
      setFilteredModel([]);
    }
  };

  const onClickUpload: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    reset();
    navigate(`upload`);
  };

  return (
    <>
      <section className="mb-4 px-3 flex justify-between">
        <MainActionButton routeName="models" onClick={onClickUpload} />
        <Searchbar setQuery={setQuery} onConfirm={onClickConfirm} />
      </section>
      {models.length > 0 ? (
        <>
          <div className="flex flex-wrap">
            <div className="w-full px-3">
              <section className="bg-white w-full rounded-lg shadow mb-2 pb-4">
                <table className="w-full p-6">
                  <thead className="border-b border-lineGray">
                    <ModelHeadTableRow>
                      <TableHeader className="w-[46px]" />
                      <TableHeader>Name</TableHeader>
                      <TableHeader className="w-40">Task</TableHeader>
                      <TableHeader className="w-32">Device</TableHeader>
                      <TableHeader className="w-32">Metric</TableHeader>
                      <TableHeader className="w-24">Latency (ms)</TableHeader>
                      <TableHeader className="w-[172px]" />
                    </ModelHeadTableRow>
                  </thead>
                  {filteredModel.length > 0 ? (
                    <tbody>{filteredModel.map(renderModels)}</tbody>
                  ) : (
                    <tbody>{models.map(renderModels)}</tbody>
                  )}
                </table>
                {isConverting ? (
                  <InfoContainer infoType="warning" iconName="warning">
                    <InfoText color="warning">Model converting...</InfoText>
                  </InfoContainer>
                ) : null}
              </section>
            </div>
          </div>
          <section className="flex justify-center items-center mb-1">
            <span className="material-icons mr-1 text-warning">error_outline</span>
            <p className="text-xs text-darkGray">
              To test your model on device, refer to{' '}
              <strong>
                <a data-test="model-runtime-link" href={RUNTIME_LINK} target="_blank" rel="noreferrer">
                  runtime repository
                </a>
              </strong>{' '}
              .
            </p>
          </section>
        </>
      ) : (
        <EmptyMessage>
          Currently, there is no model.
          <br />
          Save the model from the project detail page.
        </EmptyMessage>
      )}
    </>
  );
};
