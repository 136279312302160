/* eslint-disable camelcase */
import { apiClient, API_DATASET_V1, API_PROJECT_V1 } from '@netspresso/shared';
import axios, { AxiosResponse } from 'axios';
import { Dataset, StorageLocationType } from '../../lib';
import { UploadDatasetPayloadType } from '../../utils';

export type UploadLinksType = {
  train_file?: {
    size: number;
    md5: string;
  };
  dataset_yaml_file: {
    size: number;
    md5: string;
  };
  val_file?: {
    size: number;
    md5: string;
  };
  test_file?: {
    size: number;
    md5: string;
  };
  storage_type: StorageLocationType;
};

type FileType = 'train' | 'val' | 'test';

const TEN_MINUTE = 600000;

export const DatasetService = (() => {
  const getDatasets = () => {
    return apiClient.get<Dataset[]>(API_DATASET_V1);
  };

  const getDatasetById = (datasetId: string) => {
    return apiClient.get<Dataset>(`${API_DATASET_V1}/${datasetId}`);
  };

  const getRetrainableDatasets = (datasetId: string) => {
    return apiClient.get<Dataset[]>(`${API_DATASET_V1}/${datasetId}/retrainable`);
  };

  const getProjects = (datasetId: string) => {
    return apiClient.get(`${API_PROJECT_V1}/dataset/${datasetId}`);
  };

  const uploadDataset = (payload: UploadDatasetPayloadType) => {
    return apiClient.post(API_DATASET_V1, payload, { timeout: TEN_MINUTE });
  };

  const createUploadLinks = (uuid: string, payload: UploadLinksType) => {
    return apiClient.put(`${API_DATASET_V1}/${uuid}/upload`, payload, { timeout: TEN_MINUTE });
  };

  const uploadFiles = async (urls: { chunk_size: number; url: string; upload_id: string }[], file: File) => {
    const result: Record<number, string> = {};
    let sentSize = 0;

    for (let i = 0; i < urls.length; i++) {
      const chunk = file.slice(sentSize, sentSize + urls[i].chunk_size);

      // eslint-disable-next-line no-await-in-loop
      const uploadRes = await axios.put(urls[i].url, chunk, {
        headers: { 'Content-Type': file.type },
        timeout: TEN_MINUTE,
      });

      result[i + 1] = uploadRes.headers.etag.replace(/"/g, '');

      sentSize += urls[i].chunk_size;
    }

    return result;
  };

  const uploadFileToPresignedUrl = (urls: { chunk_size: number; url: string; upload_id: string }[], file: File) => {
    if (urls.length === 1 && file) {
      return axios.put(urls[0].url, file, {
        headers: {
          'Content-Type': file.type,
        },
        timeout: TEN_MINUTE,
      });
    }

    return uploadFiles(urls, file);
  };

  const completeMultipartUpload = (
    uuid: string,
    fileType: FileType,
    uploadId: string,
    md5: string,
    etags: Record<number, string> | AxiosResponse
  ) => {
    return apiClient.post(`${API_DATASET_V1}/${uuid}/part_upload/complete`, {
      file_type: fileType,
      upload_id: uploadId,
      md5,
      etags,
    });
  };

  const abortMultipartUpload = (uuid: string, fileType: FileType, uploadId: string) => {
    return apiClient.post(`${API_DATASET_V1}/${uuid}/part_upload/abort`, {
      file_type: fileType,
      upload_id: uploadId,
    });
  };

  return {
    getDatasetById,
    getDatasets,
    getProjects,
    uploadDataset,
    createUploadLinks,
    uploadFileToPresignedUrl,
    getRetrainableDatasets,
    completeMultipartUpload,
    abortMultipartUpload,
  };
})();
