import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router';
import { createPortal } from 'react-dom';

import NetspressoWhiteLogo from '@netspresso/components/assets/logos/netspresso-w.svg';
import NetspressoLogo from '@netspresso/components/assets/logos/netspresso-c.svg';
import NotaLogo from '@netspresso/components/assets/logos/nota.svg';
import UserIcon from '@netspresso/components/assets/icons/user.svg';
import LockIcon from '@netspresso/components/assets/icons/lock.svg';

import { MAIL_LINK, PRIVACY_POLICY_LINK } from '@netspresso/shared/src/constants/Links';

import { useAuthContext } from '../../context';
import { DeactivatedUser } from '../../components/Notices';

export const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showIsDeactivated, setShowIsDeactivated] = useState(true);

  const { user, login, error, loading, isDeactivated } = useAuthContext();
  const location = useLocation();

  if (user) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  const onLogInClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    login(email, password).then(() => {
      setShowIsDeactivated(true);
    });
  };

  const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setEmail(event.currentTarget.value);
  };

  const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setPassword(event.currentTarget.value);
  };

  const closeModal = () => {
    setShowIsDeactivated(false);
  };

  return (
    <>
      <div className="relative flex h-screen">
        <div className="login-form">
          <a className="login-form__logo" href="/">
            <NetspressoWhiteLogo className="c-icon c-icon-custom-size c-sidebar-brand-full" />
          </a>
          <div className="login-form__content">
            <div className="text-white flex justify-between mb-10">
              <h1 className="text-4xl"> </h1>
              <a className="pt-5 pr-8 leading-4 underline" href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
                PRIVACY POLICY
              </a>
            </div>
            <form className="pl-3 pr-8 mb-20" data-test="login-form">
              <div className="relative mb-6">
                <UserIcon className="absolute top-4 left-4" />
                <input
                  className={`login-form__input ${error ? 'error' : ''}`}
                  data-test="login-email"
                  autoComplete="username"
                  placeholder="Email"
                  type="email"
                  onChange={onEmailChange}
                />
              </div>
              <div className="relative mb-2">
                <LockIcon className="absolute top-4 left-4" />
                <input
                  className={`login-form__input ${error ? 'error' : ''}`}
                  data-test="login-password"
                  autoComplete="current-password"
                  placeholder="Password"
                  type="password"
                  onChange={onPasswordChange}
                />
                {error ? (
                  <p className="mt-3 pl-3 text-xl text-notaRed-error" data-test="login-error">
                    {error}
                  </p>
                ) : null}
              </div>
              <div className="mb-18">
                <a href="/reset_password" className="text-white flex justify-end">
                  Forgot password?
                </a>
              </div>
              <div className="relative px-10">
                <button
                  type="button"
                  className={`border-3 pt-4 pb-3 text-white w-full rounded-lg text-2xl justify-center ${
                    loading ? 'spinner' : ''
                  }`}
                  data-test="login-submit"
                  disabled={loading}
                  onClick={onLogInClick}
                >
                  Log in
                </button>
              </div>
            </form>
            <div className="text-white text-sm">
              <strong>* Please note that the performance and compatibility may be limited.</strong>
              <p className="py-4">
                Since we are still trying to improve our solution and add new features to serve you better, your cordial
                feedback will be carefully considered to develop our future service.
              </p>
              <strong>Thank you in advance!</strong>
            </div>
          </div>
          <div className="absolute bottom-4 w-full text-center">
            <a className="text-white" href={MAIL_LINK}>
              netspresso@nota.ai
            </a>
          </div>
        </div>
        <div className="login-text">
          <div>
            <NetspressoLogo />
            <p className="mt-16">
              <span className="text-notaBlue-2">Lighter</span>,
            </p>
            <p>
              <span className="text-notaBlue-2">Faster</span>,
            </p>
            <p>
              <span className="text-notaBlue-2">Easier</span>.
            </p>
            <p>
              Automatic Platform <span className="text-notaBlue-2">NetsPresso</span>
            </p>
          </div>
          <div className="login-footer">
            <span className="text-sm text-notaGray-900 mt-6 mr-4">Copyright @ Nota Inc. All rights reserved.</span>
            <NotaLogo className="mt-4" />
          </div>
        </div>
      </div>
      {isDeactivated && showIsDeactivated
        ? createPortal(<DeactivatedUser onClose={closeModal} />, document.getElementById('modal') as HTMLElement)
        : null}
    </>
  );
};
