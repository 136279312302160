/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { AuthService, TokenService } from '@netspresso/app/src/services';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

interface RequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const compressApiClient = axios.create({
  baseURL: process.env.REACT_APP_COMPRESS_API_URL,
});

const setTokenOnRequestHeader = (config: AxiosRequestConfig) => {
  const token = TokenService.getLocalAccessToken();
  // const refreshToken = TokenService.getLocalRefreshToken();

  if (token) {
    if (config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
  }

  return config;
};

const refershTokenOnResponse = async (err: AxiosError) => {
  // config에 동적으로 변수를 할당하기 위해 as 사용
  const originalConfig = err.config as RequestConfig;

  if (
    originalConfig.url &&
    originalConfig.url.indexOf('/token') < 0 &&
    err.response &&
    err.response.data.detail &&
    err.response.data.detail.message === 'JWT has expired'
  ) {
    // Access Token was expired
    if (!originalConfig._retry) {
      originalConfig._retry = true;

      try {
        const rs = await AuthService.refreshToken({
          access_token: TokenService.getLocalAccessToken(),
          refresh_token: TokenService.getLocalRefreshToken(),
        });

        const { tokens } = rs.data;

        TokenService.updateLocalAccessToken(tokens.access_token);
        TokenService.updateLocalRefreshToken(tokens.refresh_token);

        return apiClient(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  } else if (
    err.response &&
    err.response.data.detail &&
    (err.response.data.detail.message === 'Invalid refresh token' ||
      err.response.data.detail.message.indexOf('Access token and refresh token are not matched') > -1)
  ) {
    TokenService.removeLocalTokens();
    TokenService.removeUser();
    window.location.href = `${process.env.REACT_APP_PORTAL_URL}/signin`;
  }

  return Promise.reject(err);
};

// request interceptors
apiClient.interceptors.request.use(setTokenOnRequestHeader, (error) => {
  return Promise.reject(error);
});

compressApiClient.interceptors.request.use(setTokenOnRequestHeader, (error) => {
  return Promise.reject(error);
});

// response interceptors
apiClient.interceptors.response.use((res) => res, refershTokenOnResponse);

compressApiClient.interceptors.response.use((res) => res, refershTokenOnResponse);
