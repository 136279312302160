/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  DetailLabel,
  HeaderText,
  HyperParameterDisplay,
  LabeledValueDisplay,
  Panel,
  PanelContent,
  PanelHeader,
} from '@netspresso/components';
import {
  JETPACK_441,
  JETPACK_46,
  JETPACK_502,
  NotificationMessages,
  RETRAINING,
  STATUS_MISMATCH_ERROR_CODE,
  TasksMap,
} from '@netspresso/shared';

import { AxiosError, AxiosResponse } from 'axios';
import { Dialog } from '../../../../components';
import {
  LEVEL_DANGER,
  LEVEL_INFO,
  LEVEL_SUCCESS,
  Toast,
  useNotificationContext,
} from '../../../../components/Notifications';
import { REFRESH_INTERVAL } from '../../../../constants';
import { useAuthContext, useLoaderContext, useModalContext } from '../../../../context';
import {
  AugmentationDetailsType,
  BaseModelTypes,
  DEVICES,
  DeviceNames,
  DevicesMap,
  FRAMEWORKS,
  HyperparameterDetailsType,
  InferenceDetailType,
  NPProjectError,
  Project,
  ProjectStatus,
  ProjectTaskType,
  ProjectTypes,
  Recommendations,
  Trial,
  isRecommendationType,
} from '../../../../lib';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { ProjectService } from '../../../../services';
import {
  isBenchmarkingError,
  isConfigError,
  isConvertingError,
  isImageClassification,
  isObjectDetection,
  isSemanticSegmentation,
  isSubmitted,
  isTrainingError,
  isTrainingStopped,
  parseBaseModel,
  parseDevices,
  parseProjectType,
  parseRecommendationsModel,
  useGTM,
} from '../../../../utils';
import { ProjectStatusDisplayer } from '../../components';
import { EditProjectDialog } from './components';

export const ProjectDetails: React.FC = () => {
  const navigate = useNavigate();
  const { projectUid } = useParams();
  const { loading: authLoading } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState<Project>();
  const [projectTask, setProjectTask] = useState<ProjectTaskType>();
  const [projectName, setProjectName] = useState('');
  const [datasetName, setDatasetName] = useState('');
  const [projectNote, setProjectNote] = useState('');
  const [targetDevice, setTargetDevice] = useState<DeviceNames>();
  const [outputModel, setOutputModel] = useState('');
  const [projectType, setProjectType] = useState<ProjectTypes>();
  const [projectStatus, setProjectStatus] = useState<ProjectStatus>();
  const [projectTrials, setProjectTrials] = useState<Trial[]>([]);
  const [outputDataType, setOutputDataType] = useState('');
  const [startTime, setStartTime] = useState('');
  const [estimatedFinishTime, setEstimatedFinishTime] = useState('');
  const [targetLatency, setTargetLatency] = useState<number | null>(0);
  const [architecture, setArchitecture] = useState<BaseModelTypes>();
  const [baseModel, setBaseModel] = useState<Recommendations>();
  const [imageSizeWidth, setImageSizeWidth] = useState(0);
  const [imageSizeHeight, setImageSizeHeight] = useState(0);
  const [batchSize, setBatchSize] = useState(0);
  const [epochNum, setEpochNum] = useState(0);
  const [jetpackVersion, setJetpackVersion] = useState(JETPACK_46);
  const [hyperParameter, setHyperParameter] = useState<HyperparameterDetailsType>({
    lr0: 0,
    lrf: 0,
    momentum: 0,
    weight_decay: 0,
    warmup_epochs: 0,
    warmup_momentum: 0,
    warmup_bias_lr: 0,
    box: 0,
    cls: 0,
    cls_pw: 0,
    obj: 0,
    obj_pw: 0,
    iou_t: 0,
    anchor_t: 0,
    fl_gamma: 0,
  });
  const [augementation, setAugementation] = useState<AugmentationDetailsType>({
    hsv_h: 0,
    hsv_s: 0,
    hsv_v: 0,
    degrees: 0,
    translate: 0,
    scale: 0,
    shear: 0,
    perspective: 0,
    flipud: 0,
    fliplr: 0,
    mosaic: 0,
    mixup: 0,
    copy_paste: 0,
  });
  const [inference, setInference] = useState<InferenceDetailType>({
    slide_eval: false,
    stride: 512,
    crop_w: 768,
    crop_h: 768,
  });
  const { setPageToDataLayer } = useGTM();
  const [, dispatchLoading] = useLoaderContext();
  const [, dispatchModal] = useModalContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();

  const fetchProject = async (projectId: string) => {
    try {
      const res = await ProjectService.getProjectById(projectId);

      if (res.status === 200) {
        setProject(res.data);
      }
    } catch (err) {
      const error = err as AxiosError;

      if (error.response?.status === 403 || error.response?.status === 500) {
        navigate('/forbidden');
      }

      if (error.response?.status === 404) {
        navigate('/not_found', { replace: true });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (projectUid) {
      setIsLoading(true);
      fetchProject(projectUid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUid]);

  useEffect(() => {
    setPageToDataLayer('Project Details');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatchLoading({ type: isLoading || authLoading ? LoaderActions.Show : LoaderActions.Hide });
  }, [isLoading, authLoading, dispatchLoading]);

  useEffect(() => {
    const refreshDataset = setInterval(async () => {
      if (!projectUid) {
        clearInterval(refreshDataset);

        return;
      }

      const res = await ProjectService.getProjectById(projectUid);

      if (res.status === 200) {
        const { start_time, estimated_finish_time } = res.data;

        setStartTime(start_time);
        setEstimatedFinishTime(estimated_finish_time || '');
      }
    }, REFRESH_INTERVAL);

    return () => {
      clearInterval(refreshDataset);
    };
  }, [projectUid]);

  useEffect(() => {
    if (!project) {
      return;
    }

    const {
      project_name,
      dataset_name,
      note,
      task,
      project_type,
      estimated_finish_time,
      output_data_type,
      output_model_type,
      output_model_version,
      base_model_name,
      device,
      base_architecture,
      status,
      start_time,
      img_size_width,
      img_size_height,
      output_batch_size,
      target_latency,
      epochs_per_trial,
      hyp_detail,
      augmentation_details,
      inference_detail,
      trials,
    } = project;

    setProjectNote(note);
    setProjectName(project_name);
    setDatasetName(dataset_name);
    setProjectTask(task);
    setProjectType(project_type);
    setOutputModel(output_model_type);
    setJetpackVersion(output_model_version);
    setProjectTrials(trials);

    if (base_model_name) {
      setBaseModel(base_model_name);
    }

    if (isSemanticSegmentation(task)) {
      setInference(inference_detail);
    }

    setArchitecture(base_architecture);
    setProjectStatus(status);
    setOutputDataType(output_data_type);
    setImageSizeWidth(img_size_width);
    setImageSizeHeight(img_size_height);
    setTargetDevice(parseDevices(device));
    setStartTime(start_time);
    setEstimatedFinishTime(estimated_finish_time || '');
    setBatchSize(output_batch_size);
    setTargetLatency(target_latency);
    setEpochNum(epochs_per_trial);
    setHyperParameter(hyp_detail);
    setAugementation(augmentation_details);
  }, [project]);

  const onClosePrompt: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const onEditComplete = () => {
    if (projectUid) {
      setIsLoading(true);
      fetchProject(projectUid);
    }
  };

  const onEditProject: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({
      type: ModalActions.Show,
      payload: (
        <EditProjectDialog
          dialogName={projectName}
          dialogMemo={projectNote}
          projectId={projectUid}
          onComplete={onEditComplete}
        />
      ),
    });
  };

  const onStopProject: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({
      type: ModalActions.Show,
      payload: (
        <Dialog
          title={NotificationMessages.stopProjectTitle}
          infoText={NotificationMessages.stopProjectMessage}
          onClickCancel={onClosePrompt}
          onClickConfirm={onSubmitStop}
        />
      ),
    });
  };

  const onSubmitStop: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();

    try {
      dispatchModal({ type: ModalActions.Hide });
      dispatchLoading({ type: LoaderActions.Show });

      if (!projectUid) {
        showToast(
          <Toast
            content="Required info is missing in this project."
            level={LEVEL_DANGER}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );

        return;
      }

      const res = await ProjectService.stopProject(projectUid);

      if (res.status === 200) {
        if (projectUid) {
          fetchProject(projectUid);
        }

        showToast(
          <Toast
            content="The project is successfully stopped."
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
      }
    } catch (err) {
      if ((err as NPProjectError)?.response?.data.detail.error_code === STATUS_MISMATCH_ERROR_CODE) {
        dispatchModal({
          type: ModalActions.Show,
          payload: (
            <Dialog
              title={NotificationMessages.projectStatusMismatchTitle}
              infoText={NotificationMessages.projectStatusMismatchMessage}
              onClickCancel={onClosePrompt}
              onClickConfirm={() => {
                dispatchModal({ type: ModalActions.Hide });
                !!projectUid && fetchProject(projectUid);
              }}
            />
          ),
        });

        return;
      }

      showToast(
        <Toast
          content="Requesting to stop failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const onResumeClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (!projectStatus) {
      showToast(
        <Toast
          content="Project status value is missing"
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );

      return;
    }

    const isFromInitial =
      isSubmitted(projectStatus) ||
      isConfigError(projectStatus) ||
      isTrainingError(projectStatus) ||
      isTrainingStopped(projectStatus);

    if (isFromInitial) {
      showToast(
        <Toast
          content="This page is redirected to select a training server."
          level={LEVEL_INFO}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );

      navigate(`/projects/${projectUid}/start`);

      return;
    }

    dispatchModal({
      type: ModalActions.Show,
      payload: (
        <Dialog
          title={NotificationMessages.resumeProjectTitle}
          infoText={
            projectType === RETRAINING
              ? NotificationMessages.resumeProjectMessage(50)
              : NotificationMessages.resumeProjectMessage(100)
          }
          onClickCancel={onClosePrompt}
          onClickConfirm={onSubmitResume}
        />
      ),
    });
  };

  const onSubmitResume: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });

    try {
      if (!projectTrials || projectTrials.length === 0) {
        showToast(
          <Toast
            content="An unexpected error occured."
            level={LEVEL_DANGER}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );

        return;
      }

      if (!projectStatus) {
        showToast(
          <Toast
            content="Project status value is missing"
            level={LEVEL_DANGER}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );

        return;
      }

      const trialId = projectTrials[0].trial_id;

      const isFromConverting = isConvertingError(projectStatus);

      if (isFromConverting) {
        showToastAfterResume(() => ProjectService.resumeProjectByTrialId(trialId, 'convert'));

        return;
      }

      const isFromBenchmarking = isBenchmarkingError(projectStatus);

      if (isFromBenchmarking) {
        showToastAfterResume(() => ProjectService.resumeProjectByTrialId(trialId, 'benchmark'));
      }
    } catch (err) {
      if ((err as NPProjectError)?.response?.data.detail.error_code === STATUS_MISMATCH_ERROR_CODE) {
        dispatchModal({
          type: ModalActions.Show,
          payload: (
            <Dialog
              title={NotificationMessages.projectStatusMismatchTitle}
              infoText={NotificationMessages.projectStatusMismatchMessage}
              onClickCancel={onClosePrompt}
              onClickConfirm={() => {
                dispatchModal({ type: ModalActions.Hide });
                !!projectUid && fetchProject(projectUid);
              }}
            />
          ),
        });

        return;
      }
      // eslint-disable-next-line no-console
      console.log(err);
      showToast(
        <Toast
          content="Requesting to resume failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const showToastAfterResume = async (apiCall: () => Promise<AxiosResponse>) => {
    try {
      const res = await apiCall();

      if (res.status === 200) {
        showToast(
          <Toast
            content="You requested to run a project successfully"
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
        navigate('/projects');
      }
    } catch (err) {
      showToast(
        <Toast
          content="Requesting to resume failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    }
  };

  return (
    <Panel className="relative">
      <PanelHeader className="pl-6 mb-4">{projectName}</PanelHeader>
      <button
        type="button"
        className="absolute top-0 right-6 text-m text-darkGray hover:text-secondary disabled:text-lineGray pt-1"
        onClick={onEditProject}
      >
        <span className="material-icons text-base">edit</span>
      </button>
      {projectNote && <p className="text-m text-darkGray font-normal pl-6 mb-4">{projectNote}</p>}
      <section className="bg-white rounded-lg shadow mb-6 pb-6">
        {projectStatus && targetDevice && (
          <ProjectStatusDisplayer
            status={projectStatus}
            targetLatency={targetLatency?.toString() || ''}
            targetDevice={targetDevice}
            onStopProject={onStopProject}
            onResumeProject={onResumeClick}
          />
        )}
        <PanelContent className="flex-col px-6 pt-6">
          <section className="w-full">
            <HeaderText className="mb-4">Image data</HeaderText>
            <div className="flex flex-row">
              {projectTask && (
                <LabeledValueDisplay label="Task" className="w-1/4">
                  {TasksMap[projectTask]}
                </LabeledValueDisplay>
              )}
              <LabeledValueDisplay label="Dataset" className="w-1/4">
                {datasetName}
              </LabeledValueDisplay>
            </div>
          </section>
          <section className="w-full pt-8">
            <HeaderText className="mb-4">Target device</HeaderText>
            <div className="flex flex-row">
              <LabeledValueDisplay label="Target device" className="w-1/4">
                {targetDevice}
              </LabeledValueDisplay>
              <LabeledValueDisplay label="Output format" className="w-1/4">
                {outputModel === FRAMEWORKS.TENSORRT ? (
                  targetDevice === DevicesMap[DEVICES.JETSON_AGX_ORIN] ? (
                    <>
                      JetPack 5.0.1-b118
                      <br />
                      TensorRT 8.4.0-1+cuda11.4
                    </>
                  ) : jetpackVersion === JETPACK_441 ? (
                    <>
                      JetPack 4.4.1-b50
                      <br />
                      TensorRT 7.1.3-1+cuda10.2
                    </>
                  ) : jetpackVersion === JETPACK_502 ? (
                    <>
                      JetPack 5.0.2-b231
                      <br />
                      TensorRT 8.4.1-1+cuda11.4
                    </>
                  ) : (
                    <>
                      JetPack 4.6-b199
                      <br />
                      TensorRT 8.0.1-1+cuda10.2
                    </>
                  )
                ) : outputModel === FRAMEWORKS.TENSORFLOW_LITE ? (
                  'Tensorflow Lite 2.9.1'
                ) : outputModel === FRAMEWORKS.ETLT ? (
                  'TAO Toolkit 4.0'
                ) : (
                  'OpenVINO™ Runtime 2022.1.0-7019-cdb9bec7210-releases/2022/1'
                )}
              </LabeledValueDisplay>
              <LabeledValueDisplay label="Data type" className="w-1/4">
                {outputDataType.toUpperCase()}
              </LabeledValueDisplay>
              <LabeledValueDisplay label="Batch size" className="w-1/4">
                {batchSize || 'N/A'}
              </LabeledValueDisplay>
            </div>
          </section>
          <section className="w-full pt-8">
            <HeaderText className="mb-4">Model training</HeaderText>
            <div className="flex flex-row">
              {projectType && (
                <LabeledValueDisplay label="Training method" className="w-1/4">
                  {parseProjectType(projectType)}
                </LabeledValueDisplay>
              )}
              {isRecommendationType(baseModel) ? (
                <LabeledValueDisplay label="Base model" className="w-1/4">
                  {baseModel === 'UNet (+ Resnet)' ? 'TAO UNet' : parseRecommendationsModel(baseModel)}
                </LabeledValueDisplay>
              ) : (
                architecture && (
                  <LabeledValueDisplay label="Base model architecture" className="w-1/4">
                    {parseBaseModel(architecture)}
                  </LabeledValueDisplay>
                )
              )}
              <LabeledValueDisplay label="Optimization objective" className="w-1/4">
                {projectType === RETRAINING ? 'N/A' : `Latency ${targetLatency || ''}ms`}
              </LabeledValueDisplay>
              <LabeledValueDisplay label="Defeault image size" className="w-1/4">
                {`${imageSizeWidth} x ${imageSizeHeight}`} px
              </LabeledValueDisplay>
            </div>
          </section>
          {projectTask && outputModel !== FRAMEWORKS.ETLT && (
            <section className="w-full pt-8">
              <HeaderText className="mb-4">Advanced options</HeaderText>
              <div className="flex flex-row">
                <LabeledValueDisplay label="Training epoch" className="w-1/4">
                  {epochNum || 'N/A'}
                </LabeledValueDisplay>
                <div className="w-1/4">
                  <DetailLabel>Hyperparameter</DetailLabel>
                  {isObjectDetection(projectTask) && (
                    <ul>
                      <HyperParameterDisplay label="lr0">{hyperParameter.lr0}</HyperParameterDisplay>
                      <HyperParameterDisplay label="lrf">{hyperParameter.lrf}</HyperParameterDisplay>
                      <HyperParameterDisplay label="momentum">{hyperParameter.momentum}</HyperParameterDisplay>
                      <HyperParameterDisplay label="weight_decay">{hyperParameter.weight_decay}</HyperParameterDisplay>
                      <HyperParameterDisplay label="warmup_epochs">
                        {hyperParameter.warmup_epochs}
                      </HyperParameterDisplay>
                      <HyperParameterDisplay label="warmup_momentum">
                        {hyperParameter.warmup_momentum}
                      </HyperParameterDisplay>
                      <HyperParameterDisplay label="warmup_bias_lr">
                        {hyperParameter.warmup_bias_lr}
                      </HyperParameterDisplay>
                      <HyperParameterDisplay label="box">{hyperParameter.box}</HyperParameterDisplay>
                      <HyperParameterDisplay label="cls">{hyperParameter.cls}</HyperParameterDisplay>
                      <HyperParameterDisplay label="cls_pw">{hyperParameter.cls_pw}</HyperParameterDisplay>
                      <HyperParameterDisplay label="obj">{hyperParameter.obj}</HyperParameterDisplay>
                      <HyperParameterDisplay label="obj_pw">{hyperParameter.obj_pw}</HyperParameterDisplay>
                      <HyperParameterDisplay label="iou_t">{hyperParameter.iou_t}</HyperParameterDisplay>
                      <HyperParameterDisplay label="anchor_t">{hyperParameter.anchor_t}</HyperParameterDisplay>
                      <HyperParameterDisplay label="fl_gamma">{hyperParameter.fl_gamma}</HyperParameterDisplay>
                    </ul>
                  )}
                  {isImageClassification(projectTask) && (
                    <ul>
                      <HyperParameterDisplay label="lr0">{hyperParameter.lr0}</HyperParameterDisplay>
                      <HyperParameterDisplay label="lrf">{hyperParameter.lrf}</HyperParameterDisplay>
                      <HyperParameterDisplay label="seed">{hyperParameter.seed}</HyperParameterDisplay>
                      <HyperParameterDisplay label="momentum">{hyperParameter.momentum}</HyperParameterDisplay>
                      <HyperParameterDisplay label="weight_decay">{hyperParameter.weight_decay}</HyperParameterDisplay>
                      <HyperParameterDisplay label="warmup_steps">{hyperParameter.warmup_steps}</HyperParameterDisplay>
                      <HyperParameterDisplay label="warmup_epochs">
                        {hyperParameter.warmup_epochs}
                      </HyperParameterDisplay>
                      <HyperParameterDisplay label="warmup_momentum">
                        {hyperParameter.warmup_momentum}
                      </HyperParameterDisplay>
                      <HyperParameterDisplay label="warmup_bias_lr">
                        {hyperParameter.warmup_bias_lr}
                      </HyperParameterDisplay>
                      <HyperParameterDisplay label="schd_power">{hyperParameter.schd_power}</HyperParameterDisplay>
                    </ul>
                  )}
                  {isSemanticSegmentation(projectTask) && (
                    <ul>
                      <HyperParameterDisplay label="lr0">{hyperParameter.lr0}</HyperParameterDisplay>
                      <HyperParameterDisplay label="lrf">{hyperParameter.lrf}</HyperParameterDisplay>
                      <HyperParameterDisplay label="seed">{hyperParameter.seed}</HyperParameterDisplay>
                      <HyperParameterDisplay label="weight_decay">{hyperParameter.weight_decay}</HyperParameterDisplay>
                      <HyperParameterDisplay label="warmup_steps">{hyperParameter.warmup_steps}</HyperParameterDisplay>
                      <HyperParameterDisplay label="schd_power">{hyperParameter.schd_power}</HyperParameterDisplay>
                      <HyperParameterDisplay label="reduce_zero_label">
                        {hyperParameter.reduce_zero_label?.toString() || ''}
                      </HyperParameterDisplay>
                    </ul>
                  )}
                </div>
                <div className="w-1/4">
                  <DetailLabel>Data augmentation</DetailLabel>
                  {isObjectDetection(projectTask) && (
                    <ul>
                      <HyperParameterDisplay label="hsv_h">{augementation.hsv_h}</HyperParameterDisplay>
                      <HyperParameterDisplay label="hsv_s">{augementation.hsv_s}</HyperParameterDisplay>
                      <HyperParameterDisplay label="hsv_v">{augementation.hsv_v}</HyperParameterDisplay>
                      <HyperParameterDisplay label="degrees">{augementation.degrees}</HyperParameterDisplay>
                      <HyperParameterDisplay label="translate">{augementation.translate}</HyperParameterDisplay>
                      <HyperParameterDisplay label="scale">{augementation.scale}</HyperParameterDisplay>
                      <HyperParameterDisplay label="shear">{augementation.shear}</HyperParameterDisplay>
                      <HyperParameterDisplay label="perspective">{augementation.perspective}</HyperParameterDisplay>
                      <HyperParameterDisplay label="flipud">{augementation.flipud}</HyperParameterDisplay>
                      <HyperParameterDisplay label="fliplr">{augementation.fliplr}</HyperParameterDisplay>
                      <HyperParameterDisplay label="mosaic">{augementation.mosaic}</HyperParameterDisplay>
                      <HyperParameterDisplay label="mixup">{augementation.mixup}</HyperParameterDisplay>
                      <HyperParameterDisplay label="copy_paste">{augementation.copy_paste}</HyperParameterDisplay>
                    </ul>
                  )}
                  {isImageClassification(projectTask) && (
                    <ul>
                      <HyperParameterDisplay label="mixup">{augementation.mixup}</HyperParameterDisplay>
                      <HyperParameterDisplay label="mixup_alpha">{augementation.mixup_alpha}</HyperParameterDisplay>
                      <HyperParameterDisplay label="cutmix_alpha">{augementation.cutmix_alpha}</HyperParameterDisplay>
                      <HyperParameterDisplay label="mixup_switch_prob">
                        {augementation.mixup_switch_prob}
                      </HyperParameterDisplay>
                    </ul>
                  )}
                  {isSemanticSegmentation(projectTask) && (
                    <ul>
                      <HyperParameterDisplay label="max_scale">{augementation.max_scale}</HyperParameterDisplay>
                      <HyperParameterDisplay label="min_scale">{augementation.min_scale}</HyperParameterDisplay>
                      <HyperParameterDisplay label="crop_size_w">{augementation.crop_size_w}</HyperParameterDisplay>
                      <HyperParameterDisplay label="crop_size_h">{augementation.crop_size_h}</HyperParameterDisplay>
                      <HyperParameterDisplay label="resize_ratio0">{augementation.resize_ratio0}</HyperParameterDisplay>
                      <HyperParameterDisplay label="resize_ratiof">{augementation.resize_ratiof}</HyperParameterDisplay>
                      <HyperParameterDisplay label="resize_add">{augementation.resize_add}</HyperParameterDisplay>
                      <HyperParameterDisplay label="fliplr">{augementation.fliplr}</HyperParameterDisplay>
                      {augementation.color_jitter && (
                        <>
                          <HyperParameterDisplay label="brightness">
                            {augementation.color_jitter.brightness}
                          </HyperParameterDisplay>
                          <HyperParameterDisplay label="contrast">
                            {augementation.color_jitter.contrast}
                          </HyperParameterDisplay>
                          <HyperParameterDisplay label="saturation">
                            {augementation.color_jitter.saturation}
                          </HyperParameterDisplay>
                          <HyperParameterDisplay label="hue">{augementation.color_jitter.hue}</HyperParameterDisplay>
                          <HyperParameterDisplay label="colorjitter_p">
                            {augementation.color_jitter.colorjitter_p}
                          </HyperParameterDisplay>
                        </>
                      )}
                    </ul>
                  )}
                </div>
                {isSemanticSegmentation(projectTask) && (
                  <div className="w-1/4">
                    <DetailLabel>Inference</DetailLabel>
                    <ul>
                      <HyperParameterDisplay label="slide_evaluation" dataTestId="slide_evaluation">
                        {inference.slide_eval.toString()}
                      </HyperParameterDisplay>
                      {inference.slide_eval ? (
                        <>
                          <HyperParameterDisplay label="stride" dataTestId="stride">
                            {inference.stride}
                          </HyperParameterDisplay>
                          <HyperParameterDisplay label="crop_w" dataTestId="crop_w">
                            {inference.crop_w}
                          </HyperParameterDisplay>
                          <HyperParameterDisplay label="crop_h" dataTestId="crop_h">
                            {inference.crop_h}
                          </HyperParameterDisplay>
                        </>
                      ) : null}
                    </ul>
                  </div>
                )}
              </div>
            </section>
          )}
        </PanelContent>
      </section>
    </Panel>
  );
};
