import { TASKS, TasksWithMetric } from '../types';

export const isTaskWithMetric = (value: unknown): value is TasksWithMetric => {
  switch (value) {
    case TASKS.OBJECT_DETECTION:
    case TASKS.IMAGE_CLASSIFICATION:
    case TASKS.SEMANTIC_SEGMENTATION:
      return true;
    default:
      return false;
  }
};
