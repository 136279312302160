import React, { ChangeEventHandler, useState } from 'react';
import { useFormState } from 'react-hook-form';

import { InputTextLabel, TextArea, TextInput, HeaderText } from '@netspresso/components';
import { PLACEHOLDER_MODEL_NAME, PLACEHOLDER_MODEL_MEMO } from '@netspresso/shared';
import { MODEL_NAME, DESCRIPTION } from '../../../../constants';
import { useConvertModelFormContext } from '../../../../context';

export const ModelSetting: React.FC = () => {
  const [modelName, setModelName] = useState('');
  const [modelDescription, setModelDescription] = useState('');
  const { setValue, watch, control } = useConvertModelFormContext();
  const { errors } = useFormState({ control });

  const watched = watch();

  const onNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setModelName(value);
    setValue(MODEL_NAME, value, { shouldValidate: true, shouldDirty: true });
  };

  const onDescriptionChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const { value } = event.target;

    setModelDescription(value);
    setValue(DESCRIPTION, value, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <>
      <HeaderText className="mb-4" type="formGroup">
        Model info
      </HeaderText>
      <section className="mb-3">
        <InputTextLabel htmlFor={MODEL_NAME}>Model name *</InputTextLabel>
        <TextInput
          id={MODEL_NAME}
          placeholder={PLACEHOLDER_MODEL_NAME}
          error={errors[MODEL_NAME] ? errors[MODEL_NAME].message : ''}
          value={watched[MODEL_NAME] || modelName}
          width="w-1/2"
          onChange={onNameChange}
        />
      </section>
      <section className="mb-8">
        <InputTextLabel htmlFor={DESCRIPTION}>Memo</InputTextLabel>
        <TextArea
          id={DESCRIPTION}
          error={errors[DESCRIPTION] ? errors[DESCRIPTION].message : ''}
          placeholder={PLACEHOLDER_MODEL_MEMO}
          value={watched[DESCRIPTION] || modelDescription}
          onChange={onDescriptionChange}
        />
      </section>
    </>
  );
};
