import { StorageLocationNames, StorageLocationType } from '@netspresso/app/src/lib';

export const AWS = 'aws';
export const AZURE = 'azure';
export const GCP = 'gcp';
export const S3_STORAGE = 's3';
export const LOCAL_STORAGE = 'local';

export const StorageMap: Record<StorageLocationType, StorageLocationNames> = {
  [S3_STORAGE]: 'NetsPresso server',
  [LOCAL_STORAGE]: 'Local storage',
};
