import { ReactNode } from 'react';

export enum ModalActions {
  Show = 'show_modal',
  Hide = 'hide_modal',
}

export type ModalAction = {
  type: ModalActions;
  payload?: ReactNode;
};

export type ModalState = {
  showModal: boolean;
  children?: ReactNode;
};

export const modalReducer = (_state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case ModalActions.Show:
      return { showModal: true, children: action.payload };
    case ModalActions.Hide:
      return { showModal: false, children: null };
    default:
      throw new Error(`Unhandled action :${action.type}`);
  }
};
