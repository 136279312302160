import { InputNumber, InputTextLabel } from '@netspresso/components';
import React, { useState } from 'react';
import { useFormState } from 'react-hook-form';
import { CHANNEL, INPUT_LAYERS } from '../../../../../../../../constants';
import { useUploadModelFormContext } from '../../../../../../../../context';

export const InputChannel: React.FC = () => {
  const { setValue, control } = useUploadModelFormContext();
  const { errors } = useFormState({ control });

  const [inputShapeChannel, setInputShapeChannel] = useState(3);

  const onInputShapeChannelChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = isNaN(parseInt(event.target.value, 10)) ? 0 : parseInt(event.target.value, 10);

    setInputShapeChannel(value);
    setValue(`${INPUT_LAYERS}.${CHANNEL}`, value, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <div className="w-1/12">
      <InputTextLabel htmlFor={CHANNEL}>Channel</InputTextLabel>
      <InputNumber
        id={CHANNEL}
        error={errors[INPUT_LAYERS] && errors[INPUT_LAYERS][CHANNEL] ? errors[INPUT_LAYERS][CHANNEL].message : ''}
        min={1}
        max={100}
        value={inputShapeChannel}
        placeholder="1~10"
        width="w-full"
        onChange={onInputShapeChannelChange}
      />
    </div>
  );
};
