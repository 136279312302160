import { FIELD_NAME, FormErrorMessages } from '@netspresso/shared';
import * as yup from 'yup';
import { NOTE, PROJECT_NAME } from '../constants';

export const EditProjectScheme = yup.object().shape({
  [PROJECT_NAME]: yup
    .string()
    .required(FormErrorMessages.isRequired)
    .min(2, FormErrorMessages.isNotMin(2))
    .max(20, FormErrorMessages.isNotMax(20))
    .matches(FIELD_NAME, FormErrorMessages.isWrongCharacters),
  [NOTE]: yup.string().max(100, FormErrorMessages.isNotMax(100)),
});

export type EditProjectType = yup.InferType<typeof EditProjectScheme>;
export const EditProjectDefaultValues: EditProjectType = {
  [PROJECT_NAME]: '',
  [NOTE]: '',
};
