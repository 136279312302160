import { INTELXEON, RASPBERRY_PI_4B } from '@netspresso/shared';
import {
  AUGMENTATION_DETAILS,
  BASE_ARCHITECTURE,
  BASE_IMG_SIZE,
  DEVICE,
  EPOCHS_PER_TRIAL,
  HYP_DETAIL,
  IMG_SIZE_HEIGHT,
  IMG_SIZE_WIDTH,
  OUTPUT_BATCH_SIZE,
  OUTPUT_DATA_TYPE,
  OUTPUT_MODEL_TYPE,
} from '../../../constants';
import { useProjectsFormContext } from '../../../context';
import { FRAMEWORKS, OUTPUT_DATA_TYPES, TasksToBaseModelMap, isProjectTaskType, isTaskWithMetric } from '../../../lib';
import { AugmentaionDefaultValues, HyperparameterDefaultValues } from '../../../schemes';
import { isImageClassification, isSemanticSegmentation } from '../../../utils';

// use this custom hook in ProjectFormProvider;
export const useProjectHandler = () => {
  const {
    projectsForm: { setValue },
    setTask,
  } = useProjectsFormContext();

  const resetByTask = (task: unknown) => {
    if (!isProjectTaskType(task)) {
      return;
    }

    setTask(task);
    setValue(HYP_DETAIL, HyperparameterDefaultValues(task));
    setValue(AUGMENTATION_DETAILS, AugmentaionDefaultValues(task));

    if (isTaskWithMetric(task)) {
      setValue(BASE_ARCHITECTURE, TasksToBaseModelMap[task]);
    }

    if (isSemanticSegmentation(task)) {
      setValue(DEVICE, INTELXEON);
      setValue(OUTPUT_MODEL_TYPE, FRAMEWORKS.OPENVINO);
      setValue(OUTPUT_DATA_TYPE, OUTPUT_DATA_TYPES.FP16);
      setImageSizes(512);
      setValue(EPOCHS_PER_TRIAL, 450);
    } else {
      setValue(DEVICE, RASPBERRY_PI_4B);
      setValue(OUTPUT_MODEL_TYPE, FRAMEWORKS.TENSORFLOW_LITE);
      setValue(OUTPUT_DATA_TYPE, OUTPUT_DATA_TYPES.FP16);
      setValue(OUTPUT_BATCH_SIZE, 1, { shouldValidate: true, shouldDirty: true });
      setValue(EPOCHS_PER_TRIAL, 200);

      if (isImageClassification(task)) {
        setImageSizes(224);
      } else {
        setImageSizes(640);
      }
    }
  };

  const setImageSizes = (size: number) => {
    setValue(BASE_IMG_SIZE, [size, size]);
    setValue(IMG_SIZE_WIDTH, size);
    setValue(IMG_SIZE_HEIGHT, size);
  };

  return {
    setImageSizes,
    resetByTask,
  };
};
