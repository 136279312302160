import AVHIcon from '@netspresso/components/assets/icons/avh.svg';
import NVidiaIcon from '@netspresso/components/assets/icons/nvidia.svg';
import RaspberryIcon from '@netspresso/components/assets/icons/raspberry.svg';
import XeonIcon from '@netspresso/components/assets/icons/xeon.svg';
import React from 'react';
import { DeviceNames } from '../../../../../../lib';

type ProjectDeviceProps = {
  projectDevice: DeviceNames;
};

const getDeviceIcon = (projectDevice: DeviceNames) => {
  const deviceName = projectDevice.toLowerCase();

  if (deviceName.indexOf('nvidia') > -1) {
    return <NVidiaIcon />;
  }

  if (deviceName.indexOf('raspberry') > -1) {
    return <RaspberryIcon />;
  }

  if (deviceName.indexOf('xeon') > -1) {
    return <XeonIcon />;
  }

  if (deviceName.indexOf('avh') > -1) {
    return <AVHIcon />;
  }

  return false;
};

export const ProjectDevice: React.FC<ProjectDeviceProps> = ({ projectDevice }) => {
  return (
    <span className="float-right w-[calc(100%-80px)] flex items-center justify-end">
      <span className="mr-2">{getDeviceIcon(projectDevice)}</span>
      <span className="whitespace-nowrap text-ellipsis overflow-hidden text-right">{projectDevice}</span>
    </span>
  );
};
