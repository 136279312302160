import {
  CUTMIX_ALPHA,
  LR0,
  LRF,
  MIXUP,
  MIXUP_ALPHA,
  MIXUP_SWITCH_PROB,
  MOMENTUM,
  SCHD_POWER,
  SEED,
  WARMUP_BIAS_LR,
  WARMUP_EPOCHS,
  WARMUP_MOMENTUM,
  WARMUP_STEPS,
  WEIGHT_DECAY,
} from '@netspresso/shared';

export const CLASSIFICATION_HYPERPARAMETERS = [
  LR0,
  LRF,
  SEED,
  MOMENTUM,
  WEIGHT_DECAY,
  WARMUP_STEPS,
  WARMUP_EPOCHS,
  WARMUP_MOMENTUM,
  WARMUP_BIAS_LR,
  SCHD_POWER,
  MIXUP,
  MIXUP_ALPHA,
  CUTMIX_ALPHA,
  MIXUP_SWITCH_PROB,
] as const;

export type ClassificationParameter = (typeof CLASSIFICATION_HYPERPARAMETERS)[number];

export const ClassificationPlaceholdersMap: Record<ClassificationParameter, string> = {
  [LR0]: '1E-6 <= lr0 <= 0.1',
  [LRF]: '1E-6 <= lrf <= 0.1',
  [SEED]: '0 <= seed < inf',
  [MOMENTUM]: '0 <= momentum <= 1',
  [WEIGHT_DECAY]: '0 <= weight_decay <= 0.9',
  [WARMUP_STEPS]: '0 <= warmup_steps < inf',
  [WARMUP_EPOCHS]: '0 <= warmup_epochs <= epochs',
  [WARMUP_MOMENTUM]: '0 <= warmup_momentum <= 1',
  [WARMUP_BIAS_LR]: '0 <= warmup_bias_lr <= 0.01',
  [SCHD_POWER]: '0 <= schd_power < inf',
  [MIXUP]: '0 <= mixup <=1',
  [MIXUP_ALPHA]: '0 <= mixup_alpha <=1',
  [CUTMIX_ALPHA]: '0 <= cutmix_alpha <=1',
  [MIXUP_SWITCH_PROB]: '0 <= mixup_switch_prob <= 1',
};
