// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectToFormData = (obj: Record<string, any>): FormData => {
  const formData = new FormData();

  for (const key in obj) {
    if (obj[key]) {
      formData.append(key, obj[key]);
    }
  }

  return formData;
};
