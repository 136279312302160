import React from 'react';

export type Partial<T> = { [P in keyof T]?: T[P] };

export type ObjectValues<T> = T[keyof T];

type JSONPrimitive = string | number | boolean | null;
type JSONObject = { [k: string]: JSONValue };
type JSONArray = JSONValue[];
export type JSONValue = JSONArray | JSONObject | JSONPrimitive;

type AsProp<C extends React.ElementType> = { as?: C };

export type PropsToOmit<C extends React.ElementType, P> = keyof (AsProp<C> & P);

export type PolymorphicRef<C extends React.ElementType> = React.ComponentPropsWithRef<C>['ref'];

export type PolymorphicComponentProp<
  C extends React.ElementType,
  Props = Record<string, unknown>
> = React.PropsWithChildren<Props & AsProp<C>> & Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

export type PolymorphicComponentPropWithRef<
  C extends React.ElementType,
  Props = Record<string, unknown>
> = PolymorphicComponentProp<C, Props> & {
  ref?: PolymorphicRef<C>;
};

export const isSomeEnum =
  <E extends { [s: string]: unknown } | ArrayLike<unknown>>(value: E) =>
  (token: unknown): token is E[keyof E] =>
    Object.values(value).includes(token as E[keyof E]);

export const isString = (value: unknown): value is string => typeof value === 'string';

export const isRecord = <T>(value: unknown): value is Record<string, T> => typeof value === 'object';

export const isVoidFunction = (value: unknown): value is VoidFunction => typeof value === 'function';
