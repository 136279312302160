import {
  CLASSIFICATION_EVALUATION_METRICS,
  INS_SEGMENTATION_EVALUATION_METRICS,
  OBJECT_DETECTION_EVALUATION_METRICS,
  PAN_SEGMENTATION_EVALUATION_METRICS,
  SEGMENTATION_EVALUATION_METRICS,
} from '../../../../../constants';
import { Tasks } from '../../../../../lib';
import {
  isInstanceSegmentation,
  isObjectDetection,
  isPanopticSegmentation,
  isSemanticSegmentation,
} from '../../../../../utils';

export const metricsOptions = (task: Tasks) => {
  if (isObjectDetection(task)) {
    return OBJECT_DETECTION_EVALUATION_METRICS;
  }

  if (isSemanticSegmentation(task)) {
    return SEGMENTATION_EVALUATION_METRICS;
  }

  if (isInstanceSegmentation(task)) {
    return INS_SEGMENTATION_EVALUATION_METRICS;
  }

  if (isPanopticSegmentation(task)) {
    return PAN_SEGMENTATION_EVALUATION_METRICS;
  }

  return CLASSIFICATION_EVALUATION_METRICS;
};
