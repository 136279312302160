import React, { useState, useEffect } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputTextLabel, TextInput, TextArea, OutlineButton, SubmitButton } from '@netspresso/components';
import {
  FIELD_NAME,
  FormErrorMessages,
  isEmpty,
  API_PROJECT_V1,
  PLACEHOLDER_PROJECT_NAME,
  PLACEHOLDER_PROJECT_MEMO,
  apiClient,
} from '@netspresso/shared';

import { PROJECT_NAME, NOTE } from '../../../../../../constants';
import { useLoaderContext, useModalContext } from '../../../../../../context';
import {
  useNotificationContext,
  LEVEL_SUCCESS,
  Toast,
  LEVEL_DANGER,
  LEVEL_WARNING,
} from '../../../../../../components/Notifications';
import { LoaderActions, ModalActions } from '../../../../../../reducers';
import {
  EditProjectDefaultValues,
  EditProjectScheme,
  EditProjectType,
} from '../../../../../../schemes/EditProjectScheme';
import { ProjectService } from '../../../../../../services';

type EditProjectDialogProps = {
  onComplete: VoidFunction;
  dialogName?: string;
  dialogMemo?: string;
  projectId?: string;
};

export const EditProjectDialog: React.FC<EditProjectDialogProps> = ({
  onComplete,
  dialogName = '',
  dialogMemo = '',
  projectId = '',
}) => {
  const [datasetName, setDatasetName] = useState('');
  const [datasetDescription, setDatasetDescription] = useState('');
  const { handleSubmit, setValue, watch, control } = useForm<EditProjectType>({
    defaultValues: EditProjectDefaultValues,
    mode: 'onBlur',
    resolver: yupResolver(EditProjectScheme),
  });
  const { isDirty, errors } = useFormState({ control });
  const [, dispatchLoading] = useLoaderContext();
  const [, dispatchModal] = useModalContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();

  useEffect(() => {
    setValue(PROJECT_NAME, dialogName, { shouldValidate: true, shouldDirty: true });
    setValue(NOTE, dialogMemo, { shouldValidate: true, shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const watched = watch();

  const isDisabled = () => {
    return !isDirty || !isEmpty(errors);
  };

  const onClickCancel: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setDatasetName(value);
    setValue(PROJECT_NAME, value, { shouldValidate: true, shouldDirty: true });
  };

  const onDescriptionChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const { value } = event.target;

    setDatasetDescription(value);
    setValue(NOTE, value, { shouldValidate: true, shouldDirty: true });
  };

  const onSubmit: SubmitHandler<EditProjectType> = async (data) => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      const res = await ProjectService.editProject(projectId, data);

      if (res.status === 200) {
        dispatchLoading({ type: LoaderActions.Hide });
        showToast(
          <Toast
            content="You requested to edit project successfully"
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      showToast(
        <Toast
          content="Editing project request failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      onComplete();
      dispatchModal({ type: ModalActions.Hide });
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const onError: SubmitErrorHandler<EditProjectType> = (projectFormErrors) => {
    // eslint-disable-next-line no-console
    console.log(projectFormErrors);
    showToast(
      <Toast
        content="Please review form fields."
        level={LEVEL_WARNING}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );
  };

  return (
    <div className="flex justify-center items-center h-screen w-full fixed left-0 top-0 bg-black bg-opacity-50 z-20">
      <div className="flex flex-col justify-between bg-white rounded shadow-lg w-[600px] p-6">
        <h3 className="text-xl text-gray-900 font-title font-semibold mb-4">Edit project</h3>
        <form className="w-full" onSubmit={handleSubmit(onSubmit, onError)}>
          <section className="mb-3" data-test="dataset-name-input">
            <InputTextLabel htmlFor={PROJECT_NAME}>Project name *</InputTextLabel>
            <TextInput
              id={PROJECT_NAME}
              placeholder={PLACEHOLDER_PROJECT_NAME}
              error={errors[PROJECT_NAME] ? errors[PROJECT_NAME].message : ''}
              value={watched[PROJECT_NAME] || datasetName}
              width="w-full"
              onChange={onNameChange}
            />
          </section>
          <section className="mb-8" data-test="dataset-memo-input">
            <InputTextLabel htmlFor={NOTE}>Memo</InputTextLabel>
            <TextArea
              id={NOTE}
              error={errors[NOTE] ? errors[NOTE].message : ''}
              placeholder={PLACEHOLDER_PROJECT_MEMO}
              value={watched[NOTE] || datasetDescription}
              rows={3}
              width="w-full"
              onChange={onDescriptionChange}
            />
          </section>
          <div className="flex justify-end pt-2">
            <OutlineButton className="mr-3" onClick={onClickCancel}>
              Cancel
            </OutlineButton>
            <SubmitButton disabled={isDisabled()}>Confirm</SubmitButton>
          </div>
        </form>
      </div>
    </div>
  );
};
