import React from 'react';

interface InfoButtonProps extends React.PropsWithChildren {
  className?: string;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const InfoButton: React.FC<InfoButtonProps> = ({ children, className, ...restProps }) => (
  <button
    type="button"
    className={`transition ease-in-out duration-200 text-sm text-white bg-secondary hover:bg-secondary-active disabled:bg-disabledGray px-7 py-1 rounded leading-[14px] ${className}`}
    {...restProps}
  >
    {children}
  </button>
);
