import React from 'react';
import { VBMF } from '../../../../../../constants';
import { RecommendedMethod } from '../../../../../../lib';

export const ConfigToolTips: React.FC<{ method: RecommendedMethod }> = ({ method }) => {
  return (
    <div className="material-symbols-outlined text-xl text-gray-300 cursor-pointer pb-1 tooltip">
      help
      {method === VBMF ? (
        <span className="tooltiptext text-left text-xs font-sans font-semibold -ml-[190px]">
          This function control compression level of model if the result
          <br />
          of recommendation doesn&apos;t meet the compression level user wants.
          <br />
          Remained rank add or subtract (removed rank x calibration ratio)
          <br />
          according to calibration ratio range.
          <br />
          <img className="w-full mt-2 mb-1" src="/images/calibration_ratio.png" alt="Calibration Ratio" />
        </span>
      ) : (
        <span className="tooltiptext text-left text-xs font-sans font-semibold -ml-[190px]">
          Remove corresponding amounts of the filters considering layer-wise importance.
          <br />
          (e.g. 0.2 removes 20% of the filters in a model)
        </span>
      )}
    </div>
  );
};
