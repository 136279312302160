interface Response {
  headers: {
    [key: string]: string;
    'content-disposition': string;
    'content-type': string;
  };
  data: BlobPart;
}

export const downloadBlob = (res: Response): void => {
  const { headers } = res;
  const fileName = headers['content-disposition'].split('filename=')[1].replaceAll('"', '');
  const type = headers['content-type'];
  const url = window.URL.createObjectURL(new Blob([res.data], { type }));
  const link = document.createElement('a');

  link.href = url;
  link.download = fileName;
  link.click();
};

export const download = (url: string): void => {
  const link = document.createElement('a');

  link.target = '_blank';

  link.href = url;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};
