/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useFormState } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { PanelHeader } from '@netspresso/components';
import {
  API_PROJECT_V1,
  NotificationMessages,
  OBJECT_DETECTION,
  QUICK_SEARCH,
  RETRAINING,
  apiClient,
  isEmpty,
  isString,
} from '@netspresso/shared';

import { AxiosError } from 'axios';
import { Dialog } from '../../../../components/Dialog';
import {
  LEVEL_DANGER,
  LEVEL_INFO,
  LEVEL_SUCCESS,
  LEVEL_WARNING,
  Toast,
  useNotificationContext,
} from '../../../../components/Notifications';
import {
  AGENT_ID,
  DATASET_UID,
  LOOKUPTABLE_UID,
  MODEL_DATASET_UID,
  MODEL_ID,
  PROJECT_NAME,
  PROJECT_TASK,
  PROJECT_TYPE,
} from '../../../../constants';
import { useAuthContext, useLoaderContext, useModalContext, useProjectsFormContext } from '../../../../context';
import { CURRENT_STEPS, CurrentSteps, Recommendation, isProjectTaskType } from '../../../../lib';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { ProjectType } from '../../../../schemes';
import { DatasetService, ProjectService } from '../../../../services';
import { convertToProjectpayload, convertToRecommendationPayload } from '../../../../utils';
import { ProjectSteps, TrainingFormButtons } from '../../components';
import { useProjectHandler } from '../../hooks';
import { SelectServer } from '../SelectServer';
import { ProjectSettingStep, RunProjectStep, SelectModel, SetProject } from './steps';

export type Recommendations = { img_size: Recommendation[]; latency: Recommendation[] };

export const TrainingProject: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, dispatchLoading] = useLoaderContext();
  const [, dispatchModal] = useModalContext();

  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const { projectUid, datasetUid, modelUid } = useParams();

  const { user, loading: authLoading } = useAuthContext();
  const { resetByTask } = useProjectHandler();
  const {
    projectsForm: { getValues, setValue, watch, handleSubmit, control, reset },
  } = useProjectsFormContext();
  const { isDirty, errors, isValid } = useFormState({ control });

  const [currentStep, setCurrentStep] = useState<CurrentSteps>(CURRENT_STEPS.FIRST);
  const [recommendations, setRecommendations] = useState<Recommendations>({
    img_size: [],
    latency: [],
  });

  useEffect(() => {
    dispatchLoading({ type: authLoading ? LoaderActions.Show : LoaderActions.Hide });

    return () => reset();
  }, [authLoading, dispatchLoading]);

  const setTaskFromDatasetInfo = async (datasetId: string) => {
    const datasetInfo = await DatasetService.getDatasetById(datasetId);
    const { dataset_task } = datasetInfo.data;

    const hasTask = isProjectTaskType(dataset_task);

    if (!hasTask) {
      setValue(PROJECT_TASK, OBJECT_DETECTION);
      setValue(DATASET_UID, '');

      return;
    }
    setValue(DATASET_UID, datasetId);
    // FIXME: MODEL_DATASET_UID: only use in retraining for ui
    setValue(MODEL_DATASET_UID, datasetId);
    resetByTask(dataset_task);
  };

  useEffect(() => {
    if (datasetUid) {
      setTaskFromDatasetInfo(datasetUid);
    }
  }, [datasetUid]);

  useEffect(() => {
    setValue(PROJECT_TYPE, pathname.includes('quicksearch') ? QUICK_SEARCH : RETRAINING);
  }, [setValue, pathname]);

  const fetchProject = useCallback(
    async (uid: string) => {
      try {
        const { data } = await ProjectService.getProjectById(uid);

        setValue(PROJECT_NAME, data[PROJECT_NAME]);
        setValue(PROJECT_TYPE, data[PROJECT_TYPE]);
        setValue(LOOKUPTABLE_UID, data[MODEL_ID]);
        setValue(MODEL_ID, data[MODEL_ID]);
        setValue(DATASET_UID, data[DATASET_UID]);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);

        const httpError = err as AxiosError;

        if (httpError.response?.status === 403 || httpError.response?.status === 500) {
          navigate('/forbidden');
        }
      }
    },
    [navigate, setValue]
  );

  const settingForSelectServer = useCallback(async () => {
    if (projectUid) {
      await fetchProject(projectUid);
      setCurrentStep(CURRENT_STEPS.THIRD);
    }
  }, [projectUid, fetchProject]);

  useEffect(() => {
    if (projectUid) {
      settingForSelectServer();
    }
  }, [projectUid, settingForSelectServer]);
  const watched = watch();

  const isRecommendationSelected = () => {
    return watched[PROJECT_TYPE] === QUICK_SEARCH && !!watched[MODEL_ID];
  };

  const isDatasetSelectedForRetraining = () => {
    return watched[PROJECT_TYPE] === RETRAINING && !!watched[DATASET_UID];
  };

  const isSecondStepValid = () => {
    return currentStep === CURRENT_STEPS.SECOND
      ? [isRecommendationSelected(), isDatasetSelectedForRetraining()].some(Boolean)
      : true;
  };

  const isServerSelected = () => {
    return currentStep === CURRENT_STEPS.THIRD && watched[AGENT_ID] && watched[AGENT_ID].length > 1;
  };

  const isDisabled = () => {
    return !isDirty || !isValid || !isEmpty(errors) || !isSecondStepValid();
  };

  const onClickBack = () => {
    if (currentStep === CURRENT_STEPS.FIRST) {
      reset();

      if (datasetUid && modelUid) {
        navigate('/models');
      } else {
        navigate('/projects/create_a_new_project');
      }
    }

    if (currentStep === CURRENT_STEPS.SECOND) {
      setValue(LOOKUPTABLE_UID, '');
      setCurrentStep(CURRENT_STEPS.FIRST);
    }

    if (currentStep === CURRENT_STEPS.THIRD) {
      projectUid ? navigate('/projects') : showStartLaterPrompt(getValues());
    }
  };

  const fetchRecommendations = async (data: ProjectType) => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      const res = await apiClient.post(`${API_PROJECT_V1}/recommend`, convertToRecommendationPayload(data));

      if (res.status === 200) {
        dispatchLoading({ type: LoaderActions.Hide });
        setRecommendations(res.data);
        setCurrentStep(CURRENT_STEPS.SECOND);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      showToast(
        <Toast
          content="Requesting recommendations failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const onSubmit: SubmitHandler<ProjectType> = async (data) => {
    if (currentStep === CURRENT_STEPS.FIRST) {
      if (pathname.includes('quicksearch')) {
        await fetchRecommendations(data);
      } else {
        setCurrentStep(CURRENT_STEPS.SECOND);
      }
    } else if (currentStep === CURRENT_STEPS.SECOND) {
      setCurrentStep(CURRENT_STEPS.THIRD);
    } else {
      showPrompt(data);
    }
  };

  const onStartLater = () => {
    showToast(
      <Toast
        content="You requested to run a project later."
        level={LEVEL_INFO}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );
    navigate('/projects');
  };

  const onConfirmStart = (uid: string) => {
    dispatchModal({ type: ModalActions.Hide });
    dispatchLoading({ type: LoaderActions.Show });

    onStartProject(uid);
  };

  const onStartProject = async (uid: string) => {
    const agentId = getValues()[AGENT_ID] || 'nota_server';

    try {
      const res = await apiClient.post(`${API_PROJECT_V1}/${uid}/start`, { agent_id: agentId });

      if (res.status === 200) {
        showToast(
          <Toast
            content="You requested to run a project successfully."
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
        navigate('/projects');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      showToast(
        <Toast
          content="Start a project request failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );

      if (!projectUid) {
        navigate('/projects');
      }
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const createProject = (data: ProjectType) => (cb: ((uid: string) => Promise<void>) | (() => void)) => async () => {
    try {
      dispatchModal({ type: ModalActions.Hide });
      dispatchLoading({ type: LoaderActions.Show });

      const res = await apiClient.post(API_PROJECT_V1, convertToProjectpayload(data));

      if (res.status === 200) {
        cb(projectUid ? null : res.data.project_id);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      showToast(
        <Toast
          content="Start a project request failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const onError: SubmitErrorHandler<ProjectType> = (projectFormErrors) => {
    // eslint-disable-next-line no-console
    console.log(projectFormErrors);
    showToast(
      <Toast
        content="Please review form fields."
        level={LEVEL_WARNING}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );
  };

  const onClosePrompt: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const showStartLaterPrompt = (data: ProjectType) => {
    const cb = createProject(data)(onStartLater);

    dispatchModal({
      type: ModalActions.Show,
      payload: (
        <Dialog
          title={NotificationMessages.startLaterTitle}
          infoText={NotificationMessages.startLaterMessage}
          onClickConfirm={cb}
          onClickCancel={onClosePrompt}
        />
      ),
    });
  };

  const showPrompt = (data: ProjectType) => {
    if (user.credit >= 50) {
      dispatchModal({
        type: ModalActions.Show,
        payload: (
          <Dialog
            title={NotificationMessages.startProjectTitle}
            infoText={
              watched[PROJECT_TYPE] === RETRAINING
                ? NotificationMessages.startProjectMessage(50)
                : NotificationMessages.startProjectMessage(100)
            }
            onClickConfirm={projectUid ? () => onConfirmStart(projectUid) : createProject(data)(onStartProject)}
            onClickCancel={onClosePrompt}
          />
        ),
      });
    } else {
      showToast(
        <Toast
          content={NotificationMessages.notEnoughCredit}
          level={LEVEL_WARNING}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full px-3 relative">
        {isString(watched[PROJECT_TYPE]) && (
          <ProjectSteps projectType={watched[PROJECT_TYPE]} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        )}
        <PanelHeader className="pl-6 mb-6">
          {watched[PROJECT_TYPE] === QUICK_SEARCH ? 'Quick Search' : 'Retraining'}
        </PanelHeader>
        <section className="bg-white rounded-lg shadow mb-6 p-6">
          <div className="flex flex-col lg:flex-row">
            <form className="w-full" onSubmit={handleSubmit(onSubmit, onError)}>
              {currentStep === CURRENT_STEPS.FIRST ? (
                pathname.includes('quicksearch') ? (
                  <ProjectSettingStep />
                ) : (
                  <SelectModel />
                )
              ) : currentStep === CURRENT_STEPS.SECOND ? (
                pathname.includes('quicksearch') ? (
                  <RunProjectStep imgSize={recommendations.img_size} latency={recommendations.latency} />
                ) : (
                  <SetProject />
                )
              ) : (
                <SelectServer />
              )}
              <TrainingFormButtons
                projectUid={projectUid}
                currentStep={currentStep}
                onClickBack={onClickBack}
                isDisabled={projectUid || currentStep === CURRENT_STEPS.THIRD ? !isServerSelected() : !!isDisabled()}
              />
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};
