import { MainAction, ResponsiveContainer } from '@netspresso/components';
import { formatizeLatency, NotificationMessages, sortByPropName } from '@netspresso/shared';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmptyMessage, LEVEL_WARNING, Toast, useNotificationContext } from '../../components';
import { REFRESH_INTERVAL } from '../../constants';
import { useAuthContext, useLoaderContext } from '../../context';
import { Project } from '../../lib';
import { LoaderActions } from '../../reducers';
import { ProjectService } from '../../services';
import { parseDevices, useGTM } from '../../utils';
import { ProjectCard } from './components';

export const Projects: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { setPageToDataLayer } = useGTM();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const [, dispatchLoading] = useLoaderContext();

  const [projectData, setProjectData] = useState<{ data: Project[]; isFetching: boolean }>({
    data: [],
    isFetching: false,
  });

  const fetchProjects = async () => {
    try {
      setProjectData({ data: projectData.data, isFetching: true });

      const res = await ProjectService.getProjects();

      setProjectData({ data: sortByPropName(res.data), isFetching: false });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      const error = err as AxiosError;

      if (error.response?.status === 403 || error.response?.status === 500) {
        navigate('/forbidden');
      } else {
        setProjectData({ data: projectData.data, isFetching: false });
      }
    }
  };

  useEffect(() => {
    fetchProjects();
    const refreshProject = setInterval(async () => {
      fetchProjects();
    }, REFRESH_INTERVAL);

    return () => clearInterval(refreshProject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatchLoading({ type: projectData.isFetching ? LoaderActions.Show : LoaderActions.Hide });
  }, [projectData.isFetching, dispatchLoading]);

  const requestHandler = () => {
    if (user.credit >= 50) {
      navigate(`create_a_new_project`);
    } else {
      showToast(
        <Toast
          content={NotificationMessages.notEnoughCredit}
          level={LEVEL_WARNING}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    }
  };

  const refresh = () => {
    fetchProjects();
  };

  const renderDataset = (item: Project) => {
    return (
      <ProjectCard
        key={item.project_id}
        projectUid={item.project_id}
        projectName={item.project_name}
        projectType={item.project_type}
        projectTask={item.task}
        trials={item.trials}
        status={item.status}
        refresh={refresh}
        createdAt={item.created_at}
        updatedAt={item.updated_at}
        datasetName={item.dataset_name}
        projectDevice={parseDevices(item.device)}
        targetLatency={item.target_latency ? formatizeLatency(item.target_latency) : ''}
      />
    );
  };

  useEffect(() => {
    setPageToDataLayer('Projects');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainAction routeName="projects" totalNum={100} dataNum={projectData.data.length} onClick={requestHandler} />
      {projectData.data.length > 0 ? (
        <ResponsiveContainer>{projectData.data.map(renderDataset)}</ResponsiveContainer>
      ) : (
        <EmptyMessage>
          Currently, there is no project.
          <br />
          Start a new project with the uploaded dataset.
        </EmptyMessage>
      )}
    </>
  );
};
