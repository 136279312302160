import { FormErrorMessages, STRONG_PASSWORD } from '@netspresso/shared';
import * as yup from 'yup';
import { CONFIRM_PASSWORD, NEW_PASSWORD } from '../constants';

export const ChangePasswordScheme = yup.object({
  [NEW_PASSWORD]: yup
    .string()
    .required(FormErrorMessages.isRequired)
    .min(8, FormErrorMessages.isNotLonger(8))
    .matches(STRONG_PASSWORD, FormErrorMessages.isNotStrongEnough),
  [CONFIRM_PASSWORD]: yup
    .string()
    .oneOf([yup.ref(NEW_PASSWORD), ''], 'Passwords must match.')
    .required(FormErrorMessages.isRequired),
});
export const ChangePasswordDefaultValues = {
  [NEW_PASSWORD]: '',
  [CONFIRM_PASSWORD]: '',
};
