import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  InfoButton,
  InfoContainer,
  InfoText,
  Panel,
  PanelContent,
  PanelHeader,
  ResponsiveContainer,
} from '@netspresso/components';
import { parseDate, VALIDATOR_LINK } from '@netspresso/shared';

import { AxiosError } from 'axios';
import { useLoaderContext, useModalContext } from '../../../../context';
import { StorageLocationType, Dataset, DatasetFormatType, NumInstanceType, Project, Tasks } from '../../../../lib';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { DatasetService } from '../../../../services';
import { parseDevices, useGTM } from '../../../../utils';
import { ProjectCard } from '../../../Projects';
import { ClassesTable, DatasetInfo, EditDatasetDialog } from './components';

export const DatasetDetails: React.FC = () => {
  const [, dispatchLoading] = useLoaderContext();
  const [, dispatchModal] = useModalContext();
  const navigate = useNavigate();
  const { setPageToDataLayer } = useGTM();
  const { datasetUid } = useParams();
  const [datasetName, setDatasetName] = useState('');
  const [datasetTask, setDatasetTask] = useState<Tasks>();
  const [datasetFormat, setDatasetFormat] = useState<DatasetFormatType>();
  const [storageType, setStorageType] = useState<StorageLocationType>();
  const [datasetMemo, setDatasetMemo] = useState('');
  const [isSample, setIsSample] = useState(false);
  const [status] = useState(2);
  const [trainNum, setTrainNum] = useState(0);
  const [validNum, setValidNum] = useState(0);
  const [testNum, setTestNum] = useState(0);
  const [classNames, setClassNames] = useState<string[]>([]);
  const [numInstances, setNumInstances] = useState<NumInstanceType[]>([]);
  const [dataset, setDataset] = useState<Dataset | null>(null);
  const [createdAt, setCreatedAt] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [projectData, setProjectData] = useState<{ projects: Project[]; isFetching: boolean }>({
    projects: [],
    isFetching: false,
  });

  const fetchDataset = async (datasetId: string) => {
    try {
      const res = await DatasetService.getDatasetById(datasetId);

      if (res.status === 200) {
        setDataset(res.data);
      }
    } catch (err) {
      const error = err as AxiosError;

      if (error.response?.status === 403 || error.response?.status === 500) {
        navigate('/forbidden');
      }

      if (error.response?.status === 404) {
        navigate('/not_found', { replace: true });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      if (!datasetUid) {
        return;
      }

      setProjectData({ projects: projectData.projects, isFetching: true });

      const res = await DatasetService.getProjects(datasetUid);

      setProjectData({ projects: res.data, isFetching: false });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setProjectData({ projects: projectData.projects, isFetching: false });
    }
  };

  const onEditComplete = () => {
    if (datasetUid) {
      setIsLoading(true);
      fetchDataset(datasetUid);
    }
  };

  const onCreateProject: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    navigate(`/projects/create_a_new_project/dataset/${datasetUid}`);
  };

  const onEditDataset: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({
      type: ModalActions.Show,
      payload: (
        <EditDatasetDialog
          dialogName={datasetName}
          dialogMemo={datasetMemo}
          datasetId={datasetUid || ''}
          onComplete={onEditComplete}
        />
      ),
    });
  };

  const renderProject = (item: Project) => {
    return (
      <ProjectCard
        key={item.project_id}
        projectUid={item.project_id}
        projectName={item.project_name}
        projectType={item.project_type}
        projectTask={item.task}
        trials={item.trials}
        status={item.status}
        createdAt={item.created_at}
        updatedAt={item.updated_at}
        datasetName={item.dataset_name}
        projectDevice={parseDevices(item.device)}
        targetLatency={item.target_latency?.toString() || ''}
      />
    );
  };

  useEffect(() => {
    if (datasetUid) {
      setIsLoading(true);
      fetchDataset(datasetUid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetUid]);

  useEffect(() => {
    if (!dataset) {
      return;
    }
    setDatasetName(dataset.dataset_name);
    setDatasetTask(dataset.dataset_task);
    setDatasetFormat(dataset.dataset_format);
    setDatasetMemo(dataset.description);
    // setStatus(dataset.status);
    setIsSample(dataset.user_uid === 'sample_dataset');
    setStorageType(dataset.storage_type);

    if (dataset.num_images) {
      setTrainNum(dataset.num_images.train);
      setValidNum(dataset.num_images.val);
      setTestNum(dataset.num_images.test);
    }

    if (dataset.num_instances) {
      setNumInstances(dataset.num_instances);
    }
    setClassNames([...dataset.class_names]);
    setCreatedAt(parseDate(dataset.created_time));
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset]);

  useEffect(() => {
    dispatchLoading({
      type: isLoading || projectData.isFetching ? LoaderActions.Show : LoaderActions.Hide,
    });
  }, [isLoading, projectData.isFetching, dispatchLoading]);

  useEffect(() => {
    setPageToDataLayer('Dataset Detail');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {datasetFormat && datasetTask && storageType && (
        <ResponsiveContainer>
          <div className="w-full px-3">
            <Panel className="relative">
              <PanelHeader className="mb-4 pl-6">{datasetName}</PanelHeader>
              <button
                type="button"
                className="absolute top-0 right-6 text-m text-darkGray hover:text-secondary disabled:text-lineGray pt-1"
                onClick={onEditDataset}
              >
                <span className="material-icons text-base">edit</span>
              </button>
              {datasetMemo ? <p className="text-m text-darkGray font-normal pt-3 pl-6">{datasetMemo}</p> : null}
              <section className="bg-white rounded-lg shadow mb-6 p-6 mt-4">
                <PanelContent className="flex-row">
                  <DatasetInfo
                    storageType={storageType}
                    datasetFormat={datasetFormat}
                    datasetTask={datasetTask}
                    trainNum={trainNum}
                    validNum={validNum}
                    testNum={testNum}
                    classesNum={classNames.length}
                    createdAt={createdAt}
                    isSample={isSample}
                  />
                  <ClassesTable classNames={classNames} numInstances={numInstances} />
                </PanelContent>
                {status === 2 ? (
                  <InfoContainer className="mt-8">
                    <>
                      <InfoText color="secondary">Running a new project with this dataset.</InfoText>
                      <InfoButton data-test="create-project-btn" onClick={onCreateProject}>
                        Create a New Project
                      </InfoButton>
                    </>
                  </InfoContainer>
                ) : (
                  <InfoContainer infoType="danger" iconName="error">
                    <InfoText color="danger">
                      Please check your dataset with the dataset{' '}
                      <a
                        href={VALIDATOR_LINK}
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                        data-test="validator-link"
                      >
                        validator
                      </a>
                      .
                    </InfoText>
                  </InfoContainer>
                )}
              </section>
            </Panel>
          </div>
        </ResponsiveContainer>
      )}
      {projectData.projects.length > 0 ? (
        <>
          <PanelHeader className="mb-4 pl-12">Related projects</PanelHeader>
          <ResponsiveContainer className="px-3">{projectData.projects.map(renderProject)}</ResponsiveContainer>
        </>
      ) : null}
    </>
  );
};
