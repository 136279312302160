import { YOLOV5, ATOMIX, SEGFORMER, MIXNET, SHUFFLENET, TAO_UNET } from '@netspresso/shared';

export const BASE_MODELS = [YOLOV5, ATOMIX, SEGFORMER, MIXNET, SHUFFLENET, TAO_UNET] as const;

export type BaseModelTypes = (typeof BASE_MODELS)[number];

export const BaseModelsMap: Record<BaseModelTypes, string> = {
  [YOLOV5]: 'YOLOv5',
  [ATOMIX]: 'Atomix',
  [SEGFORMER]: 'SegFormer',
  [MIXNET]: 'MixNet',
  [SHUFFLENET]: 'ShuffleNet',
  [TAO_UNET]: 'TAO UNet',
};
