import React from 'react';

export const DetailValue: React.FC<React.PropsWithChildren & { dataTestId?: string }> = ({
  children,
  dataTestId = 'detail-value',
}) => (
  <div className="text-normal text-darkGray" data-testid={dataTestId}>
    {children}
  </div>
);
