import React from 'react';
import { ProjectTaskType } from '../../../../lib';
import { isImageClassification, isSemanticSegmentation } from '../../../../utils';
import { ClassificationParams, DetectionParams, SegmentationParams } from './components';

interface IHyperParameterForm {
  task: ProjectTaskType;
}

export const HyperParameterForm: React.FC<IHyperParameterForm> = ({ task }) => {
  if (isImageClassification(task)) {
    return <ClassificationParams />;
  }

  if (isSemanticSegmentation(task)) {
    return <SegmentationParams />;
  }

  return <DetectionParams />;
};
