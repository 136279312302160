import React from 'react';
import { MainActionButton } from './MainActionButton';

type MainActionProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  routeName: 'datasets' | 'projects' | 'models';
  dataNum?: number;
  totalNum?: number;
  isDisabled?: boolean;
};

export const MainAction: React.FC<MainActionProps> = ({
  onClick,
  routeName = 'datasets',
  dataNum = 0,
  totalNum = 10,
  isDisabled = false,
}) => {
  let actionLabel = 'Datasets';
  let textColor = 'text-secondary';

  if (routeName === 'projects') {
    actionLabel = 'Projects';
  } else if (routeName === 'models') {
    actionLabel = 'Models';
  }

  if (dataNum === totalNum) {
    textColor = 'text-warning';
  }

  return (
    <section className="mb-4 px-3">
      <MainActionButton routeName={routeName} onClick={onClick} disabled={dataNum >= totalNum || isDisabled} />
      <span className={`${textColor} inline-block font-semibold align-top pt-1.5 ml-2`}>
        {actionLabel}: {dataNum.toString()}
        <span className="font-normal text-notaGray">/{totalNum.toString()}</span>
      </span>
    </section>
  );
};
