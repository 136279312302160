/* eslint-disable camelcase */
import {
  SHUFFLENETV2PLUS_S,
  SHUFFLENETV2PLUS_M,
  SHUFFLENETV2PLUS_L,
  MIXNET_S,
  MIXNET_M,
  MIXNET_L,
  SEGFORMER_B0,
  SEGFORMER_B1,
  SEGFORMER_B2,
  SEGFORMER_B3,
  SEGFORMER_B4,
  SEGFORMER_B5,
  YOLOV5N,
  YOLOV5S,
  YOLOV5M,
  YOLOV5L,
  YOLOV5N6,
  YOLOV5S6,
  YOLOV5M6,
  YOLOV5L6,
  YOLOFASTEST,
  UNET_RESNET,
} from '@netspresso/shared';
import { DatasetFormatType } from '../datasets';
import { Devices } from '../Devices';
import { OutputDataTypes } from '../enums';
import { ModelFormats } from '../models';
import { TasksWithMetric } from '../Tasks';

export const RECOMMENDATIONS = [
  SHUFFLENETV2PLUS_S,
  SHUFFLENETV2PLUS_M,
  SHUFFLENETV2PLUS_L,
  MIXNET_S,
  MIXNET_M,
  MIXNET_L,
  SEGFORMER_B0,
  SEGFORMER_B1,
  SEGFORMER_B2,
  SEGFORMER_B3,
  SEGFORMER_B4,
  SEGFORMER_B5,
  YOLOV5N,
  YOLOV5S,
  YOLOV5M,
  YOLOV5L,
  YOLOV5N6,
  YOLOV5S6,
  YOLOV5M6,
  YOLOV5L6,
  YOLOFASTEST,
  UNET_RESNET,
] as const;

export type Recommendation = {
  model_id: string;
  lookuptable_id: string;
  device: Devices;
  img_size_width: number;
  img_size_channel: number;
  output_model_type: ModelFormats;
  pretrained_dataset: DatasetFormatType;
  deleted: false;
  updated_time: string;
  id: number;
  model_name: Recommendations;
  task: TasksWithMetric;
  img_size_height: number;
  output_batch_size: number;
  output_data_type: OutputDataTypes;
  latency_s: number;
  created_time: string;
};

export type Recommendations = (typeof RECOMMENDATIONS)[number];

export const RecommendationsMap: Record<Recommendations, string> = {
  [SHUFFLENETV2PLUS_S]: 'ShuffleNet v2+ s',
  [SHUFFLENETV2PLUS_M]: 'ShuffleNet v2+ m',
  [SHUFFLENETV2PLUS_L]: 'ShuffleNet v2+ l',
  [MIXNET_S]: 'MixNet-s',
  [MIXNET_M]: 'MixNet-m',
  [MIXNET_L]: 'MixNet-l',
  [SEGFORMER_B0]: 'SegFormer-b0',
  [SEGFORMER_B1]: 'SegFormer-b1',
  [SEGFORMER_B2]: 'SegFormer-b2',
  [SEGFORMER_B3]: 'SegFormer-b3',
  [SEGFORMER_B4]: 'SegFormer-b4',
  [SEGFORMER_B5]: 'SegFormer-b5',
  [YOLOV5N]: 'YOLOv5n',
  [YOLOV5S]: 'YOLOv5s',
  [YOLOV5M]: 'YOLOv5m',
  [YOLOV5L]: 'YOLOv5l',
  [YOLOV5N6]: 'YOLOv5n6',
  [YOLOV5S6]: 'YOLOv5s6',
  [YOLOV5M6]: 'YOLOv5m6',
  [YOLOV5L6]: 'YOLOv5l6',
  [YOLOFASTEST]: 'YOLO-fastest',
  [UNET_RESNET]: 'UNet (+ Resnet)',
};
