import {
  RASPBERRY_PI_4B,
  RASPBERRY_PI_3BPLUS,
  JETSON_NANO,
  JETSON_NX,
  JETSON_TX2,
  JETSON_XAVIER,
  JETSON_AGX_ORIN,
  INTELXEON,
  ARM_HARDWARE,
  ObjectValues,
} from '@netspresso/shared';

export const DEVICES = {
  RASPBERRY_PI: 'RaspberryPi',
  RASPBERRY_PI_4B: 'RaspberryPi4B',
  RASPBERRY_PI_3BPLUS: 'RaspberryPi3BPlus',
  RASPBERRY_PI_2: 'raspberrypi2',
  RASPBERRY_PI_ZEROW: 'raspberrypizerow',
  JETSON: 'Jetson',
  JETSON_NANO: 'Jetson-Nano',
  JETSON_NX: 'Jetson-Nx',
  JETSON_TX2: 'Jetson-Tx2',
  JETSON_XAVIER: 'Jetson-Xavier',
  JETSON_AGX_ORIN: 'Jetson-AGX-Orin',
  INTEL_XEON: 'Intel-Xeon',
  ARM_VIRTUAL_HARDWARE_ETHOS_U_SERIES: 'Arm Virtual Hardware Ethos-U Series',
} as const;

export type Devices = ObjectValues<typeof DEVICES>;

export const DEVICENAMES = [
  'Raspberry Pi',
  'Raspberry Pi 4B',
  'Raspberry Pi 3B+',
  'Raspberry Pi 2B',
  'Raspberry Pi Zero W',
  'NVIDIA Jetson',
  'NVIDIA Jetson Nano',
  'NVIDIA Jetson Xavier NX',
  'NVIDIA Jetson TX2',
  'NVIDIA Jetson AGX Xavier',
  'NVIDIA Jetson AGX Orin',
  'Intel Xeon W-2223',
  'AVH Corstone-300 (Ethos-U65 High End)',
] as const;

export type DeviceNames = (typeof DEVICENAMES)[number];

export const DevicesMap: Record<Devices, DeviceNames> = {
  [DEVICES.RASPBERRY_PI]: 'Raspberry Pi',
  [DEVICES.RASPBERRY_PI_4B]: 'Raspberry Pi 4B',
  [DEVICES.RASPBERRY_PI_3BPLUS]: 'Raspberry Pi 3B+',
  [DEVICES.RASPBERRY_PI_2]: 'Raspberry Pi 2B',
  [DEVICES.RASPBERRY_PI_ZEROW]: 'Raspberry Pi Zero W',
  [DEVICES.JETSON]: 'NVIDIA Jetson',
  [DEVICES.JETSON_NANO]: 'NVIDIA Jetson Nano',
  [DEVICES.JETSON_NX]: 'NVIDIA Jetson Xavier NX',
  [DEVICES.JETSON_TX2]: 'NVIDIA Jetson TX2',
  [DEVICES.JETSON_XAVIER]: 'NVIDIA Jetson AGX Xavier',
  [DEVICES.JETSON_AGX_ORIN]: 'NVIDIA Jetson AGX Orin',
  [DEVICES.INTEL_XEON]: 'Intel Xeon W-2223',
  [DEVICES.ARM_VIRTUAL_HARDWARE_ETHOS_U_SERIES]: 'AVH Corstone-300 (Ethos-U65 High End)',
};

export const TARGET_DEVICES = [
  RASPBERRY_PI_4B,
  RASPBERRY_PI_3BPLUS,
  JETSON_NANO,
  JETSON_NX,
  JETSON_TX2,
  JETSON_XAVIER,
  JETSON_AGX_ORIN,
  INTELXEON,
  ARM_HARDWARE,
] as const;

export type TargetDevice = (typeof TARGET_DEVICES)[number];

export const DEVICES_WITH_CPU_INFO = [
  'RaspberryPi4B',
  'RaspberryPi3BPlus',
  'Jetson-Nano',
  'Jetson-Nx',
  'Jetson-Tx2',
  'Jetson-Xavier',
  'Jetson-AGX-Orin',
  'Intel-Xeon',
] as const;

export type DevicesWithCPUInfo = (typeof DEVICES_WITH_CPU_INFO)[number];

export const CPUInfosMap: Record<DevicesWithCPUInfo, string> = {
  [DEVICES.RASPBERRY_PI_4B]: 'Quad core Cortex-A72 (ARM v8) 64-bit',
  [DEVICES.RASPBERRY_PI_3BPLUS]: 'Quad core Cortex-A53 (ARMv8) 64-bit',
  [DEVICES.JETSON_NANO]: 'Quad core Cortex-A57',
  [DEVICES.JETSON_NX]: '6-core NVIDIA Carmel (ARMv8.2) 64-bit',
  [DEVICES.JETSON_TX2]: 'Dual-core NVIDIA Denver 2 64-bit and quad-core ARM Cortex-A57 Complex',
  [DEVICES.JETSON_XAVIER]: '8-Core NVIDIA Carmel (ARMv8.2) 64-bit',
  [DEVICES.JETSON_AGX_ORIN]: '12-core Arm Cortex-A78AE v8.2 64-bit',
  [DEVICES.INTEL_XEON]: 'Intel Xeon Processor W-2223',
};

export const DEVICES_WITH_GPU_INFO = [
  'Jetson-Nano',
  'Jetson-Nx',
  'Jetson-Tx2',
  'Jetson-Xavier',
  'Jetson-AGX-Orin',
] as const;

export type DevicesWithGPUInfo = (typeof DEVICES_WITH_GPU_INFO)[number];

export const GPUInfosMap: Record<DevicesWithGPUInfo, string> = {
  [DEVICES.JETSON_NANO]: '128-core NVIDIA Maxwell',
  [DEVICES.JETSON_NX]: '384-core NVIDIA Volta with 48 Tensor cores',
  [DEVICES.JETSON_TX2]: '256-core NVIDIA Pascal',
  [DEVICES.JETSON_XAVIER]: '512-core NVIDIA Volta with 64 Tensor Cores',
  [DEVICES.JETSON_AGX_ORIN]: '2048-core NVIDIA Ampere with 64 Tensor Cores',
};
