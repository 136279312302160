import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import {
  InputTextLabel,
  OptionType,
  OutlineButton,
  Select,
  SubmitButton,
  TextArea,
  TextInput,
} from '@netspresso/components';
import {
  FIELD_NAME,
  FormErrorMessages,
  isEmpty,
  PLACEHOLDER_MODEL_MEMO,
  PLACEHOLDER_MODEL_NAME,
} from '@netspresso/shared';

import {
  LEVEL_DANGER,
  LEVEL_SUCCESS,
  LEVEL_WARNING,
  Toast,
  useNotificationContext,
} from '../../../../../../components/Notifications';
import {
  CLASSIFICATION_EVALUATION_METRICS,
  DESCRIPTION,
  DISPLAY_NAME,
  IMAGE_SEGMENTAION_EVALUATION_METRICS,
  INS_SEGMENTATION_EVALUATION_METRICS,
  METRIC_UNIT,
  METRIC_VALUE,
  MODEL_NAME,
  OBJECT_DETECTION_EVALUATION_METRICS,
  ORIGINAL_METRIC_VALUE,
  PAN_SEGMENTATION_EVALUATION_METRICS,
  SEGMENTATION_EVALUATION_METRICS,
} from '../../../../../../constants';
import { useLoaderContext, useModalContext } from '../../../../../../context';
import { CompressModel } from '../../../../../../lib';
import { LoaderActions, ModalActions } from '../../../../../../reducers';
import { CompressService } from '../../../../../../services';
import {
  isInstanceSegmentation,
  isObjectDetection,
  isPanopticSegmentation,
  isSemanticSegmentation,
} from '../../../../../../utils';
import { isImageSegmentation } from '../../../../../../utils/conditions/tasks/isImageSegmentation';

const EditModelScheme = yup.object().shape({
  [MODEL_NAME]: yup
    .string()
    .required(FormErrorMessages.isRequired)
    .min(2, FormErrorMessages.isNotMin(2))
    .max(20, FormErrorMessages.isNotMax(20))
    .matches(FIELD_NAME, FormErrorMessages.isWrongCharacters),
  [DESCRIPTION]: yup.string().max(100, FormErrorMessages.isNotMax(100)),
  [METRIC_UNIT]: yup.string().required(FormErrorMessages.isRequired),
  [METRIC_VALUE]: yup.number().typeError('Metric value should be a numeric').required(FormErrorMessages.isRequired),
  [ORIGINAL_METRIC_VALUE]: yup
    .number()
    .typeError('Metric value should be a numeric')
    .required(FormErrorMessages.isRequired),
});

export type EditCompressedModelType = yup.InferType<typeof EditModelScheme>;

const EditModelDefaultValues = {
  [MODEL_NAME]: '',
  [DESCRIPTION]: '',
  [METRIC_UNIT]: '',
  [METRIC_VALUE]: 0,
  [ORIGINAL_METRIC_VALUE]: 0,
};

interface IEditCompressedModelDialog {
  onComplete: VoidFunction;
  compressedModel: CompressModel;
}

export const EditCompressedModelDialog: React.FC<IEditCompressedModelDialog> = ({ onComplete, compressedModel }) => {
  const { handleSubmit, setValue, watch, control, getValues } = useForm({
    defaultValues: EditModelDefaultValues,
    mode: 'onBlur',
    resolver: yupResolver(EditModelScheme),
  });
  const [innerMetricUnit, setInnerMetricUnit] = useState('');
  const { isDirty, errors, isValid } = useFormState({ control });
  const [, dispatchLoading] = useLoaderContext();
  const [, dispatchModal] = useModalContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();

  const metricUnitOptions = useMemo<OptionType[]>(() => {
    if (!compressedModel || !compressedModel.task) {
      return [];
    }
    const { task } = compressedModel;

    if (isObjectDetection(task)) {
      return OBJECT_DETECTION_EVALUATION_METRICS;
    }

    if (isSemanticSegmentation(task)) {
      return SEGMENTATION_EVALUATION_METRICS;
    }

    if (isInstanceSegmentation(task)) {
      return INS_SEGMENTATION_EVALUATION_METRICS;
    }

    if (isPanopticSegmentation(task)) {
      return PAN_SEGMENTATION_EVALUATION_METRICS;
    }

    if (isImageSegmentation(task)) {
      return IMAGE_SEGMENTAION_EVALUATION_METRICS;
    }

    return CLASSIFICATION_EVALUATION_METRICS;

    // return metricUnitsForTask[compressedModel.task];
  }, [compressedModel]);

  useEffect(() => {
    if (compressedModel && metricUnitOptions.length > 0) {
      setValue(MODEL_NAME, compressedModel[MODEL_NAME], { shouldValidate: true, shouldDirty: true });
      setValue(DESCRIPTION, compressedModel[DESCRIPTION], { shouldValidate: true, shouldDirty: true });
      const metricUnit = compressedModel.metric.metric_unit;
      const originalMetricValue = compressedModel.metric.metric_value;

      const isExist = metricUnitOptions.some((option) => option.value === metricUnit);

      if (isExist) {
        setInnerMetricUnit(metricUnit);
      } else {
        setInnerMetricUnit('Other');
      }
      setValue(METRIC_UNIT, metricUnit);
      setValue(ORIGINAL_METRIC_VALUE, originalMetricValue || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compressedModel, metricUnitOptions]);

  const watched = watch();

  const isDisabled = () => !isDirty || !isEmpty(errors) || !isValid;

  const onClickCancel: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setValue(MODEL_NAME, value, { shouldValidate: true, shouldDirty: true });
  };

  const handleDescriptionChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const { value } = event.target;

    setValue(DESCRIPTION, value, { shouldValidate: true, shouldDirty: true });
  };

  const handleChangeMetricUnit: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    setInnerMetricUnit(value);

    const updateMetricUnit = value === 'Other' ? '' : value;

    setValue(METRIC_UNIT, updateMetricUnit, { shouldValidate: true, shouldDirty: true });
  };

  const handleChangeMetricUnitOtherText: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setValue(METRIC_UNIT, value, { shouldValidate: true, shouldDirty: true });
  };

  const handleChangeCompressedEvaluation: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setValue(METRIC_VALUE, Number(value), { shouldValidate: true, shouldDirty: true });
  };

  const handleChangeOriginalEvaluation: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setValue(ORIGINAL_METRIC_VALUE, Number(value), { shouldValidate: true, shouldDirty: true });
  };

  const onSubmit: SubmitHandler<EditCompressedModelType> = async (data) => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      const res = await CompressService.updateModel({
        model_id: compressedModel.model_id,
        ...data,
      });

      if (res.status === 200) {
        dispatchLoading({ type: LoaderActions.Hide });
        showToast(
          <Toast
            content="You requested to edit model successfully"
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      showToast(
        <Toast
          content="Editing model request failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      onComplete();
      dispatchModal({ type: ModalActions.Hide });
    }
  };

  const onError: SubmitErrorHandler<EditCompressedModelType> = (formErrors) => {
    // eslint-disable-next-line no-console
    console.error(formErrors);
    showToast(
      <Toast
        content="Please review form fields."
        level={LEVEL_WARNING}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );
  };

  return (
    <div className="flex justify-center items-center h-screen w-full fixed left-0 top-0 bg-black bg-opacity-50 z-20">
      <div className="flex flex-col justify-between bg-white rounded shadow-lg w-[600px] p-6">
        <h3 className="text-xl text-gray-900 font-title font-semibold mb-4">Edit model</h3>
        <form className="w-full" onSubmit={handleSubmit(onSubmit, onError)}>
          <section className="mb-3" data-test="dataset-name-input">
            <InputTextLabel htmlFor={MODEL_NAME}>Model name *</InputTextLabel>
            <TextInput
              id={DISPLAY_NAME}
              placeholder={PLACEHOLDER_MODEL_NAME}
              error={errors[MODEL_NAME] ? errors[MODEL_NAME].message : ''}
              value={watched[MODEL_NAME] || ''}
              width="w-full"
              onChange={handleNameChange}
            />
          </section>
          <section className="mb-8" data-test="dataset-memo-input">
            <InputTextLabel htmlFor={DESCRIPTION}>Memo</InputTextLabel>
            <TextArea
              id={DESCRIPTION}
              error={errors[DESCRIPTION] ? errors[DESCRIPTION].message : ''}
              placeholder={PLACEHOLDER_MODEL_MEMO}
              value={watched[DESCRIPTION] || ''}
              rows={3}
              width="w-full"
              onChange={handleDescriptionChange}
            />
          </section>
          <section className="mb-8" data-test="dataset-metric-input">
            <InputTextLabel htmlFor={METRIC_UNIT}>Evaluation metric</InputTextLabel>
            <div className="w-full flex items-start">
              <Select
                width="w-1/2"
                margin="mr-2"
                value={innerMetricUnit}
                onChange={handleChangeMetricUnit}
                options={metricUnitOptions}
              />
              {innerMetricUnit === 'Other' && (
                <TextInput
                  id={`${METRIC_UNIT}_text`}
                  width="w-1/2"
                  error={errors[METRIC_UNIT] ? errors[METRIC_UNIT].message : ''}
                  value={watched[METRIC_UNIT]}
                  onChange={handleChangeMetricUnitOtherText}
                />
              )}
            </div>
          </section>
          <section className="mb-8" data-test="dataset-originalMetricValue-input">
            <InputTextLabel htmlFor={ORIGINAL_METRIC_VALUE}>Evaluation Value - Original</InputTextLabel>
            <TextInput
              id={ORIGINAL_METRIC_VALUE}
              value={watched[ORIGINAL_METRIC_VALUE].toString()}
              error={errors[ORIGINAL_METRIC_VALUE] ? errors[ORIGINAL_METRIC_VALUE].message : ''}
              width="w-full"
              onChange={handleChangeOriginalEvaluation}
            />
          </section>
          <section className="mb-8" data-test="dataset-metricValue-input">
            <InputTextLabel htmlFor={METRIC_VALUE}>Evaluation Value - Compressed</InputTextLabel>
            <TextInput
              id={METRIC_VALUE}
              value={watched[METRIC_VALUE].toString()}
              error={errors[METRIC_VALUE] ? errors[METRIC_VALUE].message : ''}
              width="w-full"
              onChange={handleChangeCompressedEvaluation}
            />
          </section>
          <div className="flex justify-end pt-2">
            <OutlineButton className="mr-3" onClick={onClickCancel}>
              Cancel
            </OutlineButton>
            <SubmitButton disabled={isDisabled()}>Confirm</SubmitButton>
          </div>
        </form>
      </div>
    </div>
  );
};
