import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { ReactFlowProvider } from 'react-flow-renderer';
import ErrorBoundary from './ErrorBoundary';

import App from './App';
import { CookieConsent } from './components/CookieConsent';
import { NotificationProvider } from './components/Notifications';
import {
  AuthContextProvider,
  CompressFormContextProvider,
  ConvertModelFormContextProvider,
  DatasetsContextProvider,
  DatasetsFormContextProvider,
  LoaderContextProvider,
  ModalContextProvider,
  ModelsContextProvider,
  PackagingContextProvider,
  ProjectsContextProvider,
  ProjectsFormContextProvider,
  SaveModelFormContextProvider,
  SideNavContextProvider,
  UploadModelFormContextProvider,
} from './context';
import { Compose } from './utils';

const providers = [
  LoaderContextProvider,
  ModalContextProvider,
  NotificationProvider,
  AuthContextProvider,
  DatasetsContextProvider,
  ProjectsContextProvider,
  ModelsContextProvider,
  DatasetsFormContextProvider,
  ProjectsFormContextProvider,
  ConvertModelFormContextProvider,
  SaveModelFormContextProvider,
  PackagingContextProvider,
  UploadModelFormContextProvider,
  CompressFormContextProvider,
  ReactFlowProvider,
  SideNavContextProvider,
];

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <Compose providers={providers}>
          <>
            <App />
            <CookieConsent />
          </>
        </Compose>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
