import React from 'react';

interface ITaskTag extends React.PropsWithChildren {
  className?: string;
  dataTestId?: string;
}

export const TaskTag: React.FC<ITaskTag> = ({ children, className, dataTestId = 'task-tag' }) => (
  <span
    className={`bg-primary-50 rounded-full px-3 py-1.5 text-xs font-medium text-primary-500 drop-shadow-sm uppercase ${className}`}
    data-testid={dataTestId}
  >
    {children}
  </span>
);
