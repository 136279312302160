import React, { useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { InputTextLabel, OutlineButton, SubmitButton, TextArea, TextInput } from '@netspresso/components';
import { PLACEHOLDER_MODEL_NAME, PLACEHOLDER_MODEL_MEMO, isEmpty, API_MODEL_V1, apiClient } from '@netspresso/shared';

import {
  LEVEL_DANGER,
  LEVEL_SUCCESS,
  LEVEL_WARNING,
  Toast,
  useNotificationContext,
} from '../../../../components/Notifications';
import { useLoaderContext, useModalContext, useSaveModelFormContext } from '../../../../context';
import { DISPLAY_NAME, DESCRIPTION } from '../../../../constants';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { SaveModelType } from '../../../../schemes';

type SaveModelProps = {
  modelId: string;
};

export const SaveModel: React.FC<SaveModelProps> = ({ modelId }) => {
  const navigate = useNavigate();
  const [modelName, setModelName] = useState('');
  const [modelDescription, setModelDescription] = useState('');
  const { handleSubmit, setValue, watch, control } = useSaveModelFormContext();
  const { isDirty, errors } = useFormState({ control });
  const [, dispatchLoading] = useLoaderContext();
  const [, dispatchModal] = useModalContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();

  const watched = watch();

  const isDisabled = () => {
    return !isDirty || !isEmpty(errors);
  };

  const onClickCancel: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setModelName(value);
    setValue(DISPLAY_NAME, value, { shouldValidate: true, shouldDirty: true });
  };

  const onDescriptionChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const { value } = event.target;

    setModelDescription(value);
    setValue(DESCRIPTION, value, { shouldValidate: true, shouldDirty: true });
  };

  const onSubmit: SubmitHandler<SaveModelType> = async (data) => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      const res = await apiClient.put(`${API_MODEL_V1}/${modelId}/save`, data);

      if (res.status === 200) {
        dispatchLoading({ type: LoaderActions.Hide });
        showToast(
          <Toast
            content="You requested to save model successfully"
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
        navigate(`/models`);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      showToast(
        <Toast
          content="Saving model request failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchModal({ type: ModalActions.Hide });
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const onError: SubmitErrorHandler<SaveModelType> = (formErrors) => {
    // eslint-disable-next-line no-console
    console.log(formErrors);
    showToast(
      <Toast
        content="Please review form fields."
        level={LEVEL_WARNING}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );
  };

  return (
    <div className="bg-white shadow-lg rounded-lg w-[600px] ml-0 xl:-ml-[186px] 3xl:-ml-[300px] -mt-[64px] p-6">
      <h1 className="text-secondary-800 text-xl font-title font-bold mb-2">Save model</h1>
      <form className="w-full" onSubmit={handleSubmit(onSubmit, onError)}>
        <section className="mb-2">
          <InputTextLabel htmlFor={DISPLAY_NAME}>Model name *</InputTextLabel>
          <TextInput
            id={DISPLAY_NAME}
            placeholder={PLACEHOLDER_MODEL_NAME}
            error={errors[DISPLAY_NAME] ? errors[DISPLAY_NAME].message : ''}
            value={watched[DISPLAY_NAME] || modelName}
            width="w-full"
            onChange={onNameChange}
          />
        </section>
        <section className="mb-2">
          <InputTextLabel htmlFor={DESCRIPTION}>Memo</InputTextLabel>
          <TextArea
            id={DESCRIPTION}
            error={errors[DESCRIPTION] ? errors[DESCRIPTION].message : ''}
            placeholder={PLACEHOLDER_MODEL_MEMO}
            rows={3}
            width="w-full"
            value={watched[DESCRIPTION] || modelDescription}
            onChange={onDescriptionChange}
          />
        </section>
        <div className="flex justify-end pt-2">
          <OutlineButton className="mr-3" onClick={onClickCancel}>
            Cancel
          </OutlineButton>
          <SubmitButton disabled={isDisabled()}>Confirm</SubmitButton>
        </div>
      </form>
    </div>
  );
};
