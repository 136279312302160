/* eslint-disable camelcase */
import React from 'react';
import { parseUserServerStatus } from '../../../../../../utils';
import { UserAgentType } from '../../../../../../lib';

type UserServerProps = {
  userServer: UserAgentType;
  selected: string;
  isDisabled: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

export const UserServer: React.FC<UserServerProps> = ({ userServer, selected, isDisabled, onClick }) => {
  const statusText = parseUserServerStatus({
    availableResult: 'Available',
    busyResult: 'Running',
    disabledResult: 'Not Available',
  });

  const serverStatusColor = parseUserServerStatus({
    availableResult: 'text-green-600',
    busyResult: 'text-sub',
    disabledResult: 'text-danger',
  });

  return (
    <div
      className={`${isDisabled ? 'bg-gray-100' : selected === userServer.agent_id ? 'bg-secondary-50' : 'bg-white'} ${
        !isDisabled ? 'cursor-pointer hover:shadow' : ''
      } text-sm rounded-lg border border-gray-300 px-6 py-4 mt-4 flex flex-row items-center justify-between`}
      onClick={!isDisabled ? onClick : undefined}
    >
      <div>
        <h1 className="font-title font-bold text-gray-900">{userServer.name}</h1>
        <p className="text-right text-gray-500">{userServer.created_time}</p>
      </div>
      <div>
        <p className={`text-center font-semibold ${serverStatusColor(userServer)}`}>{statusText(userServer)}</p>
      </div>
    </div>
  );
};
