import React from 'react';

interface RadioButtonProps {
  groupName: string;
  label: string;
  value: string | number;
  children?: React.ReactElement;
  classes?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  isChecked?: boolean;
  isDisabled?: boolean;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  groupName,
  label,
  value,
  children,
  classes = '',
  onChange,
  isChecked = false,
  isDisabled = false,
}) => {
  const id = `${groupName}-${value}`.replace(/ /g, '_');

  return (
    <label className={`inline-flex items-center radio-normal ${classes}`} htmlFor={id}>
      {onChange ? (
        <input
          type="radio"
          id={id}
          name={groupName}
          value={value}
          disabled={isDisabled}
          checked={isChecked}
          onChange={onChange}
        />
      ) : (
        <input type="radio" id={id} name={groupName} value={value} disabled={isDisabled} defaultChecked={isChecked} />
      )}

      <span className="leading-4 font-darkGray">{label}</span>
      {children}
    </label>
  );
};
