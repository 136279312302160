import { OutputDataTypes, OUTPUT_DATA_TYPES } from '../types';

export const isOutputDataTypes = (value: unknown): value is OutputDataTypes => {
  switch (value) {
    case OUTPUT_DATA_TYPES.FP32:
    case OUTPUT_DATA_TYPES.FP16:
    case OUTPUT_DATA_TYPES.INT8:
    case OUTPUT_DATA_TYPES.INT4:
      return true;
    default:
      return false;
  }
};
