import { useNavigate, useParams } from 'react-router-dom';

import { PanelHeader } from '@netspresso/components';
import { ADVANCED_SEARCH, QUICK_SEARCH, RETRAINING } from '@netspresso/shared';

import React, { useEffect } from 'react';
import { useProjectsFormContext } from '../../../../context';
import { useGTM } from '../../../../utils';
import { TrainingMethodCard } from '../../components';

export const CreateProjects: React.FC = () => {
  const navigate = useNavigate();
  const {
    projectsForm: { reset },
  } = useProjectsFormContext();
  const { datasetUid } = useParams();
  const { setPageToDataLayer } = useGTM();

  const onClickQuickSearch = () => {
    reset();
    navigate(`/projects/quicksearch${datasetUid ? `/dataset/${datasetUid}` : ''}`);
  };

  const onClickRetraining = () => {
    reset();
    navigate(`/projects/retraining${datasetUid ? `/dataset/${datasetUid}` : ''}`);
  };

  useEffect(() => {
    setPageToDataLayer('Select a Training Method');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full flex justify-center">
      <section className="w-[960px] px-3">
        <PanelHeader className="mb-6">Select a Training Method</PanelHeader>
        <div className="flex justify-between w-full">
          <TrainingMethodCard method={QUICK_SEARCH} onClick={onClickQuickSearch} />
          <TrainingMethodCard method={ADVANCED_SEARCH} />
          <TrainingMethodCard method={RETRAINING} onClick={onClickRetraining} />
        </div>
      </section>
    </div>
  );
};
