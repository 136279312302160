import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-hook-form';

import { DatasetInput, FormHeaderLabel, InfoContainer, InfoText, RadioButton } from '@netspresso/components';
import {
  LOCAL_DATASET,
  LOCAL_STORAGE,
  OBJECT_DETECTION,
  S3_STORAGE,
  StorageMap,
  featureFlags,
  parseYamlForLocalDataset,
  parseYamlToClassnames,
  parseYamlToFormat,
  parseYamlToNumImages,
  parseYamlToNumInstances,
  parseYamlToTask,
} from '@netspresso/shared';

import {
  CERT_FILE,
  CLASS_NAME,
  DATASET_FORMAT,
  DATASET_PATH,
  NUM_IMAGES,
  NUM_INSTANCES,
  STORAGE_TYPE,
  SYSTEM_UUID,
  TASK,
  TEST_FILE,
  TRAIN_FILE,
  VAL_FILE,
  YAML_FILE,
} from '../../../../../../constants';
import { useAuthContext, useDatasetsFormContext } from '../../../../../../context';
import { DATASETS, isStorageType } from '../../../../../../lib';

export const UploadDatasetFiles: React.FC = () => {
  const { user } = useAuthContext();
  const [trainFile, setTrainFile] = useState<File | null>(null);
  const [valFile, setValFile] = useState<File | null>(null);
  const [testFile, setTestFile] = useState<File | null>(null);
  const [certFile, setCertFile] = useState<File | null>(null);
  const [yamlFile, setYamlFile] = useState<File | null>(null);
  const { setValue, trigger, control, watch, setError } = useDatasetsFormContext();
  const { errors } = useFormState({ control });

  const isS3Stroage = watch(STORAGE_TYPE) === S3_STORAGE;

  useEffect(() => {
    let fileReader: FileReader;
    let isCancel = false;

    if (yamlFile) {
      fileReader = new FileReader();
      fileReader.onload = (event) => {
        const reader = event.target;

        if (!(reader instanceof FileReader)) {
          return;
        }

        const { result } = reader;

        if (result && !isCancel) {
          const numImages = parseYamlToNumImages(`${result}`);
          const numInstances = parseYamlToNumInstances(`${result}`);
          const classNames = parseYamlToClassnames(`${result}`);
          const datasetTask = parseYamlToTask(`${result}`);
          const datasetFormat = parseYamlToFormat(`${result}`);

          const {
            dataset_path: datasetPath,
            storage_type: storageType,
            system_uuid: systemUuid,
          } = parseYamlForLocalDataset(`${result}`);

          setValue(NUM_IMAGES, numImages);
          setValue(NUM_INSTANCES, numInstances);
          setValue(CLASS_NAME, classNames);
          setValue(TASK, datasetTask);
          setValue(DATASET_FORMAT, datasetFormat);

          if (watch(STORAGE_TYPE) === LOCAL_STORAGE) {
            if (!datasetPath) {
              setError(YAML_FILE, { message: `[${yamlFile.name}] dataset_path is missing in the file.` });

              return;
            }

            if (!systemUuid) {
              setError(YAML_FILE, { message: `[${yamlFile.name}] system_uuid is missing in the file.` });

              return;
            }
          }

          setValue(DATASET_PATH, datasetPath);
          setValue(SYSTEM_UUID, systemUuid);
        }
      };
      fileReader.readAsText(yamlFile);
    }

    return () => {
      isCancel = true;

      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [yamlFile, setValue, setError, watch]);

  const onTrainFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) {
      setTrainFile(null);
      setValue(TRAIN_FILE, null, { shouldValidate: true, shouldDirty: true });

      return;
    }

    const file = event.target.files[0];

    setTrainFile(file);
    setValue(TRAIN_FILE, file, { shouldValidate: true, shouldDirty: true });
  };

  const onValFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) {
      setValFile(null);
      setValue(VAL_FILE, null, { shouldValidate: true, shouldDirty: true });

      return;
    }

    const file = event.target.files[0];

    setValFile(file);
    setValue(VAL_FILE, file, { shouldValidate: true, shouldDirty: true });
    trigger(TEST_FILE, { shouldFocus: true });
  };

  const onTestFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) {
      setTestFile(null);
      setValue(TEST_FILE, null, { shouldValidate: true, shouldDirty: true });

      return;
    }

    const file = event.target.files[0];

    setTestFile(file);
    setValue(TEST_FILE, file, { shouldValidate: true, shouldDirty: true });
    trigger(VAL_FILE, { shouldFocus: true });
  };

  const onCertFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) {
      setCertFile(null);
      setValue(CERT_FILE, null, { shouldValidate: true, shouldDirty: true });

      return;
    }

    const file = event.target.files[0];

    setCertFile(file);
    setValue(CERT_FILE, file, { shouldValidate: true, shouldDirty: true });
    trigger(VAL_FILE, { shouldFocus: true });
  };

  const onYamlFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) {
      setYamlFile(null);
      setValue(YAML_FILE, null, { shouldValidate: true, shouldDirty: true });

      return;
    }

    const file = event.target.files[0];

    setYamlFile(file);
    setValue(YAML_FILE, file, { shouldValidate: true, shouldDirty: true });
    trigger(VAL_FILE, { shouldFocus: true });
  };

  const onStorageTypeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    if (!isStorageType(value)) {
      return;
    }

    // initialize
    setValue(TRAIN_FILE, null);
    setTrainFile(null);
    setValue(VAL_FILE, null);
    setValFile(null);
    setValue(TEST_FILE, null);
    setTestFile(null);
    setValue(CERT_FILE, null);
    setCertFile(null);
    setValue(YAML_FILE, null);
    setYamlFile(null);

    // yamlInfo
    setValue(NUM_IMAGES, null);
    setValue(NUM_INSTANCES, []);
    setValue(CLASS_NAME, []);
    setValue(DATASET_FORMAT, DATASETS.YOLO);
    setValue(TASK, OBJECT_DETECTION);
    setValue(DATASET_PATH, '');
    setValue(SYSTEM_UUID, '');

    setValue(STORAGE_TYPE, value);
  };

  return (
    <>
      {featureFlags(LOCAL_DATASET) && (
        <section className="mb-8 pb-8">
          <FormHeaderLabel>Dataset Location *</FormHeaderLabel>
          <div className="flex flex-col gap-4">
            <RadioButton
              groupName={STORAGE_TYPE}
              value={S3_STORAGE}
              label={StorageMap[S3_STORAGE]}
              isChecked={watch(STORAGE_TYPE) === S3_STORAGE}
              onChange={onStorageTypeChange}
            />
            <RadioButton
              groupName={STORAGE_TYPE}
              value={LOCAL_STORAGE}
              label={StorageMap[LOCAL_STORAGE]}
              isChecked={watch(STORAGE_TYPE) === LOCAL_STORAGE}
              onChange={onStorageTypeChange}
            />
          </div>
        </section>
      )}
      <section className="mb-8 border-b pb-8">
        <FormHeaderLabel>Select files</FormHeaderLabel>
        {isS3Stroage && (
          <DatasetInput
            className="border-b pb-2"
            id="training-dataset"
            label="Training dataset *"
            max={`${user.authorities[1] > 9 ? '200' : '5'}`}
            fileError={errors[TRAIN_FILE] ? errors[TRAIN_FILE].message : ''}
            filePlaceHolder="Select zip file"
            localFile={trainFile}
            onFileChange={onTrainFileChange}
          />
        )}
        {isS3Stroage && (
          <DatasetInput
            className="border-b py-2"
            id="validation-dataset"
            label="Validation dataset"
            max={`${user.authorities[1] > 9 ? '200' : '1'}`}
            fileError={errors[VAL_FILE] ? errors[VAL_FILE].message : ''}
            filePlaceHolder="Select zip file"
            localFile={valFile}
            onFileChange={onValFileChange}
          />
        )}
        {isS3Stroage && (
          <DatasetInput
            className="border-b py-2"
            id="testing-dataset"
            label="Testing dataset"
            max={`${user.authorities[1] > 9 ? '200' : '1'}`}
            fileError={errors[TEST_FILE] ? errors[TEST_FILE].message : ''}
            filePlaceHolder="Select zip file"
            localFile={testFile}
            onFileChange={onTestFileChange}
          />
        )}
        <DatasetInput
          className="border-b py-2"
          id="cert-dataset"
          label="Certification *"
          max=""
          accept=".np"
          fileError={errors[CERT_FILE] ? errors[CERT_FILE].message : ''}
          filePlaceHolder="Select cert file"
          localFile={certFile}
          onFileChange={onCertFileChange}
        />
        <DatasetInput
          className="pt-2 mb-4"
          id="classInfo-dataset"
          label="Class information *"
          max=""
          accept=".yaml, .yml"
          fileError={errors[YAML_FILE] ? errors[YAML_FILE].message : ''}
          filePlaceHolder="Select yaml file"
          localFile={yamlFile}
          onFileChange={onYamlFileChange}
        />
        <InfoContainer infoType="warning" iconName="warning" className="mb-2">
          <InfoText color="warning">
            It is recommended to upload at least 1000 training dataset for model performance.
          </InfoText>
        </InfoContainer>
        <InfoContainer>
          <ul className="w-full list-disc flex flex-col ml-3">
            <li className="text-xs text-secondary leading-[18px]">
              Trainig dataset : Dataset used to train the model.
            </li>
            <li className="text-xs text-secondary leading-[18px]">
              Validation dataset : Dataset used for evaluation to improve the performance of the model during training.
            </li>
            <li className="text-xs text-secondary leading-[18px]">
              Testing dataset : Dataset used to evaluate the performance of the model after training.
            </li>
          </ul>
        </InfoContainer>
      </section>
    </>
  );
};
