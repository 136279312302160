/* eslint-disable camelcase */
import { API_PROJECT_V1, LOCAL_DATASET, LOCAL_STORAGE, S3_STORAGE, featureFlags } from '@netspresso/shared';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLoaderContext } from '../../../../context';
import { Dataset, Project } from '../../../../lib';
import { LoaderActions } from '../../../../reducers';
import { DatasetService, ProjectService, TestImage } from '../../../../services';
import { useFetch } from '../../../../utils';

export const ModelTesting: React.FC = () => {
  const [, dispatchLoading] = useLoaderContext();
  const { projectUid } = useParams();
  const [trialId, setTrialId] = useState('');
  const { data: projectData, loading: projectLoading } = useFetch<Project>(`${API_PROJECT_V1}/${projectUid}`);
  const [projectName, setProjectName] = useState('');
  const [modelImages, setModelImages] = useState<{ data: TestImage[]; loading: boolean }>({ data: [], loading: false });
  const [datasetInfo, setDatasetInfo] = useState<{ data: Dataset | null; loading: boolean }>({
    data: null,
    loading: false,
  });

  const fetchTestImages = async (projectId: string, trialUid: string) => {
    try {
      setModelImages((prev) => ({ ...prev, loading: true }));
      const res = await ProjectService.getTestImages(projectId, trialUid);

      const images = res.data;

      setModelImages({ data: [...images], loading: false });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setModelImages({ data: [], loading: false });
    }
  };

  const fetchDatasetInfo = async (datasetId: string) => {
    try {
      setDatasetInfo((prev) => ({ ...prev, loading: true }));

      const { data: datasetData } = await DatasetService.getDatasetById(datasetId);

      setDatasetInfo({ data: datasetData, loading: false });
    } catch (err) {
      setDatasetInfo({ data: null, loading: false });
    }
  };

  const isLoading = useMemo(
    () => projectLoading || datasetInfo.loading || modelImages.loading,
    [projectLoading, datasetInfo.loading, modelImages.loading]
  );

  useEffect(() => {
    dispatchLoading({ type: isLoading ? LoaderActions.Show : LoaderActions.Hide });
  }, [isLoading, dispatchLoading]);

  useEffect(() => {
    if (projectData) {
      const { project_name, trials, dataset_id } = projectData;

      fetchDatasetInfo(dataset_id);

      setProjectName(project_name);
      const { trial_id } = trials[0];

      setTrialId(trial_id);
    }
  }, [projectData]);

  useEffect(() => {
    if (projectUid && trialId) {
      fetchTestImages(projectUid, trialId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trialId]);

  return (
    <div className="w-full px-3">
      <section className="bg-white rounded-lg shadow mb-6 p-6">
        <h1 className="text-secondary-800 text-xl font-title font-bold">{projectName}</h1>
        <p className="text-m text-darkGray font-normal pt-4">
          The inference results of the model on the test dataset are shown below. If test datasets are not uploaded,
          they will be replaced with validation datasets.
        </p>
        {featureFlags(LOCAL_DATASET) && datasetInfo.data?.storage_type === LOCAL_STORAGE ? (
          <div className="mt-8 flex flex-col items-center justify-center h-[304px] gap-4 text-neutralBlack-300">
            <span className="material-symbols-outlined text-4xl">image</span>
            <p className="">There are no images to display as the model is trained with a local dataset.</p>
          </div>
        ) : (
          modelImages.data.map((item) => (
            <img key={item.img_name} src={item.img_data} alt={item.img_name} className="w-full pt-8" />
          ))
        )}
      </section>
    </div>
  );
};
