import { InfoButton, InfoContainer, InfoText, Panel, PanelContent, PanelHeader } from '@netspresso/components';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LEVEL_DANGER, Toast, useNotificationContext } from '../../../../components';
import { useLoaderContext, useModalContext } from '../../../../context';
import { Compressed, CompressModel } from '../../../../lib';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { CompressService, ModelService } from '../../../../services';
import { download, parseFramework, useGTM } from '../../../../utils';
import { CompressionDetail, CompressionInfo, EditCompressedModelDialog, ModelPerformance } from './components';

export const CompressedModelDetail: React.FC = () => {
  const { modelUid } = useParams();
  const [, dispatchLoading] = useLoaderContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const [, dispatchModal] = useModalContext();
  const [compressedModel, setCompressedModel] = useState<CompressModel>();
  const [baseModel, setBaseModel] = useState<CompressModel>();
  const [compression, setCompression] = useState<Compressed>();
  const { setPageToDataLayer } = useGTM();

  const fetchDetailInfo = async (modelId: string) => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      const { data: compressedModelInfo } = await CompressService.getModel({ model_id: modelId });

      if (!compressedModelInfo) {
        return;
      }
      setCompressedModel(compressedModelInfo);

      if (!compressedModelInfo.original_compression_id || !compressedModelInfo.original_compression_id) {
        return;
      }

      const [{ data: baseModelInfo }, { data: compressionInfo }] = await Promise.all([
        CompressService.getModel({ model_id: compressedModelInfo.original_model_id }),
        CompressService.getCompression({ compression_id: compressedModelInfo.original_compression_id }),
      ]);

      setBaseModel(baseModelInfo);
      setCompression(compressionInfo);
      dispatchLoading({ type: LoaderActions.Hide });
    } catch (error) {
      showToast(
        <Toast
          content="Fetch Model Detail Info failed, please try again."
          level={LEVEL_DANGER}
          hideToast={hideToast}
          onClick={onClickToastHandler}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const handleCompleteModelUpdate = () => {
    if (modelUid) {
      fetchDetailInfo(modelUid);
    }
  };

  const handleClickEditModal: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (compressedModel) {
      dispatchModal({
        type: ModalActions.Show,
        payload: <EditCompressedModelDialog compressedModel={compressedModel} onComplete={handleCompleteModelUpdate} />,
      });
    }
  };

  const onClickDownload: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();

    if (modelUid) {
      const res = await ModelService.downloadModel(modelUid);

      if (res.status === 200) {
        const downloadUrl = res.data.s3_urls;

        for (const key in downloadUrl) {
          if (downloadUrl[key]) {
            download(downloadUrl[key]);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (modelUid) {
      fetchDetailInfo(modelUid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelUid]);

  useEffect(() => {
    setPageToDataLayer('Compressed Model Detail');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!compressedModel || !baseModel || !compression) {
    return null;
  }

  return (
    <div className="w-full px-3">
      <Panel className="relative">
        <PanelHeader className="pl-6 mb-4">{compressedModel.model_name || 'test default'}</PanelHeader>
        {/* {compressedModel.origin_from !== 'npms' && ( */}
        <button
          type="button"
          className="absolute top-0 right-6 text-m text-darkGray hover:text-secondary disabled:text-lineGray pt-1"
          onClick={handleClickEditModal}
        >
          <span className="material-icons text-base">edit</span>
        </button>
        {/* )} */}
        {compressedModel.description && (
          <p className="text-m text-darkGray font-normal pl-6 mb-4">{compressedModel.description}</p>
        )}
        <section className="bg-white rounded-lg shadow mb-6 p-6">
          <PanelContent className="flex-col">
            <CompressionInfo compression={compression} compressedModel={compressedModel} />
            <InfoContainer className="mt-4">
              <>
                <InfoText>You can download {`${parseFramework(compressedModel.framework)} (.pt)`}.</InfoText>
                <InfoButton onClick={onClickDownload}>Download</InfoButton>
              </>
            </InfoContainer>
            <ModelPerformance baseModel={baseModel} compressedModel={compressedModel} />
            <CompressionDetail baseModel={baseModel} compression={compression} />
          </PanelContent>
        </section>
      </Panel>
    </div>
  );
};
