import React, { createContext, useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormReturn, useForm } from 'react-hook-form';
import { DatasetScheme, DatasetType, DatasetsDefaultValues } from '../schemes';

export const DatasetsFormContext = createContext<UseFormReturn<DatasetType> | null>(null);

export const DatasetsFormContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const datasetsForm = useForm({
    defaultValues: DatasetsDefaultValues,
    resolver: yupResolver(DatasetScheme),
  });

  return <DatasetsFormContext.Provider value={datasetsForm}>{children}</DatasetsFormContext.Provider>;
};

export const useDatasetsFormContext = (): UseFormReturn<DatasetType> => {
  const context = useContext(DatasetsFormContext);

  if (!context) {
    throw new Error('useDatasetsFormContext must be used within a DatasetsFormContextProvider');
  }

  return context;
};
