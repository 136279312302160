import { NotaServerType } from '../../lib';

/* eslint-disable camelcase */
export const parseNotaServerStatus =
  ({
    availableResult,
    busyResult,
    disabledResult,
  }: {
    availableResult: string;
    busyResult: string;
    disabledResult: string;
  }) =>
  ({ remained_num, status, free_servers_num }: NotaServerType): string =>
    status === 'running'
      ? busyResult
      : status !== 'available' || remained_num <= 0 || free_servers_num <= 0
      ? disabledResult
      : availableResult;
