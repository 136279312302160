/* eslint-disable camelcase */
import { ADVANCED_COMPRESSION, AUTOMATIC_COMPRESSION, FIELD_NAME, FormErrorMessages } from '@netspresso/shared';
import * as yup from 'yup';
import {
  BASE_MODEL,
  COMPRESSED_MODEL,
  COMPRESSION,
  COMPRESSION_ID,
  COMPRESSION_METHOD,
  COMPRESSION_TYPE,
  DATASET_FILE,
  DESCRIPTION,
  FD_CP,
  FD_SVD,
  FD_TK,
  LAYERS,
  MODEL_ID,
  MODEL_NAME,
  POLICY,
  PR_GM,
  PR_ID,
  PR_L2,
  PR_NN,
  RECOMMENDATION_METHOD,
  RECOMMENDATION_RATIO,
} from '../constants';
// import { getIndexListFromLayerInput } from '../utils';
import {
  AvailableLayer,
  Compressed,
  CompressionMethod,
  CompressionType,
  CompressModel,
  isAvailableLayers,
  MethodInfoKey,
} from '../lib';
import { getIndexListFromLayerInput } from '../utils';

export const CompressionScheme = yup.object({
  [MODEL_NAME]: yup
    .string()
    .required(FormErrorMessages.isRequired)
    .min(2, FormErrorMessages.isNotMin(2))
    .max(20, FormErrorMessages.isNotMax(20))
    .matches(FIELD_NAME, FormErrorMessages.isWrongCharacters),
  [DESCRIPTION]: yup.string().max(100, FormErrorMessages.isNotMax(100)),
  [COMPRESSION_METHOD]: yup.string<CompressionMethod | ''>().when(COMPRESSION_TYPE, {
    is: ADVANCED_COMPRESSION,
    then: (scheme) => scheme.required(FormErrorMessages.isRequired),
  }),
  [BASE_MODEL]: yup.object<CompressModel>().required(FormErrorMessages.isRequired),
  [RECOMMENDATION_RATIO]: yup.number().required(FormErrorMessages.isRequired),
  [RECOMMENDATION_METHOD]: yup.string<MethodInfoKey>(),
  [LAYERS]: yup
    .array()
    .of(yup.object<AvailableLayer>())
    .test('layer_validation', 'dummy message', (value, validationContext) => {
      const { createError, parent } = validationContext;

      if (parent[COMPRESSION_TYPE] !== ADVANCED_COMPRESSION) {
        return true;
      }

      if (value?.length && isAvailableLayers(value)) {
        const usedLayers: AvailableLayer[] = value.filter((layer) => {
          return layer.use;
        });

        if (usedLayers.length === 0) {
          return createError({ message: 'Please select at least one layer to proceed with compression' });
        }

        if (usedLayers.some((layer) => layer.values.includes(''))) {
          return createError({ message: 'Please enter a value for the selected layer' });
        }

        for (const layer of usedLayers) {
          const [firstValue, secondValue] = layer.values;
          const [firstChannel, secondChannel] = layer.channels;

          if (parent[COMPRESSION_METHOD] === FD_TK) {
            // in rank
            if (!(firstValue > 0 && firstValue <= firstChannel)) {
              return createError({ message: `Please insert valid in rank at ${layer.name}` });
            }

            // out rank
            if (!(secondValue > 0 && secondValue <= secondChannel)) {
              return createError({ message: `Please insert valid out rank at ${layer.name}` });
            }
          }

          if ([FD_SVD, FD_CP].includes(parent[COMPRESSION_METHOD])) {
            const fullRank = Math.min(firstChannel, secondChannel);

            if (!(firstValue > 0 && firstValue <= fullRank)) {
              return createError({ message: `Please insert valid rank at ${layer.name}` });
            }
          }

          if (parent[COMPRESSION_METHOD] === PR_ID) {
            const regexp =
              /^(([0-9]{1}[0-9]{0,}){1}(-[0-9]{1}[0-9]{0,}){0,1}){1}(,([0-9]{1}[0-9]{0,}){1}(-[0-9]{1}[0-9]{0,}){0,1}){0,}$/;

            if (!layer.text?.match(regexp)) {
              return createError({ message: `Please insert valid channels at ${layer.name}` });
            }

            const indexList = getIndexListFromLayerInput(layer.text);

            for (const index of indexList) {
              if (!(index >= 0 && index < firstChannel)) {
                return createError({ message: `Please insert valid channels at ${layer.name}` });
              }
            }
          }

          if ([PR_L2, PR_GM, PR_NN].includes(parent[COMPRESSION_METHOD])) {
            if (firstValue > 1 || firstValue <= 0 || firstValue === '') {
              return createError({ message: `Please insert valid ratio at ${layer.name}` });
            }
          }
        }
      }

      return true;
    }),
});

export type CompressModelType = {
  model_name: string;
  model_id: string;
  description: string;
  compression_type: CompressionType;
  compression_method: CompressionMethod | '';
  compression_id: string;
  policy: string;
  dataset_file: File | null;
  recommendation_method: MethodInfoKey | '';
  recommendation_ratio: number;
  base_model: CompressModel | null;
  compressed_model: CompressModel | null;
  compression: Compressed | null;
  layers: AvailableLayer[] | null;
};

export const CompressDefaultValues: CompressModelType = {
  [MODEL_NAME]: '',
  [MODEL_ID]: '',
  [DESCRIPTION]: '',
  [COMPRESSION_TYPE]: AUTOMATIC_COMPRESSION,
  [COMPRESSION_METHOD]: '',
  [COMPRESSION_ID]: '',
  [POLICY]: 'average',
  [DATASET_FILE]: null,
  [RECOMMENDATION_METHOD]: '',
  [RECOMMENDATION_RATIO]: 0.5,
  [BASE_MODEL]: null,
  [COMPRESSED_MODEL]: null,
  [COMPRESSION]: null,
  [LAYERS]: [],
};
