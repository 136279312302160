/* eslint-disable no-underscore-dangle */
import ELK, { ElkEdge, ElkPrimitiveEdge } from 'elkjs/lib/elk.bundled';
import { CompressNode } from '../context';
import { AvailableLayer, CompressEdge, CompressModel } from '../lib';
import { RecommendationNodes } from '../lib/types/compressions/RecommendationNodes';

export const getRecommendedLayers = (recommendationLayers: RecommendationNodes[], layers: AvailableLayer[]) => {
  return layers.map((layer) => {
    const recommendation = recommendationLayers.find((recommendationLayer) => recommendationLayer.name === layer.name);

    return {
      ...layer,
      use: !!recommendation,
      ...(recommendation && { values: [...recommendation.values] }),
    };
  });
};

export const getModelShapeString = (
  array: (string | number | number[])[] | string | number | number[],
  nullToken: string = ''
): string => {
  let string = '<';

  if (Array.isArray(array)) {
    for (let i = 0; i < array.length; i++) {
      if (i > 0) {
        string += ' x ';
      }

      if (Number.isInteger(array[i])) {
        string += array[i];
      } else if (array[i] === null || array[i] === undefined) {
        string += nullToken;
      } else if (Array.isArray(array[i])) {
        string += getModelShapeString(array[i], nullToken);
      }
    }
  }

  string += '>';

  return string;
};

/**
 * 범위 또는 특정 인덱스를 뜻하는 문자열을 배열로 변환합니다.
 * @param {*} s
 * @returns
 */
export const getIndexListFromLayerInput = (s: string): number[] => {
  const indexStrings = s.split(',');
  const indexSet = new Set<number>();

  for (const indexString of indexStrings) {
    if (indexString.indexOf('-') > -1) {
      // *-* 형태
      const [num1, num2] = indexString.split('-');
      const start = Math.min(Number(num1), Number(num2));
      const finish = Math.max(Number(num1), Number(num2));

      for (let i = start; i <= finish; i++) {
        indexSet.add(i);
      }
    } else if (Number(indexString) % 1 === 0) {
      // * 형태
      indexSet.add(Number(indexString));
    }
  }
  const indexList = [...indexSet];
  const indexSort = indexList.sort((a, b) => a - b);

  return indexSort;
};

/**
 * 스네이크케이스 문자열을 대문자로 시작하는 문자열로 변환합니다.
 * @param {*} string
 * @returns
 */
export const snakeCaseToTitleCase = (snakeCase: string) => {
  return snakeCase
    .split('_')
    .map((word) => (word ? word[0].toUpperCase() + word.slice(1).toLowerCase() : ''))
    .join(' ')
    .trim();
};

/**
 * api로 받은 노드 데이터를 화면 구성에 필요한 형태의 노드 데이터로 변환합니다.
 */
export const makeNodeForView = (node: CompressNode, availableLayers: AvailableLayer[]) => {
  const DEFAULT_WIDTH = 500;
  const DEFAULT_HEIGHT = 100;

  return {
    ...node,
    id: `N${node.id}`,
    isAvailable: availableLayers.find((layer) => layer.name === node.values.name),
    width: node.__rf?.width ?? DEFAULT_WIDTH,
    height: node.__rf?.height ?? DEFAULT_HEIGHT,
  };
};

interface ExtendedCompressEdge extends ElkPrimitiveEdge {}

/**
 * api로 받은 엣지 데이터를 화면 구성에 필요한 형태의 엣지 데이터로 변환합니다.
 */
export const makeEdgeForView = (edge: CompressEdge): ExtendedCompressEdge => {
  return {
    id: `N${edge.from}-N${edge.to}`,
    source: `N${edge.from}`,
    target: `N${edge.to}`,
  };
};

const elk = new ELK();
/**
 * elk 라이브러리를 사용하여 정점과 간선 정보를 이용하여 각각의 정점과 간선의 위치를 추론합니다.
 * @param {*} model
 * @param {*} availableLayers
 * @returns
 */

export const getLayoutedElements = async (
  model: CompressModel,
  availableLayers: AvailableLayer[]
): Promise<CompressNode[] & ElkEdge[]> => {
  const nodes = model.nodes.map((node) => makeNodeForView(node, availableLayers));
  const edges = model.edges.map((edge) => makeEdgeForView(edge));
  const layoutedGraph = await elk.layout({
    id: 'root',
    layoutOptions: {
      'elk.algorithm': 'layered',
      'elk.direction': 'DOWN',
      'elk.alignment': 'CENTER',
      'elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
      'elk.contentAlignment': 'H_CENTER',
      'elk.spacing.nodeNode': '50',
      'elk.layered.spacing.nodeNodeBetweenLayers': '50',
    },
    children: nodes,
    edges,
  });
  const layoutedElements: CompressNode[] & ElkEdge[] = [];
  const layoutedNodes = layoutedGraph.children!;

  nodes.forEach((node) => {
    const layoutedNode = layoutedNodes.find((layoutNode) => layoutNode.id === node.id);

    if (layoutedNode && layoutedNode.x && layoutedNode.y && layoutedNode.width && layoutedNode.height) {
      const modifiedNode = { ...node };

      modifiedNode.position = {
        x: layoutedNode.x - layoutedNode.width / 2 + Math.random() / 1000,
        y: layoutedNode.y - layoutedNode.height / 2,
      };

      layoutedElements.push(modifiedNode as CompressNode);
    }
  });

  edges.forEach((edge) => layoutedElements.push(edge as ElkPrimitiveEdge));

  return layoutedElements;
};
