/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { TextInput } from '@netspresso/components';
import { isEmpty, PLACEHOLDER_EMAIL } from '@netspresso/shared';

import {
  LEVEL_DANGER,
  LEVEL_SUCCESS,
  LEVEL_WARNING,
  Toast,
  useNotificationContext,
} from '../../components/Notifications';
import { EMAIL } from '../../constants';
import { useAuthContext, useLoaderContext } from '../../context';
import { LoaderActions } from '../../reducers';
import { ResetPasswordDefaultValues, ResetPasswordScheme, ResetPasswordType } from '../../schemes';
import { AuthService } from '../../services';

export const ResetPassword: React.FC = () => {
  const { handleSubmit, setValue, control } = useForm({
    defaultValues: ResetPasswordDefaultValues,
    mode: 'onBlur',
    resolver: yupResolver(ResetPasswordScheme),
  });
  const { logout } = useAuthContext();
  const { isDirty, errors } = useFormState({ control });
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const [, dispatchLoading] = useLoaderContext();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');

  const isDisabled = () => {
    return !isDirty || !isEmpty(errors) || email.length === 0;
  };

  const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setEmail(event.target.value);
    setValue(EMAIL, event.target.value, { shouldValidate: true, shouldDirty: true });
  };

  const onSubmit: SubmitHandler<ResetPasswordType> = async (data) => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      const res = await AuthService.resetPassword(data);

      if (res.status === 200) {
        dispatchLoading({ type: LoaderActions.Hide });
        showToast(
          <Toast
            content="Your request to reset password is succeded."
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
        logout();
        window.location.assign(process.env.REACT_APP_PORTAL_URL as string);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      showToast(
        <Toast
          content="Requesting reset password failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const onError: SubmitErrorHandler<ResetPasswordType> = (formError) => {
    // eslint-disable-next-line no-console
    console.log(formError);
    showToast(
      <Toast
        content="Please review form fields."
        level={LEVEL_WARNING}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );
  };

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-[518px] bg-white shadow-gt overflow-hidden rounded-lg p-6">
        <h1 className="text-secondary-800 text-xl font-title font-bold mb-6">Reset password</h1>
        <p className="text-defaultGray mb-6">
          Enter a valid e-mail to receive instructions on how to reset your password.
        </p>
        <form className="w-full" onSubmit={handleSubmit(onSubmit, onError)}>
          <section className="mb-6">
            <label className="block font-semibold text-sm text-darkGray mb-1" htmlFor="email">
              Email or username
            </label>
            <TextInput
              id={EMAIL}
              placeholder={PLACEHOLDER_EMAIL}
              error={errors[EMAIL] ? errors[EMAIL].message : ''}
              value={email}
              width="w-full"
              onChange={onEmailChange}
            />
          </section>
          <div className="flex justify-center pt-2">
            <button
              type="button"
              className="text-sm text-white bg-secondary hover:bg-secondary-active px-7 py-2 rounded leading-[14px]"
              disabled={isDisabled()}
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
