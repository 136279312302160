import { AvailableLayer, CompressLatency, CompressModel } from '../lib';

const hasSameTargetDevice = (model: CompressModel) => (item: CompressLatency) =>
  item.device_name === model.target_device;

export const layersReducer = (model: CompressModel) => (resultLatency: number, currentLayer: AvailableLayer) => {
  const { latency } = currentLayer;

  const currentlatency = latency.find(hasSameTargetDevice(model));

  if (currentlatency) {
    return resultLatency > currentlatency.value ? resultLatency : currentlatency.value;
  }

  return resultLatency;
};
