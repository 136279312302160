import { sortByCreatedAt } from '@netspresso/shared';
import { Dataset } from '../lib';

export type DatasetsAction = {
  type: 'ready' | 'remove' | 'add';
  payload: Dataset[];
};

export type DatasetsState = {
  datasets: Dataset[];
};

export const datasetsReducer = (state: DatasetsState, action: DatasetsAction): DatasetsState => {
  switch (action.type) {
    case 'ready':
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        const samples = sortByCreatedAt<Dataset>(action.payload.filter((item) => item.user_uid === 'sample_dataset'));
        const userData = sortByCreatedAt<Dataset>(action.payload.filter((item) => item.user_uid !== 'sample_dataset'));

        return { datasets: [...userData, ...samples] };
      }

      return { datasets: [] };
    case 'remove':
      // eslint-disable-next-line no-case-declarations
      const { payload } = action;

      return { datasets: state.datasets.filter((item, index) => item.dataset_id !== payload[index].dataset_id) };
    case 'add':
      return { datasets: [...JSON.parse(JSON.stringify(state.datasets)), { ...action.payload }] };
    default:
      throw new Error(`Unhandled action :${action.type}`);
  }
};
