import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getTimesLeft = (endTime: string): string => {
  const endDate = dayjs(endTime);
  const current = dayjs();

  if (endDate.diff(current, 'minute') < 0) {
    return `${0}m`;
  }

  if (endDate.diff(current, 'hour') === 0) {
    return `${endDate.diff(current, 'minute')}m`;
  }

  return `${endDate.diff(current, 'hour')}h`;
};
