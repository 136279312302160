/* eslint-disable camelcase */
interface ICreatedAt {
  created_at?: string;
}

export const sortByPropName = <T extends ICreatedAt>(target: Array<T> = [], propName = 'created_at'): Array<T> => {
  const result: Array<T> = JSON.parse(JSON.stringify(target));

  if (propName === 'created_at') {
    return result.sort((a, b) => {
      return a.created_at && b.created_at ? +new Date(b.created_at) - +new Date(a.created_at) : 0;
    });
  }

  return result;
};
