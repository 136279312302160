import React, { useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { CardContainer, TaskTag } from '@netspresso/components';
import {
  API_PROJECT_V1,
  NotificationMessages,
  RETRAINING,
  STATUS_MISMATCH_ERROR_CODE,
  TasksMap,
  apiClient,
  parseDate,
} from '@netspresso/shared';

import { AxiosResponse } from 'axios';
import { Dialog } from '../../../../components';
import {
  LEVEL_DANGER,
  LEVEL_INFO,
  LEVEL_SUCCESS,
  Toast,
  useNotificationContext,
} from '../../../../components/Notifications';
import { useAuthContext, useLoaderContext, useModalContext } from '../../../../context';
import { DeviceNames, NPProjectError, ProjectStatus, ProjectTaskType, ProjectTypes, Trial } from '../../../../lib';
import { LoaderActions, ModalActions } from '../../../../reducers';
import {
  isBenchmarking,
  isBenchmarkingError,
  isCompleted,
  isConfigError,
  isConverting,
  isConvertingError,
  isSubmitted,
  isTraining,
  isTrainingError,
  isTrainingStopped,
} from '../../../../utils';
import { ProjectDevice, ProjectIcons } from './components';
import { ProjectService } from '../../../../services';

type ProjectCardProps = {
  projectUid: string;
  projectName: string;
  projectType: ProjectTypes;
  projectTask: ProjectTaskType;
  trials?: Trial[];
  refresh?: VoidFunction;
  status: ProjectStatus;
  createdAt: string;
  updatedAt: string;
  datasetName: string;
  projectDevice: DeviceNames;
  targetLatency: string;
};

export const ProjectCard: React.FC<ProjectCardProps> = ({
  projectUid,
  projectName,
  projectType,
  projectTask,
  trials,
  refresh,
  status,
  createdAt,
  updatedAt,
  datasetName,
  projectDevice,
  targetLatency,
}) => {
  const navigate = useNavigate();
  const { loading: authLoading } = useAuthContext();
  const [, dispatchModal] = useModalContext();
  const [, dispatchLoading] = useLoaderContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();

  useEffect(() => {
    dispatchLoading({ type: authLoading ? LoaderActions.Show : LoaderActions.Hide });
  }, [authLoading, dispatchLoading]);

  const { isInvalidStopped, isStopped } = useMemo(
    () => ({
      isInvalidStopped: isTrainingError(status) || isConvertingError(status) || isBenchmarkingError(status),
      isStopped: isTrainingStopped(status),
    }),
    [status]
  );

  const onClosePrompt: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const onSubmitPrompt: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();

    try {
      dispatchModal({ type: ModalActions.Hide });
      dispatchLoading({ type: LoaderActions.Show });
      const res = await ProjectService.deleteProject(projectUid);

      if (res.status === 200) {
        if (refresh) {
          refresh();
        }
        navigate('/projects');
        showToast(
          <Toast
            content="The project is successfully deleted."
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      dispatchLoading({ type: LoaderActions.Hide });
      showToast(
        <Toast
          content="Requesting to delete failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    }
  };

  const onDeleteClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({
      type: ModalActions.Show,
      payload: (
        <Dialog
          title={NotificationMessages.deleteProjectTitle}
          infoText=""
          onClickCancel={onClosePrompt}
          onClickConfirm={onSubmitPrompt}
        />
      ),
    });
  };

  const onResumeClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (!status) {
      showToast(
        <Toast
          content="Project status value is missing"
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );

      return;
    }

    const isFromInitial =
      isSubmitted(status) || isConfigError(status) || isTrainingError(status) || isTrainingStopped(status);

    if (isFromInitial) {
      showToast(
        <Toast
          content="This page is redirected to select a training server."
          level={LEVEL_INFO}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );

      navigate(`/projects/${projectUid}/start`);

      return;
    }

    event.stopPropagation();
    dispatchModal({
      type: ModalActions.Show,
      payload: (
        <Dialog
          title={NotificationMessages.resumeProjectTitle}
          infoText={
            projectType === RETRAINING
              ? NotificationMessages.resumeProjectMessage(50)
              : NotificationMessages.resumeProjectMessage(100)
          }
          onClickCancel={onClosePrompt}
          onClickConfirm={onSubmitResume}
        />
      ),
    });
  };

  const onSubmitResume: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });

    try {
      if (!trials || trials.length === 0) {
        showToast(
          <Toast
            content="An unexpected error occured."
            level={LEVEL_DANGER}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );

        return;
      }

      if (!status) {
        showToast(
          <Toast
            content="Project status value is missing"
            level={LEVEL_DANGER}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );

        return;
      }

      const trialId = trials[0].trial_id;

      const isFromConverting = isConvertingError(status);

      if (isFromConverting) {
        showToastAfterResume(() => ProjectService.resumeProjectByTrialId(trialId, 'convert'));

        return;
      }

      const isFromBenchmarking = isBenchmarkingError(status);

      if (isFromBenchmarking) {
        showToastAfterResume(() => ProjectService.resumeProjectByTrialId(trialId, 'benchmark'));
      }
    } catch (err) {
      if ((err as NPProjectError)?.response?.data.detail.error_code === STATUS_MISMATCH_ERROR_CODE) {
        dispatchModal({
          type: ModalActions.Show,
          payload: (
            <Dialog
              title={NotificationMessages.projectStatusMismatchTitle}
              infoText={NotificationMessages.projectStatusMismatchMessage}
              onClickCancel={onClosePrompt}
              onClickConfirm={() => {
                if (refresh) {
                  refresh();
                }
                dispatchModal({ type: ModalActions.Hide });
              }}
            />
          ),
        });

        return;
      }
      // eslint-disable-next-line no-console
      console.log(err);
      showToast(
        <Toast
          content="Requesting to resume failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const showToastAfterResume = async (apiCall: () => Promise<AxiosResponse>) => {
    try {
      const res = await apiCall();

      if (res.status === 200) {
        showToast(
          <Toast
            content="You requested to run a project successfully"
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
        navigate('/projects');
      }
    } catch (err) {
      showToast(
        <Toast
          content="Requesting to resume failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    }
  };

  const onStartProject: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    showToast(
      <Toast
        content="This page is redirected to select a training server."
        level={LEVEL_INFO}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );

    navigate(`/projects/${projectUid}/start`);
  };

  const onStopProject: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({
      type: ModalActions.Show,
      payload: (
        <Dialog
          title={NotificationMessages.stopProjectTitle}
          infoText={NotificationMessages.stopProjectMessage}
          onClickCancel={onClosePrompt}
          onClickConfirm={onSubmitStop}
        />
      ),
    });
  };

  const onSubmitStop: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    dispatchModal({ type: ModalActions.Hide });
    event.stopPropagation();

    try {
      dispatchModal({ type: ModalActions.Hide });
      dispatchLoading({ type: LoaderActions.Show });
      const res = await ProjectService.stopProject(projectUid);

      if (res.status === 200) {
        if (refresh) {
          refresh();
        }
        showToast(
          <Toast
            content="The project is successfully stopped."
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
      }
    } catch (err) {
      if ((err as NPProjectError)?.response?.data.detail.error_code === STATUS_MISMATCH_ERROR_CODE) {
        dispatchModal({
          type: ModalActions.Show,
          payload: (
            <Dialog
              title={NotificationMessages.projectStatusMismatchTitle}
              infoText={NotificationMessages.projectStatusMismatchMessage}
              onClickCancel={onClosePrompt}
              onClickConfirm={() => {
                if (refresh) {
                  refresh();
                }
                dispatchModal({ type: ModalActions.Hide });
              }}
            />
          ),
        });

        return;
      }

      // eslint-disable-next-line no-console
      console.log(err);
      showToast(
        <Toast
          content="Requesting to stop failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  return (
    <CardContainer>
      <div className="relative h-[428px] bg-white rounded-xl shadow mb-6 px-6 py-7" data-testid="project-card">
        <div className="flex items-center justify-between mb-5">
          <div className="flex">
            <ProjectIcons projectType={projectType} />
            <TaskTag>{TasksMap[projectTask]}</TaskTag>
          </div>
          <div className="flex gap-1 items-center justify-center">
            {isTraining(status) && (
              <button
                type="button"
                className="inline-flex tooltip border-2 border-transparent hover:bg-neutralGray-500 active:bg-neutralGray-600 focus:border-2 focus:border-primary-500 rounded-full p-1"
                onClick={onStopProject}
              >
                <span className="material-symbols-outlined text-xl/5">stop_circle</span>
                <span className="tooltiptext text-xs font-sans font-semibold min-w-[70px] -ml-[35px]">Stop</span>
              </button>
            )}
            {(isInvalidStopped || isStopped || isConfigError(status)) && (
              <button
                type="button"
                className="inline-flex tooltip border-2 border-transparent hover:bg-neutralGray-500 active:bg-neutralGray-600 focus:border-2 focus:border-primary-500 p-1 rounded-full"
                onClick={onResumeClick}
              >
                <span className="material-symbols-outlined text-xl/5">replay</span>
                <span className="tooltiptext text-xs font-sans font-semibold min-w-[70px] -ml-[35px]">Try Again</span>
              </button>
            )}
            {!isTraining(status) && !isConverting(status) && !isBenchmarking(status) && (
              <button
                type="button"
                className="inline-flex tooltip border-2 border-transparent hover:bg-neutralGray-500 active:bg-neutralGray-600 focus:border-2 focus:border-primary-500 p-1 rounded-full"
                onClick={onDeleteClick}
              >
                <span className="material-symbols-outlined text-xl/5">delete</span>
                <span className="tooltiptext text-xs font-sans font-semibold min-w-[70px] -ml-[35px]">Delete</span>
              </button>
            )}
          </div>
        </div>
        <h1 className="text-neutralBlack-500 hover:text-neutralBlack-400 font-medium text-xl mb-5 whitespace-nowrap text-ellipsis overflow-hidden w-full">
          <Link to={`/projects/details/${projectUid}`}>{projectName}</Link>
        </h1>
        <p className="text-xs font-normal text-neutralBlack-300 mb-2">Created : {parseDate(createdAt)}</p>
        <p className="text-xs font-normal text-neutralBlack-300 mb-9">Updated : {parseDate(updatedAt)}</p>
        <ul className="list-disc list-inside border-b border-neutralGray-200 text-neutralBlack-500 text-sm pl-3">
          <li className="py-3">
            <span>Dataset</span>
            <span className="float-right w-[calc(100%-80px)] whitespace-nowrap text-ellipsis overflow-hidden text-right">
              {datasetName}
            </span>
          </li>
        </ul>
        <ul className="list-disc list-inside border-b border-neutralGray-200 text-neutralBlack-500 text-sm pl-3">
          <li className="py-3">
            <span>Device</span>
            <ProjectDevice projectDevice={projectDevice} />
          </li>
        </ul>
        <ul className="list-disc list-inside border-b border-neutralGray-200 text-neutralBlack-500 text-sm pl-3 mb-6">
          <li className="border-b border-neutralGray-200 py-3">
            <span>Objective</span>
            <span className="float-right">{projectType === RETRAINING ? 'N/A' : `Latency ${targetLatency}ms`}</span>
          </li>
        </ul>
        {isSubmitted(status) && (
          <button
            className="inline-flex items-center justify-center w-full border rounded text-sm disabled:pointer-events-none py-2 bg-white border-neutralBlack-500 text-neutralBlack-500 active:text-neutralBlack-300 hover:bg-neutralGray-100 disabled:text-neutralBlack-100 disabled:border-neutralBlack-100"
            type="button"
            onClick={onStartProject}
          >
            Start Project
            <span className="material-symbols-outlined text-base ml-2 pb-px">arrow_right_alt</span>
          </button>
        )}
        {isStopped && (
          <div className="tooltip flex items-center justify-center">
            <span className="text-sm text-notaRed-600 py-[9px]">Stopped</span>
            <span className="tooltiptext bottom-tooltip text-xs font-sans font-semibold -ml-[90px]">
              The project has been stopped.
            </span>
          </div>
        )}
        {isInvalidStopped && (
          <div className="tooltip flex items-center justify-center">
            <span className="text-sm text-notaRed-600 py-[9px]">Invalid Stopped</span>
            <span className="tooltiptext bottom-tooltip text-xs font-sans font-semibold -ml-[90px]">
              An unexpected error occured.
            </span>
          </div>
        )}
        {isConfigError(status) && (
          <div className="tooltip flex items-center justify-center">
            <span className="text-sm text-notaRed-600 py-[9px]">Setup error</span>
            <span className="tooltiptext bottom-tooltip text-xs font-sans font-semibold -ml-[120px]">
              Error in setting up training environment.
            </span>
          </div>
        )}
        {isTraining(status) && (
          <div className="tooltip flex items-center justify-center">
            <span className="dynamic-ellipsis text-sm text-primary-500 py-[9px]">Training</span>
            <span className="tooltiptext bottom-tooltip text-xs font-sans font-semibold -ml-[85px]">
              Model training is in progress.
            </span>
          </div>
        )}
        {isConverting(status) && (
          <div className="tooltip flex items-center justify-center">
            <span className="dynamic-ellipsis text-sm text-primary-500 py-[9px]">Converting</span>
            <span className="tooltiptext bottom-tooltip text-xs font-sans font-semibold -ml-[130px]">
              Model optimization for device is in progress.
            </span>
          </div>
        )}
        {isBenchmarking(status) && (
          <div className="tooltip flex items-center justify-center">
            <span className="dynamic-ellipsis text-sm text-primary-500 py-[9px]">Measuring latency</span>
            <span className="tooltiptext bottom-tooltip text-xs font-sans font-semibold -ml-[85px]">
              Measuring the model latency.
            </span>
          </div>
        )}
        {isCompleted(status) && (
          <div className="tooltip flex items-center justify-center">
            <span className="text-sm text-primary-500 py-[9px]">Completed</span>
            <span className="tooltiptext bottom-tooltip text-xs font-sans font-semibold -ml-[100px]">
              The project has been successful.
            </span>
          </div>
        )}
      </div>
    </CardContainer>
  );
};
