import { FormHeaderLabel } from '@netspresso/components';
import React from 'react';
import { FRAMEWORKS, UploadableModelTypes } from '../../../../../../lib';
import { InputBatch, InputChannel, InputDimension, InputShapeInfo } from './components';

type InputShapeProps = {
  modelFramework: UploadableModelTypes;
};

export const InputShape: React.FC<InputShapeProps> = ({ modelFramework }) => {
  return (
    <>
      <section className="mb-3">
        <FormHeaderLabel>Input shape *</FormHeaderLabel>
        <div className="flex flex-row space-x-6">
          <InputBatch />
          {modelFramework === FRAMEWORKS.TENSORFLOW_KERAS ? (
            <>
              <InputDimension />
              <InputChannel />
            </>
          ) : (
            <>
              <InputChannel />
              <InputDimension />
            </>
          )}
        </div>
      </section>
      <InputShapeInfo modelFramework={modelFramework} />
    </>
  );
};
