import { CompressModel } from '../../lib';

export const getInputLayerArrayFromModel = (model: CompressModel) => {
  if (!model || !model.spec || !model.spec.input_layers || !model.spec.input_layers.length) {
    return [];
  }
  const { batch, channel, dimension } = model.spec.input_layers[0];

  return [batch, channel, ...dimension];
};
