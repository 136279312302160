import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormHeaderLabel, HeaderText, RadioButton, Select } from '@netspresso/components';
import {
  LOCAL_DATASET,
  LOCAL_STORAGE,
  Partial,
  QUICK_SEARCH,
  RETRAINING,
  featureFlags,
  sortByCreatedAt,
} from '@netspresso/shared';

import { LEVEL_DANGER, Toast, useNotificationContext } from '../../../../components/Notifications';
import { DATASET_UID, MODEL_DATASET_UID, PROJECT_TASK, PROJECT_TYPE } from '../../../../constants';
import { useLoaderContext, useProjectsFormContext } from '../../../../context';
import { Dataset, TASKS, isProjectTaskType } from '../../../../lib';
import { LoaderActions } from '../../../../reducers';
import { DatasetService } from '../../../../services';
import { isImageClassification, isObjectDetection, isSemanticSegmentation, parseTask } from '../../../../utils';
import { useProjectHandler } from '../../hooks';

export const ImageDataSetting: React.FC = () => {
  const [, dispatchLoading] = useLoaderContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const { datasetUid } = useParams();

  const { resetByTask } = useProjectHandler();
  const {
    projectsForm: { setValue, watch },
  } = useProjectsFormContext();

  const [datasets, setDatasets] = useState<{ data: Partial<Dataset>[]; isFetching: boolean }>({
    data: [],
    isFetching: false,
  });
  const [selectedDataset, setSelectedDataset] = useState<Partial<Dataset>>();
  const [filteredDatasets, setFilteredDatasets] = useState<Partial<Dataset>[]>([]);

  const watched = watch();

  useEffect(() => {
    const subscribe = watch((value, { name, type }) => {
      // change filteredDataset when change project task
      if (value && datasets.data.length > 0 && name === PROJECT_TASK && value.task) {
        filterDatasetFromTask(datasets.data, value.task);
      }
    });

    return () => subscribe.unsubscribe();
  }, [watch, datasets.data]);

  const filterDatasetFromTask = (datasetData: Partial<Dataset>[], projectTask: string) => {
    const result = datasetData
      .filter((item) => item.dataset_task === projectTask)
      .map((data) => ({
        ...data,
        dataset_name: `${featureFlags(LOCAL_DATASET) && data.storage_type === LOCAL_STORAGE ? '[Local Dataset] ' : ''}${
          data.dataset_name
        }`,
      }));

    setFilteredDatasets([{ [DATASET_UID]: '', dataset_name: 'Select dataset' }, ...result]);
  };

  const fetchDatasets = async (datasetId?: string) => {
    try {
      setDatasets((prev) => ({ ...prev, isFetching: true }));

      const res = datasetId
        ? await DatasetService.getRetrainableDatasets(datasetId)
        : await DatasetService.getDatasets();

      const sortedData = sortByCreatedAt(res.data);

      setDatasets({ data: sortedData, isFetching: false });
      filterDatasetFromTask(sortedData, watched[PROJECT_TASK]);

      setValue(DATASET_UID, datasetUid || '');

      if (datasetUid) {
        const initialDataset = sortedData.find((data) => data.dataset_id === datasetUid);

        if (initialDataset && isProjectTaskType(initialDataset.dataset_task)) {
          setValue(PROJECT_TASK, initialDataset.dataset_task);
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      showToast(
        <Toast
          content="Fetching datasets failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
      setDatasets((prev) => ({ ...prev, isFetching: false }));
    }
  };

  useEffect(() => {
    if (watched[PROJECT_TYPE] === QUICK_SEARCH) {
      fetchDatasets();
    } else if (watched[PROJECT_TYPE] === RETRAINING) {
      fetchDatasets(watched[MODEL_DATASET_UID]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watched[MODEL_DATASET_UID], watched[PROJECT_TYPE]]);

  useEffect(() => {
    dispatchLoading({ type: datasets.isFetching ? LoaderActions.Show : LoaderActions.Hide });
  }, [datasets.isFetching, dispatchLoading]);

  const onProjectTaskChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    if (!isProjectTaskType(value)) {
      return;
    }

    setValue(PROJECT_TASK, value);
    resetByTask(value);
    setValue(DATASET_UID, '');
  };

  const onDatasetChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    setValue(DATASET_UID, value, { shouldDirty: true, shouldValidate: true });
    const selectedDatasetInfo = filteredDatasets.find((dataset) => dataset.dataset_id === value);

    setSelectedDataset(selectedDatasetInfo);
  };

  return (
    <>
      <HeaderText className="mb-4" type="formGroup">
        Image data
      </HeaderText>
      {watched[PROJECT_TYPE] !== RETRAINING && (
        <section className="w-1/2 mb-4">
          <FormHeaderLabel>Task *</FormHeaderLabel>
          <div className="flex flex-col">
            <RadioButton
              groupName={PROJECT_TASK}
              value={TASKS.IMAGE_CLASSIFICATION}
              label={parseTask(TASKS.IMAGE_CLASSIFICATION)}
              classes="mb-3"
              isChecked={isImageClassification(watched[PROJECT_TASK])}
              onChange={onProjectTaskChange}
            />
            <RadioButton
              groupName={PROJECT_TASK}
              value={TASKS.OBJECT_DETECTION}
              label={parseTask(TASKS.OBJECT_DETECTION)}
              classes="mb-3"
              isChecked={isObjectDetection(watched[PROJECT_TASK])}
              onChange={onProjectTaskChange}
            />
            <RadioButton
              groupName={PROJECT_TASK}
              value={TASKS.SEMANTIC_SEGMENTATION}
              label={parseTask(TASKS.SEMANTIC_SEGMENTATION)}
              classes="mb-3"
              isChecked={isSemanticSegmentation(watched[PROJECT_TASK])}
              onChange={onProjectTaskChange}
            />
          </div>
        </section>
      )}
      <section className="w-full mb-8 gap-4">
        <Select
          className="font-semibold mb-2"
          width="w-1/2"
          label="Dataset *"
          valueProp={DATASET_UID}
          labelProp="dataset_name"
          value={watched[DATASET_UID] || ''}
          options={filteredDatasets}
          onChange={onDatasetChange}
        />
        {featureFlags(LOCAL_DATASET) && selectedDataset?.storage_type === LOCAL_STORAGE && (
          <p className="inline-flex ml-4 text-sm">To use a local dataset, a personal server must be connected.</p>
        )}
      </section>
    </>
  );
};
