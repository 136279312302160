import React from 'react';
import { HYP_DETAIL, AUGMENTATION_DETAILS, INFERENCE_DETAIL } from '../../../../constants';
import { AdvancedOptionTabNames, ProjectTaskType, TASKS } from '../../../../lib';
import { isSemanticSegmentation } from '../../../../utils';

type TabComponentsProps = {
  selectedTab: AdvancedOptionTabNames;
  setSelectedTab: React.Dispatch<React.SetStateAction<AdvancedOptionTabNames>>;
  task: ProjectTaskType;
};

export const TabComponents: React.FC<TabComponentsProps> = ({
  selectedTab,
  setSelectedTab,
  task = TASKS.OBJECT_DETECTION,
}) => {
  const tabClassName = (currentTab: AdvancedOptionTabNames) => {
    return selectedTab === currentTab
      ? 'text-gray-900 group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer'
      : 'text-gray-500 hover:text-gray-700 group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer';
  };

  const underbarClassName = (currentTab: AdvancedOptionTabNames) => {
    return selectedTab === currentTab
      ? 'bg-subpoint absolute inset-x-0 bottom-0 h-0.5'
      : 'bg-transparent absolute inset-x-0 bottom-0 h-0.5';
  };

  const onClickHandler = (currentTab: AdvancedOptionTabNames) => () => {
    setSelectedTab(currentTab);
  };

  return (
    <nav className="w-full relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
      <button
        type="button"
        className={`${tabClassName(HYP_DETAIL)} ${selectedTab === HYP_DETAIL && 'rounded-l-lg'}`}
        onClick={onClickHandler(HYP_DETAIL)}
      >
        <span>Hyperparameter tuning</span>
        <span className={underbarClassName(HYP_DETAIL)} aria-hidden="true" />
      </button>
      <button
        type="button"
        className={`${tabClassName(AUGMENTATION_DETAILS)} ${selectedTab === AUGMENTATION_DETAILS && 'rounded-r-lg'}`}
        onClick={onClickHandler(AUGMENTATION_DETAILS)}
      >
        <span>Data augmentation</span>
        <span className={underbarClassName(AUGMENTATION_DETAILS)} aria-hidden="true" />
      </button>
      {isSemanticSegmentation(task) ? (
        <button
          type="button"
          className={`${tabClassName(INFERENCE_DETAIL)} ${selectedTab === INFERENCE_DETAIL && 'rounded-r-lg'}`}
          onClick={onClickHandler(INFERENCE_DETAIL)}
        >
          <span>Inference</span>
          <span className={underbarClassName(INFERENCE_DETAIL)} aria-hidden="true" />
        </button>
      ) : null}
    </nav>
  );
};
