export const PROJECT_NAME = 'project_name';
export const NOTE = 'note';
export const PROJECT_TASK = 'task';
export const PROJECT_TYPE = 'project_type';
export const DATASET_UID = 'dataset_id';
export const MODEL_DATASET_UID = 'model_dataset_id';
export const DEVICE = 'device';
export const MODEL_ID = 'model_id';
export const CONVERT_MODEL_ID = 'convert_model_id';
export const OUTPUT_MODEL_TYPE = 'output_model_type';
export const OUTPUT_MODEL_VERSION = 'output_model_version';
export const OUTPUT_DATA_TYPE = 'output_data_type';
export const OUTPUT_BATCH_SIZE = 'output_batch_size';
export const LOOKUPTABLE_UID = 'lookuptable_id';
export const BASE_IMG_SIZE = 'base_img_size';
export const IMG_SIZE_WIDTH = 'img_size_width';
export const IMG_SIZE_HEIGHT = 'img_size_height';
export const TARGET_LATENCY = 'target_latency';
export const IMG_SIZE_CHANNEL = 'img_size_channel';
export const RECOMMENDED_BASE_IMG_SIZE = 'recommended_base_img_size';
export const RECOMMENDED_TARGET_LATENCY = 'recommended_target_latency';
export const FIX_IMG_SIZE = 'fix_img_size';
export const AUGMENTATION = 'augmentation';
export const EPOCHS_PER_TRIAL = 'epochs_per_trial';
export const HYP_DETAIL = 'hyp_detail';
export const AUGMENTATION_DETAILS = 'augmentation_details';
export const INFERENCE_DETAIL = 'inference_detail';
export const PROJECT_ID = 'project_id';
export const AGENT_ID = 'agent_id';
export const IMAGE_PROCESSING_DETAIL = 'image_processing_detail';
export const BASE_ARCHITECTURE = 'base_architecture';
