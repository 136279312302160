import { apiClient } from '@netspresso/shared';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

export const useFetch = <T>(url: string): { data: T | null; loading: boolean; error: AxiosError | null } => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setData(null);
      setError(null);

      try {
        const res = await apiClient.get<T>(url);

        if (res.data) {
          setData(res.data);
        }
      } catch (err) {
        const axiosError = err as AxiosError;

        setError(axiosError);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, loading, error };
};
