import { InputDecimalNumber, InputTextLabel, Select, TextInput } from '@netspresso/components';
import { PLACEHOLDER_EVALUATION_METRIC } from '@netspresso/shared';
import React, { useState } from 'react';
import { useFormState } from 'react-hook-form';
import { METRIC_UNIT, METRIC_VALUE, MODEL_TASKS, TASK } from '../../../../../../constants';
import { useUploadModelFormContext } from '../../../../../../context';
import { isTasks, Tasks } from '../../../../../../lib';
import { isOther } from '../../../../../../utils';
import { metricsOptions } from '../../utils';

export const ModelTaskMetric: React.FC = () => {
  const { setValue, control } = useUploadModelFormContext();
  const { errors } = useFormState({ control });
  const [modelTask, setModelTask] = useState<Tasks>();
  const [metricValue, setMetricValue] = useState(0);
  const [evaluationMetric, setEvaluationMetric] = useState('');
  const [evaluationMetricName, setEvaluationNameMetric] = useState('');

  const onTaskChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    setEvaluationMetric('');
    setMetricValue(0);

    if (isTasks(value)) {
      setModelTask(value);
      setValue(TASK, value, { shouldValidate: true, shouldDirty: true });
    }
  };

  const onMetricChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    setEvaluationMetric(value);
    setValue(METRIC_UNIT, value, { shouldValidate: true, shouldDirty: true });
  };

  const onMetricNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setEvaluationNameMetric(value);
    setValue(METRIC_UNIT, value, { shouldValidate: true, shouldDirty: true });
  };

  const setMetric = (value: string) => {
    const metric = value ? parseFloat(value) : 0;

    setMetricValue(metric);
    setValue(METRIC_VALUE, metric, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <>
      <section className="mb-3">
        <div className="min-w-[210px] w-1/4">
          <Select
            className="font-medium mb-1"
            label="Task *"
            value={modelTask || ''}
            options={MODEL_TASKS}
            onChange={onTaskChange}
          />
        </div>
      </section>
      {modelTask && (
        <section className="mb-3">
          <label className="block font-semibold text-sm text-darkGray mb-1" htmlFor="name">
            Evaluation metric
          </label>
          <div className="w-1/2 flex flex-row">
            {!isOther(modelTask) && (
              <Select
                width="w-1/2"
                margin="mr-2"
                value={evaluationMetric}
                options={metricsOptions(modelTask)}
                onChange={onMetricChange}
              />
            )}
            {(isOther(modelTask) || evaluationMetric === 'Other') && (
              <TextInput
                id={METRIC_UNIT}
                placeholder={PLACEHOLDER_EVALUATION_METRIC}
                error={errors[METRIC_UNIT]?.message}
                value={evaluationMetricName}
                width="w-1/2"
                onChange={onMetricNameChange}
              />
            )}
          </div>
        </section>
      )}
      <section className="mb-8">
        <InputTextLabel htmlFor={METRIC_VALUE}>Evaluation value</InputTextLabel>
        <InputDecimalNumber
          id={METRIC_VALUE}
          width="w-1/2"
          value={`${metricValue}`}
          decimal={3}
          min={0}
          setValue={setMetric}
        />
      </section>
    </>
  );
};
