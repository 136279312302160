import { MethodInfos } from '../lib';
import { FD_SVD, FD_TK, LAMP, PR_GM, PR_L2, PR_NN, VBMF } from './compressionFields';

export const METHOD_INFOS: MethodInfos = {
  vbmf: {
    key: VBMF,
    label: 'VBMF(Variational Bayesian Matrix Factorization)',
    ratio: {
      label: 'Calibration ratio',
      description: '-1 ≤ ratio(float) ≤ 1',
      defaultValue: 0,
      min: -1,
      max: 1,
      step: 0.01,
    },
  },
  lamp: {
    key: LAMP,
    label: 'SLAMP(Structured Layer-Adaptive sparsity for the Magnitude-based Pruning)',
    ratio: {
      label: 'Pruning ratio',
      description: '0 < ratio(float) ≤ 1',
      defaultValue: 0.5,
      min: 0.01,
      max: 1,
      step: 0.01,
    },
  },
};

export const METHODS = {
  [FD_TK]: [VBMF],
  [FD_SVD]: [VBMF],
  [PR_L2]: [LAMP],
  [PR_GM]: [LAMP],
  [PR_NN]: [LAMP],
};
