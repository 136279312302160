import * as yup from 'yup';
import {
  FIELD_NAME,
  FormErrorMessages,
  JETPACK_45,
  JETPACK_451,
  JETPACK_451_DLA,
  JETPACK_45_DLA,
  JETPACK_46,
  JETPACK_46_DLA,
  JETPACK_501,
  JETPACK_441,
  JETPACK_502,
} from '@netspresso/shared';

import {
  OUTPUT_MODEL_VERSION,
  MODEL_ID,
  DESCRIPTION,
  DEVICE,
  OUTPUT_DATA_TYPE,
  MODEL_NAME,
  OUTPUT_MODEL_TYPE,
  OUTPUT_BATCH_SIZE,
  CONVERT_MODEL_ID,
} from '../constants';
import { DEVICES, Devices, FRAMEWORKS, OutputDataTypes, OutputModelTypes, OUTPUT_DATA_TYPES } from '../lib';

export const ConvertModelScheme = yup.object({
  [MODEL_ID]: yup.string().required(FormErrorMessages.isRequired).min(2, FormErrorMessages.isNotMin(2)),
  [CONVERT_MODEL_ID]: yup.string().required(FormErrorMessages.isRequired),
  [MODEL_NAME]: yup
    .string()
    .required(FormErrorMessages.isRequired)
    .min(2, FormErrorMessages.isNotMin(2))
    .max(20, FormErrorMessages.isNotMax(20))
    .matches(FIELD_NAME, FormErrorMessages.isWrongCharacters),
  [DESCRIPTION]: yup.string().max(100, FormErrorMessages.isNotMax(100)),
  [DEVICE]: yup.string<Devices>().required(),
  [OUTPUT_MODEL_TYPE]: yup.string<OutputModelTypes>(),
  [OUTPUT_MODEL_VERSION]: yup
    .string()
    .oneOf([
      JETPACK_441,
      JETPACK_45,
      JETPACK_45_DLA,
      JETPACK_451,
      JETPACK_451_DLA,
      JETPACK_46,
      JETPACK_46_DLA,
      JETPACK_501,
      JETPACK_502,
    ]),
  [OUTPUT_DATA_TYPE]: yup.string<OutputDataTypes>(),
  [OUTPUT_BATCH_SIZE]: yup
    .number()
    .typeError('Batch size must be integer')
    .required(FormErrorMessages.isRequired)
    .integer('Batch size must be integer')
    .min(1, FormErrorMessages.isNotMin(1))
    .max(32, FormErrorMessages.isNotMax(32)),
});

export type ConvertModelType = yup.InferType<typeof ConvertModelScheme>;
export const ConvertModelsDefaultValues: ConvertModelType = {
  [MODEL_ID]: '',
  [CONVERT_MODEL_ID]: '',
  [MODEL_NAME]: '',
  [DESCRIPTION]: '',
  [DEVICE]: DEVICES.JETSON,
  [OUTPUT_MODEL_TYPE]: FRAMEWORKS.TENSORRT,
  [OUTPUT_MODEL_VERSION]: JETPACK_46,
  [OUTPUT_DATA_TYPE]: OUTPUT_DATA_TYPES.FP16,
  [OUTPUT_BATCH_SIZE]: 1,
};
