import { MainAction, ResponsiveContainer } from '@netspresso/components';
import { CONTACT_LINK, sortByCreatedAt } from '@netspresso/shared';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { REFRESH_INTERVAL } from '../../constants';
import { useDatasetsFormContext, useLoaderContext } from '../../context';
import { Dataset } from '../../lib';
import { LoaderActions } from '../../reducers';
import { DatasetService } from '../../services';
import { useGTM } from '../../utils';
import { DatasetCard } from './components';

export const Datasets: React.FC = () => {
  const [, dispatchLoading] = useLoaderContext();
  const { reset } = useDatasetsFormContext();
  const { setPageToDataLayer } = useGTM();
  const navigate = useNavigate();

  const [datasetData, setDatasetData] = useState<{ data: Dataset[]; isFetching: boolean }>({
    data: [],
    isFetching: false,
  });

  const fetchDatasets = async () => {
    try {
      setDatasetData({ data: datasetData.data, isFetching: true });

      const res = await DatasetService.getDatasets();
      const samples = sortByCreatedAt<Dataset>(res.data.filter((item) => item.user_uid === 'sample_dataset'));
      const userData = sortByCreatedAt<Dataset>(res.data.filter((item) => item.user_uid !== 'sample_dataset'));

      setDatasetData({ data: [...userData, ...samples], isFetching: false });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      const errors = err as AxiosError;

      if (errors.response?.status === 403 || errors.response?.status === 500) {
        navigate('/forbidden');
      }
      setDatasetData({ data: datasetData.data, isFetching: false });
    }
  };

  useEffect(() => {
    fetchDatasets();
    const refreshDataset = setInterval(async () => {
      fetchDatasets();
    }, REFRESH_INTERVAL);

    return () => {
      clearInterval(refreshDataset);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageToDataLayer('Datasets');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatchLoading({ type: datasetData.isFetching ? LoaderActions.Show : LoaderActions.Hide });
  }, [datasetData.isFetching, dispatchLoading]);

  const uploadHandler: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    reset();
    navigate(`upload`);
  };

  const renderDataset = (item: Dataset) => {
    return (
      <DatasetCard
        key={item.dataset_id}
        datasetUid={item.dataset_id}
        datasetName={item.dataset_name}
        datasetTask={item.dataset_task}
        createdAt={item.created_time}
        classNames={item.class_names}
        numImages={item.num_images}
        isSample={item.user_uid === 'sample_dataset'}
      />
    );
  };

  return (
    <>
      <MainAction routeName="datasets" totalNum={100} dataNum={datasetData.data.length} onClick={uploadHandler} />
      <ResponsiveContainer>
        {datasetData.data.length > 0 ? datasetData.data.map(renderDataset) : null}
      </ResponsiveContainer>
      <section className="flex justify-center items-center mb-1">
        <span className="material-icons mr-1 text-warning">error_outline</span>
        <p className="text-xs text-darkGray">
          If you have any difficulties in preparing the dataset, please contact{' '}
          <strong>
            <a href={CONTACT_LINK}>netspresso@nota.ai</a>
          </strong>
        </p>
      </section>
    </>
  );
};
