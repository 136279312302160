import AdvancedSearchIcon from '@netspresso/components/assets/icons/adv_search.svg';
import React from 'react';

export const AdvancedSearch: React.FC = () => {
  return (
    <>
      <div className="w-32 h-32 pt-2 pb-1 rounded-[64px] border-2 border-secondary flex flex-col items-center">
        <AdvancedSearchIcon />
        <h1 className="text-secondary text-center text-sm font-medium leading-4 mt-2">
          Advanced
          <br />
          Search
        </h1>
      </div>
      <ul className="list-disc text-defaultGray mt-6 mb-5 text-m">
        <li>Excellent performance</li>
        <li>Long search (1~2weeks)</li>
        <li>Get multiple models of choice</li>
      </ul>
      <p className="text-xs text-defaultGray mb-6">
        Search and train multiple models to find the most optimized model for your objective based on Neural
        Architecture Search (NAS).
      </p>
    </>
  );
};
