import Cookies from 'js-cookie';
import { UserType } from '../../context/hooks';

export const TokenService = (() => {
  const getLocalAccessToken = () => {
    return Cookies.get('access_token');
  };

  const getLocalRefreshToken = () => {
    return Cookies.get('refresh_token');
  };

  const updateLocalAccessToken = (token: string) => {
    Cookies.set('access_token', token, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  };

  const updateLocalRefreshToken = (token: string) => {
    Cookies.set('refresh_token', token, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  };

  const removeLocalTokens = () => {
    Cookies.remove('access_token', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
    Cookies.remove('refresh_token', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  };

  const getUser = () => {
    const user = localStorage.getItem('currentUser');

    if (user) {
      return JSON.parse(user);
    }

    return '';
  };

  const setUser = (user: UserType) => {
    localStorage.setItem('currentUser', JSON.stringify(user));
  };

  const removeUser = () => {
    localStorage.removeItem('currentUser');
  };

  return {
    getLocalAccessToken,
    getLocalRefreshToken,
    updateLocalAccessToken,
    updateLocalRefreshToken,
    removeLocalTokens,
    getUser,
    setUser,
    removeUser,
  };
})();
