/* eslint-disable camelcase */
import { TableData } from '@netspresso/components';
import {
  apiClient,
  API_MODEL_V1,
  COMBINED_COMPRESSION,
  featureFlags,
  formatizeLatencyToMS,
  isEmpty,
  isRecord,
  parseDate,
  TasksMetricMap,
} from '@netspresso/shared';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LEVEL_DANGER, LEVEL_SUCCESS, Toast, useNotificationContext } from '../../../../components/Notifications';
import { useLoaderContext, useModalContext } from '../../../../context';
import {
  Devices,
  Frameworks,
  ModelFormats,
  ModelStatus,
  OriginFromType,
  PerformanceDataType,
  TasksWithMetric,
} from '../../../../lib';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { getMaxMetric, parseDevices } from '../../../../utils';
import { ActionButtons } from './components';

export type ChildModelProps = {
  datasetId?: string | null;
  modelId: string;
  modelName: string;
  createdAt: string;
  originFrom: OriginFromType;
  latency: number;
  modelStatusData: ModelStatus;
  performanceData: Record<string, PerformanceDataType>;
  targetDevice: Devices;
  task: TasksWithMetric;
  modelFormat: ModelFormats;
  framework: Frameworks;
  projectId?: string | null;
  onDelete: (onSubmitPrompt: React.MouseEventHandler<HTMLButtonElement>) => React.MouseEventHandler<HTMLButtonElement>;
};

export const ChildModel: React.FC<ChildModelProps> = ({
  datasetId,
  modelId,
  modelName,
  createdAt,
  originFrom,
  latency,
  modelStatusData,
  performanceData,
  targetDevice,
  task,
  modelFormat,
  framework,
  onDelete,
  projectId = '',
}) => {
  const navigate = useNavigate();
  const [, dispatchModal] = useModalContext();
  const [, dispatchLoading] = useLoaderContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();

  const onClickDetails: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (modelStatusData.is_compressed) {
      if (featureFlags(COMBINED_COMPRESSION)) {
        navigate(`/models/compress/details/${modelId}`);
      } else {
        window.location.href = `${process.env.REACT_APP_COMPRESSOR_URL}/models/${modelId}`;
      }
    } else {
      navigate(`/models/details/${modelId}${projectId ? `/project/${projectId}` : ''}`);
    }
  };

  const onClickRetrain: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    navigate(`/projects/retraining/dataset/${datasetId}/model/${modelId}`);
  };

  const onSubmitPrompt: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();

    try {
      dispatchModal({ type: ModalActions.Hide });
      dispatchLoading({ type: LoaderActions.Show });
      const res = await apiClient.delete(`${API_MODEL_V1}/${modelId}`);

      if (res.status === 200) {
        showToast(
          <Toast
            content="The model is successfully deleted."
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
        navigate('/models');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      showToast(
        <Toast
          content="Requesting to delete failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const isMetricParsible = () => {
    return isEmpty(performanceData) || (datasetId && !performanceData[datasetId][TasksMetricMap[task]]);
  };

  const parseMetricValue = (performance: Record<string, PerformanceDataType>, datasetUid: string): string => {
    return getMaxMetric(performance[datasetUid][TasksMetricMap[task]]);
  };

  const parseCustomMetricValue = (performance: Record<string, PerformanceDataType>): string => {
    if (Object.prototype.hasOwnProperty.call(performance, 'custom') && isRecord<string>(performance.custom)) {
      return Object.values(performance.custom)[0];
    }

    return '';
  };

  const renderMetrics = (statusData: ModelStatus, isNotParsible: boolean) => {
    const { is_compressed, is_retrainable } = statusData;

    if (is_compressed && is_retrainable) {
      return (
        <>
          Retraining
          <br />
          needed
        </>
      );
    }

    if (isNotParsible) {
      return (
        <>
          <strong className="text-defaultGray text-xxs tracking-tight">
            {originFrom === 'custom' ? Object.keys(performanceData.custom)[0] : TasksMetricMap[task]}
          </strong>
          <br />
          {datasetId ? parseMetricValue(performanceData, datasetId) : parseCustomMetricValue(performanceData)}
        </>
      );
    }

    return <></>;
  };

  return (
    <tr className="border-b border-lineGray h-20">
      <td className="pl-7 pr-2">
        <span className="material-icons text-defaultGray">
          {modelStatusData.is_compressed ? 'compress' : 'autorenew'}
        </span>
      </td>
      <td className="px-2">
        <h6 className="font-title text-m font-bold text-darkGray tracking-tight pt-4">{modelName}</h6>
        <span className="inline-block text-xs text-defaultGray font-normal pb-4">Created: {parseDate(createdAt)}</span>
      </td>
      <TableData />
      <TableData>{parseDevices(targetDevice)}</TableData>
      <TableData dataTestId="child-model-metrics">{renderMetrics(modelStatusData, !isMetricParsible())}</TableData>
      <TableData dataTestId="child-model-latency">{formatizeLatencyToMS(latency)}</TableData>
      <ActionButtons
        modelId={modelId}
        framework={framework}
        modelFormat={modelFormat}
        isNotRetrainable={!modelStatusData.is_retrainable}
        isNotDownloadable={!modelStatusData.is_downloadable}
        isPackagable={modelStatusData.is_packageable}
        onClickRetrain={onClickRetrain}
        onClickDelete={onDelete(onSubmitPrompt)}
        onClickDetails={onClickDetails}
      />
    </tr>
  );
};
