import {
  LR0,
  LRF,
  MOMENTUM,
  SCHD_POWER,
  SEED,
  WARMUP_BIAS_LR,
  WARMUP_EPOCHS,
  WARMUP_MOMENTUM,
  WARMUP_STEPS,
  WEIGHT_DECAY,
} from '@netspresso/shared';
import { HYP_DETAIL } from '../../../../../../constants';
import { parseHypPlaceholderCls, parseHypTooltip } from '../../../../../../utils';
import { HyperParameterInput } from '../../../HyperParameterInput';

export const ClassificationParams = () => {
  return (
    <>
      <section className="flex flex-row">
        <HyperParameterInput
          paramName={LR0}
          groupName={HYP_DETAIL}
          className="pr-9"
          tooltipMsg={parseHypTooltip(LR0)}
          placeholder={parseHypPlaceholderCls(LR0)}
        />
        <HyperParameterInput
          paramName={LRF}
          groupName={HYP_DETAIL}
          className="px-9"
          tooltipMsg={parseHypTooltip(LRF)}
          placeholder={parseHypPlaceholderCls(LRF)}
        />
        <HyperParameterInput
          paramName={SEED}
          groupName={HYP_DETAIL}
          className="pl-9"
          tooltipMsg={parseHypTooltip(SEED)}
          placeholder={parseHypPlaceholderCls(SEED)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={MOMENTUM}
          groupName={HYP_DETAIL}
          className="pr-9"
          tooltipMsg={parseHypTooltip(MOMENTUM)}
          placeholder={parseHypPlaceholderCls(MOMENTUM)}
        />
        <HyperParameterInput
          paramName={WEIGHT_DECAY}
          groupName={HYP_DETAIL}
          className="px-9"
          tooltipMsg={parseHypTooltip(WEIGHT_DECAY)}
          placeholder={parseHypPlaceholderCls(WEIGHT_DECAY)}
        />
        <HyperParameterInput
          paramName={WARMUP_STEPS}
          groupName={HYP_DETAIL}
          className="pl-9"
          tooltipMsg={parseHypTooltip(WARMUP_STEPS)}
          placeholder={parseHypPlaceholderCls(WARMUP_STEPS)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={WARMUP_EPOCHS}
          groupName={HYP_DETAIL}
          className="pr-9"
          tooltipMsg={parseHypTooltip(WARMUP_EPOCHS)}
          placeholder={parseHypPlaceholderCls(WARMUP_EPOCHS)}
        />
        <HyperParameterInput
          paramName={WARMUP_MOMENTUM}
          groupName={HYP_DETAIL}
          className="px-9"
          tooltipMsg={parseHypTooltip(WARMUP_MOMENTUM)}
          placeholder={parseHypPlaceholderCls(WARMUP_MOMENTUM)}
        />
        <HyperParameterInput
          paramName={WARMUP_BIAS_LR}
          groupName={HYP_DETAIL}
          className="pl-9"
          tooltipMsg={parseHypTooltip(WARMUP_BIAS_LR)}
          placeholder={parseHypPlaceholderCls(WARMUP_BIAS_LR)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={SCHD_POWER}
          groupName={HYP_DETAIL}
          className="pr-9"
          tooltipMsg={parseHypTooltip(SCHD_POWER)}
          placeholder={parseHypPlaceholderCls(SCHD_POWER)}
        />
      </section>
    </>
  );
};
