import { sortByCreatedAt } from '@netspresso/shared';
import { Project } from '../lib';

export type ProjectsAction = {
  type: 'ready' | 'remove' | 'add';
  payload: Project[];
};

export type ProjectsState = { projects: Project[] };

export const projectsReducer = (state: ProjectsState, action: ProjectsAction): ProjectsState => {
  switch (action.type) {
    case 'ready':
      return { projects: sortByCreatedAt<Project>(action.payload ? action.payload : []) };
    case 'remove':
      // eslint-disable-next-line no-case-declarations
      const { payload } = action;

      return { projects: state.projects.filter((item, index) => item.project_id !== payload[index].project_id) };
    case 'add':
      return { projects: [...JSON.parse(JSON.stringify(state.projects)), { ...action.payload }] };
    default:
      throw new Error(`Unhandled action :${action.type}`);
  }
};
