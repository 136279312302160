import { useMemo } from 'react';
import { OriginFromType } from '../../lib';

interface OriginFromTagProps {
  from: OriginFromType;
}
export const OriginFromTag = ({ from }: OriginFromTagProps) => {
  const { labelClassName, labelText } = useMemo(
    () => ({
      labelClassName: from === 'npms' ? 'bg-notaNavy-100' : 'bg-gray-300',
      labelText: from === 'npms' ? 'NetsPresso' : 'Custom',
    }),
    [from]
  );

  return (
    <span
      className={`inline-block mt-2 mb-1 px-2.5 py-1 rounded-full font-medium text-xxs text-notaNavy-800 ${labelClassName}`}
    >
      {labelText}
    </span>
  );
};
