import React from 'react';

export const Loading: React.FC<{ show: boolean }> = ({ show = false }) => {
  return (
    <section
      className={`${
        show ? 'block' : 'hidden'
      } top-0 left-0 fixed flex items-center justify-center h-screen w-full bg-white/70 z-30`}
    >
      <div className="flex flex-col items-center justify-center bg-white shadow-lg rounded-xl w-40 h-32">
        <div className="loader mt-2 mb-3">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        <p className="text-secondary font-title font-bold pl-2">loading</p>
      </div>
    </section>
  );
};
