/* eslint-disable camelcase */
import {
  DatasetFormatType,
  NumImages,
  NumInstanceType,
  ProjectTaskType,
  StorageLocationType,
} from '@netspresso/app/src/lib';
import yaml from 'yaml';

type ParsedYaml = {
  names: string[];
  task: ProjectTaskType;
  dataset_path: string;
  storage_type: StorageLocationType;
  system_uuid: string;
} & Record<'train' | 'val' | 'test', { num_images: number; obj_stat: Record<string, number> }>;

export const parseYamlToNumImages = (value: string): NumImages => {
  const parsed: ParsedYaml = yaml.parse(value);

  const trainNum = parsed.train ? parsed.train.num_images : 0;
  const valNum = parsed.val ? parsed.val.num_images : 0;
  const testNum = parsed.test ? parsed.test.num_images : 0;

  return { train: trainNum, val: valNum, test: testNum };
};

export const parseYamlToNumInstances = (value: string): NumInstanceType[] => {
  const parsed: ParsedYaml = yaml.parse(value);
  const classNames = parsed.names;

  const result = classNames.map((item) => {
    const trainNum = parsed.train ? parsed.train.obj_stat[item] : 0;
    const validNum = parsed.val ? parsed.val.obj_stat[item] : 0;
    const testNum = parsed.test ? parsed.test.obj_stat[item] : 0;

    return {
      class_name: item,
      val: validNum,
      test: testNum,
      train: trainNum,
      total: validNum + testNum + trainNum,
    };
  });

  return result;
};

export const parseYamlToClassnames = (value: string): string[] => {
  const parsed: ParsedYaml = yaml.parse(value);

  return parsed.names;
};

export const parseYamlToTask = (value: string): ProjectTaskType => {
  const parsed: ParsedYaml = yaml.parse(value);

  return parsed.task;
};

export const parseYamlToFormat = (value: string): DatasetFormatType => {
  const parsed = yaml.parse(value);

  return parsed.format;
};

export const parseYamlForLocalDataset = (
  value: string
): Pick<ParsedYaml, 'dataset_path' | 'storage_type' | 'system_uuid'> => {
  const { dataset_path, storage_type, system_uuid }: ParsedYaml = yaml.parse(value);

  return {
    dataset_path,
    storage_type,
    system_uuid,
  };
};
