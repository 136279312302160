import { InputNumber, InputTextLabel } from '@netspresso/components';
import React, { useState } from 'react';
import { useFormState } from 'react-hook-form';
import { BATCH, INPUT_LAYERS } from '../../../../../../../../constants';
import { useUploadModelFormContext } from '../../../../../../../../context';

export const InputBatch: React.FC = () => {
  const { setValue, control } = useUploadModelFormContext();
  const { errors } = useFormState({ control });

  const [inputShapeBatch, setInputShapeBatch] = useState(1);

  const onInputShapeBatchChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = isNaN(parseInt(event.target.value, 10)) ? 0 : parseInt(event.target.value, 10);

    setInputShapeBatch(value);
    setValue(`${INPUT_LAYERS}.${BATCH}`, value, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <div className="w-1/12">
      <InputTextLabel htmlFor={BATCH}>Batch</InputTextLabel>
      <InputNumber
        id={BATCH}
        error={errors[INPUT_LAYERS] && errors[INPUT_LAYERS][BATCH]?.message}
        value={inputShapeBatch}
        min={1}
        max={3}
        placeholder="1 or 3"
        width="w-full"
        onChange={onInputShapeBatchChange}
      />
    </div>
  );
};
