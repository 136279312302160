import React, { createContext, useContext, useReducer } from 'react';
import { modalReducer, ModalState, ModalAction } from '../reducers';

type ModalContextType = [ModalState, React.Dispatch<ModalAction>];

export const ModalContext = createContext<ModalContextType | null>(null);

const initialState = { showModal: false, children: null };

export const ModalContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);

  return <ModalContext.Provider value={[state, dispatch]}>{children}</ModalContext.Provider>;
};

export const useModalContext = (): ModalContextType => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModalContext must be used within a ModalContextProvider');
  }

  return context;
};
