/* eslint-disable camelcase */
import { apiClient, API_PROJECT_V1 } from '@netspresso/shared';
import { Project } from '../../lib';
import { EditProjectType } from '../../schemes/EditProjectScheme';

export type TestImage = {
  img_name: string;
  img_data: string;
};

export const ProjectService = (() => {
  const getProjects = () => {
    return apiClient.get<Project[]>(API_PROJECT_V1);
  };

  const getProjectById = (projectUid: string) => {
    return apiClient.get<Project>(`${API_PROJECT_V1}/${projectUid}`);
  };

  const getTestImages = (projectUid: string, trialId: string) => {
    return apiClient.get<TestImage[]>(`${API_PROJECT_V1}/${projectUid}/trial/${trialId}/test_images`);
  };

  const stopProject = (projectUid: string) => {
    return apiClient.post(`${API_PROJECT_V1}/${projectUid}/stop`, {
      status: 902,
    });
  };

  const resumeProjectByTrialId = (trialId: string, from: 'convert' | 'benchmark') => {
    return apiClient.post(`${API_PROJECT_V1}/trials/${trialId}/${from}`);
  };

  const editProject = (projectUid: string, data: EditProjectType) => {
    return apiClient.patch(`${API_PROJECT_V1}/${projectUid}`, data);
  };

  const deleteProject = (projectUid: string) => {
    return apiClient.delete(`${API_PROJECT_V1}/${projectUid}`);
  };

  return {
    getProjects,
    getProjectById,
    getTestImages,
    stopProject,
    resumeProjectByTrialId,
    editProject,
    deleteProject,
  };
})();
