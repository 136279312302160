import React, { useEffect, useRef, useState } from 'react';

type IconButtonProps = {
  isDisabled?: boolean;
  width?: string;
  height?: string;
  tooltipMsg?: string;
  dataTestId?: string;
  outline?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: VoidFunction;
};

export const IconButton: React.FC<IconButtonProps & React.PropsWithChildren> = ({
  children,
  tooltipMsg = '',
  width = 'w-[30px]',
  height = 'h-[30px]',
  dataTestId = 'icon-btn',
  outline = false,
  isDisabled = false,
  ...restProps
}) => {
  const [tooltipWidth, setTooltipWidth] = useState(0);
  const ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (ref.current) {
      const tooltipTextWidth = ref.current.offsetWidth;
      setTooltipWidth(tooltipTextWidth / 2);
    }
  }, []);

  return (
    <button
      type="button"
      data-testid={dataTestId}
      aria-label={tooltipMsg}
      className={`transition ease-in-out duration-200 flex items-center justify-center rounded border disabled:border-disabledGray ${
        outline
          ? 'border-notaNavy-900 hover:bg-notaNavy-900 text-notaNavy-900 disabled:text-disabledGray'
          : 'text-white bg-notaNavy-900 hover:bg-notaNavy-700 disabled:bg-disabledGray'
      } ${isDisabled ? 'pointer-events-none' : ''} ${width} ${height} ${tooltipMsg ? 'tooltip' : ''}`}
      disabled={isDisabled}
      {...restProps}
    >
      {typeof children === 'string' ? (
        <span className={`material-icons ${outline ? 'hover:text-white' : ''}`}>{children}</span>
      ) : (
        children
      )}
      {!!tooltipMsg && (
        <span
          ref={ref}
          className="tooltiptext text-xs font-sans font-semibold"
          style={{ marginLeft: `-${tooltipWidth}px`, bottom: '36px' }}
        >
          {tooltipMsg}
        </span>
      )}
    </button>
  );
};
