import React, { createContext, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import { SaveModelScheme, SaveModelsDefaultValues, SaveModelType } from '../schemes';

export const SaveModelFormContext = createContext<UseFormReturn<SaveModelType> | null>(null);

export const SaveModelFormContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const convertModelForm = useForm({
    defaultValues: SaveModelsDefaultValues,
    mode: 'onBlur',
    resolver: yupResolver(SaveModelScheme),
  });

  return <SaveModelFormContext.Provider value={convertModelForm}>{children}</SaveModelFormContext.Provider>;
};

export const useSaveModelFormContext = (): UseFormReturn<SaveModelType> => {
  const context = useContext(SaveModelFormContext);

  if (!context) {
    throw new Error('useSaveModelFormContext must be used within a SaveModelFormContextProvider');
  }

  return context;
};
