import {
  CROP_SIZE_H,
  CROP_SIZE_W,
  FLIPLR,
  MAX_SCALE,
  MIN_SCALE,
  RESIZE_RATIO0,
  RESIZE_RATIOF,
  SCHD_POWER,
  SEED,
  WARMUP_STEPS,
  WEIGHT_DECAY,
} from '@netspresso/shared';

export const SEGMENTATION_HYPERPARAMETERS = [
  SEED,
  WEIGHT_DECAY,
  WARMUP_STEPS,
  RESIZE_RATIO0,
  RESIZE_RATIOF,
  FLIPLR,
  MAX_SCALE,
  MIN_SCALE,
  CROP_SIZE_H,
  CROP_SIZE_W,
  SCHD_POWER,
] as const;

export type SegmentationParameter = (typeof SEGMENTATION_HYPERPARAMETERS)[number];

export const SegmentationPlaceholdersMap: Record<SegmentationParameter, string> = {
  [SEED]: '1 <= seed < inf',
  [WEIGHT_DECAY]: '0 <= weight_decay <= 0.01',
  [WARMUP_STEPS]: '100 <= warmup_steps <= 2000',
  [RESIZE_RATIO0]: '0.5 <= resize_ratio0 <= resize_ratiof',
  [RESIZE_RATIOF]: 'resize_ratio0 <= resize_ratiof < inf',
  [FLIPLR]: '0 <= fliplr <= 1',
  [MAX_SCALE]: '',
  [MIN_SCALE]: '',
  [CROP_SIZE_H]: '',
  [CROP_SIZE_W]: '',
  [SCHD_POWER]: '',
};

export type OmittedSegmentationParameter =
  | 'seed'
  | 'weight_decay'
  | 'warmup_steps'
  | 'resize_ratio0'
  | 'resize_ratiof'
  | 'fliplr';
