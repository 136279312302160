import React from 'react';

type InputTextLabelProps = {
  htmlFor: string;
};

export const InputTextLabel: React.FC<InputTextLabelProps & React.PropsWithChildren> = ({ htmlFor, children }) => {
  return (
    <label className="block font-semibold text-sm text-darkGray mb-1" htmlFor={htmlFor}>
      {children}
    </label>
  );
};
