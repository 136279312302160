import { InfoContainer } from '@netspresso/components';
import React from 'react';
import { FRAMEWORKS, UploadableModelTypes } from '../../../../../../../../lib';

type InputShapeInfoProps = {
  modelFramework: UploadableModelTypes;
};

export const InputShapeInfo: React.FC<InputShapeInfoProps> = ({ modelFramework }) => {
  return (
    <InfoContainer className="mb-6">
      <div className="text-m text-secondary">
        <h2 className="mt-0.5">For input shape, use the same values that you used to train the model.</h2>
        <ul className="w-full list-disc ml-4 mb-4">
          <li className="text-xs text-secondary leading-[18px]">
            Only channels {modelFramework === FRAMEWORKS.TENSORFLOW_KERAS ? 'last' : 'first'} format is supported
            (batch, channel, dimension).
          </li>
          <li className="text-xs text-secondary leading-[18px]">Channel: 3 for RGB images, 1 for gray images.</li>
          <li className="text-xs text-secondary leading-[18px]">
            For example, width=1024, height=768 RGB images and the batch size is 1.
          </li>
        </ul>
        {modelFramework === FRAMEWORKS.TENSORFLOW_KERAS ? (
          <img src="/images/batch_dimension_channel.png" alt="batch_dimension_channel" />
        ) : (
          <img src="/images/batch_channel_dimension.png" alt="batch_channel_dimension" />
        )}
        <p className="mt-4">Currently, only single input models are supported.</p>
      </div>
    </InfoContainer>
  );
};
