/* eslint-disable camelcase */
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useFormState } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { InfoContainer, InfoText, Panel, PanelHeader, Select, SubmitButton } from '@netspresso/components';
import {
  API_CONVERT_V1,
  API_MODEL_V1,
  CONTACT_LINK,
  JSONValue,
  NotificationMessages,
  Partial,
  isEmpty,
} from '@netspresso/shared';

import {
  LEVEL_DANGER,
  LEVEL_SUCCESS,
  LEVEL_WARNING,
  Toast,
  useNotificationContext,
} from '../../components/Notifications';
import { CONVERT_MODEL_ID, MODEL_ID } from '../../constants';
import { useConvertModelFormContext, useLoaderContext } from '../../context';
import { Model, Tasks } from '../../lib';
import { LoaderActions } from '../../reducers';
import { ConvertModelType } from '../../schemes';
import { ConvertService } from '../../services';
import { parseJsonWithNaN, useFetch, useGTM } from '../../utils';
import { ModelSetting, TargetDevices } from '../Models/components';

export const ConvertingModel: React.FC = () => {
  const navigate = useNavigate();
  const [, dispatchLoading] = useLoaderContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const { setPageToDataLayer } = useGTM();
  const { modelUid } = useParams();

  const { handleSubmit, setValue, watch, control, reset } = useConvertModelFormContext();
  const { isDirty, errors, isValid } = useFormState({ control });
  const watched = watch();

  const [filteredModels, setFilteredModels] = useState<Partial<Model>[]>([]);
  const [modelTask, setModelTask] = useState<Tasks | undefined>();

  const {
    data: availabilityData,
    loading,
    error,
  } = useFetch<{ availability?: boolean }>(`${API_CONVERT_V1}/availability`);

  useEffect(() => {
    if (availabilityData && !availabilityData.availability) {
      showToast(
        <Toast
          content={NotificationMessages.convertingIsNotAvailable}
          level={LEVEL_WARNING}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityData]);

  const { data: modelData, loading: modelLoading } = useFetch<JSONValue>(API_MODEL_V1);

  const isDisabled = !isDirty || !isEmpty(errors) || !availabilityData?.availability || !isValid;

  if (error) {
    navigate('/internal_error', { replace: true });
  }

  useEffect(() => {
    const subscribe = watch((value, { name, type }) => {
      if (value && filteredModels.length > 0 && name === MODEL_ID) {
        const selectedModel = filteredModels.find((model) => model.model_id === value.model_id);

        if (selectedModel && selectedModel.children) {
          const convertibleChild = selectedModel.children.find((child) => child.model_status_data.is_convertible);

          setValue(CONVERT_MODEL_ID, convertibleChild?.model_id || '');
        }
      }
    });

    return () => subscribe.unsubscribe();
  }, [watch, filteredModels, setValue]);

  useEffect(() => {
    setPageToDataLayer('Converting Model');

    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatchLoading({ type: loading || modelLoading ? LoaderActions.Show : LoaderActions.Hide });
  }, [modelLoading, loading, dispatchLoading]);

  useEffect(() => {
    if (modelUid && filteredModels.length > 0) {
      if (modelUid) {
        setValue(MODEL_ID, modelUid || '', { shouldValidate: true, shouldDirty: true });
      }
    }
  }, [modelUid, filteredModels, setValue]);

  useEffect(() => {
    if (modelData) {
      const convertibleModels = parseJsonWithNaN<Model[]>(modelData).filter(
        (data) => data.model_status_data.is_convertible && data.model_status_data.is_visible
      );

      setFilteredModels([{ model_id: '', display_name: 'Select model' }, ...convertibleModels]);
    }
  }, [modelData, setValue]);

  const getModelTask = (modelId: string) => filteredModels.find((item) => item.model_id === modelId)?.task;

  const onSubmit: SubmitHandler<ConvertModelType> = async (data) => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      const { convert_model_id, model_id, ...restData } = data;

      const res = await ConvertService.convert({ ...restData, model_id: convert_model_id });

      if (res.status === 200) {
        dispatchLoading({ type: LoaderActions.Hide });
        showToast(
          <Toast
            content="You requested to convert model successfully"
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
        navigate(`/models`);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      showToast(
        <Toast
          content="Model converting request failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const onError: SubmitErrorHandler<ConvertModelType> = (convertingErrors) => {
    // eslint-disable-next-line no-console
    console.log(convertingErrors);
    showToast(
      <Toast
        content="Please review form fields."
        level={LEVEL_WARNING}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );
  };

  const onSelectChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    setModelTask(getModelTask(value));
    setValue(MODEL_ID, value, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <Panel>
      <PanelHeader className="pl-6 mb-4">Converting</PanelHeader>
      <section className="bg-white rounded-lg shadow mb-6 p-6">
        {!availabilityData?.availability && (
          <InfoContainer className="mb-2">
            <InfoText color="secondary" leading="leading-5">
              Converting is in progress. Converting for the NVIDIA Jetson family (TensorRT) may take up to 1 hour.
              <br />
              If the process takes longer than 1 hour, please contact <a href={CONTACT_LINK}>netspresso@nota.ai</a>.
            </InfoText>
          </InfoContainer>
        )}
        <form className="w-full" onSubmit={handleSubmit(onSubmit, onError)}>
          <ModelSetting />
          <h2 className="text-secondary font-semibold mb-4">Select model</h2>
          <section className="w-1/2 mb-8">
            <Select
              className="font-semibold mb-2"
              label="Base model *"
              valueProp={MODEL_ID}
              labelProp="display_name"
              value={watched[MODEL_ID] || ''}
              options={filteredModels}
              onChange={onSelectChange}
            />
          </section>
          <TargetDevices task={modelTask} />
          <div className="flex justify-between">
            <a className="text-sm flex items-center text-secondary hover:text-primary" href="/models">
              <span className="material-icons">navigate_before</span>
              <span>Back to Models page</span>
            </a>
            <SubmitButton disabled={isDisabled}>Start converting</SubmitButton>
          </div>
        </form>
      </section>
    </Panel>
  );
};
