import { OutlineButton, SubmitButton } from '@netspresso/components';
import React, { useState } from 'react';
import { CurrentSteps, CURRENT_STEPS } from '../../../../lib';

interface ITrainingFormButtons {
  currentStep: CurrentSteps;
  onClickBack: React.MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  projectUid?: string;
}

export const TrainingFormButtons: React.FC<ITrainingFormButtons> = ({
  currentStep,
  onClickBack,
  isDisabled,
  projectUid,
}) => {
  const [isTooltipClicked, setIsTooltipClicked] = useState(false);

  const handleClickTooltip: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setIsTooltipClicked((prev) => !prev);
  };

  return (
    <div className="flex justify-end border-t border-defaultGray-100 pt-6 mt-8">
      <div>
        <div
          className={`tooltip ${
            currentStep === CURRENT_STEPS.THIRD && !projectUid && !isTooltipClicked ? 'show' : 'hide'
          }`}
        >
          <div className="tooltiptext flex flex-col text-left text-xs font-sans font-semibold w-48 -ml-[102px]">
            <div>If you click this button, you can start training the model when you want</div>
            <button
              type="button"
              className="p-1 rounded-md bg-white text-neutralBlack-500 self-end"
              onClick={handleClickTooltip}
            >
              Got it!
            </button>
          </div>
          <OutlineButton className="mr-3" onClick={onClickBack} type="button">
            {currentStep === CURRENT_STEPS.THIRD ? (projectUid ? 'Back' : 'Start Later') : 'Back'}
          </OutlineButton>
        </div>

        <SubmitButton disabled={isDisabled}>
          {currentStep === CURRENT_STEPS.THIRD ? 'Start Project' : 'Next'}
        </SubmitButton>
      </div>
    </div>
  );
};
