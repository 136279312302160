import React from 'react';

interface MainActionButton {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  routeName?: string;
  disabled?: boolean;
}

export const MainActionButton: React.FC<MainActionButton> = ({ onClick, routeName = 'datasets', disabled = false }) => {
  let actionLabel = 'Upload Dataset';
  let iconName = 'drive_folder_upload';

  if (routeName === 'projects') {
    actionLabel = 'New Project';
    iconName = 'add';
  } else if (routeName === 'models') {
    actionLabel = 'Upload Model';
    iconName = 'add';
  }

  return (
    <button
      type="button"
      className="transition ease-in-out duration-200 bg-sub hover:bg-sub-600 disabled:bg-disabledGray text-white text-sm leading-none px-2 pr-3 py-1 rounded shadow"
      onClick={onClick}
      disabled={disabled}
    >
      <span className="material-icons mt-0.5 mr-2">{iconName}</span>
      <span className="font-semibold inline-block leading-7 align-top">{actionLabel}</span>
    </button>
  );
};
