import React from 'react';
import { VBMF } from '../../../../../../constants';
import { RecommendedMethod } from '../../../../../../lib';

export const MethodTooltips: React.FC<{ method: RecommendedMethod }> = ({ method }) => {
  return (
    <div className="material-symbols-outlined text-xl text-gray-300 cursor-pointer pb-1 tooltip">
      help
      {method === VBMF ? (
        <span className="tooltiptext text-left text-xs font-sans font-semibold -ml-[111px]">
          Recommendation calculates the ranks
          <br />
          based on global analytic variational
          <br />
          Bayesian solution.
        </span>
      ) : (
        <span className="tooltiptext text-left text-xs font-sans font-semibold -ml-[111px]">
          Global pruning method to consider
          <br />
          the layer-wise importance for
          <br />
          structured pruning.
        </span>
      )}
    </div>
  );
};
