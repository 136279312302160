import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

import { Layout } from './components/DashboardApp';
import {
  ConvertingModel,
  CreateProjects,
  DatasetDetails,
  Datasets,
  Forbidden,
  InternalError,
  Login,
  Models,
  NotFound,
  PackagingModel,
  ProjectDetails,
  ProjectPerformance,
  Projects,
  ResetPassword,
  TrainingProject,
  UploadDataset,
} from './domain';
import { AdvancedCompression, AutomaticCompression, SelectCompression } from './domain/Compression';
import { CompressedModelDetail, ModelDetails, ModelTesting, ModelUpload } from './domain/Models/subs';

import './style.css';

// Sentry.init({
//   dsn: 'https://1042d3ba9bed45cf96ac29da6269c9fc@o922093.ingest.sentry.io/5869079',
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 0.5,
// });

const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate replace to="models" />} />
          <Route path="datasets" element={<Datasets />} />
          <Route path="datasets/upload" element={<UploadDataset />} />
          <Route path="datasets/details/:datasetUid" element={<DatasetDetails />} />
          <Route path="projects" element={<Projects />} />
          <Route path="projects/create_a_new_project" element={<CreateProjects />} />
          <Route path="projects/create_a_new_project/dataset/:datasetUid" element={<CreateProjects />} />
          <Route path="projects/quicksearch" element={<TrainingProject />} />
          <Route path="projects/quicksearch/dataset/:datasetUid" element={<TrainingProject />} />
          <Route path="projects/retraining" element={<TrainingProject />} />
          <Route path="projects/retraining/dataset/:datasetUid" element={<TrainingProject />} />
          <Route path="projects/retraining/dataset/:datasetUid/model/:modelUid" element={<TrainingProject />} />
          <Route path="projects/retraining/model/:modelUid" element={<TrainingProject />} />
          <Route path="projects/:projectUid/start" element={<TrainingProject />} />
          <Route path="projects/details/:projectUid" element={<Navigate replace to="info" />} />
          <Route path="projects/details/:projectUid/info" element={<ProjectDetails />} />
          <Route path="projects/details/:projectUid/performance" element={<ProjectPerformance />} />
          <Route path="projects/details/:projectUid/testing" element={<ModelTesting />} />
          <Route path="convert" element={<ConvertingModel />} />
          <Route path="convert/model/:modelUid" element={<ConvertingModel />} />
          <Route path="package" element={<PackagingModel />} />
          <Route path="package/model/:modelUid" element={<PackagingModel />} />
          <Route path="models" element={<Models />} />
          <Route path="models/upload" element={<ModelUpload />} />
          <Route path="models/details/:modelUid" element={<Navigate replace to="info" />} />
          <Route path="models/details/:modelUid/info" element={<ModelDetails />} />
          <Route path="models/details/:modelUid/testing" element={<ModelTesting />} />
          <Route path="models/details/:modelUid/project/:projectUid" element={<Navigate replace to="info" />} />
          <Route path="models/details/:modelUid/project/:projectUid/info" element={<ModelDetails />} />
          <Route path="models/details/:modelUid/project/:projectUid/testing" element={<ModelTesting />} />
          <Route path="models/compress/details/:modelUid" element={<CompressedModelDetail />} />
          <Route path="compression" element={<Navigate replace to="new" />} />
          <Route path="compression/new" element={<SelectCompression />} />
          <Route path="compression/new/:modelUid" element={<SelectCompression />} />
          <Route path="compression/new/automatic" element={<AutomaticCompression />} />
          <Route path="compression/new/advanced" element={<AdvancedCompression />} />
          <Route path="compression/new/automatic/:modelUid" element={<AutomaticCompression />} />
          <Route path="compression/new/advanced/:modelUid" element={<AdvancedCompression />} />
          <Route path="compression/new/advanced/config" element={<AdvancedCompression />} />
          <Route path="compression/new/advanced/:modelUid/config" element={<AdvancedCompression />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/forbidden" element={<Forbidden />} />
        <Route path="/not_found" element={<NotFound />} />
        <Route path="/internal_error" element={<InternalError />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
