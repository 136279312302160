import { HeaderText, OutlineButton, SubmitButton } from '@netspresso/components';
import { COMBINED_COMPRESSION, featureFlags } from '@netspresso/shared';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from '../../../../../../context';
import { CompressModel } from '../../../../../../lib';
import { ModalActions } from '../../../../../../reducers';
import { downloadCSV } from '../../../../../../utils';
import { ResultBarChart } from '../ResultBarChart';

type ResultDetailsProps = {
  baseModel: CompressModel | null;
  compressedModel: CompressModel | null;
};

export const ResultDetails: React.FC<ResultDetailsProps> = ({ baseModel, compressedModel }) => {
  const navigate = useNavigate();
  const [, dispatchModal] = useModalContext();

  const onClickShowDetails: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    dispatchModal({ type: ModalActions.Hide });

    if (featureFlags(COMBINED_COMPRESSION)) {
      navigate(`/models/compress/details/${compressedModel?.model_id}`);
    } else {
      window.location.href = `${process.env.REACT_APP_COMPRESSOR_URL}/models/${compressedModel?.model_id}`;
    }
  };

  const onClickDownloadCSV: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (!baseModel || !compressedModel) {
      return;
    }

    downloadCSV(baseModel, compressedModel);
  };

  if (!baseModel || !compressedModel) {
    return null;
  }

  return (
    <>
      <HeaderText className="mb-2" type="formGroup">
        Compression result details
      </HeaderText>
      <section className="grid grid-cols-2 overflow-hidden mb-2">
        <ResultBarChart
          name="FLOPs(M)"
          originalModel={baseModel.spec.flops}
          compressedModel={compressedModel.spec.flops}
          compressedLabel={`${compressedModel.spec.flops} (${(
            baseModel.spec.flops / compressedModel.spec.flops
          ).toFixed(2)}x)`}
        />
        <ResultBarChart
          name="Model Size(MB)"
          originalModel={baseModel.spec.model_size}
          compressedModel={compressedModel.spec.model_size}
          compressedLabel={`${compressedModel.spec.model_size} (${(
            baseModel.spec.model_size / compressedModel.spec.model_size
          ).toFixed(2)}x)`}
        />
        <ResultBarChart
          name="Trainable Parameters(M)"
          originalModel={baseModel.spec.trainable_parameters}
          compressedModel={compressedModel.spec.trainable_parameters}
          compressedLabel={`${compressedModel.spec.trainable_parameters} (${(
            baseModel.spec.trainable_parameters / compressedModel.spec.trainable_parameters
          ).toFixed(2)}x)`}
          hasLegend
        />
        <ResultBarChart
          name="Non Trainable Parameters(M)"
          originalModel={baseModel.spec.non_trainable_parameters}
          compressedModel={compressedModel.spec.non_trainable_parameters}
          compressedLabel={`${compressedModel.spec.non_trainable_parameters} (${
            compressedModel.spec.non_trainable_parameters !== 0
              ? (baseModel.spec.non_trainable_parameters / compressedModel.spec.non_trainable_parameters).toFixed(2)
              : '1.00'
          }x)`}
          hasLegend
        />
      </section>
      <section className="flex justify-end mb-2">
        <OutlineButton className="mr-3" onClick={onClickShowDetails}>
          Show Details
        </OutlineButton>
        <SubmitButton type="button" onClick={onClickDownloadCSV}>
          Download CSV
        </SubmitButton>
      </section>
    </>
  );
};
