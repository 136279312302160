import { FormErrorMessages, PROPER_EMAIL } from '@netspresso/shared';
import * as yup from 'yup';
import { EMAIL } from '../constants';

export const ResetPasswordScheme = yup.object({
  [EMAIL]: yup.string().required(FormErrorMessages.isRequired).matches(PROPER_EMAIL, FormErrorMessages.isNotEmail),
});

export type ResetPasswordType = yup.InferType<typeof ResetPasswordScheme>;

export const ResetPasswordDefaultValues = {
  [EMAIL]: '',
};
