export enum LoaderActions {
  Show = 'show_loader',
  Hide = 'hide_loader',
}

export type LoaderAction = {
  type: LoaderActions;
};

export type LoaderState = {
  isLoading: boolean;
};

export const loaderReducer = (_state: LoaderState, action: LoaderAction): LoaderState => {
  switch (action.type) {
    case LoaderActions.Show:
      return { isLoading: true };
    case LoaderActions.Hide:
      return { isLoading: false };
    default:
      throw new Error(`Unhandled action :${action.type}`);
  }
};
