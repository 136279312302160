import React from 'react';
import { parseContainerColor, parseIconColor } from '../../utils';

export type InfoType = 'info' | 'danger' | 'warning' | 'success';
export type InfoIconNameType = 'error_outline' | 'error' | 'warning' | 'find_in_page' | 'check_circle' | 'cancel';

interface InfoContainerProps extends React.PropsWithChildren {
  className?: string;
  infoType?: InfoType;
  iconName?: InfoIconNameType;
}

export const InfoContainer: React.FC<InfoContainerProps> = ({
  children,
  className,
  infoType = 'info',
  iconName = 'error_outline',
}) => {
  return (
    <section className={`flex items-start p-2 rounded bg-${parseContainerColor(infoType)}-50 ${className}`}>
      <span className={`material-icons mr-2 text-${parseIconColor(infoType)}`}>{iconName}</span>
      <div className="w-full flex justify-between">{children}</div>
    </section>
  );
};
