import React from 'react';

import CloseIcon from '@netspresso/components/assets/icons/close.svg';
import { MAIL_LINK } from '@netspresso/shared/src/constants/Links';

interface IDeactivatedUser {
  onClose: VoidFunction;
}

export const DeactivatedUser: React.FC<IDeactivatedUser> = ({ onClose }) => {
  return (
    <div className="modal">
      <div className="notice__container">
        <div className="notice__header">
          <button type="button" onClick={() => onClose()}>
            <CloseIcon />
          </button>
        </div>
        <p className="font-semibold text-notaRed">Due to inactivity during your trial, your account is deactivated.</p>
        <p className="font-semibold">
          If you want to register for the trial again or need any support, <br />
          please contact,&nbsp;
          <a className="text-black" href={MAIL_LINK}>
            netspresso@nota.ai
          </a>{' '}
          .
        </p>
      </div>
    </div>
  );
};
