import { YOLOV5, ATOMIX, SEGFORMER } from '@netspresso/shared';
import { TasksWithMetric } from '../Tasks';

const BASE_ARCHITECTURE = [YOLOV5, ATOMIX, SEGFORMER] as const;

export type BaseArchitectureTypes = (typeof BASE_ARCHITECTURE)[number];

export const TasksToBaseModelMap: Record<TasksWithMetric, BaseArchitectureTypes> = {
  image_classification: ATOMIX,
  object_detection: YOLOV5,
  semantic_segmentation: SEGFORMER,
};
