import { FormHeaderLabel, HeaderText, SubmitButton } from '@netspresso/components';
import React, { useEffect } from 'react';
import { LEVEL_DANGER, Toast, useNotificationContext } from '../../../../../../components';
import { BASE_MODEL, COMPRESSION, COMPRESSION_METHOD, LAYERS } from '../../../../../../constants';
import { useCompressFormContext, useLoaderContext, useModalContext } from '../../../../../../context';
import { CurrentSteps, CURRENT_STEPS, isCompressMethodWithRecommendation } from '../../../../../../lib';
import { LoaderActions, ModalActions } from '../../../../../../reducers';
import { CompressService } from '../../../../../../services';
import { getRecommendedLayers, parseCompressionMethod, useGTM } from '../../../../../../utils';
import { NetworkGraph, NetworkGraphTable, RecommendationConfigs } from '../../../../components';
import { totalLatency } from '../../../../utils';

interface ISetCompression {
  currentStep: CurrentSteps;
}

export const SetCompression = ({ currentStep }: ISetCompression) => {
  const [, dispatchModal] = useModalContext();
  const {
    compressForm: { getValues, setValue, watch },
  } = useCompressFormContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const [, dispatchLoading] = useLoaderContext();
  const { setPageToDataLayer } = useGTM();

  const watched = watch();
  const layers = watched[LAYERS];

  const isCompletedStep = React.useMemo(() => currentStep === CURRENT_STEPS.COMPLETED, [currentStep]);

  useEffect(() => {
    setPageToDataLayer('Compression detail');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseModal: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const submitCallback = async () => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      const data = getValues();
      const res = await CompressService.createRecommendation(data);

      if (layers && layers.length) {
        setValue(LAYERS, getRecommendedLayers(res.data, layers), { shouldValidate: true });
      }

      dispatchModal({ type: ModalActions.Hide });
    } catch (error) {
      showToast(
        <Toast
          content="Create Recommendation failed, please try again."
          level={LEVEL_DANGER}
          hideToast={hideToast}
          onClick={onClickToastHandler}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const handleSubmitRecommendation: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    submitCallback();
  };

  const handleClickRecommendation: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();

    dispatchModal({
      type: ModalActions.Show,
      payload: (
        <RecommendationConfigs
          onClose={onCloseModal}
          onSubmit={handleSubmitRecommendation}
          submitCallback={submitCallback}
        />
      ),
    });
  };

  const handleClickLayerCheckbox: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const {
      dataset: { layerName },
      checked,
    } = event.target;

    if (layers && layers.length) {
      setValue(
        LAYERS,
        layers.map((layer) => ({
          ...layer,
          ...(layer.name === layerName && { use: checked }),
        })),
        { shouldValidate: true }
      );
    }
  };

  const onClickPaneHandler = (event: React.MouseEvent) => {
    if (layers && layers.length) {
      // const {
      //   dataset: { layerName },
      //   checked,
      // } = event.target;

      setValue(
        LAYERS,
        layers.map((layer) => ({ ...layer, use: false })),
        { shouldValidate: true }
      );
    }
  };

  const handleClickAllLayerCheckbox: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;

    if (layers && layers.length) {
      setValue(
        LAYERS,
        layers.map((layer) => ({ ...layer, use: checked })),
        { shouldValidate: true }
      );
    }
  };

  return (
    <>
      <HeaderText className="mb-4" type="formGroup">
        Compression detail
      </HeaderText>
      <section className="mb-4 flex justify-between">
        <div className="text-defaultGray-600">
          <FormHeaderLabel display="inline-block" margin="mr-2">
            Base Model
          </FormHeaderLabel>
          <span className="mr-4">{watched[BASE_MODEL]?.model_name || ''}</span>
          <FormHeaderLabel display="inline-block" margin="mr-2">
            Compression Method
          </FormHeaderLabel>
          <span className="mr-4">
            {watched[COMPRESSION_METHOD] ? parseCompressionMethod(watched[COMPRESSION_METHOD]) : ''}
          </span>
          <FormHeaderLabel display="inline-block" margin="mr-2">
            Latency
          </FormHeaderLabel>
          <span className="mr-4">{watched[BASE_MODEL] ? totalLatency(watched[BASE_MODEL]) : ''}</span>
          <FormHeaderLabel display="inline-block" margin="mr-2">
            Device
          </FormHeaderLabel>
          <span>{watched[BASE_MODEL]?.target_device || ''}</span>
        </div>
        {isCompressMethodWithRecommendation(watched[COMPRESSION_METHOD]) && (
          <SubmitButton type="button" onClick={handleClickRecommendation} disabled={isCompletedStep}>
            Recommendation
          </SubmitButton>
        )}
      </section>
      <section className="mb-4">
        <FormHeaderLabel>Configuration</FormHeaderLabel>
        <div className="h-[660px] flex flex-row border broder-gray-300 rounded-lg">
          <div className="w-1/2 border-r">
            <NetworkGraph
              model={watched[BASE_MODEL]}
              compression={watched[COMPRESSION]}
              layers={layers}
              onClickLayerCheckbox={handleClickLayerCheckbox}
              onClickPane={onClickPaneHandler}
            />
          </div>
          <div className="relative w-1/2 overflow-y-auto">
            <NetworkGraphTable
              model={watched[BASE_MODEL]}
              compression={watched[COMPRESSION]}
              layers={layers}
              onClickLayerCheckbox={handleClickLayerCheckbox}
              onClickAllLayerCheckbox={handleClickAllLayerCheckbox}
            />
          </div>
        </div>
      </section>
    </>
  );
};
