import { IconButton } from '@netspresso/components';
import React, { useState } from 'react';
import { Frameworks, ModelFormats } from '../../../../../../lib';
import { DownloadOptions } from '../DownloadOptions';

export type ActionButtonsProps = {
  modelId: string;
  framework: Frameworks;
  modelFormat: ModelFormats;
  isNotRetrainable: boolean;
  isNotDownloadable: boolean;
  isPackagable: boolean;
  onClickRetrain: React.MouseEventHandler<HTMLButtonElement>;
  onClickDelete: React.MouseEventHandler<HTMLButtonElement>;
  onClickDetails: React.MouseEventHandler<HTMLButtonElement>;
};

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  modelId,
  framework,
  modelFormat,
  isNotRetrainable,
  isNotDownloadable,
  isPackagable,
  onClickRetrain,
  onClickDelete,
  onClickDetails,
}) => {
  const [showDownloadOpition, setShowDownloadOption] = useState(false);

  const onMouseEnterDownload = () => {
    setShowDownloadOption(true);
  };

  const onMouseLeaveDownload = () => {
    setShowDownloadOption(false);
  };

  return (
    <td className="px-5">
      <div
        className="flex flex-row items-center justify-flex flex-row items-center justify-end relative"
        onMouseLeave={onMouseLeaveDownload}
      >
        <div className="flex flex-row space-x-1">
          <IconButton
            dataTestId="retrain-button"
            outline
            tooltipMsg="Retrain"
            isDisabled={isNotRetrainable}
            onClick={onClickRetrain}
          >
            <span className="material-symbols-outlined hover:text-white">hub</span>
          </IconButton>
          <IconButton isDisabled={isNotDownloadable} onMouseEnter={onMouseEnterDownload}>
            download
          </IconButton>
          <IconButton outline tooltipMsg="Delete" onClick={onClickDelete}>
            <span className="material-symbols-outlined hover:text-white">delete</span>
          </IconButton>
          <button
            type="button"
            className="flex items-center justify-center px-1 w-[30px] h-[30px] text-notaNavy-900 hover:text-notaNavy-700"
            onClick={onClickDetails}
          >
            <span className="material-symbols-outlined">arrow_forward_ios</span>
          </button>
        </div>
        {showDownloadOpition && (
          <DownloadOptions
            modelId={modelId}
            framework={framework}
            modelFormat={modelFormat}
            isPackagable={isPackagable}
          />
        )}
      </div>
    </td>
  );
};
