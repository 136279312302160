import React, { ChangeEventHandler } from 'react';

import { FileAcceptType, InputFile } from '../InputFile';

interface DatasetInputProps {
  className?: string;
  id: string;
  label: string;
  localFile: File | null;
  onFileChange: ChangeEventHandler<HTMLInputElement>;
  accept?: FileAcceptType;
  fileError?: string;
  filePlaceHolder?: string;
  max?: string;
}

export const DatasetInput: React.FC<DatasetInputProps> = ({
  className,
  id,
  label,
  localFile,
  onFileChange,
  accept = 'application/zip',
  fileError = '',
  filePlaceHolder = '',
  max = '1',
}) => {
  return (
    <div className={`flex flex-row ${className}`} data-test="dataset-input">
      <label className="min-w-[240px] w-1/6 font-darkGray cursor-pointer" htmlFor={id}>
        <span className="leading-8">{label}</span>
        {max && <span className="text-xs leading-8 pl-1 mr-4">(max {max}GB)</span>}
      </label>
      <InputFile
        id={id}
        error={fileError}
        accept={accept}
        placeholder={filePlaceHolder}
        localFile={localFile}
        onChange={onFileChange}
      />
    </div>
  );
};
