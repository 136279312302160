import React from 'react';
import { Outlet, useLocation } from 'react-router';

import { useLoaderContext, useModalContext } from '../../../context';
import { AuthRequired } from '../../AuthRequired';
import { Loading } from '../../Loading';
import { ModalContainer } from '../../Modal';
import { Container } from '../Container';
import { Content } from '../Content';
import { Header, SubNavigation } from '../Header';
import { Main } from '../Main';
import { Sidebar } from '../Sidebar';

export const Layout: React.FC = () => {
  const [{ isLoading }] = useLoaderContext();
  const [{ showModal, children }] = useModalContext();
  const { pathname } = useLocation();

  const showSubNavigation = (path: string) => {
    return path.includes('/projects/details') || path.includes('/models/details');
  };

  return (
    <AuthRequired>
      <Container>
        <Header />
        <Main>
          <Sidebar />
          <ModalContainer showModal={showModal}>{children}</ModalContainer>
          <Loading show={isLoading} />
          {showSubNavigation(pathname) ? <SubNavigation pathname={pathname} /> : null}
          <Content>
            <Outlet />
          </Content>
        </Main>
      </Container>
    </AuthRequired>
  );
};
