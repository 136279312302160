import { LabeledValueDisplay } from '@netspresso/components';
import { LOCAL_DATASET, featureFlags } from '@netspresso/shared';
import React from 'react';
import { DatasetFormatType, StorageLocationType, Tasks } from '../../../../../../lib';
import { parseDatasetFormat, parseTask } from '../../../../../../utils';
import { parseDataLocation } from '../../../../../../utils/parsers/parseStorageType';

type DatasetInfoProps = {
  storageType: StorageLocationType;
  datasetFormat: DatasetFormatType;
  datasetTask: Tasks;
  trainNum: number;
  validNum: number;
  testNum: number;
  classesNum: number;
  createdAt: string;
  isSample?: boolean;
};

export const DatasetInfo: React.FC<DatasetInfoProps> = ({
  storageType,
  datasetFormat,
  datasetTask,
  trainNum = 0,
  validNum = 0,
  testNum = 0,
  classesNum = 0,
  createdAt = '',
  isSample = false,
}) => {
  return (
    <section className="w-full w-1/3 pr-3">
      {featureFlags(LOCAL_DATASET) && (
        <LabeledValueDisplay label="Dataset Location" className="mb-4">
          {parseDataLocation(storageType)}
        </LabeledValueDisplay>
      )}
      <LabeledValueDisplay label="Task" className="mb-4" dataTestId="task-tag">
        {parseTask(datasetTask)}
      </LabeledValueDisplay>
      <LabeledValueDisplay label="Format" className="mb-4" dataTestId="dataset-format">
        {parseDatasetFormat(datasetFormat)}
      </LabeledValueDisplay>
      <LabeledValueDisplay label="Total dataset" className="mb-4">
        {trainNum + validNum + testNum}
      </LabeledValueDisplay>
      <LabeledValueDisplay
        label="Training / Validation / Testing (Images)"
        className="mb-4"
      >{`${trainNum} / ${validNum} / ${testNum}`}</LabeledValueDisplay>
      <LabeledValueDisplay label="Classes" className="mb-4">
        {classesNum}
      </LabeledValueDisplay>
      {isSample ? null : <LabeledValueDisplay label="Created">{createdAt}</LabeledValueDisplay>}
    </section>
  );
};
