import * as yup from 'yup';
import {
  HSV_H,
  HSV_S,
  HSV_V,
  DEGREES,
  TRANSLATE,
  SCALE,
  MAX_SCALE,
  MIN_SCALE,
  CROP_SIZE_H,
  CROP_SIZE_W,
  SHEAR,
  PERSPECTIVE,
  FLIPUD,
  FLIPLR,
  MOSAIC,
  MIXUP,
  COPY_PASTE,
  RESIZE_RATIO0,
  RESIZE_RATIOF,
  RESIZE_ADD,
  MIXUP_ALPHA,
  CUTMIX_ALPHA,
  MIXUP_SWITCH_PROB,
} from '@netspresso/shared';
import { Tasks } from '../lib';
import {
  isImageClassification,
  isObjectDetection,
  isSemanticSegmentation,
  parseHypPlaceholderCls,
  parseHypPlaceholderDet,
  parseHypPlaceholderSeg,
} from '../utils';

export const AgumentationScheme = (task: Tasks): yup.Schema => {
  if (isObjectDetection(task)) {
    return ClassifiAugmentScheme;
  }

  if (isImageClassification(task)) {
    return SegmentationAugmentScheme;
  }

  return DetectionAugmentScheme;
};

export const ClassifiAugmentScheme = yup.object({
  [MIXUP]: yup.number().typeError(parseHypPlaceholderCls(MIXUP)).min(0).max(1),
  [MIXUP_ALPHA]: yup.number().typeError(parseHypPlaceholderCls(MIXUP_ALPHA)).min(0).max(1),
  [CUTMIX_ALPHA]: yup.number().typeError(parseHypPlaceholderCls(CUTMIX_ALPHA)).min(0).max(1),
  [MIXUP_SWITCH_PROB]: yup.number().typeError(parseHypPlaceholderCls(MIXUP_SWITCH_PROB)).min(0).max(1),
});

export const DetectionAugmentScheme = yup.object({
  [HSV_H]: yup.number().typeError(parseHypPlaceholderDet(HSV_H)).min(0).max(1),
  [HSV_S]: yup.number().typeError(parseHypPlaceholderDet(HSV_S)).min(0).max(1),
  [HSV_V]: yup.number().typeError(parseHypPlaceholderDet(HSV_V)).min(0).max(1),
  [DEGREES]: yup.number().typeError(parseHypPlaceholderDet(DEGREES)).min(-180).max(180),
  [TRANSLATE]: yup.number().typeError(parseHypPlaceholderDet(TRANSLATE)).min(-1).max(1),
  [SCALE]: yup.number().typeError(parseHypPlaceholderDet(SCALE)).min(-1).max(1),
  [SHEAR]: yup.number().typeError(parseHypPlaceholderDet(SHEAR)).min(-180).max(180),
  [PERSPECTIVE]: yup.number().typeError(parseHypPlaceholderDet(PERSPECTIVE)).min(0).max(0.001),
  [FLIPUD]: yup.number().typeError(parseHypPlaceholderDet(FLIPUD)).min(0).max(1),
  [FLIPLR]: yup.number().typeError(parseHypPlaceholderDet(FLIPLR)).min(0).max(1),
  [MOSAIC]: yup.number().typeError(parseHypPlaceholderDet(MOSAIC)).min(0).max(1),
  [MIXUP]: yup.number().typeError(parseHypPlaceholderDet(MIXUP)).min(0).max(1),
  [COPY_PASTE]: yup.number().typeError(parseHypPlaceholderDet(COPY_PASTE)).min(0).max(1),
});

export const SegmentationAugmentScheme = yup.object({
  [MAX_SCALE]: yup.number().typeError(parseHypPlaceholderSeg(MAX_SCALE)).oneOf([1024, 2048]),
  [MIN_SCALE]: yup.number().typeError(parseHypPlaceholderSeg(MIN_SCALE)).oneOf([512, 768, 1024]),
  [CROP_SIZE_H]: yup.number().typeError(parseHypPlaceholderSeg(CROP_SIZE_H)).oneOf([512, 768, 1024]),
  [CROP_SIZE_W]: yup.number().typeError(parseHypPlaceholderSeg(CROP_SIZE_W)).oneOf([512, 768, 1024]),
  [RESIZE_RATIO0]: yup.number().typeError(parseHypPlaceholderSeg(RESIZE_RATIO0)).min(0.5).max(yup.ref(RESIZE_RATIOF)),
  [RESIZE_RATIOF]: yup.number().typeError(parseHypPlaceholderSeg(RESIZE_RATIOF)).min(yup.ref(RESIZE_RATIO0)),
  [RESIZE_ADD]: yup.number().default(1),
  [FLIPLR]: yup.number().typeError(parseHypPlaceholderDet(FLIPLR)).min(0).max(1),
});

export const AugmentaionDefaultValues = (task: Tasks): Record<string, number> => {
  if (isImageClassification(task)) {
    return ClassifiAugmentDefaultValues;
  }

  if (isSemanticSegmentation(task)) {
    return SegmentationAugmentDefaultValues;
  }

  return DetectionAugmentDefaultValues;
};

export const ClassifiAugmentDefaultValues: Record<string, number> = {
  [MIXUP]: 1,
  [MIXUP_ALPHA]: 0,
  [CUTMIX_ALPHA]: 0,
  [MIXUP_SWITCH_PROB]: 0.5,
};

export const DetectionAugmentDefaultValues: Record<string, number> = {
  [HSV_H]: 0.015,
  [HSV_S]: 0.7,
  [HSV_V]: 0.4,
  [DEGREES]: 0,
  [TRANSLATE]: 0.1,
  [SCALE]: 0.5,
  [SHEAR]: 0,
  [PERSPECTIVE]: 0,
  [FLIPUD]: 0,
  [FLIPLR]: 0.5,
  [MOSAIC]: 1.0,
  [MIXUP]: 0,
  [COPY_PASTE]: 0,
};

export const SegmentationAugmentDefaultValues: Record<string, number> = {
  [MAX_SCALE]: 2048,
  [MIN_SCALE]: 768,
  [CROP_SIZE_H]: 768,
  [CROP_SIZE_W]: 768,
  [RESIZE_RATIO0]: 0.5,
  [RESIZE_RATIOF]: 2,
  [RESIZE_ADD]: 1,
  [FLIPLR]: 0.5,
};
