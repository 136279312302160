import { CompressModel } from '../../lib';

export const downloadCSV = (baseModel: CompressModel, compressedModel: CompressModel) => {
  const csv = `\uFEFFcategory,original model,compressed model
FLOPs (M),${baseModel.spec.flops},${compressedModel.spec.flops}
layer count,${baseModel.spec.layer_count},${compressedModel.spec.layer_count}
model size (MB),${baseModel.spec.model_size},${compressedModel.spec.model_size}
non trainable parameters (M),${baseModel.spec.non_trainable_parameters},${compressedModel.spec.non_trainable_parameters}
trainable parameters (M),${baseModel.spec.trainable_parameters},${compressedModel.spec.trainable_parameters}
`.trim();
  const csvFile = new Blob([csv], { type: 'text/csv' });
  const fileName = 'compression_report.csv';
  const link = document.createElement('a');

  link.download = fileName;
  link.href = window.URL.createObjectURL(csvFile);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  link.remove();
};
