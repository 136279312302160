/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';

import { FormHeaderLabel, RadioButton, ToggleSwitch } from '@netspresso/components';
import { featureFlags, TEST_EPOCH } from '@netspresso/shared';

import { TabComponents } from '../TabComponents';
import { HyperParameterForm } from '../HyperParameterForm';
import { DataAugmentationForm } from '../DataAugmentationForm';
import { useProjectsFormContext } from '../../../../context';
import { EPOCHS_PER_TRIAL, HYP_DETAIL, PROJECT_TASK, AUGMENTATION_DETAILS } from '../../../../constants';
import { InferenceForm } from '../InferenceForm';
import { AdvancedOptionTabNames, ProjectTaskType, TASKS } from '../../../../lib/types';
import { isObjectDetection, isSemanticSegmentation } from '../../../../utils';

const BASIC_EPOCH = [100, 150, 200, 250, 300];
const SEGMENTATION_EPOCH = [150, 250, 350, 450];

export const AdvancedOptions: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<AdvancedOptionTabNames>(HYP_DETAIL);
  const [isEditable, setIsEditable] = useState(false);
  const [selectedTask, setSelectedTask] = useState<ProjectTaskType>(TASKS.OBJECT_DETECTION);
  const {
    projectsForm: { setValue, watch },
  } = useProjectsFormContext();

  const watched = watch();

  const onOptionChange = (checked: boolean) => {
    setIsEditable(checked);
  };

  const onEpochChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = parseInt(event.target.value, 10);

    setValue(EPOCHS_PER_TRIAL, value);

    if (isObjectDetection(watched[PROJECT_TASK])) {
      setValue(`${HYP_DETAIL}.${EPOCHS_PER_TRIAL}`, value);
    }
  };

  useEffect(() => {
    setSelectedTab(HYP_DETAIL);
  }, [selectedTask]);

  useEffect(() => {
    setSelectedTask(watched[PROJECT_TASK]);
  }, [watched]);

  return (
    <>
      <section className="flex flex-row items-center mt-6">
        <label className="mr-2">Advanced Options</label>
        <ToggleSwitch id="advanced-options" onToggle={onOptionChange} />
      </section>
      {isEditable && (
        <>
          <section className="mt-2">
            <FormHeaderLabel color="darkGray">Training epoch</FormHeaderLabel>
            <div>
              {isSemanticSegmentation(watched[PROJECT_TASK])
                ? SEGMENTATION_EPOCH.map((epoch) => (
                    <RadioButton
                      key={`epochs-${epoch}`}
                      groupName={EPOCHS_PER_TRIAL}
                      value={epoch.toString()}
                      label={epoch.toString()}
                      classes="mr-4"
                      isChecked={watched[EPOCHS_PER_TRIAL] === epoch}
                      onChange={onEpochChange}
                    />
                  ))
                : BASIC_EPOCH.map((epoch) => (
                    <RadioButton
                      key={`epochs-${epoch}`}
                      groupName={EPOCHS_PER_TRIAL}
                      value={epoch.toString()}
                      label={epoch.toString()}
                      classes="mr-4"
                      isChecked={watched[EPOCHS_PER_TRIAL] === epoch}
                      onChange={onEpochChange}
                    />
                  ))}
              {featureFlags(TEST_EPOCH) ? (
                <RadioButton
                  groupName={EPOCHS_PER_TRIAL}
                  value="3"
                  label="3"
                  classes="mr-4"
                  isChecked={watched[EPOCHS_PER_TRIAL] === 3}
                  onChange={onEpochChange}
                />
              ) : null}
            </div>
          </section>
          <div className="mt-10 mb-4 w-full">
            <TabComponents task={watched[PROJECT_TASK]} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <div className="w-full bg-lightGray px-15 py-8">
              {selectedTab === HYP_DETAIL ? (
                <HyperParameterForm task={watched[PROJECT_TASK]} />
              ) : selectedTab === AUGMENTATION_DETAILS ? (
                <DataAugmentationForm task={watched[PROJECT_TASK]} />
              ) : (
                <InferenceForm />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
