import { apiClient, API_CONVERT_V1 } from '@netspresso/shared';
import { ConvertModelType } from '../../schemes';

export const ConvertService = (() => {
  const convert = (payload: Omit<ConvertModelType, 'convert_model_id'>) => {
    return apiClient.post(API_CONVERT_V1, payload);
  };

  return {
    convert,
  };
})();
