import { AvailableLayer } from '../types';

export const isAvailableLayer = (value: unknown): value is AvailableLayer => {
  return (
    (value as AvailableLayer).latency !== undefined &&
    (value as AvailableLayer).name !== undefined &&
    (value as AvailableLayer).use !== undefined &&
    (value as AvailableLayer).values !== undefined
  );
};
