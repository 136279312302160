import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { ProjectService } from '../../../../../services';
import { Project } from '../../../../../lib';
import { isCompleted, isNotObjectDetection } from '../../../../../utils';

interface ISubNavigation {
  pathname: string;
}

type SubPath = 'info' | 'performance' | 'testing';

export const SubNavigation: React.FC<ISubNavigation> = ({ pathname }) => {
  const { projectUid } = useParams();
  const [projectData, setProjectData] = useState<{ data: Project | null; isFetching: boolean }>({
    data: null,
    isFetching: false,
  });

  const fetchProject = async (projectId: string) => {
    try {
      setProjectData({ data: projectData.data, isFetching: true });

      const res = await ProjectService.getProjectById(projectId);

      setProjectData({ data: { ...res.data }, isFetching: false });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setProjectData({ data: projectData.data, isFetching: true });
    }
  };

  useEffect(() => {
    if (projectUid) {
      fetchProject(projectUid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUid]);

  const getRouteToNavigate = (path: string, target: SubPath) => {
    const temp = path.split('/');

    temp.pop();
    temp.push(target);

    return temp.join('/');
  };

  const isActive = (path: string, current: SubPath) => {
    return path.includes(current) ? 'pointer-events-none text-darkGray border-b-2 border-notaBlue' : 'text-defaultGray';
  };

  const isDisabled = (path: string) => {
    return path.includes('/details') && (!projectData.data || !isCompleted(projectData.data.status));
  };

  const isDisabledTesting = (path: string) => {
    return (
      path.includes('/details') &&
      (!projectData.data || !isCompleted(projectData.data.status) || isNotObjectDetection(projectData.data.task))
    );
  };

  const performanceText = (path: string) => {
    return path.includes('projects') ? 'Result ' : 'Performance';
  };

  return (
    <section className="pl-[36px] bg-white border-t border-lineGray shadow">
      <ul className="flex flex-row">
        <li>
          <Link
            to={getRouteToNavigate(pathname, 'info')}
            className={`inline-block font-bold px-3 pt-2 pb-2 ${isActive(pathname, 'info')}`}
          >
            {pathname.includes('projects') ? 'Project' : 'Model'} Info
          </Link>
        </li>
        {pathname.includes('projects/details') && (
          <li>
            {isDisabled(pathname) ? (
              <span className="inline-block font-bold px-3 pt-2 pb-2 text-defaultGray select-none">
                {performanceText(pathname)}
              </span>
            ) : (
              <Link
                to={getRouteToNavigate(pathname, 'performance')}
                className={`inline-block font-bold px-3 pt-2 pb-2 ${isActive(pathname, 'performance')}`}
              >
                {performanceText(pathname)}
              </Link>
            )}
          </li>
        )}
        {isDisabledTesting(pathname) ? (
          <span className="inline-block font-bold px-3 pt-2 pb-2 text-defaultGray select-none">Testing</span>
        ) : (
          <li>
            <Link
              to={getRouteToNavigate(pathname, 'testing')}
              className={`inline-block font-bold px-3 pt-2 pb-2 ${isActive(pathname, 'testing')}`}
            >
              Testing
            </Link>
          </li>
        )}
      </ul>
    </section>
  );
};
