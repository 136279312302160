import React, { useState } from 'react';
import { useFormState } from 'react-hook-form';

import {
  FormHeaderLabel,
  HeaderText,
  InfoContainer,
  InfoText,
  InputNumber,
  InputTextLabel,
  Select,
} from '@netspresso/components';
import { PLACEHOLDER_TARGET_LATENCY, RETRAINING } from '@netspresso/shared';

import {
  ARM_BATCH_IMAGE_SIZES,
  BASE_IMG_SIZE,
  BATCH_IMAGE_SIZES,
  CLS_BATCH_IMAGE_SIZES,
  DEVICE,
  IMG_SIZE_CHANNEL,
  IMG_SIZE_HEIGHT,
  IMG_SIZE_WIDTH,
  JETSON_ORIN_BATCH_IMAGE_SIZES,
  PROJECT_TASK,
  PROJECT_TYPE,
  SEG_BATCH_IMAGE_SIZES,
  SEG_LIMITED_BATCH_IMAGE_SIZES,
  TARGET_LATENCY,
} from '../../../../constants';
import { useProjectsFormContext } from '../../../../context';
import { ProjectTaskType } from '../../../../lib';
import {
  isArmVirtualHardware,
  isImageClassification,
  isIntelXeon,
  isJetsonAgxOrin,
  isSemanticSegmentation,
} from '../../../../utils';

export const ModelTraining: React.FC = () => {
  const {
    projectsForm: { setValue, watch, control },
  } = useProjectsFormContext();
  const { errors } = useFormState({ control });

  const [baseImage, setBaseImage] = useState('640');
  const [targetLatency, setTargetLatency] = useState(0);
  const [imageSizeChannel] = useState(3);

  const watched = watch();

  const onBaseImageChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    setBaseImage(value);
    setValue(BASE_IMG_SIZE, [parseInt(value, 10), parseInt(value, 10)]);
    setValue(IMG_SIZE_WIDTH, parseInt(value, 10));
    setValue(IMG_SIZE_HEIGHT, parseInt(value, 10));
  };

  const onTargetLatencyChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.value) {
      const value = parseInt(event.target.value, 10);

      setTargetLatency(value);
      setValue(TARGET_LATENCY, value, { shouldValidate: true, shouldDirty: true });
    } else {
      setTargetLatency(0);
      setValue(TARGET_LATENCY, 0, { shouldValidate: true, shouldDirty: true });
    }
  };

  // const filterNonFloat = (value: string) => {
  //   return value.replace(/[^0-9.]+/, '');
  // };

  const imageSizeOption = (task: ProjectTaskType) => {
    if (isImageClassification(task)) {
      return CLS_BATCH_IMAGE_SIZES;
    }

    if (isSemanticSegmentation(task)) {
      if (isIntelXeon(watched[DEVICE])) {
        return SEG_BATCH_IMAGE_SIZES;
      }

      return SEG_LIMITED_BATCH_IMAGE_SIZES;
    }

    if (isArmVirtualHardware(watched[DEVICE])) {
      return ARM_BATCH_IMAGE_SIZES;
    }

    if (isJetsonAgxOrin(watched[DEVICE])) {
      return JETSON_ORIN_BATCH_IMAGE_SIZES;
    }

    return BATCH_IMAGE_SIZES;
  };

  return (
    <>
      <HeaderText className="mt-8 mb-4" type="formGroup">
        Model training
      </HeaderText>
      {watched[PROJECT_TYPE] !== RETRAINING && (
        <section className="mb-4">
          <InputTextLabel htmlFor={TARGET_LATENCY}>Target latency (ms) *</InputTextLabel>
          <InputNumber
            id={TARGET_LATENCY}
            className="border border-defaultGray"
            placeholder={PLACEHOLDER_TARGET_LATENCY}
            error={errors[TARGET_LATENCY] ? errors[TARGET_LATENCY].message : ''}
            value={watched[TARGET_LATENCY] || targetLatency}
            width="w-1/6"
            onChange={onTargetLatencyChange}
          />
        </section>
      )}
      <InfoContainer className="mb-4">
        <InfoText color="secondary">Support range: 10~5000</InfoText>
      </InfoContainer>
      <HeaderText className="mb-1" type="formSection">
        Default image size for model training
      </HeaderText>
      <section className="w-1/6 mb-4">
        <FormHeaderLabel>Width x Height (px) *</FormHeaderLabel>
        <Select
          value={`${watched[BASE_IMG_SIZE][0]}` || baseImage}
          options={imageSizeOption(watched[PROJECT_TASK])}
          onChange={onBaseImageChange}
        />
      </section>
      <InfoContainer>
        <InfoText color="secondary">
          If you set larger latency and image size (width x height), you may get higher accuracy.
        </InfoText>
      </InfoContainer>
      <section className="mt-4 mb-4">
        <InputTextLabel htmlFor={IMG_SIZE_CHANNEL}>Input channel *</InputTextLabel>
        <InputNumber
          id={IMG_SIZE_CHANNEL}
          className="border border-defaultGray"
          value={imageSizeChannel}
          width="w-1/6"
          isDisable
        />
      </section>
      <InfoContainer>
        <InfoText color="secondary">Input channel 3 is for RGB images</InfoText>
      </InfoContainer>
    </>
  );
};
