/* eslint-disable camelcase */
import { removeNull, RETRAINING, TAO, TAO_UNET } from '@netspresso/shared';
import { EPOCHS_PER_TRIAL } from '../../constants';
import { OutputDataTypes, Tasks } from '../../lib';
import { ProjectType } from '../../schemes';
import { isObjectDetection, isSemanticSegmentation } from '../conditions';

export type ProjectPayloadType = {
  project_name: string;
  note: string;
  task?: Tasks;
  device?: string;
  output_model_type?: string;
  output_model_version?: string;
  output_data_type?: OutputDataTypes;
  output_batch_size: number;
  training_batch_size: number;
  target_latency?: number | null;
  epochs_per_trial?: number;
  dataset_id?: string;
  img_size_width?: number;
  img_size_height?: number;
  img_size_channel: number;
  fix_img_size: number;
  augmentation: number;
  augmentation_details: Record<string, number>;
  train_detail: Record<string, unknown>;
  hyp_detail: Record<string, number | boolean>;
  inference_detail?: Record<string, number | boolean> | null;
  base_architecture?: string;
  project_type?: string;
  trial_num: number;
  model_id?: string;
};

export const convertToProjectpayload = ({
  project_name,
  project_type,
  task,
  device,
  graphmodule_pt,
  output_model_type,
  output_model_version,
  output_data_type,
  output_batch_size,
  recommended_target_latency,
  hyp_detail,
  augmentation_details,
  epochs_per_trial,
  dataset_id,
  img_size_width,
  img_size_height,
  img_size_channel,
  inference_detail,
  base_architecture,
  augmentation,
  model_id,
  note = '',
}: ProjectType): ProjectPayloadType => {
  const hypDetail = { ...hyp_detail };

  if (isObjectDetection(task)) {
    delete hypDetail[EPOCHS_PER_TRIAL];
  }

  const result: ProjectPayloadType = {
    project_name,
    note,
    task,
    device,
    output_model_type,
    output_model_version,
    output_data_type,
    output_batch_size,
    training_batch_size: 64,
    target_latency: project_type === RETRAINING ? null : recommended_target_latency,
    epochs_per_trial,
    dataset_id,
    img_size_width,
    img_size_height,
    img_size_channel,
    fix_img_size: 1,
    augmentation,
    augmentation_details: { ...augmentation_details },
    train_detail: {},
    hyp_detail: { ...hypDetail },
    base_architecture,
    project_type,
    trial_num: 1,
    // model_id: project_type === RETRAINING ? graphmodule_pt : model_id,
    model_id,
  };

  if (isSemanticSegmentation(task)) {
    result.inference_detail = { ...inference_detail };
  }

  if (output_model_type === TAO) {
    result.output_model_version = '';
    result.hyp_detail = {};
    result.inference_detail = null;
    result.augmentation_details = {};
    result.base_architecture = TAO_UNET;
    result.augmentation = 0;
  }

  return removeNull(result) as ProjectPayloadType;
};
