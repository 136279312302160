import { useFormState } from 'react-hook-form';

import { HeaderText, InputTextLabel, TextArea, TextInput } from '@netspresso/components';
import { PLACEHOLDER_PROJECT_NAME, PLACEHOLDER_PROJECT_MEMO } from '@netspresso/shared';

import { NOTE, PROJECT_NAME } from '../../../../constants';
import { useProjectsFormContext } from '../../../../context';

export const ProjectSetting: React.FC = () => {
  const {
    projectsForm: { setValue, watch, control },
  } = useProjectsFormContext();
  const { errors } = useFormState({ control });

  const watched = watch();

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setValue(PROJECT_NAME, value, { shouldValidate: true, shouldDirty: true });
  };

  const onMemoChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const { value } = event.target;

    setValue(NOTE, value, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <>
      <HeaderText className="mb-4" type="formGroup">
        Project info
      </HeaderText>
      <section className="mb-3">
        <InputTextLabel htmlFor={PROJECT_NAME}>Project name *</InputTextLabel>
        <TextInput
          id={PROJECT_NAME}
          placeholder={PLACEHOLDER_PROJECT_NAME}
          error={errors[PROJECT_NAME] ? errors[PROJECT_NAME].message : ''}
          value={watched[PROJECT_NAME] || ''}
          width="w-1/2"
          onChange={onNameChange}
        />
      </section>
      <section className="mb-8">
        <InputTextLabel htmlFor={NOTE}>Memo</InputTextLabel>
        <TextArea
          id={NOTE}
          error={errors[NOTE] ? errors[NOTE].message : ''}
          placeholder={PLACEHOLDER_PROJECT_MEMO}
          value={watched[NOTE] || ''}
          onChange={onMemoChange}
        />
      </section>
    </>
  );
};
