import { FrameworkNames, Frameworks } from '../lib';

export const TENSORRT = 'tensorrt';
export const TENSORFLOW_LITE = 'tensorflow_lite';
export const TENSORFLOW_TAO = 'tensorflow_tao';
export const PYTORCH_TAO = 'pytorch_tao';
export const ETLT = 'etlt';
export const OPENVINO = 'openvino';
export const TENSORFLOW_KERAS = 'tensorflow_keras';
export const ONNX = 'onnx';
export const PYTORCH = 'pytorch';

export const FrameworksMap: Record<Frameworks, FrameworkNames> = {
  [TENSORRT]: 'TensorRT',
  [TENSORFLOW_LITE]: 'TensorFlow Lite',
  [TENSORFLOW_TAO]: 'NVIDIA-TAO',
  [PYTORCH_TAO]: 'NVIDIA-TAO',
  [ETLT]: 'NVIDIA-TAO',
  [OPENVINO]: 'OpenVINO',
  [TENSORFLOW_KERAS]: 'TensorFlow-Keras',
  [ONNX]: 'ONNX',
  [PYTORCH]: 'Pytorch',
};
