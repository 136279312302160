/* eslint-disable camelcase */
import { API_AGENT_V1, apiClient } from '@netspresso/shared';
import { NotaServerType, UserAgentType } from '../../lib';

export type AgentInfo = {
  nota_server: NotaServerType;
  user_server: UserAgentType[];
};

export const AgentService = (() => {
  const getAgentInfoByDatasetId = (datasetId: string) => {
    return apiClient.get<AgentInfo>(API_AGENT_V1, {
      params: {
        dataset_id: datasetId,
      },
    });
  };

  return {
    getAgentInfoByDatasetId,
  };
})();
