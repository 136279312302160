import React from 'react';
import { Frameworks, ModelFormats } from '../../../../../../lib';
import { ModelService } from '../../../../../../services';
import { download, parseFramework, parseModelFormat } from '../../../../../../utils';
import { PackagablePanel } from '../PackagablePanel';

export type DownloadOptionsProps = {
  modelId: string;
  framework: Frameworks;
  modelFormat: ModelFormats;
  isPackagable: boolean;
};

export const DownloadOptions: React.FC<DownloadOptionsProps> = ({ modelId, framework, modelFormat, isPackagable }) => {
  const onClickDownload: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    const res = await ModelService.downloadModel(modelId);

    if (res.status === 200) {
      const downloadUrl = res.data.s3_urls;

      for (const key in downloadUrl) {
        if (downloadUrl[key]) {
          download(downloadUrl[key]);
        }
      }
    }
  };

  return (
    <ul
      className="absolute bg-white text-sm top-[30px] right-[30px] w-[224px] z-20 rounded-lg shadow"
      data-testid="download-options"
    >
      <li>
        <button
          type="button"
          className="px-4 py-2 text-left text-gray-700 w-full disabled:text-lineGray hover:text-secondary"
          onClick={onClickDownload}
        >{`${parseFramework(framework)} (.${parseModelFormat(modelFormat)})`}</button>
      </li>
      {isPackagable && <PackagablePanel modelId={modelId} />}
    </ul>
  );
};
