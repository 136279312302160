import YOLOv5nIcon from '@netspresso/components/assets/icons/yolov5n.svg';
import YOLOv5sIcon from '@netspresso/components/assets/icons/yolov5s.svg';
import YOLOv5mIcon from '@netspresso/components/assets/icons/yolov5m.svg';
import YOLOv5lIcon from '@netspresso/components/assets/icons/yolov5l.svg';
import YOLOv5xIcon from '@netspresso/components/assets/icons/yolov5x.svg';
import TAOIcon from '@netspresso/components/assets/icons/nvidia_tao.svg';
import NPNetIcon from '@netspresso/components/assets/icons/npnet.svg';
import React from 'react';

type ModelIconProps = {
  modelName: string;
};

export const ModelIcon: React.FC<ModelIconProps> = ({ modelName }) => {
  if (modelName.indexOf('yolov5n') > -1 || modelName === 'segformer-b0') {
    return <YOLOv5nIcon />;
  }

  if (
    modelName.indexOf('yolov5s') > -1 ||
    modelName === 'segformer-b1' ||
    modelName === 'mixnet_s' ||
    modelName === 'shufflenetv2plus_s'
  ) {
    return <YOLOv5sIcon />;
  }

  if (
    modelName.indexOf('yolov5m') > -1 ||
    modelName === 'segformer-b3' ||
    modelName === 'mixnet_m' ||
    modelName === 'shufflenetv2plus_m'
  ) {
    return <YOLOv5mIcon />;
  }

  if (modelName === 'UNet (+ Resnet)') {
    return <TAOIcon />;
  }

  if (
    modelName.indexOf('yolov5l') > -1 ||
    modelName === 'segformer-b5' ||
    modelName === 'mixnet_l' ||
    modelName === 'shufflenetv2plus_l'
  ) {
    return <YOLOv5lIcon />;
  }

  if (modelName.indexOf('yolov5x') > -1) {
    return <YOLOv5xIcon />;
  }

  return <NPNetIcon />;
};
