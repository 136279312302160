export const SUBMITTED = [100] as const;
export const CONFIG_ERROR = [112, 122, 222] as const;
export const TRAINING = [110, 120, 210, 220, 301, 320] as const;
export const TRAINING_STOPPED = [902] as const;
export const TRAINING_ERROR = [302, 312] as const;
export const CONVERTING = [400] as const;
export const CONVERTING_ERROR = [402] as const;
export const BENCHMARKING = [410] as const;
export const BENCHMARKING_ERROR = [412] as const;
export const COMPLETED = [420] as const;
