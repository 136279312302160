/* eslint-disable jsx-a11y/label-has-associated-control */
import { SubmitButton } from '@netspresso/components';
import { isEmpty, NotificationMessages, objectToFormData } from '@netspresso/shared';
import React, { useEffect, useState } from 'react';
import { SubmitErrorHandler, useFormState } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';
import { ModelErrorModal, Prompt } from '../../../../components';
import { LEVEL_SUCCESS, LEVEL_WARNING, Toast, useNotificationContext } from '../../../../components/Notifications';
import { useLoaderContext, useModalContext, useUploadModelFormContext } from '../../../../context';
import { FRAMEWORKS, NPModelUploadError, UploadableModelTypes } from '../../../../lib';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { UploadModelType } from '../../../../schemes';
import { ModelService } from '../../../../services';
import { useGTM } from '../../../../utils';
import { InputShape, ModelFramework, ModelSetting, ModelTaskMetric } from './components';

export const ModelUpload: React.FC = () => {
  const [, dispatchLoading] = useLoaderContext();
  const [, dispatchModal] = useModalContext();
  const { setPageToDataLayer } = useGTM();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const { handleSubmit, control } = useUploadModelFormContext();
  const { isDirty, errors } = useFormState({ control });

  const [modelFramework, setModelFramework] = useState<UploadableModelTypes>(FRAMEWORKS.ONNX);

  const navigate = useNavigate();

  useEffect(() => {
    setPageToDataLayer('Upload Model');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabled = () => {
    return !isDirty || !isEmpty(errors);
  };

  const onSubmit = (data: UploadModelType) => {
    showPrompt(data);

    return false;
  };

  const onError: SubmitErrorHandler<UploadModelType> = (uploadErrors) => {
    // eslint-disable-next-line no-console
    console.log(uploadErrors);
    showToast(
      <Toast
        content="Please review form fields."
        level={LEVEL_WARNING}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );
  };

  const converInputLayers = (data: UploadModelType) => {
    const inputLayers = data.input_layers;

    inputLayers.dimension = data.input_layers.dimension
      .toString()
      .replaceAll(' ', '')
      .split(',')
      .map((item) => parseInt(item, 10));

    return inputLayers;
  };

  const startUpload = async (data: UploadModelType) => {
    try {
      dispatchModal({ type: ModalActions.Hide });
      dispatchLoading({ type: LoaderActions.Show });

      const formData = objectToFormData(data);

      formData.set('input_layers', `[${JSON.stringify(converInputLayers(data))}]`);

      const res = await ModelService.createModel(formData);

      if (res.status === 200) {
        dispatchLoading({ type: LoaderActions.Hide });
        showToast(
          <Toast
            content="Models are successfully uploaded."
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
        navigate(`/models`);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      const error = err as NPModelUploadError;

      if (error.response && error.response.status === 400) {
        dispatchModal({
          type: ModalActions.Show,
          payload: (
            <ModelErrorModal
              errorTitle="Fail to upload a model"
              errorMessage={error.response?.data.detail.message}
              errorLog={error.response?.data.detail.data.error_log}
              modelId={error.response?.data.detail.data.model_id}
              onSubmit={onClosePrompt}
            />
          ),
        });
      }
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const onClosePrompt: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const showPrompt = (data: UploadModelType) => {
    dispatchModal({
      type: ModalActions.Show,
      payload: (
        <Prompt
          headerMessage={NotificationMessages.modelUploadHeader}
          onClose={onClosePrompt}
          onSubmit={() => startUpload(data)}
        />
      ),
    });
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full px-3">
        <h1 className="text-secondary-800 text-xl font-title font-bold pl-6 mb-4">Upload Model</h1>
        <section className="bg-white rounded-lg shadow mb-6 p-6">
          <div className="flex flex-col lg:flex-row">
            <form className="w-full" onSubmit={handleSubmit(onSubmit, onError)}>
              <h2 className="text-secondary font-semibold mb-4">Model info</h2>
              <ModelSetting />
              <ModelTaskMetric />
              <h2 className="text-secondary font-semibold mb-4">Model file</h2>
              <ModelFramework modelFramework={modelFramework} setModelFramework={setModelFramework} />
              <InputShape modelFramework={modelFramework} />
              <div className="flex justify-end border-t border-defaultGray-100 pt-6">
                <SubmitButton disabled={isDisabled()}>Start upload</SubmitButton>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};
