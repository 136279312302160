import React, { createContext, useContext, useReducer } from 'react';

import { ModelsAction, modelsReducer, ModelsState } from '../reducers';

type ModelsContextType = [ModelsState, React.Dispatch<ModelsAction>];

export const ModelsContext = createContext<ModelsContextType | null>(null);

export const ModelsContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [models, dispatch] = useReducer(modelsReducer, []);

  return <ModelsContext.Provider value={[models, dispatch]}>{children}</ModelsContext.Provider>;
};

export const useModelsContext = (): ModelsContextType => {
  const context = useContext(ModelsContext);

  if (!context) {
    throw new Error('useModelsContext must be used within a ModelsContextProvider');
  }

  return context;
};
