import { FIELD_NAME, FormErrorMessages, LOCAL_STORAGE, OBJECT_DETECTION, S3_STORAGE } from '@netspresso/shared';
import * as yup from 'yup';
import {
  AUTH,
  CERT_FILE,
  CLASS_NAME,
  DATASET_FORMAT,
  DATASET_NAME,
  DATASET_PATH,
  DATASET_YAML,
  DESCRIPTION,
  FILE_ID,
  NUM_IMAGES,
  NUM_INSTANCES,
  STORAGE_TYPE,
  SYSTEM_UUID,
  TASK,
  TEST_FILE,
  TRAIN_FILE,
  VAL_FILE,
  YAML_FILE,
} from '../constants';
import { DATASETS, DatasetFormatType, ProjectTaskType, StorageLocationType } from '../lib';

const checkIfFilesAreTooBig = (fileSize: number) => (file: File) => {
  let valid = true;

  const maxSize = fileSize * 1073741824;

  if (file) {
    const { size } = file;

    if (size > maxSize) {
      valid = false;
    }
  }

  return valid;
};

export const DatasetScheme = yup.object({
  [DATASET_NAME]: yup
    .string()
    .required(FormErrorMessages.isRequired)
    .min(2, FormErrorMessages.isNotMin(2))
    .max(20, FormErrorMessages.isNotMax(20))
    .matches(FIELD_NAME, FormErrorMessages.isWrongCharacters),
  [DESCRIPTION]: yup.string().max(100, FormErrorMessages.isNotMax(100)),
  [TRAIN_FILE]: yup
    .mixed<File>()
    .when(STORAGE_TYPE, { is: S3_STORAGE, then: (s) => s.required(FormErrorMessages.isRequired) })
    .when(AUTH, {
      is: (auth: number) => auth < 9,
      then: (t) =>
        t.test('is-big-file', FormErrorMessages.exceedFileSize(5), (file) => checkIfFilesAreTooBig(5)(file as File)),
    })
    .nullable(),
  [VAL_FILE]: yup
    .mixed<File>()
    .when(STORAGE_TYPE, {
      is: S3_STORAGE,
      then: (s) =>
        s.when(TEST_FILE, {
          is: null,
          then: (t) => t.required(FormErrorMessages.valorTrain),
        }),
    })
    .when(AUTH, {
      is: (auth: number) => auth < 9,
      then: (t) =>
        t.test('is-big-file', FormErrorMessages.exceedFileSize(1), (file) => checkIfFilesAreTooBig(1)(file as File)),
    })
    .nullable(),
  [TEST_FILE]: yup
    .mixed<File>()
    .when(STORAGE_TYPE, {
      is: S3_STORAGE,
      then: (s) =>
        s.when(VAL_FILE, {
          is: null,
          then: (t) => t.required(FormErrorMessages.valorTrain),
        }),
    })
    .when(AUTH, {
      is: (auth: number) => auth < 9,
      then: (s) =>
        s.test('is-big-file', FormErrorMessages.exceedFileSize(1), (file) => checkIfFilesAreTooBig(1)(file as File)),
    })
    .nullable(),
  [CERT_FILE]: yup.mixed<File>().required(FormErrorMessages.isRequired).nullable(),
  [YAML_FILE]: yup.mixed<File>().required(FormErrorMessages.isRequired).nullable(),
  [TASK]: yup.string<ProjectTaskType>().default(OBJECT_DETECTION),
  [NUM_IMAGES]: yup
    .object({
      val: yup.number().required(),
      test: yup.number().required(),
      train: yup.number().required(),
    })
    .nullable(),
  [NUM_INSTANCES]: yup.array().of(
    yup.object({
      class_name: yup.string().required(),
      val: yup.number().required(),
      test: yup.number().required(),
      train: yup.number().required(),
      total: yup.number().required(),
    })
  ),
  [CLASS_NAME]: yup.array().of(yup.string().required()),
  [DATASET_FORMAT]: yup.string<DatasetFormatType>(),
  [AUTH]: yup.number(),
  [FILE_ID]: yup.string(),
  [DATASET_YAML]: yup.string(),
  [STORAGE_TYPE]: yup.string<StorageLocationType>().default(S3_STORAGE),
  [DATASET_PATH]: yup.string().when(STORAGE_TYPE, {
    is: LOCAL_STORAGE,
    then: (s) => s.required(FormErrorMessages.isRequired),
  }),
  [SYSTEM_UUID]: yup.string().when(STORAGE_TYPE, {
    is: LOCAL_STORAGE,
    then: (s) => s.required(FormErrorMessages.isRequired),
  }),
});

export type DatasetType = yup.InferType<typeof DatasetScheme>;

export const DatasetsDefaultValues: DatasetType = {
  [DATASET_NAME]: '',
  [DESCRIPTION]: '',
  [TASK]: OBJECT_DETECTION,
  [DATASET_FORMAT]: DATASETS.YOLO,
  [TRAIN_FILE]: null,
  [VAL_FILE]: null,
  [TEST_FILE]: null,
  [CERT_FILE]: null,
  [YAML_FILE]: null,
  [NUM_IMAGES]: null,
  [NUM_INSTANCES]: [],
  [CLASS_NAME]: [],
  [AUTH]: 1,
  [FILE_ID]: '',
  [STORAGE_TYPE]: S3_STORAGE,
  [DATASET_PATH]: '',
  [SYSTEM_UUID]: '',
};
