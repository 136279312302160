import React, { createContext, useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import { UploadModelScheme, UploadModelsDefaultValues, UploadModelType } from '../schemes';

export const UploadModelFormContext = createContext<UseFormReturn<UploadModelType> | null>(null);

export const UploadModelFormContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const uploadModelForm = useForm<UploadModelType>({
    defaultValues: UploadModelsDefaultValues,
    resolver: yupResolver(UploadModelScheme),
  });

  return <UploadModelFormContext.Provider value={uploadModelForm}>{children}</UploadModelFormContext.Provider>;
};

export const useUploadModelFormContext = (): UseFormReturn<UploadModelType> => {
  const context = useContext(UploadModelFormContext);

  if (!context) {
    throw new Error('useUploadModelFormContext must be used within a UploadModelFormContextProvider');
  }

  return context;
};
