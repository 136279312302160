export const LR0 = 'lr0';
export const LRF = 'lrf';
export const SEED = 'seed';
export const MOMENTUM = 'momentum';
export const WEIGHT_DECAY = 'weight_decay';
export const WARMUP_STEPS = 'warmup_steps';
export const WARMUP_EPOCHS = 'warmup_epochs';
export const WARMUP_MOMENTUM = 'warmup_momentum';
export const WARMUP_BIAS_LR = 'warmup_bias_lr';
export const SCHD_POWER = 'schd_power';
export const REDUCE_ZERO_LABEL = 'reduce_zero_label';
export const BOX = 'box';
export const CLS = 'cls';
export const CLS_PW = 'cls_pw';
export const OBJ = 'obj';
export const OBJ_PW = 'obj_pw';
export const IOU_T = 'iou_t';
export const ANCHOR_T = 'anchor_t';
export const FL_GAMMA = 'fl_gamma';
export const HSV_H = 'hsv_h';
export const HSV_S = 'hsv_s';
export const HSV_V = 'hsv_v';
export const DEGREES = 'degrees';
export const TRANSLATE = 'translate';
export const SCALE = 'scale';
export const MAX_SCALE = 'max_scale';
export const MIN_SCALE = 'min_scale';
export const CROP_SIZE_H = 'crop_size_h';
export const CROP_SIZE_W = 'crop_size_w';
export const RESIZE_RATIO0 = 'resize_ratio0';
export const RESIZE_RATIOF = 'resize_ratiof';
export const RESIZE_ADD = 'resize_add';
export const SHEAR = 'shear';
export const PERSPECTIVE = 'perspective';
export const FLIPUD = 'flipud';
export const FLIPLR = 'fliplr';
export const MOSAIC = 'mosaic';
export const MIXUP = 'mixup';
export const COPY_PASTE = 'copy_paste';
export const MIXUP_ALPHA = 'mixup_alpha';
export const CUTMIX_ALPHA = 'cutmix_alpha';
export const MIXUP_SWITCH_PROB = 'mixup_switch_prob';
export const SLIDE_EVAL = 'slide_evaluation';
export const STRIDE = 'stride';
export const CROP_W = 'crop_w';
export const CROP_H = 'crop_h';

// export const SegPlaceholdersMap = {
//   [SEED]: '1 <= seed < inf',
//   [WEIGHT_DECAY]: '0 <= weight_decay <= 0.01',
//   [WARMUP_STEPS]: '100 <= warmup_steps <= 2000',
//   [RESIZE_RATIO0]: '0.5 <= resize_ratio0 <= resize_ratiof',
//   [RESIZE_RATIOF]: 'resize_ratio0 <= resize_ratiof < inf',
//   [FLIPLR]: '0 <= fliplr <= 1',
// };
