/* eslint-disable camelcase */
import { Select } from '@netspresso/components';
import { API_MODEL_V1, JSONValue, NotificationMessages, OBJECT_DETECTION, Partial } from '@netspresso/shared';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LEVEL_DANGER, Toast, useNotificationContext } from '../../../../../../components';
import { DATASET_UID, MODEL_DATASET_UID, MODEL_ID, PROJECT_TASK } from '../../../../../../constants';
import { useLoaderContext, useProjectsFormContext } from '../../../../../../context';
import { Dataset, Model, isProjectTaskType } from '../../../../../../lib';
import { LoaderActions, retrainableReducer } from '../../../../../../reducers';
import { DatasetService } from '../../../../../../services';
import { parseJsonWithNaN, parseTask, useFetch, useGTM } from '../../../../../../utils';
import { ProjectSetting } from '../../../../components';
import { useProjectHandler } from '../../../../hooks';

export const SelectModel: React.FC = () => {
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const [, dispatchLoading] = useLoaderContext();
  const { setPageToDataLayer } = useGTM();
  const { modelUid, datasetUid } = useParams();

  const { resetByTask } = useProjectHandler();
  const {
    projectsForm: { setValue, watch },
  } = useProjectsFormContext();

  const { data: modelData, loading: isModelLoading } = useFetch<JSONValue>(API_MODEL_V1);
  const [datasetData, setDataset] = useState<{ data: Dataset | null; isFetching: boolean }>({
    data: null,
    isFetching: false,
  });
  const [filteredModels, setFilteredModels] = useState<Partial<Model>[]>([]);

  const watched = watch();

  const fetchDataset = async (datasetId: string) => {
    try {
      setDataset((prev) => ({ ...prev, isFetching: true }));
      const datasetInfo = await DatasetService.getDatasetById(datasetId);
      const { dataset_task } = datasetInfo.data;

      const hasTask = isProjectTaskType(dataset_task);

      if (!hasTask) {
        setValue(PROJECT_TASK, OBJECT_DETECTION);
        setValue(DATASET_UID, '');

        return;
      }

      setValue(DATASET_UID, datasetId);
      setValue(MODEL_DATASET_UID, datasetId);
      resetByTask(dataset_task);
      setDataset({ data: datasetInfo.data, isFetching: false });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      showToast(
        <Toast
          content="Fetching dataset info failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
      setDataset((prev) => ({ ...prev, isFetching: false }));
    }
  };

  useEffect(() => {
    setPageToDataLayer('Retraining | Select Model');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (datasetUid) {
      fetchDataset(datasetUid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetUid]);

  useEffect(() => {
    const isLoading = isModelLoading || datasetData.isFetching;

    dispatchLoading({ type: isLoading ? LoaderActions.Show : LoaderActions.Hide });
  }, [dispatchLoading, isModelLoading, datasetData.isFetching]);

  useEffect(() => {
    if (!modelData && !datasetData.data) {
      return;
    }

    if (modelData) {
      const retrainableModels = parseJsonWithNaN<Model[]>(modelData).reduce(retrainableReducer, []);

      if (modelUid) {
        const result = retrainableModels.filter((model) => model.model_id === modelUid);

        setValue(MODEL_ID, modelUid || '');
        setFilteredModels([{ model_id: '', display_name: 'Select model' }, ...result]);

        return;
      }

      if (datasetData.data && datasetUid) {
        const { dataset_task } = datasetData.data;
        const result = retrainableModels.filter((model) => model.task === dataset_task);
        let defaultLabel = 'Select model';

        if (result.length === 0) {
          defaultLabel = NotificationMessages.noOptionToSelectForTask(parseTask(dataset_task));

          showToast(
            <Toast
              content={NotificationMessages.noOptionToSelectForTask(parseTask(dataset_task))}
              level={LEVEL_DANGER}
              onClick={onClickToastHandler}
              hideToast={hideToast}
            />
          );
        }

        setFilteredModels([{ model_id: '', display_name: defaultLabel }, ...result]);

        return;
      }

      setFilteredModels([{ model_id: '', display_name: 'Select model' }, ...retrainableModels]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelData, datasetData.data]);

  const onSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    const selectedModel = filteredModels.filter((item) => item.model_id === value) as Model[];

    setValue(MODEL_ID, value);

    if (selectedModel.length > 0) {
      setValue(PROJECT_TASK, selectedModel[0].task);

      resetByTask(selectedModel[0].task);
    }
  };

  return (
    <>
      <ProjectSetting />
      <h2 className="text-secondary font-semibold mb-4">Select model</h2>
      <section className="w-1/2 mb-8">
        <Select
          className="font-semibold mb-2"
          label="Base model *"
          valueProp={MODEL_ID}
          labelProp="display_name"
          value={watched.model_id || ''}
          options={filteredModels}
          onChange={onSelectChange}
        />
      </section>
    </>
  );
};
