import React from 'react';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '@netspresso/shared';

type TextProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<C, { className: string }>;

type TextComponent = <C extends React.ElementType = 'span'>(props: TextProps<C>) => React.ReactElement | null;

export const Text: TextComponent = React.forwardRef(
  <C extends React.ElementType = 'span'>({ as, className, children }: TextProps<C>, ref?: PolymorphicRef<C>) => {
    const Component = as || 'span';

    return (
      <Component className={className} ref={ref}>
        {children}
      </Component>
    );
  }
);
