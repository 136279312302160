import { ADVANCED_COMPRESSION } from '@netspresso/shared';

import React from 'react';
import { AdvancedMethod, AutomaticMethod } from './components';
import { CompressionMode } from '../../../../lib';

const displayExplain = (method: CompressionMode) => {
  if (method === ADVANCED_COMPRESSION) {
    return <AdvancedMethod />;
  }

  return <AutomaticMethod />;
};

type CompressionModeCardProps = {
  method: CompressionMode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
};

export const CompressionModeCard: React.FC<CompressionModeCardProps> = ({ method, onClick, className = '' }) => {
  return (
    <section className={`w-[326px] mb-1 ${className}`}>
      <div className="bg-white rounded-lg shadow px-6 py-8 flex flex-col items-center">
        {displayExplain(method)}
        <button
          type="button"
          className="text-sm text-white disabled:text-defaultGray disabled:font-bold px-7 py-3 rounded-[23px] disabled:bg-white bg-secondary hover:bg-secondary-activebg-secondary hover:bg-secondary-active"
          onClick={onClick}
        >
          Compress
        </button>
      </div>
    </section>
  );
};
