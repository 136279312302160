import React from 'react';

type TableDataProps = {
  dataTestId?: string;
} & React.PropsWithChildren;

export const TableData: React.FC<TableDataProps> = ({ children, dataTestId = 'model-related-project-info' }) => (
  <td data-testid={dataTestId} className="text-sm text-darkGray px-4">
    {children}
  </td>
);
