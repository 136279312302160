import AutomaticCompressionIcon from '@netspresso/components/assets/icons/auto_compression.svg';

export const AutomaticMethod: React.FC = () => {
  return (
    <>
      <div className="w-32 h-32 pt-2 pb-1 rounded-[64px] border-2 border-secondary flex flex-col items-center">
        <AutomaticCompressionIcon />
        <h1 className="text-secondary text-center text-sm font-medium leading-4 mt-1">
          Automatic
          <br />
          Compression
        </h1>
      </div>
      <ul className="list-disc text-secondary mt-4 mb-[120px] text-m">
        <li>One-click compression</li>
        <li>May not be the best results</li>
      </ul>
    </>
  );
};
