import { InputTextLabel, TextInput } from '@netspresso/components';
import React, { useState } from 'react';
import { useFormState } from 'react-hook-form';
import { DIMENSION, INPUT_LAYERS } from '../../../../../../../../constants';
import { useUploadModelFormContext } from '../../../../../../../../context';

export const InputDimension: React.FC = () => {
  const { setValue, control } = useUploadModelFormContext();
  const { errors } = useFormState({ control });

  const [inputShapeDimension, setInputShapeDimension] = useState('');

  const onInputShapeDimensionChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value.toString();

    setInputShapeDimension(value);
    setValue(`${INPUT_LAYERS}.${DIMENSION}`, value, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <div className="w-1/8">
      <InputTextLabel htmlFor={DIMENSION}>Dimension</InputTextLabel>
      <TextInput
        id={DIMENSION}
        error={errors[INPUT_LAYERS] && errors[INPUT_LAYERS][DIMENSION] ? errors[INPUT_LAYERS][DIMENSION].message : ''}
        value={inputShapeDimension}
        placeholder="Input numbers with ,"
        width="w-full"
        onChange={onInputShapeDimensionChange}
      />
    </div>
  );
};
