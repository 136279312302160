import React from 'react';

import PreviousIcon from '@netspresso/components/assets/icons/navigate_before.svg';
import NextIcon from '@netspresso/components/assets/icons/navigate_next.svg';

interface IPagination {
  total: number;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
}

export const Pagination: React.FC<IPagination> = ({ total, pageNum, setPageNum }) => {
  const onClickPagination =
    (page: number): React.MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      event.stopPropagation();
      setPageNum(page);
    };

  const renderPagination = (_item: unknown, i: number) => {
    return (
      <button
        key={`pagination_${i}`}
        type="button"
        className={`w-6 text-center ${
          i === pageNum ? 'pointer-events-none text-bgblue-active font-bold' : 'cursor-pointer text-darkGray'
        }`}
        onClick={onClickPagination(i)}
      >
        {i + 1}
      </button>
    );
  };

  if (total < 2) {
    return null;
  }

  return (
    <section className="flex justify-end mb-6 lg:mb-0">
      <button
        type="button"
        className={`${pageNum < 1 ? 'pointer-events-none' : 'cursor-pointer'}`}
        onClick={onClickPagination(pageNum - 1)}
      >
        <PreviousIcon className={`fill-${pageNum < 1 ? 'disabledGray' : 'darkGray'}`} />
      </button>
      <div className="flex">{Array(total).fill('').map(renderPagination)}</div>
      <button
        type="button"
        className={`${pageNum + 1 >= total ? 'pointer-events-none' : 'cursor-pointer'}`}
        onClick={onClickPagination(pageNum + 1)}
      >
        <NextIcon className={`fill-${pageNum + 1 >= total ? 'disabledGray' : 'darkGray'}`} />
      </button>
    </section>
  );
};
