import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton, TableData } from '@netspresso/components';
import {
  apiClient,
  API_MODEL_V1,
  COMBINED_COMPRESSION,
  featureFlags,
  parseDate,
  TasksMetricMap,
} from '@netspresso/shared';
import { LEVEL_DANGER, LEVEL_SUCCESS, Toast, useNotificationContext } from '../../../../components/Notifications';
import { useLoaderContext, useModalContext } from '../../../../context';
import {
  Frameworks,
  Model,
  ModelFormats,
  ModelStatus,
  OriginFromType,
  PerformanceDataType,
  TasksWithMetric,
} from '../../../../lib';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { ModelService } from '../../../../services';
import { download, getMaxMetric, parseDownloadModel, parseTask } from '../../../../utils';
import { ChildModel } from '../ChildModel';

const filterModel = (item: Model[]) => {
  return item && item.filter((model) => model.model_status_data.is_visible);
};

type ParentProjectProps = {
  modelId: string;
  datasetId: string;
  createdAt?: string;
  task: TasksWithMetric;
  originFrom: OriginFromType;
  projectName: string;
  modelStatusData: ModelStatus;
  performanceData: Record<string, PerformanceDataType>;
  modelFormat: ModelFormats;
  framework: Frameworks;
  models: Model[];
  isConverting: boolean;
  onDelete: (onSubmitPrompt: React.MouseEventHandler<HTMLButtonElement>) => React.MouseEventHandler<HTMLButtonElement>;
};

export const ParentProject: React.FC<ParentProjectProps> = ({
  modelId,
  datasetId,
  createdAt,
  task,
  originFrom,
  projectName,
  modelStatusData,
  performanceData,
  framework,
  modelFormat,
  onDelete,
  models = [],
  isConverting = false,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [filteredModel, setFilteredModel] = useState<Model[]>([]);
  const [, dispatchModal] = useModalContext();
  const [, dispatchLoading] = useLoaderContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const [showDownloadOpition, setShowDownloadOption] = useState(false);
  const [customProperty, setCustomProperty] = useState('');
  const [customValue, setCustomValue] = useState('');

  useEffect(() => {
    if (models && models.length > 0) {
      setFilteredModel(filterModel(models));
    }
  }, [models]);

  useEffect(() => {
    if (originFrom === 'custom' && Object.prototype.hasOwnProperty.call(performanceData, 'custom')) {
      for (const propertyName in performanceData.custom) {
        if (
          performanceData.custom[propertyName] &&
          !isNaN(parseFloat(performanceData.custom[propertyName].toString()))
        ) {
          setCustomProperty(propertyName);
          setCustomValue(performanceData.custom[propertyName].toString());
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performanceData]);

  const onClickOpen = () => {
    setIsOpen(!isOpen);
  };

  const onClickCompress: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    featureFlags(COMBINED_COMPRESSION)
      ? navigate(`/compression/new/${modelId}`)
      : (window.location.href = `${process.env.REACT_APP_COMPRESSOR_URL}/compression?model=${modelId}`);
  };

  const onClickConvert: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    navigate(`/convert/model/${modelId}`);
  };

  const onClickRetrain: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    navigate(`/projects/retraining/${datasetId ? `dataset/${datasetId}/` : ''}model/${modelId}`);
  };

  const renderModels = (item: Model) => {
    const latency =
      item.measure_data.length && item.measure_data[0].measure_metric ? item.measure_data[0].measure_metric.latency : 0;

    return (
      <ChildModel
        key={item.model_id}
        latency={latency}
        modelId={item.model_id}
        datasetId={item.dataset_id}
        projectId={item.project_id}
        originFrom={item.origin_from}
        createdAt={item.created_time}
        modelName={item.display_name}
        modelFormat={item.model_format}
        framework={item.framework}
        modelStatusData={item.model_status_data}
        performanceData={item.performance_data}
        targetDevice={item.model_detail_data.target_device}
        task={item.task}
        onDelete={onDelete}
      />
    );
  };

  const onSubmitPrompt: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();

    try {
      dispatchModal({ type: ModalActions.Hide });
      dispatchLoading({ type: LoaderActions.Show });
      const res = await apiClient.delete(`${API_MODEL_V1}/${modelId}`);

      if (res.status === 200) {
        showToast(
          <Toast
            content="The model is successfully deleted."
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
        navigate('/models');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      showToast(
        <Toast
          content="Requesting to delete failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const onClickDownload: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    const res = await ModelService.downloadModel(modelId);

    if (res.status === 200) {
      const downloadUrl = res.data.s3_urls;

      for (const key in downloadUrl) {
        if (downloadUrl[key]) {
          download(downloadUrl[key]);
        }
      }
    }
  };

  const onClickPackage: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    navigate(`/package/model/${modelId}`);
  };

  const onMouseEnterDownload = () => {
    setShowDownloadOption(true);
  };

  const onMouseLeaveDownload = () => {
    setShowDownloadOption(false);
  };

  const parseMetricValue = (
    origin: OriginFromType,
    performance: Record<string, PerformanceDataType>,
    datasetUid: string
  ): string => {
    if (origin === 'custom') {
      return customValue;
    }

    if (Object.prototype.hasOwnProperty.call(performance, datasetUid)) {
      return getMaxMetric(performance[datasetUid][TasksMetricMap[task]]);
    }

    return '';
  };

  return (
    <>
      <tr className="border-b border-lineGray h-20">
        <td className="pl-6 pr-2">
          <IconButton outline={!isOpen} onClick={onClickOpen} isDisabled={!(filteredModel.length > 0)}>
            {isOpen ? 'arrow_drop_down' : 'arrow_right'}
          </IconButton>
        </td>
        <td className="px-2">
          {originFrom === 'custom' ? (
            <span className="inline-block mt-2 mb-1 px-2.5 py-1 rounded-full font-medium text-xxs text-notaNavy-800 bg-gray-300">
              Custom
            </span>
          ) : (
            <span className="inline-block mt-2 mb-1 px-2.5 py-1 rounded-full font-medium text-xxs text-notaNavy-800 bg-notaNavy-100">
              NetsPresso
            </span>
          )}
          <h6 className="font-title font-bold text-notaNavy-900 tracking-tight">{projectName}</h6>
          <span className="inline-block text-xs text-defaultGray font-normal pb-4">
            Created: {parseDate(createdAt)}
          </span>
        </td>
        <td className="px-2">
          <span className="px-1.5 py-0.5 text-sm text-left">{parseTask(task)}</span>
        </td>
        <TableData />
        <TableData>
          <strong className="text-defaultGray text-xxs tracking-tight">
            {originFrom === 'custom' ? customProperty : TasksMetricMap[task]}
            <br />
          </strong>
          {parseMetricValue(originFrom, performanceData, datasetId)}
        </TableData>
        <TableData />
        <td className="px-5">
          <div
            className="flex flex-row items-center justify-between relative pr-[34px]"
            onMouseLeave={onMouseLeaveDownload}
          >
            <div className="flex flex-row justify-between space-x-1">
              <IconButton
                outline
                tooltipMsg="Compress"
                onClick={onClickCompress}
                isDisabled={!modelStatusData.is_compressible}
              >
                compress
              </IconButton>
              <IconButton
                outline
                tooltipMsg="Convert"
                onClick={onClickConvert}
                isDisabled={!modelStatusData.is_convertible || isConverting}
              >
                autorenew
              </IconButton>
              <IconButton
                outline
                tooltipMsg="Retrain"
                onClick={onClickRetrain}
                isDisabled={!modelStatusData.is_retrainable}
              >
                <span className="material-symbols-outlined hover:text-white">hub</span>
              </IconButton>
              <IconButton
                data-testid="model-download"
                onMouseEnter={onMouseEnterDownload}
                isDisabled={!modelStatusData.is_downloadable}
              >
                download
              </IconButton>
              <IconButton outline tooltipMsg="Delete" onClick={onDelete(onSubmitPrompt)}>
                <span className="material-symbols-outlined hover:text-white disabled:text-disabledGray">delete</span>
              </IconButton>
            </div>
            {showDownloadOpition && (
              <ul className="absolute bg-white text-sm top-[30px] right-[30px] w-[224px] z-20 rounded-lg shadow">
                <li>
                  <button
                    type="button"
                    className="px-4 py-2 text-left text-gray-700 w-full disabled:text-lineGray hover:text-secondary"
                    onClick={onClickDownload}
                  >
                    {parseDownloadModel(framework, modelFormat)}
                  </button>
                </li>
                {modelStatusData.is_packageable && (
                  <>
                    <li>
                      <button
                        type="button"
                        className="px-4 py-2 text-left text-secondary w-full disabled:text-lineGray hover:text-secondary border-t border-lineGray"
                        onClick={onClickPackage}
                      >
                        New package
                      </button>
                    </li>
                    <li>
                      <div className="px-4 py-2 text-left text-lineGray w-full select-none">See existing packages</div>
                    </li>
                  </>
                )}
              </ul>
            )}
          </div>
        </td>
      </tr>
      {isOpen ? <>{models && models.length > 0 ? filteredModel.map(renderModels) : null}</> : null}
    </>
  );
};
