import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import LogoutIcon from '@netspresso/components/assets/icons/logout.svg';
import NetspressoLogo from '@netspresso/components/assets/logos/netspresso-w.svg';
import NPLogo from '@netspresso/components/assets/logos/np.svg';

import { CREDIT_LINK } from '@netspresso/shared';
import { useAuthContext, useSideNavContext } from '../../../context';
import { SideNavActions } from '../../../reducers';
import { BreadCrumbs } from '../../BreadCrumbs';

export const Header: React.FC = () => {
  const { user, logout } = useAuthContext();
  const [{ isOpen, isExpand }, dispatchSideNav] = useSideNavContext();
  const { pathname } = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [credits, setCredits] = useState(0);
  const [useName, setUserName] = useState('');

  const [showDropdown, setShowDrapdown] = useState(false);

  useEffect(() => {
    if (user) {
      setCredits(user.credit);
      setUserName(user.username);
    }
  }, [user]);

  const onLogoutClick = () => {
    logout();
  };

  const onUserIdMouseEnter = () => {
    setShowDrapdown(true);
  };

  const onUserIdMouseLeave = () => {
    setShowDrapdown(false);
  };

  const headerLink = (path: string) => {
    if (path.includes('projects')) {
      return <Link to="/projects">Projects</Link>;
    }

    if (path.includes('models')) {
      return <Link to="/models">Models</Link>;
    }

    if (path.includes('datasets')) {
      return <Link to="/datasets">Datasets</Link>;
    }

    if (path.includes('convert')) {
      return <Link to="/convert">Convert</Link>;
    }

    if (path.includes('package')) {
      return <Link to="/package">Package</Link>;
    }

    if (path.includes('compression')) {
      return <Link to="/compression">Compress</Link>;
    }

    return 'NetsPresso';
  };

  const onClickHamburger: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (isMobile) {
      dispatchSideNav({ type: isOpen ? SideNavActions.Hide : SideNavActions.Show, state: { isOpen, isExpand } });
    } else {
      dispatchSideNav({ type: isExpand ? SideNavActions.Shrink : SideNavActions.Expand, state: { isOpen, isExpand } });
    }
  };

  const creditWidthStyle = (charNum = 0) => ({ width: `${charNum * 8 + 80}px` });

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1024);
  };

  useEffect(() => {
    if (window) {
      setIsMobile(window.innerWidth < 1024);
      window.addEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <header className={`np-header ${isExpand ? 'open' : 'close'}`}>
        <div className="bg-neutralBlack-500 xl:pointer-events-auto">
          <Link to="/" className="block w-full h-full flex items-center justify-center py-4">
            <span className="hidden lg:inline-block">{isExpand ? <NetspressoLogo /> : <NPLogo />}</span>
            <NPLogo className="inline-block lg:hidden" />
          </Link>
        </div>
        <div className="w-full flex items-center px-6 py-3.5">
          <button type="button" className="items-center md:block mr-3" onClick={onClickHamburger}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <title>Mobile menu</title>
              <path d="M4 11H16V13H4V11ZM4 6H20V8H4V6ZM4 18H11.235V16H4V18Z" fill="#252A39" />
            </svg>
          </button>
          <h1 className="text-xl font-semibold font-title text-darkGray tracking-tight">{headerLink(pathname)}</h1>
          <BreadCrumbs pathname={pathname} />
          <div className="relative flex flex-row justify-end items-center">
            <a
              href={CREDIT_LINK}
              className="flex justify-center text-secondary text-sm font-title font-bold py-1 px-3 rounded-2xl bg-secondary-50 select-none cursor-pointer"
              target="_blank"
              rel="noreferrer"
              style={creditWidthStyle(credits.toString().length)}
            >
              Credit : {credits}
            </a>
            <div className="ml-4" onMouseEnter={onUserIdMouseEnter} onMouseLeave={onUserIdMouseLeave}>
              <button type="button" className="flex flex-row items-center h-7">
                <span className="text-notaGray text-sm mr-2">{useName}</span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.825 6.66663L10 10.4833L6.175 6.66663L5 7.84163L10 12.8416L15 7.84163L13.825 6.66663Z"
                    fill="#9A9A9A"
                  />
                </svg>
              </button>
              <div
                className={`absolute right-3 z-30 ${showDropdown ? '' : 'hidden'}`}
                onMouseLeave={onUserIdMouseLeave}
              >
                <div className="bg-white rounded-lg drop-shadow-lg">
                  <a
                    href={`${process.env.REACT_APP_PORTAL_URL}/account/settings`}
                    className="block cursor-pointer px-5 py-2.5 hover:text-neutralBlack-500"
                  >
                    Account settings
                  </a>
                  <a
                    href={`${process.env.REACT_APP_PORTAL_URL}/account/resources`}
                    className="block cursor-pointer px-5 py-2.5 border-b border-primary-50 hover:text-neutralBlack-500"
                  >
                    Resources
                  </a>
                  <button
                    type="button"
                    className="flex flex-row items-center cursor-pointer px-5 py-2.5 hover:text-neutralBlack-500"
                    onClick={onLogoutClick}
                  >
                    <span className="inline-flex justify-center items-center mr-2">
                      <LogoutIcon />
                    </span>
                    <span>Sign out</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
