import { ObjectValues } from '@netspresso/shared';

export const CURRENT_STEPS = {
  FIRST: 'first_step',
  SECOND: 'second_step',
  THIRD: 'third_step',
  COMPLETED: 'completed',
} as const;

export type CurrentSteps = ObjectValues<typeof CURRENT_STEPS>;

export const OUTPUT_DATA_TYPES = {
  FP32: 'fp32',
  FP16: 'fp16',
  INT8: 'int8',
  INT4: 'int4',
} as const;

export type OutputDataTypes = ObjectValues<typeof OUTPUT_DATA_TYPES>;
