/* eslint-disable camelcase */
import {
  HeaderText,
  LabeledPerformanceDisplay,
  LabeledValueDisplay,
  Panel,
  PanelContent,
  PanelHeader,
  ResponsiveContainer,
} from '@netspresso/components';
import { formatizeLatency } from '@netspresso/shared';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoaderContext, useModalContext } from '../../../../context';
import {
  Frameworks,
  FRAMEWORKS,
  isDevicesWithCpuInfo,
  isDevicesWithGpuInfo,
  MeasureDataType,
  Model,
  Project,
  TASKS,
  TasksWithMetric,
} from '../../../../lib';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { ModelService, ProjectService } from '../../../../services';
import {
  getMaxMetric,
  isImageClassification,
  isObjectDetection,
  isSemanticSegmentation,
  parseCPU,
  parseDevices,
  parseGPU,
} from '../../../../utils';
import { ProjectCard } from '../../../Projects';
import { EditModelDialog } from './components/EditModelDialog/EditModelDialog';

export const ModelDetails: React.FC = () => {
  const navigate = useNavigate();
  const { modelUid, projectUid } = useParams();
  const [, dispatchLoading] = useLoaderContext();
  const [targetDevice, setTargetDevice] = useState('');
  const [outputModel, setOutputModel] = useState<Frameworks>();
  const [modelName, setModelName] = useState('');
  const [modelDescription, setModelDescription] = useState('');
  const [outputDataType, setOutputDataType] = useState('');
  const [batchSize, setBatchSize] = useState(0);
  const [targetLatency, setTargetLatency] = useState('');
  const [accuracy5, setAccuracy5] = useState('0');
  const [accuracy, setAccuracy] = useState('0');
  const [accuracyPrecision, setAccuracyPrecision] = useState('0');
  const [accuracyRecall, setAccuracyRecall] = useState('0');
  const [accuracyCls, setAccuracyCls] = useState('0');
  const [accuracyMIoU, setAccuracyMIoU] = useState('0');
  const [accuracyPix, setAccuracyPix] = useState('0');
  const [accuracyF1, setAccuracyF1] = useState('0');
  const [modelSize, setModelSize] = useState(0);
  const [footprintGPU, setFootprintGPU] = useState(0);
  const [footprintCPU, setFootprintCPU] = useState(0);
  const [powerConsuption, setPowerConsumption] = useState(0);
  const [ramSize, setRamSize] = useState(0);
  const [cpu, setCPU] = useState('');
  const [gpu, setGPU] = useState('');
  const [pythonVersion, setPythonVersion] = useState('');
  const [numpyVersion, setNumpyVersion] = useState('');
  const [openvinoVersion, setOpenVinoVersion] = useState('');
  const [tensorrtVersion, setTensorrtVersion] = useState('');
  const [jetpackVersion, setJetpackVersion] = useState('');
  const [tfliteVersion, setTfliteVersion] = useState('');
  const [measureData, setMeasureData] = useState<MeasureDataType>();
  const [projectData, setProjectData] = useState<{ data: Project | null; isFetching: boolean }>({
    data: null,
    isFetching: false,
  });
  const [isModelLoading, setIsModelLoading] = useState(false);
  const [modelData, setModelData] = useState<Model>();
  const [, dispatchModal] = useModalContext();
  const [modelTask, setModelTask] = useState<TasksWithMetric>();

  const fetchProject = async (projectId: string) => {
    try {
      setProjectData({ data: projectData.data, isFetching: true });

      const res = await ProjectService.getProjectById(projectId);

      setProjectData({ data: { ...res.data }, isFetching: false });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      const error = err as AxiosError;

      if (error.response?.status === 403 || error.response?.status === 500) {
        navigate('/forbidden');
      }
      setProjectData({ data: projectData.data, isFetching: false });
    }
  };

  const fetchModel = async (modelId: string) => {
    try {
      const res = await ModelService.getModel(modelId);

      if (res.status === 200) {
        setModelData(res.data);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      const error = err as AxiosError;

      if (error.response?.status === 403 || error.response?.status === 500) {
        navigate('/forbidden');
      }

      if (error.response?.status === 404) {
        navigate('/not_found', { replace: true });
      }
    } finally {
      setIsModelLoading(false);
    }
  };

  const onEditComplete = () => {
    if (modelUid) {
      setIsModelLoading(true);
      fetchModel(modelUid);
    }
  };

  const isTao = (framework?: Frameworks) => framework && framework.indexOf('tao') > -1;

  const renderProject = (item: Project) => {
    return (
      <ProjectCard
        key={item.project_id}
        projectUid={item.project_id}
        projectName={item.project_name}
        projectType={item.project_type}
        projectTask={item.task}
        status={item.status}
        createdAt={item.created_at}
        updatedAt={item.updated_at}
        datasetName={item.dataset_name}
        projectDevice={parseDevices(item.device)}
        targetLatency={item.target_latency?.toString() || ''}
      />
    );
  };

  const onEditModel: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({
      type: ModalActions.Show,
      payload: (
        <EditModelDialog
          dialogName={modelName}
          dialogMemo={modelDescription}
          modelId={modelUid || ''}
          onComplete={onEditComplete}
        />
      ),
    });
  };

  useEffect(() => {
    dispatchLoading({
      type: isModelLoading || projectData.isFetching ? LoaderActions.Show : LoaderActions.Hide,
    });
  }, [isModelLoading, projectData.isFetching, dispatchLoading]);

  useEffect(() => {
    if (projectUid) {
      fetchProject(projectUid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUid]);

  useEffect(() => {
    if (modelUid) {
      setIsModelLoading(true);
      fetchModel(modelUid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelUid]);

  useEffect(() => {
    if (modelData) {
      const { display_name, model_format, measure_data, description, framework, model_detail_data } = modelData;

      setModelName(display_name);
      setModelDescription(description);
      setTargetDevice(parseDevices(model_detail_data.target_device));

      if (model_detail_data.dtype) {
        setOutputDataType(model_detail_data.dtype);
      }

      if (model_detail_data.input_size) {
        setBatchSize(model_detail_data.input_size.batch);
      } else {
        setBatchSize(model_detail_data.input_layers[0].batch);
      }

      setOutputModel(framework);

      if (model_format !== 'graphmodule_pt' && measure_data.length > 0) {
        const fetchedMeasureData = measure_data[0];
        const measureMetric = fetchedMeasureData.measure_metric;

        if (measureMetric) {
          setTargetLatency((measureMetric.latency * 1000).toFixed(2));

          setFootprintGPU(measureMetric.memory_footprint_mb_gpu || 0);
          setFootprintCPU(measureMetric.memory_footprint_mb_cpu || 0);
          setPowerConsumption(measureMetric.power_consumption || 0);
          setMeasureData(fetchedMeasureData);
        }
      }

      const { performance_data, dataset_id, task } = modelData;

      setModelTask(task);

      const performanceData = performance_data[dataset_id];

      if (performanceData) {
        if (isObjectDetection(task)) {
          setAccuracy5(getMaxMetric(performanceData['metrics/mAP_0.5'] || 0));
          setAccuracy(getMaxMetric(performanceData['metrics/mAP_0.5:0.95'] || 0));
          setAccuracyPrecision(getMaxMetric(performanceData['metrics/precision'] || 0));
          setAccuracyRecall(getMaxMetric(performanceData['metrics/recall'] || 0));
        } else if (isImageClassification(task)) {
          setAccuracyCls(getMaxMetric(performanceData.valid_accuracy));
        } else if (isSemanticSegmentation(task) && isTao(outputModel)) {
          setAccuracyMIoU(formatizeLatency(performanceData['Mean IOU'] || 0));
          setAccuracyF1(formatizeLatency(performanceData['F1 score'] || 0));
          setAccuracyPrecision(formatizeLatency(performanceData['Average precision'] || 0));
          setAccuracyRecall(formatizeLatency(performanceData['Average recall'] || 0));
        } else {
          setAccuracyMIoU(getMaxMetric(performanceData['valid_miou %']));
          setAccuracyPix(getMaxMetric(performanceData['valid_pixAcc %']));
        }
      }

      if (TASKS.SEMANTIC_SEGMENTATION && isTao(outputModel)) {
        const modelSizeFromData = performanceData['model size'];

        if (!Array.isArray(modelSizeFromData)) {
          setModelSize(parseFloat(`${modelSizeFromData}`));
        }
      } else {
        setModelSize(model_detail_data.model_size);
      }
    }
  }, [modelData, outputModel]);

  useEffect(() => {
    if (outputModel && measureData) {
      const deviceSpec = measureData.device_spec;
      const device = deviceSpec.device_name;

      setTargetDevice(parseDevices(device));

      if (isDevicesWithCpuInfo(device)) {
        setCPU(parseCPU(device) || 'N/A');
      }

      if (isDevicesWithGpuInfo(device)) {
        setGPU(parseGPU(device) || 'N/A');
      }

      const versions = deviceSpec.installed_software_versions;

      if (versions.numpy) {
        setNumpyVersion(versions.numpy[0]);
      }

      if (versions.python) {
        setPythonVersion(versions.python);
      }

      if (versions['openvino-runtime']) {
        setOpenVinoVersion(versions['openvino-runtime'][0]);
      }

      if (versions.tensorrt) {
        setTensorrtVersion(versions.tensorrt[0]);
      }

      if (versions.jetpack) {
        setJetpackVersion(versions.jetpack[0]);
      }

      if (versions.tflite) {
        setTfliteVersion(versions.tflite[0]);
      }
      setRamSize(Math.round((deviceSpec.ram_size / 1024) * 100) / 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputModel, measureData]);

  return (
    <>
      <div className="w-full px-3">
        <Panel className="relative">
          <PanelHeader className="pl-6 mb-4">{modelName}</PanelHeader>
          <button
            type="button"
            className="absolute top-0 right-6 text-m text-darkGray hover:text-secondary disabled:text-lineGray pt-1"
            onClick={onEditModel}
          >
            <span className="material-icons text-base">edit</span>
          </button>
          {modelDescription ? <p className="text-m text-darkGray font-normal pl-6 mb-4">{modelDescription}</p> : null}
          <section className="bg-white rounded-lg shadow mb-6 p-6">
            <PanelContent className="flex-col">
              <section className="w-full">
                <HeaderText className="mb-4">Target device</HeaderText>
                <div className="flex flex-row">
                  <LabeledValueDisplay label="Target device" className="w-1/4">
                    {targetDevice}
                  </LabeledValueDisplay>
                  <LabeledValueDisplay label="Output format" className="w-1/4">
                    {projectData ? (
                      outputModel === FRAMEWORKS.TENSORFLOW_LITE ? (
                        <>{`TFLite ${tfliteVersion}`}</>
                      ) : outputModel === FRAMEWORKS.TENSORRT ? (
                        <>
                          {`JetPack ${jetpackVersion}`}
                          <br />
                          {`TensorRT ${tensorrtVersion}`}
                        </>
                      ) : isTao(outputModel) ? (
                        'TAO Toolkit 4.0'
                      ) : (
                        openvinoVersion && <>{`OpenVINO ${openvinoVersion}`}</>
                      )
                    ) : (
                      'N/A'
                    )}
                  </LabeledValueDisplay>
                  <LabeledValueDisplay label="Data type" className="w-1/4">
                    {outputDataType}
                  </LabeledValueDisplay>
                  <LabeledValueDisplay label="Batch size" className="w-1/4">
                    {batchSize || 'N/A'}
                  </LabeledValueDisplay>
                </div>
              </section>
              {modelTask && (
                <section className="pt-8">
                  <HeaderText>Model performance</HeaderText>
                  {isObjectDetection(modelTask) && (
                    <section className="flex flex-row mb-4 pt-4 pl-[170px] relative">
                      <div className="absolute left-0 top-10 w-[170px]">Original</div>
                      <LabeledPerformanceDisplay className="mb-2" value={accuracy5}>
                        Accuracy
                        <br />
                        (mAP 0.5)
                      </LabeledPerformanceDisplay>
                      <LabeledPerformanceDisplay className="mb-2" value={accuracy}>
                        Accuracy
                        <br />
                        (mAP 0.5:0.95)
                      </LabeledPerformanceDisplay>
                      <LabeledPerformanceDisplay className="mb-2" value={accuracyPrecision}>
                        Accuracy
                        <br />
                        (Precision)
                      </LabeledPerformanceDisplay>
                      <LabeledPerformanceDisplay className="mb-2" isLast value={accuracyRecall}>
                        Accuracy
                        <br />
                        (Recall)
                      </LabeledPerformanceDisplay>
                    </section>
                  )}
                  {isImageClassification(modelTask) && (
                    <section className="flex flex-row mb-4 pt-4 pl-[170px] relative">
                      <div className="absolute left-0 top-10 w-[170px]">Original</div>
                      <LabeledPerformanceDisplay className="mb-2" value={accuracyCls}>
                        Accuracy
                        <br />
                        (Top-1 Accuracy (%))
                      </LabeledPerformanceDisplay>
                    </section>
                  )}
                  {isSemanticSegmentation(modelTask) ? (
                    isTao(outputModel) ? (
                      <section className="flex flex-row mb-4 pt-4 pl-[170px] relative">
                        <div className="absolute left-0 top-10 w-[170px]">Original</div>
                        <LabeledPerformanceDisplay className="mb-2" value={accuracyMIoU}>
                          Accuracy
                          <br />
                          (mIoU)
                        </LabeledPerformanceDisplay>
                        <LabeledPerformanceDisplay className="mb-2" value={accuracyF1}>
                          Accuracy
                          <br />
                          (F1 Score)
                        </LabeledPerformanceDisplay>
                        <LabeledPerformanceDisplay className="mb-2" value={accuracyPrecision || 'N/A'}>
                          Accuracy
                          <br />
                          (Precision)
                        </LabeledPerformanceDisplay>
                        <LabeledPerformanceDisplay className="mb-2" isLast value={accuracyRecall || 'N/A'}>
                          Accuracy
                          <br />
                          (Recall)
                        </LabeledPerformanceDisplay>
                      </section>
                    ) : (
                      <section className="flex flex-row mb-4 pt-4">
                        <LabeledPerformanceDisplay className="mb-2" value={accuracyMIoU}>
                          Accuracy
                          <br />
                          (mIoU %)
                        </LabeledPerformanceDisplay>
                        <LabeledPerformanceDisplay className="mb-2" value={accuracyPix}>
                          Accuracy
                          <br />
                          (pixAcc %)
                        </LabeledPerformanceDisplay>
                      </section>
                    )
                  ) : null}
                  <section className="flex flex-row pl-[170px] relative">
                    <div className="absolute left-0 top-6 w-[170px]">Converted</div>
                    <LabeledPerformanceDisplay className="mb-1" value={targetLatency || 'N/A'}>
                      Latency
                      <br />
                      (ms)
                    </LabeledPerformanceDisplay>
                    <LabeledPerformanceDisplay className="mb-1" value={modelSize ? modelSize.toFixed(2) : 'N/A'}>
                      Model size
                      <br />
                      (MB)
                    </LabeledPerformanceDisplay>
                    <LabeledPerformanceDisplay className="mb-1" value={footprintGPU ? footprintGPU.toFixed(2) : 'N/A'}>
                      Memory footprint
                      <br />
                      GPU (MB)
                    </LabeledPerformanceDisplay>
                    <LabeledPerformanceDisplay className="mb-1" value={footprintCPU ? footprintCPU.toFixed(2) : 'N/A'}>
                      Memory footprint
                      <br />
                      CPU (MB)
                    </LabeledPerformanceDisplay>
                    <LabeledPerformanceDisplay
                      className="mb-1"
                      isLast
                      value={powerConsuption > 0 ? powerConsuption.toFixed(2) : 'N/A'}
                    >
                      Power
                      <br />
                      consumption (Wh)
                    </LabeledPerformanceDisplay>
                  </section>
                </section>
              )}
              {measureData && (
                <section className="w-full pt-8">
                  <HeaderText className="mb-4">Device Setting Info</HeaderText>
                  <div className="flex flex-row">
                    <div className="w-1/4">
                      <LabeledValueDisplay label="Device">{targetDevice}</LabeledValueDisplay>
                      <LabeledValueDisplay label="Library" className="pt-4">
                        {`Python ${pythonVersion}`}
                        <br />
                        {`NumPy ${numpyVersion}`}
                        <br />
                        {outputModel === FRAMEWORKS.TENSORFLOW_LITE ? (
                          <>{`TFLite ${tfliteVersion}`}</>
                        ) : outputModel === FRAMEWORKS.TENSORRT ? (
                          <>
                            {`JetPack ${jetpackVersion}`}
                            <br />
                            {`TensorRT ${tensorrtVersion}`}
                          </>
                        ) : (
                          openvinoVersion && <>{`OpenVINO ${openvinoVersion}`}</>
                        )}
                      </LabeledValueDisplay>
                    </div>
                    <div className="w-1/4">
                      <LabeledValueDisplay label="CPU">{cpu}</LabeledValueDisplay>
                      <LabeledValueDisplay label="GPU" className="pt-4">
                        {gpu}
                      </LabeledValueDisplay>
                    </div>
                    <div className="w-1/4">
                      <LabeledValueDisplay label="RAM">{ramSize} GB</LabeledValueDisplay>
                      <LabeledValueDisplay label="Power mode">
                        {targetDevice.indexOf('Jetson') > -1 ? 'Max' : 'N/A'}
                      </LabeledValueDisplay>
                    </div>
                  </div>
                </section>
              )}
            </PanelContent>
          </section>
        </Panel>
      </div>
      {projectData.data && (
        <>
          <PanelHeader className="mb-4 pl-12">Related projects</PanelHeader>
          <ResponsiveContainer className="px-3">{renderProject(projectData.data)}</ResponsiveContainer>
        </>
      )}
    </>
  );
};
