import {
  CROP_SIZE_H,
  CROP_SIZE_W,
  FLIPLR,
  MAX_SCALE,
  MIN_SCALE,
  RESIZE_ADD,
  RESIZE_RATIO0,
  RESIZE_RATIOF,
} from '@netspresso/shared';
import React from 'react';
import { AUGMENTATION_DETAILS } from '../../../../../../constants';
import { parseHypPlaceholderSeg, parseHypTooltip } from '../../../../../../utils';
import { HyperParameterInput } from '../../../HyperParameterInput';

export const SegmentationParams: React.FC = () => {
  return (
    <>
      <section className="flex flex-row">
        <HyperParameterInput
          inputType="radio"
          paramName={MAX_SCALE}
          groupName={AUGMENTATION_DETAILS}
          className="pr-9"
          options={[
            { value: 1024, label: '1024' },
            { value: 2048, label: '2048' },
          ]}
          tooltipMsg={parseHypTooltip(MAX_SCALE)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          inputType="radio"
          paramName={[MIN_SCALE, CROP_SIZE_W, CROP_SIZE_H]}
          groupName={AUGMENTATION_DETAILS}
          className="pr-9"
          options={[
            { value: 512, label: '512' },
            { value: 768, label: '768' },
            { value: 1024, label: '1024' },
          ]}
          tooltipMsg={parseHypTooltip(MAX_SCALE)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={RESIZE_RATIO0}
          groupName={AUGMENTATION_DETAILS}
          className="pr-9"
          tooltipMsg={parseHypTooltip(RESIZE_RATIO0)}
          placeholder={parseHypPlaceholderSeg(RESIZE_RATIO0)}
        />
        <HyperParameterInput
          paramName={RESIZE_RATIOF}
          groupName={AUGMENTATION_DETAILS}
          className="px-9"
          tooltipMsg={parseHypTooltip(RESIZE_RATIOF)}
          placeholder={parseHypPlaceholderSeg(RESIZE_RATIOF)}
        />
        <HyperParameterInput
          paramName={RESIZE_ADD}
          groupName={AUGMENTATION_DETAILS}
          className="pl-9"
          tooltipMsg={parseHypTooltip(RESIZE_ADD)}
          isDisabled
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={FLIPLR}
          groupName={AUGMENTATION_DETAILS}
          className="pr-9"
          tooltipMsg={parseHypTooltip(FLIPLR)}
          placeholder={parseHypPlaceholderSeg(FLIPLR)}
        />
      </section>
    </>
  );
};
