/* eslint-disable camelcase */
import { API_AUTH_V1, apiClient } from '@netspresso/shared';
import { ResetPasswordType } from '../../schemes';

export const AuthService = (() => {
  const login = (formData: { username: string; password: string }) => {
    return apiClient.post(`${API_AUTH_V1}/local/login`, formData);
  };

  const resetPassword = (payload: ResetPasswordType) => {
    return apiClient.post(`${API_AUTH_V1}/reset_password`, payload);
  };

  const changePassword = (payload: { password: string }) => {
    return apiClient.post(`${API_AUTH_V1}/change_password`, payload);
  };

  const refreshToken = (payload: { access_token?: string; refresh_token?: string }) => {
    return apiClient.post(`${API_AUTH_V1}/token`, payload);
  };

  return {
    login,
    resetPassword,
    changePassword,
    refreshToken,
  };
})();
