import { InputTextLabel, TextArea, TextInput } from '@netspresso/components';
import { PLACEHOLDER_MODEL_MEMO, PLACEHOLDER_MODEL_NAME } from '@netspresso/shared';
import { useFormState } from 'react-hook-form';
import { DESCRIPTION, MODEL_NAME } from '../../../../constants';
import { useCompressFormContext } from '../../../../context';

export const ModelSetting: React.FC = () => {
  const {
    compressForm: { watch, setValue, control },
  } = useCompressFormContext();

  const watched = watch();

  const { errors } = useFormState({ control });

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setValue(MODEL_NAME, value, { shouldValidate: true, shouldDirty: true });
  };

  const onMemoChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const { value } = event.target;

    setValue(DESCRIPTION, value);
  };

  return (
    <>
      <section className="mb-3" data-test="dataset-name-input">
        <InputTextLabel htmlFor={MODEL_NAME}>Model name *</InputTextLabel>
        <TextInput
          id={MODEL_NAME}
          placeholder={PLACEHOLDER_MODEL_NAME}
          value={watched[MODEL_NAME]}
          width="w-1/2"
          onChange={onNameChange}
          error={errors[MODEL_NAME] ? errors[MODEL_NAME].message : ''}
        />
      </section>
      <section className="mb-8" data-test="dataset-memo-input">
        <InputTextLabel htmlFor={DESCRIPTION}>Memo</InputTextLabel>
        <TextArea
          id={DESCRIPTION}
          placeholder={PLACEHOLDER_MODEL_MEMO}
          value={watched[DESCRIPTION]}
          onChange={onMemoChange}
        />
      </section>
    </>
  );
};
