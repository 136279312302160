import * as Environments from '../../constants/Environments';
import * as FeatureFlags from '../../constants/FeatureFlags';

export const FLAGS = [
  FeatureFlags.TEST_EPOCH,
  FeatureFlags.NVIDIA_TAO,
  FeatureFlags.COMBINED_COMPRESSION,
  FeatureFlags.LOCAL_DATASET,
] as const;

export type FlagTypes = (typeof FLAGS)[number];

const defaultFlags = {
  [FeatureFlags.TEST_EPOCH]: {
    [Environments.PRODUCTION]: false,
    [Environments.STAGING]: true,
    [Environments.DEVELOPMENT]: true,
    [Environments.LOCAL]: true,
    [Environments.LGCNS]: false,
    [Environments.TEST]: true,
  },
  [FeatureFlags.NVIDIA_TAO]: {
    [Environments.PRODUCTION]: false,
    [Environments.STAGING]: false,
    [Environments.DEVELOPMENT]: true,
    [Environments.LOCAL]: false,
    [Environments.LGCNS]: false,
    [Environments.TEST]: true,
  },
  [FeatureFlags.COMBINED_COMPRESSION]: {
    [Environments.PRODUCTION]: true,
    [Environments.STAGING]: true,
    [Environments.DEVELOPMENT]: true,
    [Environments.LOCAL]: true,
    [Environments.LGCNS]: false,
    [Environments.TEST]: true,
  },
  [FeatureFlags.LOCAL_DATASET]: {
    [Environments.PRODUCTION]: true,
    [Environments.STAGING]: true,
    [Environments.DEVELOPMENT]: true,
    [Environments.LOCAL]: true,
    [Environments.LGCNS]: false,
    [Environments.TEST]: false,
  },
};

export const featureFlags = (featureFlag: FlagTypes) => {
  const processEnv = process.env.REACT_APP_ENVIRONMENT || process.env.ENVIRONMENT || process.env.NODE_ENV;

  let env;

  switch (processEnv) {
    case Environments.PRODUCTION:
    case Environments.STAGING:
    case Environments.DEVELOPMENT:
    case Environments.LOCAL:
    case Environments.LGCNS:
    case Environments.TEST:
      env = processEnv;
      break;
    default:
      throw new Error(`Unexpected environment: ${processEnv}`);
  }

  const isEnabled = defaultFlags[featureFlag][env];

  return isEnabled;
};
