import * as yup from 'yup';
import {
  ADVANCED_SEARCH,
  RETRAINING,
  FIELD_NAME,
  FormErrorMessages,
  JETPACK_45,
  JETPACK_451,
  JETPACK_451_DLA,
  JETPACK_45_DLA,
  JETPACK_46,
  JETPACK_46_DLA,
  QUICK_SEARCH,
  RASPBERRY_PI_4B,
  JETPACK_501,
  SLIDE_EVAL,
  STRIDE,
  CROP_W,
  CROP_H,
  YOLOV5,
  ATOMIX,
  SEGFORMER,
  JETPACK_441,
  JETPACK_502,
  TAO_UNET,
  isString,
  GRAPHMODULE_PT,
} from '@netspresso/shared';

import { AgumentationScheme, AugmentaionDefaultValues } from './AugmentationDetailScheme';
import { HyperparameterScheme, HyperparameterDefaultValues } from './HyperparameterDetailScheme';
import {
  BASE_IMG_SIZE,
  DATASET_UID,
  DEVICE,
  NOTE,
  OUTPUT_DATA_TYPE,
  OUTPUT_MODEL_TYPE,
  OUTPUT_MODEL_VERSION,
  PROJECT_NAME,
  TARGET_LATENCY,
  PROJECT_TASK,
  AUGMENTATION,
  EPOCHS_PER_TRIAL,
  LOOKUPTABLE_UID,
  OUTPUT_BATCH_SIZE,
  PROJECT_TYPE,
  RECOMMENDED_TARGET_LATENCY,
  IMG_SIZE_CHANNEL,
  IMG_SIZE_HEIGHT,
  IMG_SIZE_WIDTH,
  FIX_IMG_SIZE,
  AGENT_ID,
  MODEL_ID,
  MODEL_DATASET_UID,
  IMAGE_PROCESSING_DETAIL,
  AUGMENTATION_DETAILS,
  HYP_DETAIL,
  INFERENCE_DETAIL,
  BASE_ARCHITECTURE,
} from '../constants';
import {
  FRAMEWORKS,
  OutputModelTypes,
  OUTPUT_DATA_TYPES,
  ProjectTaskType,
  TASKS,
  TargetDevice,
  OutputDataTypes,
  BaseArchitectureTypes,
} from '../lib';

const IMAGE_SIZES = [96, 128, 160, 192, 224, 256, 384, 480, 512, 640, 768, 1024];

const thenNotEmpty = (s: yup.Schema) => {
  return s.test('is-not-empty', 'Please select dataset.', isNotEmpty);
};

const isNotEmpty = (value: string | undefined) => {
  if (isString(value)) {
    return value.length !== 0;
  }

  return false;
};

export const ProjectScheme = yup.object({
  [PROJECT_NAME]: yup
    .string()
    .required(FormErrorMessages.isRequired)
    .min(2, FormErrorMessages.isNotMin(2))
    .max(20, FormErrorMessages.isNotMax(20))
    .matches(FIELD_NAME, FormErrorMessages.isWrongCharacters),
  [NOTE]: yup.string().max(100, FormErrorMessages.isNotMax(100)),
  [PROJECT_TASK]: yup.string<ProjectTaskType>().required(),
  [PROJECT_TYPE]: yup.string().oneOf([QUICK_SEARCH, ADVANCED_SEARCH, RETRAINING]),
  [DATASET_UID]: yup.string().when(PROJECT_TYPE, {
    is: QUICK_SEARCH,
    then: thenNotEmpty,
  }),
  [DEVICE]: yup.string<TargetDevice>().required(),
  [OUTPUT_MODEL_TYPE]: yup.string<OutputModelTypes>().required(),
  [OUTPUT_MODEL_VERSION]: yup
    .string()
    .oneOf([
      JETPACK_441,
      JETPACK_45,
      JETPACK_45_DLA,
      JETPACK_451,
      JETPACK_451_DLA,
      JETPACK_46,
      JETPACK_46_DLA,
      JETPACK_501,
      JETPACK_502,
    ]),
  [OUTPUT_DATA_TYPE]: yup.string<OutputDataTypes>().required(),
  [OUTPUT_BATCH_SIZE]: yup
    .number()
    .typeError('Batch size must be integer')
    .required(FormErrorMessages.isRequired)
    .integer('Batch size must be integer')
    .min(1, FormErrorMessages.isNotMin(1))
    .max(32, FormErrorMessages.isNotMax(32)),
  [RECOMMENDED_TARGET_LATENCY]: yup.number(),
  [TARGET_LATENCY]: yup.number().when(PROJECT_TYPE, {
    is: QUICK_SEARCH,
    then: () =>
      yup
        .number()
        .typeError('Target latency must be number')
        .required(FormErrorMessages.isRequired)
        .when(LOOKUPTABLE_UID, {
          is: (value: string | undefined) => {
            if (isString(value)) {
              return value.length < 1;
            }

            return false;
          },
          then: () => yup.number().min(10, FormErrorMessages.wrongLatency).max(5000, FormErrorMessages.wrongLatency),
        }),
  }),
  [IMG_SIZE_CHANNEL]: yup.number().default(3),
  [BASE_IMG_SIZE]: yup.array().required().of(yup.number().required().oneOf(IMAGE_SIZES)),
  [IMG_SIZE_WIDTH]: yup.number().required().oneOf(IMAGE_SIZES),
  [IMG_SIZE_HEIGHT]: yup.number().required().oneOf(IMAGE_SIZES),
  [FIX_IMG_SIZE]: yup.number().default(1),
  [EPOCHS_PER_TRIAL]: yup.number().oneOf([3, 100, 150, 200, 250, 300, 350, 450]).required(),
  [LOOKUPTABLE_UID]: yup.string(),
  [MODEL_ID]: yup.string().when(PROJECT_TYPE, {
    is: RETRAINING,
    then: thenNotEmpty,
  }),
  [AUGMENTATION]: yup.number().default(1),
  [AGENT_ID]: yup.string(),
  [MODEL_DATASET_UID]: yup.string(),
  [IMAGE_PROCESSING_DETAIL]: yup.object({}),
  [BASE_ARCHITECTURE]: yup.string<BaseArchitectureTypes | 'tao_unet'>().oneOf([YOLOV5, ATOMIX, SEGFORMER, TAO_UNET]),
  [AUGMENTATION_DETAILS]: yup
    .mixed<Record<string, number>>()
    .when(PROJECT_TASK, { is: TASKS.OBJECT_DETECTION, then: () => AgumentationScheme(TASKS.OBJECT_DETECTION) })
    .when(PROJECT_TASK, { is: TASKS.IMAGE_CLASSIFICATION, then: () => AgumentationScheme(TASKS.IMAGE_CLASSIFICATION) })
    .when(PROJECT_TASK, {
      is: TASKS.SEMANTIC_SEGMENTATION,
      then: () => AgumentationScheme(TASKS.SEMANTIC_SEGMENTATION),
    }),
  [HYP_DETAIL]: yup
    .mixed<Record<string, number | boolean>>()
    .when(PROJECT_TASK, { is: TASKS.OBJECT_DETECTION, then: () => HyperparameterScheme(TASKS.OBJECT_DETECTION) })
    .when(PROJECT_TASK, {
      is: TASKS.IMAGE_CLASSIFICATION,
      then: () => HyperparameterScheme(TASKS.IMAGE_CLASSIFICATION),
    })
    .when(PROJECT_TASK, {
      is: TASKS.SEMANTIC_SEGMENTATION,
      then: () => HyperparameterScheme(TASKS.SEMANTIC_SEGMENTATION),
    }),
  [INFERENCE_DETAIL]: yup.mixed<Record<string, number | boolean>>().when(PROJECT_TASK, {
    is: TASKS.SEMANTIC_SEGMENTATION,
    then: () =>
      yup.object({
        [SLIDE_EVAL]: yup.boolean(),
        [STRIDE]: yup.number().when(SLIDE_EVAL, { is: true, then: () => yup.number().oneOf([512, 768]) }),
        [CROP_W]: yup.number().when(SLIDE_EVAL, { is: true, then: () => yup.number().oneOf([768, 1024]) }),
        [CROP_H]: yup.number().when(SLIDE_EVAL, { is: true, then: () => yup.number().oneOf([768, 1024]) }),
      }),
  }),
  [GRAPHMODULE_PT]: yup.string(),
});

// export type ProjectType = yup.InferType<typeof ProjectScheme>;
export type ProjectType = yup.InferType<typeof ProjectScheme>;

export const ProjectsDefaultValues = (task: ProjectTaskType): ProjectType => ({
  [PROJECT_NAME]: '',
  [NOTE]: '',
  [DATASET_UID]: '',
  [MODEL_DATASET_UID]: '',
  [PROJECT_TASK]: task,
  [PROJECT_TYPE]: '',
  [DEVICE]: RASPBERRY_PI_4B,
  [OUTPUT_MODEL_TYPE]: FRAMEWORKS.TENSORFLOW_LITE,
  [OUTPUT_MODEL_VERSION]: JETPACK_46,
  [OUTPUT_DATA_TYPE]: OUTPUT_DATA_TYPES.FP16,
  [OUTPUT_BATCH_SIZE]: 1,
  [TARGET_LATENCY]: 500,
  [IMG_SIZE_CHANNEL]: 3,
  [BASE_IMG_SIZE]: [640, 640],
  [IMG_SIZE_WIDTH]: 640,
  [IMG_SIZE_HEIGHT]: 640,
  [FIX_IMG_SIZE]: 1,
  [RECOMMENDED_TARGET_LATENCY]: 500,
  [EPOCHS_PER_TRIAL]: 200,
  [LOOKUPTABLE_UID]: '',
  [MODEL_ID]: '',
  [AUGMENTATION]: 1,
  [AUGMENTATION_DETAILS]: { ...AugmentaionDefaultValues(task) },
  [HYP_DETAIL]: { ...HyperparameterDefaultValues(task) },
  [INFERENCE_DETAIL]: {
    [SLIDE_EVAL]: false,
    [STRIDE]: 512,
    [CROP_W]: 768,
    [CROP_H]: 768,
  },
  [BASE_ARCHITECTURE]: YOLOV5,
  [AGENT_ID]: '',
  [IMAGE_PROCESSING_DETAIL]: {},
  [GRAPHMODULE_PT]: '',
});
