/* eslint-disable camelcase */

import { DatasetFormatType, NumImages, NumInstanceType, ProjectTaskType, StorageLocationType } from '../../lib';
import { DatasetType } from '../../schemes';

export type UploadDatasetPayloadType = {
  dataset_name: string;
  description?: string;
  dataset_task?: ProjectTaskType;
  dataset_format?: DatasetFormatType;
  dataset_path?: string;
  system_uuid?: string;
  num_images: NumImages | null;
  num_instances?: NumInstanceType[];
  class_names?: string[];
  file_id?: string;
  storage_type: StorageLocationType;
};

export const convertToUploadDatasetPayload = ({
  dataset_name,
  description,
  task,
  dataset_format,
  num_images,
  num_instances,
  class_names,
  file_id,
  storage_type,
  dataset_path,
  system_uuid,
}: DatasetType): UploadDatasetPayloadType => {
  return {
    dataset_name,
    description,
    dataset_task: task,
    dataset_format,
    num_images,
    num_instances,
    class_names,
    file_id,
    storage_type,
    dataset_path,
    system_uuid,
  };
};
