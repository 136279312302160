export const InfoContainerColors = {
  info: 'secondary',
  danger: 'danger',
  warning: 'warning',
  success: 'success',
};

export const InfoTextColors = {
  info: 'secondary',
  danger: 'danger',
  warning: 'warning',
  success: 'success-active',
};

export const DatasetStatusColors = {
  '-6': 'danger',
  '-5': 'danger',
  '-4': 'danger',
  '-3': 'danger',
  '-2': 'danger',
  '-1': 'danger',
  0: 'danger',
  1: 'info',
  2: 'success-active',
};

export const ProjectStatusColors = {
  submitted: 'info',
  running: 'info',
  completed: 'success-active',
  'user stopped': 'danger',
  'invalid stopped': 'danger',
};
