import { FRAMEWORKS, UploadableModelTypes } from '../types';

export const isUploadableFrameworks = (value: unknown): value is UploadableModelTypes => {
  switch (value) {
    case FRAMEWORKS.TENSORRT:
    case FRAMEWORKS.TENSORFLOW_LITE:
    case FRAMEWORKS.OPENVINO:
    case FRAMEWORKS.TENSORFLOW_KERAS:
    case FRAMEWORKS.ONNX:
    case FRAMEWORKS.PYTORCH:
      return true;
    default:
      return false;
  }
};
