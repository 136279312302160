import {
  COPY_PASTE,
  DEGREES,
  FLIPLR,
  FLIPUD,
  HSV_H,
  HSV_S,
  HSV_V,
  MIXUP,
  MOSAIC,
  PERSPECTIVE,
  SCALE,
  SHEAR,
  TRANSLATE,
} from '@netspresso/shared';
import React from 'react';
import { AUGMENTATION_DETAILS } from '../../../../../../constants';
import { parseHypPlaceholderDet, parseHypTooltip } from '../../../../../../utils';
import { HyperParameterInput } from '../../../HyperParameterInput';

export const DetectionParams: React.FC = () => {
  return (
    <>
      <section className="flex flex-row">
        <HyperParameterInput
          paramName={HSV_H}
          groupName={AUGMENTATION_DETAILS}
          className="pr-9"
          tooltipMsg={parseHypTooltip(HSV_H)}
          placeholder={parseHypPlaceholderDet(HSV_H)}
        />
        <HyperParameterInput
          paramName={HSV_S}
          groupName={AUGMENTATION_DETAILS}
          className="px-9"
          tooltipMsg={parseHypTooltip(HSV_S)}
          placeholder={parseHypPlaceholderDet(HSV_S)}
        />
        <HyperParameterInput
          paramName={HSV_V}
          groupName={AUGMENTATION_DETAILS}
          className="pl-9"
          tooltipMsg={parseHypTooltip(HSV_V)}
          placeholder={parseHypPlaceholderDet(HSV_V)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={DEGREES}
          groupName={AUGMENTATION_DETAILS}
          className="pr-9"
          tooltipMsg={parseHypTooltip(DEGREES)}
          placeholder={parseHypPlaceholderDet(DEGREES)}
        />
        <HyperParameterInput
          paramName={TRANSLATE}
          groupName={AUGMENTATION_DETAILS}
          className="px-9"
          tooltipMsg={parseHypTooltip(TRANSLATE)}
          placeholder={parseHypPlaceholderDet(TRANSLATE)}
        />
        <HyperParameterInput
          paramName={SCALE}
          groupName={AUGMENTATION_DETAILS}
          className="pl-9"
          tooltipMsg={parseHypTooltip(SCALE)}
          placeholder={parseHypPlaceholderDet(SCALE)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={SHEAR}
          groupName={AUGMENTATION_DETAILS}
          className="pr-9"
          tooltipMsg={parseHypTooltip(SHEAR)}
          placeholder={parseHypPlaceholderDet(SHEAR)}
        />
        <HyperParameterInput
          paramName={PERSPECTIVE}
          groupName={AUGMENTATION_DETAILS}
          className="px-9"
          tooltipMsg={parseHypTooltip(PERSPECTIVE)}
          placeholder={parseHypPlaceholderDet(PERSPECTIVE)}
        />
        <HyperParameterInput
          paramName={FLIPUD}
          groupName={AUGMENTATION_DETAILS}
          className="pl-9"
          tooltipMsg={parseHypTooltip(FLIPUD)}
          placeholder={parseHypPlaceholderDet(FLIPUD)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={FLIPLR}
          groupName={AUGMENTATION_DETAILS}
          className="pr-9"
          tooltipMsg={parseHypTooltip(FLIPLR)}
          placeholder={parseHypPlaceholderDet(FLIPLR)}
        />
        <HyperParameterInput
          paramName={MOSAIC}
          groupName={AUGMENTATION_DETAILS}
          className="px-9"
          tooltipMsg={parseHypTooltip(MOSAIC)}
          placeholder={parseHypPlaceholderDet(MOSAIC)}
        />
        <HyperParameterInput
          paramName={MIXUP}
          groupName={AUGMENTATION_DETAILS}
          className="pl-9"
          tooltipMsg={parseHypTooltip(MIXUP)}
          placeholder={parseHypPlaceholderDet(MIXUP)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={COPY_PASTE}
          groupName={AUGMENTATION_DETAILS}
          className="pr-9"
          tooltipMsg={parseHypTooltip(COPY_PASTE)}
          placeholder={parseHypPlaceholderDet(COPY_PASTE)}
        />
      </section>
    </>
  );
};
