import React from 'react';

interface TextAreaProps {
  id: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  width?: string;
  rows?: number;
  error?: string;
  placeholder?: string;
}

export const TextArea: React.FC<TextAreaProps> = ({
  id,
  value,
  onChange,
  width = 'w-1/2',
  rows = 2,
  error = '',
  placeholder = '',
}) => {
  return (
    <div className={width}>
      <textarea
        className={`${
          error ? 'border-2 border-danger focus:border-danger' : 'border border-defaultGray focus:border-secondary'
        } block w-full resize-none rounded px-3 py-1 placeholder-defaultGray-300 focus:outline-none`}
        id={id}
        name={id}
        placeholder={placeholder}
        rows={rows}
        value={value}
        onChange={onChange}
      ></textarea>
      <span className={`${error ? 'block' : 'hidden'} text-xs text-danger`}>{error}</span>
    </div>
  );
};
