export const DATASET_NAME = 'dataset_name';
export const DESCRIPTION = 'description';
export const FILE = 'file';
export const AUTH = 'auth';
export const FILE_ID = 'file_id';
export const TRAIN_FILE = 'train_file';
export const VAL_FILE = 'val_file';
export const TEST_FILE = 'test_file';
export const CERT_FILE = 'cert_file';
export const YAML_FILE = 'yaml_file';
export const TASK = 'task';
export const NUM_IMAGES = 'num_images';
export const NUM_INSTANCES = 'num_instances';
export const CLASS_NAME = 'class_names';
export const DATASET_FORMAT = 'dataset_format';
export const DATASET_YAML = 'dataset_yaml';
export const UPLOAD_S3_LINK = 'upload_s3_link';
export const UPLOAD_TRAIN_S3_LINK = 'upload_train_s3_link';
export const UPLOAD_VAL_S3_LINK = 'upload_val_s3_link';
export const UPLOAD_TEST_S3_LINK = 'upload_test_s3_link';
export const STORAGE_TYPE = 'storage_type';
export const DATASET_PATH = 'dataset_path';
export const SYSTEM_UUID = 'system_uuid';
