import React from 'react';
import { useLocation } from 'react-router-dom';
import { QUICK_SEARCH } from '@netspresso/shared';
import { LOOKUPTABLE_UID } from '../../../../constants';
import { StepPointer } from './components/StepPointer';
import { useProjectsFormContext } from '../../../../context';
import { CurrentSteps, CURRENT_STEPS } from '../../../../lib';

type ProjectStepsProps = {
  projectType: string;
  currentStep: CurrentSteps;
  setCurrentStep: React.Dispatch<React.SetStateAction<CurrentSteps>>;
};

export const ProjectSteps: React.FC<ProjectStepsProps> = ({ projectType, currentStep, setCurrentStep }) => {
  const { pathname } = useLocation();
  const {
    projectsForm: { setValue },
  } = useProjectsFormContext();

  const getFirstStepColor = (step: CurrentSteps) => {
    return step !== CURRENT_STEPS.FIRST ? 'secondary' : 'disabledGray';
  };

  const getSecondStepColor = (step: CurrentSteps) => {
    return step === CURRENT_STEPS.THIRD ? 'secondary' : 'disabledGray';
  };

  const onClickTargetStep = (targetStep: CurrentSteps) => () => {
    if (targetStep === CURRENT_STEPS.FIRST) {
      setValue(LOOKUPTABLE_UID, '');
    }
    setCurrentStep(targetStep);
  };

  const firstStep = (path: string) => {
    return path.includes('quicksearch') || projectType === QUICK_SEARCH ? 'Set Project' : 'Select Model';
  };

  const secondStep = (path: string) => {
    return path.includes('quicksearch') || projectType === QUICK_SEARCH ? 'Select Model' : 'Set Project';
  };

  return (
    <section className="absolute right-6 flex flex-row">
      <button
        type="button"
        className={`flex flex-col items-center -mr-[18px] ${
          currentStep !== CURRENT_STEPS.FIRST ? 'cursor-pointer' : 'pointer-events-none'
        }`}
        onClick={onClickTargetStep(CURRENT_STEPS.FIRST)}
      >
        <StepPointer
          currentCondition={currentStep === CURRENT_STEPS.FIRST}
          checkCondition={currentStep !== CURRENT_STEPS.FIRST}
        />
        <p className="w-20 text-xs text-center text-secondary">{firstStep(pathname)}</p>
      </button>

      <div className={`mt-[9px] -ml-3 w-20 h-0.5 -mr-[18px] bg-${getFirstStepColor(currentStep)}`} />

      <button
        type="button"
        className={`flex flex-col items-center -ml-3 -mr-[18px] ${
          currentStep === CURRENT_STEPS.THIRD ? 'cursor-pointer' : 'pointer-events-none'
        }`}
        onClick={onClickTargetStep(CURRENT_STEPS.SECOND)}
      >
        <StepPointer
          defaultCondition={currentStep === CURRENT_STEPS.FIRST}
          currentCondition={currentStep === CURRENT_STEPS.SECOND}
          checkCondition={currentStep === CURRENT_STEPS.THIRD}
        />
        <p className={`w-20 text-xs text-center text-${getFirstStepColor(currentStep)}`}>{secondStep(pathname)}</p>
      </button>

      <div className={`mt-[9px] -ml-3 w-20 h-0.5 -mr-[18px] bg-${getSecondStepColor(currentStep)}`} />

      <div className="-ml-3 flex flex-col items-center">
        <StepPointer
          defaultCondition={currentStep !== CURRENT_STEPS.THIRD}
          currentCondition={currentStep === CURRENT_STEPS.THIRD}
        />
        <p className={`w-20 text-xs text-center text-${getSecondStepColor(currentStep)}`}>Select Server</p>
      </div>
    </section>
  );
};
