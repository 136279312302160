import React from 'react';

type ModalContainerProps = {
  showModal?: boolean;
} & React.PropsWithChildren;

export const ModalContainer: React.FC<ModalContainerProps> = ({ children, showModal = false }) => {
  return (
    <section
      className={`${
        showModal ? 'flex' : 'hidden'
      } justify-center items-center h-screen w-full fixed left-0 top-0 bg-black bg-opacity-50 z-20`}
    >
      {children}
    </section>
  );
};
