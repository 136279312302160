import React from 'react';
import { FD_CP, FD_SVD, FD_TK, PR_GM, PR_ID, PR_L2, PR_NN } from '../../../../constants';
import { CompressionMethod } from '../../../../lib';

export const MethodsDisplayer: React.FC<{ method?: CompressionMethod | '' }> = ({ method = '' }) => {
  const isStructuredPruning = (compMethod: CompressionMethod) => {
    return [PR_L2, PR_GM, PR_NN, PR_ID].includes(compMethod);
  };

  const isFilterDecomposition = (compMethod: CompressionMethod) => {
    return [FD_TK, FD_SVD, FD_CP].includes(compMethod);
  };

  const getDetailLink = (compMethod: CompressionMethod) => {
    if (isStructuredPruning(compMethod)) {
      return 'https://docs.netspresso.ai/docs/mc-structured-pruning#structured-pruning';
    }

    if (isFilterDecomposition(compMethod)) {
      return 'https://docs.netspresso.ai/docs/mc-filter-decomposition#supported-method';
    }

    return '#';
  };

  return (
    <div className="w-3/4 ml-2 h-[368px] px-6 py-4 border border-defaultGray-100 overflow-y-auto rounded-lg">
      {method === PR_L2 && (
        <>
          <p className="mb-2">
            Difference of each pruning method is about <strong>measuring importance of filters</strong> in each layer.
            Filters in each layer will be automatically pruned based on certain criteria.
            <br />
            L2-Norm is used to represent the importance of the corresponding filter. In other words, this method prunes
            filters based on the <strong>magnitude of weights</strong>.
          </p>
          <img className="w-2/3" src="/images/pruning_l2.png" alt="L2 Norm Pruning" />
        </>
      )}
      {method === PR_GM && (
        <>
          <p className="mb-2">
            Difference of each pruning method is about <strong>measuring importance of filters</strong> in each layer.
            Filters in each layer will be automatically pruned based on certain criteria.
            <br />
            <a
              href="https://arxiv.org/abs/1811.00250"
              target="_blank"
              rel="noreferrer"
              className="underline text-secondary hover:text-secondary-active"
            >
              Geometric Median
            </a>{' '}
            is used to measure the <strong>redundancy</strong> of the corresponding filter and remove redundant filters.
          </p>
          <img className="w-2/3 mb-2" src="/images/pruning_gm.png" alt="GM Pruning" />
        </>
      )}
      {method === PR_NN && (
        <>
          <p className="mb-4">
            Difference of each pruning method is about <strong>measuring importance of filters</strong> in each layer.
            Filters in each layer will be automatically pruned based on certain criteria.
            <br />
            The{' '}
            <a
              href="https://arxiv.org/abs/2103.10858"
              target="_blank"
              rel="noreferrer"
              className="underline text-secondary hover:text-secondary-active"
            >
              Nuclear Norm
            </a>{' '}
            is the sum of the singular values representing the <strong>energy</strong>. It computes the nuclear norm on
            the <strong>feature map</strong> to determine the filter&apos;s relevance. For this reason, a portion of the
            dataset is needed.
          </p>
          <img className="w-2/3" src="/images/pruning_nn.png" alt="Nuclear Norm Pruning" />
        </>
      )}
      {method === PR_ID && (
        <>
          <p className="mb-4">
            This function prunes the chosen filters of each layer through the index without certain criteria.
            <br />
            You can apply your own criteria to prune the model.
            <br />
            If the selected filters are redundant or less important, it will return a better performing model.
          </p>
          <img className="w-2/5 mb-2" src="/images/pruning_by_index.png" alt="Pruning By Index" />
        </>
      )}
      {method === FD_TK && (
        <>
          <p className="mb-2">
            Approximating the original filters by Tucker decomposition method.
            <br />
            This method decomposes the convolution with a 4D kernel tensor into two factor matrices and one small core
            tensor.
          </p>
          <img className="w-1/2 mb-4" src="/images/tucker_decomposition.png" alt="Tucker Decomposition" />
          <ul className="list-disc list-inside">
            <li>In Channel: The number of input channels in each layer.</li>
            <li>Out Channel: The number of output channels in each layer.</li>
            <li>
              In Rank: The number of input channel of core tensor that represent relation level of low-rank factor
              matrix.
            </li>
            <li>
              Out Rank: The number of output channel of core tensor that represent relation level of low-rank factor
              matrix.
            </li>
          </ul>
        </>
      )}
      {method === FD_SVD && (
        <>
          <p className="mb-2">
            Approximating the original filters by Singular value decomposition method.
            <br />
            This method decomposes the pointwise convolution or fully-connected layer into two pointwise or
            fully-connected layers.
          </p>
          <img className="w-2/3" src="/images/singular_value_decomposition.png" alt="Singular Value Decomposition" />
          <ul className="list-disc list-inside">
            <li>In Channel: The number of input channels in each layer.</li>
            <li>Out Channel: The number of output channels of the target layer.</li>
            <li>Rank: The condition number of weight matrix W.</li>
          </ul>
        </>
      )}
      {method === FD_CP && (
        <>
          <p className="mb-4">
            Approximating the original filters by CP decomposition method.
            <br />
            This method approximates the convolution with a 4D kernel tensor by the sequence of four convolutions with
            small 2D kernel tensors.
          </p>
          <img className="w-2/3 mb-4" src="/images/cp_decomposition.png" alt="CP Decomposition" />
          <ul className="list-disc list-inside">
            <li>In Channel: The number of input channels in each layer.</li>
            <li>Out Channel: The number of output channels in each layer.</li>
            <li>Rank: A sum of N-way outer products of rank-one tensor for estimating original convolution filter.</li>
          </ul>
        </>
      )}
      {method === '' ? (
        <div className="h-full flex flex-col justify-center text-center text-secondary">
          <span className="material-icons text-4xl">error_outline</span>
          <p>
            Please choose one of the compression methods on the left
            <br />
            to apply to the model selected.
          </p>
        </div>
      ) : (
        <a
          className="inline-block mt-2 text-m underline text-secondary hover:text-secondary-active disabled:text-lineGray"
          href={getDetailLink(method)}
          target="_blank"
          rel="noreferrer"
        >
          Show detail
        </a>
      )}
    </div>
  );
};
