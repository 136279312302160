/* eslint-disable camelcase */
import { Model } from '../lib';

export const retrainableReducer = (prev: Model[], current: Model): Model[] => {
  const retrainableChildren: Model[] = [];
  const { model_status_data } = current;

  if (model_status_data.is_visible && model_status_data.is_retrainable) {
    retrainableChildren.push(current);
  }

  if (current.children.length > 0) {
    const filteredChildren = current.children.filter(
      (child) => child.model_status_data.is_visible && child.model_status_data.is_retrainable
    );

    retrainableChildren.push(...filteredChildren);
  }

  return prev.concat(retrainableChildren);
};

// export const convertableReducer = (prev: Model[], current: Model): Model[] => {
//   const convertables = [];

//   if (current.model_status_data.is_visible) {
//     if (current.origin_from === 'custom' && current.model_status_data.is_convertible) {
//       convertables.push({ ...current, convertible: current.model_id });
//     } else if (current.children && current.children.length > 0) {
//       const result = current.children.filter((child) => {
//         if (child.model_status_data.is_convertible) {
//           // eslint-disable-next-line no-param-reassign
//           current.convertible = child.model_id;
//         }

//         return child.model_status_data.is_convertible;
//       });

//       if (result.length > 0) {
//         convertables.push({ ...current });
//       }
//     }
//   }

//   return [...prev, ...convertables]
//     .filter((element) => element !== null)
//     .filter((element) => !(element.origin_from === 'npms' && isNotObjectDetection(element.task)));
// };

export const packagableReducer = (prev: Model[], current: Model): Model[] => {
  const packagables = [];
  const { model_status_data, children } = current;

  if (model_status_data.is_visible && model_status_data.is_packageable) {
    packagables.push(current);
  }

  if (children && children.length > 0) {
    const filteredChildren = children.filter(
      (child) => child.model_status_data.is_packageable && child.model_status_data.is_visible
    );

    packagables.push(...filteredChildren);
  }

  return prev.concat(packagables);
};
