import { Text } from '@netspresso/components';
import React from 'react';

export const Magnification: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Text as="span" className="text-green-700 text-xs font-bold">
      (x{children})
    </Text>
  );
};
