import { CompressModel } from '../../../lib';

export const totalLatency = (baseModel: CompressModel) => {
  let latency = '';

  if (baseModel) {
    const findDevice = baseModel.devices.find((device) => device.name === baseModel.target_device);

    if (findDevice) {
      if (findDevice.total_latency > 0) {
        latency = `${findDevice.total_latency} ms`;
      }

      if (findDevice.total_latency === 0) {
        latency = 'Measuring';
      }

      if (findDevice.total_latency === -1) {
        latency = 'Error';
      }
    }
  }

  return latency;
};
