import React from 'react';
import { createPortal } from 'react-dom';
import { useNotificationContext } from './notificationContext';

export const Notifications = (): React.ReactPortal | null => {
  const { isVisible, notifyContent } = useNotificationContext();

  if (isVisible) {
    return createPortal(
      <div className="flex fixed right-0 top-0 mt-4 mr-3 z-50 shadow-lg rounded-lg">{notifyContent}</div>,
      document.getElementById('modal') as HTMLElement
    );
  }

  return null;
};
