/* eslint-disable camelcase */
import { ObjectValues } from '@netspresso/shared';
import { StorageLocationType } from '../StorageType';
import { Tasks } from '../Tasks';

export type Dataset = {
  dataset_name: string;
  description: string;
  dataset_task: Tasks;
  dataset_format: DatasetFormatType;
  file_id: string;
  num_images: NumImages;
  num_instances: NumInstanceType[];
  class_names: string[];
  dataset_id: string;
  s3_bucket_name: string;
  user_uid?: string;
  created_at?: string;
  created_time?: string;
  storage_type: StorageLocationType;
};

export const DATASETS = {
  YOLO: 'yolo',
  VOC: 'voc',
  COCO: 'coco',
  IMAGENET: 'imagenet',
  UNET: 'unet',
} as const;

export type DatasetFormatType = ObjectValues<typeof DATASETS>;

export const DATASET_TASK = ['yolo', 'voc', 'coco'] as const;

export type DatasetTaskType = (typeof DATASET_TASK)[number];

export type NumImages = {
  train: number;
  val: number;
  test: number;
};

export type NumInstanceType = {
  class_name: string;
  val: number;
  test: number;
  train: number;
  total: number;
};

export type DatasetNames = 'YOLO' | 'VOC' | 'COCO' | 'ImageNet' | 'UNet (like YOLO)';

export const DatasetsMap: Record<DatasetFormatType, DatasetNames> = {
  [DATASETS.YOLO]: 'YOLO',
  [DATASETS.VOC]: 'VOC',
  [DATASETS.COCO]: 'COCO',
  [DATASETS.IMAGENET]: 'ImageNet',
  [DATASETS.UNET]: 'UNet (like YOLO)',
};
