import React from 'react';
import { Text } from '../Text';

type HyperTextProps = {
  type?: string;
  className?: string;
};

export const HeaderText: React.FC<HyperTextProps & React.PropsWithChildren> = ({ type, children, className }) => {
  if (type === 'main') {
    return (
      <Text as="h1" className={`text-secondary-800 text-xl font-title font-bold ${className}`}>
        {children}
      </Text>
    );
  }
  if (type === 'formGroup') {
    <Text as="h1" className={`text-secondary font-title font-semibold ${className}`}>
      {children}
    </Text>;
  }
  if (type === 'formSection') {
    return <h3 className={`text-darkGray text-sm font-title font-bold ${className}`}>{children}</h3>;
  }
  return (
    <Text as="h2" className={`text-bgblue-active font-title font-bold ${className}`}>
      {children}
    </Text>
  );
};
