import React, { useEffect, useRef } from 'react';

export type FileAcceptType =
  | 'application/zip'
  | '.onnx'
  | '.pt'
  | '.h5, .zip'
  | '.py'
  | '.npy'
  | '.np'
  | 'application/x-yaml'
  | '.yaml, .yml';

interface InputFileType {
  id: string;
  width?: string;
  localFile: File | null;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  accept: FileAcceptType;
  error?: string;
  placeholder?: string;
}

export const InputFile: React.FC<InputFileType> = ({
  id,
  width = 'w-1/2',
  localFile,
  onChange,
  accept = 'application/zip',
  error = '',
  placeholder = '',
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (localFile === null && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [localFile]);

  return (
    <div className={width}>
      <label className={`relative inline-block cursor-pointer w-full ${error ? 'error' : ''}`} htmlFor={id}>
        <input
          ref={inputRef}
          className="min-w-full m-0 opacity-0"
          accept={accept}
          type="file"
          id={id}
          onChange={onChange}
        />
        <span className="file-custom">{localFile ? localFile.name : placeholder}</span>
      </label>
      <span className={`${error ? 'block' : 'hidden'} text-xs text-danger mt-1`}>{error}</span>
    </div>
  );
};
