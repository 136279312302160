import { NOT_NUMBER, NUMBER_WITH_DIGIT } from '@netspresso/shared';
import React from 'react';

type InputDecimalNumberProps = {
  id: string;
  value: string;
  max?: number;
  min?: number;
  width?: string;
  margin?: string;
  decimal?: number;
  setValue: (value: string) => void;
};

export const InputDecimalNumber: React.FC<InputDecimalNumberProps> = ({
  id,
  value,
  max,
  min,
  setValue,
  width = 'w-[60px]',
  margin = '',
  decimal = 2,
}) => {
  const [currentValue, setCurrentValue] = React.useState('');

  React.useEffect(() => {
    if (value) {
      setCurrentValue(`${value}`);
    }
  }, [value]);

  const onBlur = () => {
    let maybeNumber = currentValue.trim();

    maybeNumber = maybeNumber.replace(NOT_NUMBER, '').replace(',', '.').replace('--', '-');

    if (maybeNumber.length > 2) {
      maybeNumber = maybeNumber.charAt(0) + maybeNumber.substring(1).replace('-', '');
    }

    if (typeof min === 'number') {
      maybeNumber = `${Math.max(min || 1, parseFloat(maybeNumber))}`;
    }

    if (typeof max === 'number') {
      maybeNumber = `${Math.min(max || 1, parseFloat(maybeNumber))}`;
    }

    if (NUMBER_WITH_DIGIT.test(maybeNumber)) {
      updateValue(maybeNumber);

      return;
    }

    updateValue(value);
  };

  const updateValue = (value: string) => {
    setValue(parseFloat(value).toFixed(decimal));
    setCurrentValue(parseFloat(value).toFixed(decimal));
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setCurrentValue(value);
  };

  return (
    <input
      className={`block border border-defaultGray rounded px-3 py-1 placeholder-disabledGray focus:outline-none focus:border-secondary ${width} ${margin}`}
      type="text"
      id={id}
      name="compress-ratioratio"
      placeholder="ratio"
      value={currentValue}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
