import {
  LR0,
  LRF,
  SEED,
  MOMENTUM,
  WEIGHT_DECAY,
  WARMUP_STEPS,
  WARMUP_EPOCHS,
  WARMUP_MOMENTUM,
  WARMUP_BIAS_LR,
  SCHD_POWER,
  REDUCE_ZERO_LABEL,
  BOX,
  CLS,
  CLS_PW,
  OBJ,
  OBJ_PW,
  IOU_T,
  ANCHOR_T,
  FL_GAMMA,
  HSV_H,
  HSV_S,
  HSV_V,
  DEGREES,
  TRANSLATE,
  SCALE,
  MAX_SCALE,
  MIN_SCALE,
  CROP_SIZE_H,
  CROP_SIZE_W,
  RESIZE_RATIO0,
  RESIZE_RATIOF,
  RESIZE_ADD,
  SHEAR,
  PERSPECTIVE,
  FLIPUD,
  FLIPLR,
  MOSAIC,
  MIXUP,
  COPY_PASTE,
  MIXUP_ALPHA,
  CUTMIX_ALPHA,
  MIXUP_SWITCH_PROB,
  SLIDE_EVAL,
  STRIDE,
  CROP_W,
  CROP_H,
} from '@netspresso/shared';

export const HYPER_PARAMETER_TOOLTIPS = [
  LR0,
  LRF,
  SEED,
  MOMENTUM,
  WEIGHT_DECAY,
  WARMUP_STEPS,
  WARMUP_EPOCHS,
  WARMUP_MOMENTUM,
  WARMUP_BIAS_LR,
  SCHD_POWER,
  REDUCE_ZERO_LABEL,
  BOX,
  CLS,
  CLS_PW,
  OBJ,
  OBJ_PW,
  IOU_T,
  ANCHOR_T,
  FL_GAMMA,
  HSV_H,
  HSV_S,
  HSV_V,
  DEGREES,
  TRANSLATE,
  SCALE,
  MAX_SCALE,
  MIN_SCALE,
  CROP_SIZE_H,
  CROP_SIZE_W,
  RESIZE_RATIO0,
  RESIZE_RATIOF,
  RESIZE_ADD,
  SHEAR,
  PERSPECTIVE,
  FLIPUD,
  FLIPLR,
  MOSAIC,
  MIXUP,
  COPY_PASTE,
  MIXUP_ALPHA,
  CUTMIX_ALPHA,
  MIXUP_SWITCH_PROB,
  SLIDE_EVAL,
  STRIDE,
  CROP_W,
  CROP_H,
] as const;

export type HyperParameterTooltips = (typeof HYPER_PARAMETER_TOOLTIPS)[number];

export const TooltipMsgMap: Record<HyperParameterTooltips, string> = {
  [LR0]: 'Initial learning rate (SGD=1E-2, Adam=1E-3)',
  [LRF]: 'Final OneCycleLR learning rate (lr0 * lrf)',
  [SEED]: 'Random seed number',
  [MOMENTUM]: 'SGD momentum/Adam beta1',
  [WEIGHT_DECAY]: 'Optimizer weight decay 5e-4',
  [WARMUP_STEPS]: 'Warmup steps',
  [WARMUP_EPOCHS]: 'Warmup epochs',
  [WARMUP_MOMENTUM]: 'Warmup initial momentum',
  [WARMUP_BIAS_LR]: 'Warmup initial bias lr',
  [SCHD_POWER]: 'Power factor of get polynomial decay schedule with warmup',
  [REDUCE_ZERO_LABEL]: 'Whether ignore background mask during training and evaluation',
  [BOX]: 'Box loss gain',
  [CLS]: 'Cls loss gain',
  [CLS_PW]: 'Cls BCELoss positive_weight',
  [OBJ]: 'Obj loss gain (scale with pixels)',
  [OBJ_PW]: 'Obj BCELoss positive_weight',
  [IOU_T]: 'IoU training threshold',
  [ANCHOR_T]: 'Anchor-multiple threshold',
  [FL_GAMMA]: 'Focal Loss Gamma (efficientDet default gamma=1.5)',
  [HSV_H]: 'Image HSV-Hue augmentation (fraction)',
  [HSV_S]: 'Image HSV-Saturation augmentation (fraction)',
  [HSV_V]: 'Image HSV-Value augmentation (fraction)',
  [DEGREES]: 'Image rotation (+/- deg)',
  [TRANSLATE]: 'Image translation (+/- fraction)',
  [SCALE]: 'Image scale (+/- gain)',
  [MAX_SCALE]: 'Maximum scale pixel for resize',
  [MIN_SCALE]: 'Minimum scale pixel for resize',
  [CROP_SIZE_H]: 'Crop size (pixel) in height',
  [CROP_SIZE_W]: 'Crop size (pixel) in width',
  [RESIZE_RATIO0]: 'Minimum ratio for random resize',
  [RESIZE_RATIOF]: 'Maximum ratio for random resize',
  [RESIZE_ADD]: 'Value for random resize',
  [SHEAR]: 'Image shear (+/- deg)',
  [PERSPECTIVE]: 'Image perspective (+/- fraction)',
  [FLIPUD]: 'Image flip up-down (probability)',
  [FLIPLR]: 'Image flip left-right (probability)',
  [MOSAIC]: 'Image mosaic (probability)',
  [MIXUP]: 'Image mixup (probability)',
  [COPY_PASTE]: 'Segment copy-paste (probability)',
  [MIXUP_ALPHA]: 'Transparency of mixup (Not a probability)',
  [CUTMIX_ALPHA]: 'Transparency of cutmix (Not a probability)',
  [MIXUP_SWITCH_PROB]: 'Probability of switching to cutmix when both mixup and cutmix are enabled',
  [SLIDE_EVAL]: 'Whether to slide for evaluation',
  [STRIDE]: 'How much pixel to stride when slide_eval is TRUE',
  [CROP_W]: 'How much pixel to crop in width when slide_eval is TRUE',
  [CROP_H]: 'How much pixel to crop in height when slide_eval is TRUE',
};
