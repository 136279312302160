/* eslint-disable camelcase */
import {
  ANCHOR_T,
  BOX,
  CLS,
  CLS_PW,
  COPY_PASTE,
  DEGREES,
  FLIPLR,
  FLIPUD,
  FL_GAMMA,
  HSV_H,
  HSV_S,
  HSV_V,
  IOU_T,
  LR0,
  LRF,
  MIXUP,
  MOMENTUM,
  MOSAIC,
  OBJ,
  OBJ_PW,
  PERSPECTIVE,
  SCALE,
  SHEAR,
  TRANSLATE,
  WARMUP_BIAS_LR,
  WARMUP_EPOCHS,
  WARMUP_MOMENTUM,
  WEIGHT_DECAY,
} from '@netspresso/shared';

export const DETECTION_HYPERPARAMETERS = [
  LR0,
  LRF,
  MOMENTUM,
  WEIGHT_DECAY,
  WARMUP_EPOCHS,
  WARMUP_MOMENTUM,
  WARMUP_BIAS_LR,
  BOX,
  CLS,
  CLS_PW,
  OBJ,
  OBJ_PW,
  IOU_T,
  ANCHOR_T,
  FL_GAMMA,
  HSV_H,
  HSV_S,
  HSV_V,
  DEGREES,
  TRANSLATE,
  SCALE,
  SHEAR,
  PERSPECTIVE,
  FLIPUD,
  FLIPLR,
  MOSAIC,
  MIXUP,
  COPY_PASTE,
] as const;

export type DetectionParameter = (typeof DETECTION_HYPERPARAMETERS)[number];

export const DetectionPlaceholdersMap: Record<DetectionParameter, string> = {
  [LR0]: '1E-6 <= lr0 < 1',
  [LRF]: '1E-6 <= lrf < 1',
  [MOMENTUM]: '0 < momentum < 1',
  [WEIGHT_DECAY]: '0 < weight_decay < 1',
  [WARMUP_EPOCHS]: '0 <= warmup_epochs <= epochs',
  [WARMUP_MOMENTUM]: '0 < warmup_momentum < 1',
  [WARMUP_BIAS_LR]: '0 < warmup_bias_lr < 1',
  [BOX]: '0 <= box < 1',
  [CLS]: '0 <= cls < 1',
  [CLS_PW]: '0 < cls_pw',
  [OBJ]: '0 <= obj <= 1',
  [OBJ_PW]: '0 < obj_pw',
  [IOU_T]: '0 < iou_t < 1',
  [ANCHOR_T]: '0 < anchor_t',
  [FL_GAMMA]: '0 < fl_gamma',
  [HSV_H]: '0 <= hsv_h <= 1',
  [HSV_S]: '0 <= hsv_s <= 1',
  [HSV_V]: '0 <= hsv_v <= 1',
  [DEGREES]: '-180 <= degrees <= 180',
  [TRANSLATE]: '-1 <= translate <= 1',
  [SCALE]: '-1 <= scale <= 1',
  [SHEAR]: '-180 <= shear <= 180',
  [PERSPECTIVE]: '0 - 0.001',
  [FLIPUD]: '0 <= flipud <= 1',
  [FLIPLR]: '0 <= fliplr <= 1',
  [MOSAIC]: '0 <= mosaic <= 1',
  [MIXUP]: '0 <= mixup <= 1',
  [COPY_PASTE]: '0 <= copy_paste <= 1',
};
