import React from 'react';
import { Link } from 'react-router-dom';

const pathToList = (pathname: string) => {
  if (pathname.includes('datasets')) {
    return pathDataset(pathname);
  }

  if (pathname.includes('projects')) {
    return pathProject(pathname);
  }

  if (pathname.includes('models')) {
    return pathModel(pathname);
  }

  if (pathname.includes('compression')) {
    return pathCompression(pathname);
  }

  return null;
};

const pathDataset = (pathname: string) => {
  if (pathname.includes('details')) {
    return <li className="text-secondary font-title">Dataset details</li>;
  }

  if (pathname.includes('upload')) {
    return <li className="text-secondary font-title">Upload Dataset</li>;
  }

  return null;
};

const pathProject = (pathname: string) => {
  if (pathname.includes('details')) {
    return <li className="text-secondary font-title">Project details</li>;
  }

  if (pathname.includes('create_a_new_project')) {
    return <li className="text-secondary font-title">Create a New Project</li>;
  }

  if (pathname.includes('quicksearch') || pathname.includes('retraining')) {
    return (
      <>
        <li className="text-darkGray font-title">
          <Link to="/projects/create_a_new_project">Create a New Project</Link>
        </li>
        <li className="text-secondary font-title">
          {pathname.includes('quicksearch') ? 'Quick Search' : 'Retraining'}
        </li>
      </>
    );
  }

  return null;
};

const pathModel = (pathname: string) => {
  if (pathname.includes('details')) {
    return <li className="text-secondary font-title">Model Details</li>;
  }

  if (pathname.includes('convert')) {
    return <li className="text-secondary font-title">Converting model</li>;
  }

  return null;
};

const pathCompression = (pathname: string) => {
  if (pathname.includes('automatic') || pathname.includes('advanced')) {
    return (
      <>
        <li className="text-darkGray font-title">
          <Link to="/compression/new">New Compression</Link>
        </li>
        <li className="text-secondary font-title">
          {pathname.includes('automatic') ? 'Automatic Compression' : 'Advanced Compression'}
        </li>
      </>
    );
  }

  if (pathname.includes('new')) {
    return <li className="text-secondary font-title">New Compression</li>;
  }

  return null;
};

export const BreadCrumbs: React.FC<{ pathname: string }> = ({ pathname }) => {
  return <ul className="breadcrumbs w-full mt-0.5">{pathToList(pathname)}</ul>;
};
