import React, { useEffect } from 'react';
import { useAuthContext } from '@netspresso/app/src/context';
import { TokenService } from '../../services';

export const AuthRequired: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const { fetchUserData } = useAuthContext();

  useEffect(() => {
    const assignToken = () => {
      const accessToken = TokenService.getLocalAccessToken();
      const refreshToken = TokenService.getLocalRefreshToken();

      if (!accessToken && !refreshToken) {
        window.location.assign(process.env.REACT_APP_PORTAL_URL as string);

        return null;
      }

      if (accessToken && refreshToken) {
        fetchUserData(accessToken, refreshToken);
      }

      return null;
    };

    assignToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};
