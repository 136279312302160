/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { FormHeaderLabel, InfoContainer, InfoText, PanelHeader, SubmitButton } from '@netspresso/components';
import {
  LOCAL_DATASET,
  NotificationMessages,
  S3_STORAGE,
  VALIDATOR_LINUX_LINK,
  VALIDATOR_LOCAL_DATASET_GUIDE_LINK,
  VALIDATOR_MAC_LINK,
  VALIDATOR_WINDOW_LINK,
  featureFlags,
  isEmpty,
} from '@netspresso/shared';

import { AxiosError } from 'axios';
import {
  LEVEL_DANGER,
  LEVEL_SUCCESS,
  LEVEL_WARNING,
  Toast,
  useNotificationContext,
} from '../../../../components/Notifications';
import { Prompt } from '../../../../components/Prompt';
import {
  CERT_FILE,
  DATASET_NAME,
  FILE_ID,
  STORAGE_TYPE,
  TEST_FILE,
  TRAIN_FILE,
  VAL_FILE,
  YAML_FILE,
} from '../../../../constants';
import { useDatasetsFormContext, useLoaderContext, useModalContext } from '../../../../context';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { DatasetType } from '../../../../schemes';
import { DatasetService, UploadLinksType } from '../../../../services';
import { convertToUploadDatasetPayload, useGTM } from '../../../../utils';
import { DatasetSetting, UploadDatasetFiles } from './components';

type CertificateType = {
  train: string;
  data: string;
  val?: string;
  test?: string;
};

export const UploadDataset: React.FC = () => {
  const [, dispatchLoading] = useLoaderContext();
  const [, dispatchModal] = useModalContext();
  const { setPageToDataLayer } = useGTM();
  const { handleSubmit, control, watch } = useDatasetsFormContext();
  const { isDirty, errors } = useFormState({ control });
  const [certificate, setCertificate] = useState<CertificateType | null>(null);

  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const navigate = useNavigate();

  const watchCertFile = watch(CERT_FILE);

  useEffect(() => {
    let fileReader: FileReader;
    let isCancel = false;

    if (watchCertFile) {
      fileReader = new FileReader();
      fileReader.onload = (event) => {
        const reader = event.target;

        if (!(reader instanceof FileReader)) {
          return;
        }

        const { result } = reader;

        if (result && !isCancel) {
          const cert = (result as string)
            .split(/\r?\n/)
            .filter(Boolean)
            .reduce<Record<string, string>>((acc, cur, i) => {
              const splitted = cur.split(': ');

              // eslint-disable-next-line prefer-destructuring
              acc[splitted[0]] = splitted[1];

              return acc;
            }, {});

          setCertificate(cert as CertificateType);
        }
      };

      fileReader.readAsText(watchCertFile as File);
    }

    return () => {
      isCancel = true;

      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [watchCertFile]);

  useEffect(() => {
    setPageToDataLayer('Upload Datasets');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabled = () => {
    return !isDirty || !isEmpty(errors) || !watch(DATASET_NAME);
  };

  const onSubmit: SubmitHandler<DatasetType> = (data) => {
    showPrompt(data);

    return false;
  };

  const onError: SubmitErrorHandler<DatasetType> = (datasetFormsError) => {
    // eslint-disable-next-line no-console
    console.log(datasetFormsError);
    showToast(
      <Toast
        content="Please review form fields."
        level={LEVEL_WARNING}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );
  };

  const getFileSize = (file: File) => {
    return file.size;
  };

  const startUpload = async (data: DatasetType) => {
    if (!data.yaml_file || !certificate) {
      return;
    }

    const uuid = uuidv4();

    let trainUploadId = '';
    let valUploadId = '';
    let testUploadId = '';

    try {
      dispatchModal({ type: ModalActions.Hide });
      dispatchLoading({ type: LoaderActions.Show });

      const s3UploadPayload = {
        ...(data.train_file &&
          certificate.train && {
            train_file: {
              size: getFileSize(data.train_file as File),
              md5: certificate.train,
            },
          }),
        ...(data.val_file &&
          certificate.val && {
            val_file: {
              size: getFileSize(data.val_file as File),
              md5: certificate.val,
            },
          }),
        ...(data.test_file &&
          certificate.test && {
            test_file: {
              size: getFileSize(data.test_file as File),
              md5: certificate.test,
            },
          }),
      };

      const uploadPayload: UploadLinksType = {
        dataset_yaml_file: {
          size: getFileSize(data.yaml_file as File),
          md5: certificate.data,
        },
        storage_type: data.storage_type,
        ...(watch(STORAGE_TYPE) === S3_STORAGE && s3UploadPayload),
      };

      const res = await DatasetService.createUploadLinks(uuid, uploadPayload);

      const { dataset_yaml_s3_urls, test_s3_urls, train_s3_urls, val_s3_urls } = res.data;

      await DatasetService.uploadFileToPresignedUrl(dataset_yaml_s3_urls, data[YAML_FILE]);

      if (watch(STORAGE_TYPE) === S3_STORAGE) {
        if (data.train_file) {
          const trainRes = await DatasetService.uploadFileToPresignedUrl(train_s3_urls, data[TRAIN_FILE]);

          if (train_s3_urls.length > 1) {
            trainUploadId = train_s3_urls[0].upload_id;

            await DatasetService.completeMultipartUpload(uuid, 'train', trainUploadId, certificate.train, trainRes);
          }
        }

        if (data[VAL_FILE] && certificate.val) {
          const valRes = await DatasetService.uploadFileToPresignedUrl(val_s3_urls, data[VAL_FILE]);

          if (val_s3_urls.length > 1) {
            valUploadId = val_s3_urls[0].upload_id;

            await DatasetService.completeMultipartUpload(uuid, 'val', valUploadId, certificate.val, valRes);
          }
        }

        if (data[TEST_FILE] && certificate.test) {
          const testRes = await DatasetService.uploadFileToPresignedUrl(test_s3_urls, data[TEST_FILE]);

          if (test_s3_urls.length > 1) {
            testUploadId = test_s3_urls[0].upload_id;

            await DatasetService.completeMultipartUpload(uuid, 'test', testUploadId, certificate.test, testRes);
          }
        }
      }

      // eslint-disable-next-line no-param-reassign
      data[FILE_ID] = uuid;

      const uploadRes = await DatasetService.uploadDataset(convertToUploadDatasetPayload(data));

      if (uploadRes.status === 200) {
        dispatchLoading({ type: LoaderActions.Hide });
        showToast(
          <Toast
            content="Datasets are successfully uploaded."
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
        navigate(`/datasets`);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      const httpError = err as AxiosError;

      if (httpError.response?.status === 401) {
        await DatasetService.abortMultipartUpload(uuid, 'train', trainUploadId);

        if (data[VAL_FILE] && certificate.val) {
          await DatasetService.abortMultipartUpload(uuid, 'val', valUploadId);
        }

        if (data[TEST_FILE] && certificate.test) {
          await DatasetService.abortMultipartUpload(uuid, 'test', testUploadId);
        }

        showToast(
          <Toast
            content="Uploading dataset failed, please try again."
            level={LEVEL_DANGER}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
      }
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const onClosePrompt: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const showPrompt = (data: DatasetType) => {
    dispatchModal({
      type: ModalActions.Show,
      payload: (
        <Prompt
          headerMessage={NotificationMessages.dataUploadHeader}
          message={NotificationMessages.dataUpload}
          onClose={onClosePrompt}
          onSubmit={() => startUpload(data)}
        />
      ),
    });
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-3">
          <PanelHeader className="pl-6 mb-4">Upload Dataset</PanelHeader>
          <section className="bg-white rounded-lg shadow mb-6 p-6">
            <div className="flex flex-col lg:flex-row">
              <form className="w-full" onSubmit={handleSubmit(onSubmit, onError)}>
                <section className="mb-4">
                  <FormHeaderLabel>Dataset Validation *</FormHeaderLabel>
                  <div className="flex flex-row space-x-2">
                    <a
                      href={VALIDATOR_WINDOW_LINK}
                      target="_blank"
                      className="bg-primary hover:bg-primary-active disabled:bg-disabledGray text-white text-sm leading-none px-2 rounded shadow-sm"
                      rel="noreferrer"
                    >
                      <span className="material-symbols-outlined text-xl mr-2">file_download</span>
                      <span className="inline-block font-semibold text-xs leading-7 align-top">
                        Download Validation SW (Windows)
                      </span>
                    </a>
                    <a
                      href={VALIDATOR_LINUX_LINK}
                      target="_blank"
                      className="bg-primary hover:bg-primary-active disabled:bg-disabledGray text-white text-sm leading-none px-2 rounded shadow-sm"
                      rel="noreferrer"
                    >
                      <span className="material-symbols-outlined text-xl mr-2">file_download</span>
                      <span className="inline-block font-semibold text-xs leading-7 align-top">
                        Download Validation SW (Linux)
                      </span>
                    </a>
                    <a
                      href={VALIDATOR_MAC_LINK}
                      target="_blank"
                      className="bg-primary hover:bg-primary-active disabled:bg-disabledGray text-white text-sm leading-none px-2 rounded shadow-sm"
                      rel="noreferrer"
                    >
                      <span className="material-symbols-outlined text-xl mr-2">file_download</span>
                      <span className="inline-block font-semibold text-xs leading-7 align-top">
                        Download Validation SW (macOS)
                      </span>
                    </a>
                    {featureFlags(LOCAL_DATASET) && (
                      <a
                        href={VALIDATOR_LOCAL_DATASET_GUIDE_LINK}
                        target="_blank"
                        className="bg-secondary-600 hover:bg-secondary-active disabled:bg-disabledGray text-white text-sm leading-none px-2 rounded shadow-sm"
                        rel="noreferrer"
                      >
                        <span className="material-symbols-outlined text-xl mr-2">open_in_new</span>
                        <span className="inline-block font-semibold text-xs leading-7 align-top">
                          Validation guide for Local Dataset
                        </span>
                      </a>
                    )}
                  </div>
                </section>
                <div className="mb-6 pb-4 border-b border-defaultGray-100">
                  <InfoContainer infoType="warning" iconName="warning" className="mb-2">
                    <InfoText color="warning">
                      Dataset validation must be done to create the certification.
                      <br />
                      Please check your data format and structure for the robustness of your model.
                    </InfoText>
                  </InfoContainer>
                </div>
                <DatasetSetting />
                <UploadDatasetFiles />
                <div className="flex justify-end">
                  <SubmitButton data-test="start-upload-btn" disabled={isDisabled()}>
                    Start upload
                  </SubmitButton>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
