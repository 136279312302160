import { YOLOV5N, YOLOV5S, YOLOV5M, YOLOV5L, YOLOV5XL } from '@netspresso/shared';

export const LOOKUP_TABLES = [YOLOV5N, YOLOV5S, YOLOV5M, YOLOV5L, YOLOV5XL] as const;

export type LookupTableTypes = (typeof LOOKUP_TABLES)[number];

export const LookupTableSizes: Record<LookupTableTypes, string> = {
  [YOLOV5N]: 'Nano',
  [YOLOV5S]: 'Small',
  [YOLOV5M]: 'Medium',
  [YOLOV5L]: 'Large',
  [YOLOV5XL]: 'XLarge',
};
