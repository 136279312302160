export const FormErrorMessages = {
  isRequired: 'This field is required.',
  valorTrain: 'Either "Validation" or "Testing" is required.',
  isNotStrongEnough:
    'Must contain at least one number and one uppercase and lowercase letter, and at least one special characters between !@#$%^&*',
  isNotEmail: 'Please enter a correct email address',
  isWrongCharacters: 'Your input contains not allowed characters.',
  isWrongS3: 'Please enter a correct S3 URL.',
  isWrongBatch: 'Batch must be 1 or 3.',
  isNotZip: 'Your file must be zip format.',
  isWrongDimension: 'Dimension must be comma separated numbers',
  wrongLatency: 'Please set your target latency between 10~5000ms.',
  exceedFileSize: (n: number) => `Your file exceeds the maximum size of ${n} GB.`,
  isNotMax: (n: number) => `Your input exceeds the maximum length of ${n} characters.`,
  isNotMin: (n: number) => `Your input must exceeds the minimum length of ${n} characters.`,
  isNotLonger: (n: number) => `Must be at least ${n} characters long.`,
};
