import { apiClient, API_USER_V1 } from '@netspresso/shared';
import { UserType } from '../../context/hooks';

export const UserService = (() => {
  const getUser = () => {
    return apiClient.get<UserType>(API_USER_V1);
  };

  return {
    getUser,
  };
})();
