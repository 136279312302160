import React from 'react';

type StepPointerProps = {
  currentCondition: boolean;
  defaultCondition?: boolean;
  checkCondition?: boolean;
};

export const StepPointer: React.FC<StepPointerProps> = ({
  currentCondition,
  defaultCondition = false,
  checkCondition = false,
}) => {
  return (
    <div
      className={`w-5 h-5 rounded-full mb-1 border-2 flex items-center justify-center ${
        defaultCondition
          ? 'bg-white border-disabledGray'
          : currentCondition
          ? 'bg-white border-secondary'
          : 'bg-secondary border-transparent'
      }`}
    >
      <div className={`w-2.5 h-2.5 rounded-full bg-secondary ${defaultCondition && !currentCondition && 'hidden'}`} />
      {checkCondition && <span className="material-icons text-white text-base font-bold">check</span>}
    </div>
  );
};
