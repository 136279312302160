import React, { createContext, useContext } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { PackagingScheme, PackagingDefaultValues, PackagingType } from '../schemes';

export const PackagingContext = createContext<UseFormReturn<PackagingType> | null>(null);

export const PackagingContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const packagingForm = useForm({
    defaultValues: PackagingDefaultValues,
    mode: 'onBlur',
    resolver: yupResolver(PackagingScheme),
  });

  return <PackagingContext.Provider value={packagingForm}>{children}</PackagingContext.Provider>;
};

export const usePackagingContext = (): UseFormReturn<PackagingType> => {
  const context = useContext(PackagingContext);

  if (!context) {
    throw new Error('usePackagingContext must be used within a PackagingContextProvider');
  }

  return context;
};
