import { LR0, LRF, REDUCE_ZERO_LABEL, SCHD_POWER, SEED, WARMUP_STEPS, WEIGHT_DECAY } from '@netspresso/shared';
import { HYP_DETAIL } from '../../../../../../constants';
import { parseHypPlaceholderSeg, parseHypTooltip } from '../../../../../../utils';
import { HyperParameterInput } from '../../../HyperParameterInput';

export const SegmentationParams = () => {
  return (
    <>
      <section className="flex flex-row">
        <HyperParameterInput
          paramName={LR0}
          groupName={HYP_DETAIL}
          className="pr-9"
          tooltipMsg={parseHypTooltip(LR0)}
          isDisabled
        />
        <HyperParameterInput
          paramName={LRF}
          groupName={HYP_DETAIL}
          className="px-9"
          tooltipMsg={parseHypTooltip(LRF)}
          isDisabled
        />
        <HyperParameterInput
          paramName={SEED}
          groupName={HYP_DETAIL}
          className="pl-9"
          tooltipMsg={parseHypTooltip(SEED)}
          placeholder={parseHypPlaceholderSeg(SEED)}
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          paramName={WEIGHT_DECAY}
          groupName={HYP_DETAIL}
          className="pr-9"
          tooltipMsg={parseHypTooltip(WEIGHT_DECAY)}
          placeholder={parseHypPlaceholderSeg(WEIGHT_DECAY)}
        />
        <HyperParameterInput
          paramName={WARMUP_STEPS}
          groupName={HYP_DETAIL}
          className="px-9"
          tooltipMsg={parseHypTooltip(WARMUP_STEPS)}
          placeholder={parseHypPlaceholderSeg(WARMUP_STEPS)}
        />
        <HyperParameterInput
          paramName={SCHD_POWER}
          groupName={HYP_DETAIL}
          className="pl-9"
          tooltipMsg={parseHypTooltip(SCHD_POWER)}
          placeholder={parseHypPlaceholderSeg(SCHD_POWER)}
          isDisabled
        />
      </section>
      <section className="flex flex-row mt-6">
        <HyperParameterInput
          inputType="toggle"
          paramName={REDUCE_ZERO_LABEL}
          groupName={HYP_DETAIL}
          className="pr-9"
          tooltipMsg={parseHypTooltip(REDUCE_ZERO_LABEL)}
        />
      </section>
    </>
  );
};
