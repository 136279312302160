/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { HeaderText } from '@netspresso/components';

import { Partial } from '@netspresso/shared';
import {
  IMG_SIZE_HEIGHT,
  IMG_SIZE_WIDTH,
  LOOKUPTABLE_UID,
  MODEL_ID,
  RECOMMENDED_TARGET_LATENCY,
} from '../../../../../../constants';
import { useProjectsFormContext } from '../../../../../../context';
import { Recommendation } from '../../../../../../lib';
import { useGTM } from '../../../../../../utils';
import { RecommendationCard } from '../../../../components';

type RunProjectStepsProps = {
  imgSize: Recommendation[];
  latency: Recommendation[];
};

const initRecommended = { [LOOKUPTABLE_UID]: '' };

export const RunProjectStep: React.FC<RunProjectStepsProps> = ({ imgSize, latency }) => {
  const {
    projectsForm: { getValues, setValue },
  } = useProjectsFormContext();
  const { setPageToDataLayer } = useGTM();

  const [recommended, setRecommended] = useState<Partial<Recommendation>>(initRecommended);

  useEffect(() => {
    setRecommended({ [LOOKUPTABLE_UID]: getValues(LOOKUPTABLE_UID) });
  }, [getValues]);

  const onRecommendationSelect = (lookuptable: Recommendation) => {
    setRecommended(lookuptable);

    if (lookuptable) {
      setValue(IMG_SIZE_WIDTH, lookuptable[IMG_SIZE_WIDTH]);
      setValue(IMG_SIZE_HEIGHT, lookuptable[IMG_SIZE_HEIGHT]);
      setValue(
        RECOMMENDED_TARGET_LATENCY,
        parseFloat(parseFloat(`${Math.round(lookuptable.latency_s * 1000000) / 1000}`).toFixed(3))
      );
      setValue(LOOKUPTABLE_UID, lookuptable.lookuptable_id);
      setValue(MODEL_ID, lookuptable.model_id);
    }
  };

  const renderRecommendations = (item: Recommendation, i: number) => {
    return (
      <RecommendationCard
        key={item[LOOKUPTABLE_UID]}
        className={i > 0 ? 'ml-4' : ''}
        lookupTable={item}
        selected={recommended || initRecommended}
        onClick={onRecommendationSelect}
      />
    );
  };

  useEffect(() => {
    setPageToDataLayer('Quick Search | Recommended Models');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderText className="mb-4" type="formGroup">
        Recommendations
      </HeaderText>
      <HeaderText className="mb-4" type="formSection">
        For Image size
      </HeaderText>
      <div className="flex justify-start flex-wrap mb-6">{imgSize.map(renderRecommendations)}</div>
      <HeaderText className="mb-4" type="formSection">
        For Latency
      </HeaderText>
      <div className="flex justify-start flex-wrap">{latency.map(renderRecommendations)}</div>
    </>
  );
};
