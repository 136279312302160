import React from 'react';

type OutlineButtonProps = {
  className?: string;
  type?: 'submit' | 'button' | 'reset';
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const OutlineButton: React.FC<OutlineButtonProps & React.PropsWithChildren> = ({
  children,
  className,
  type = 'button',
  ...restProps
}) => (
  <button
    data-test="cancel-stop-progressing-btn"
    type={type}
    className={`transition ease-in-out duration-200 text-sm text-secondary bg-white border border-secondary hover:bg-secondary hover:text-white px-7 py-2 rounded leading-[14px] ${className}`}
    {...restProps}
  >
    {children}
  </button>
);
