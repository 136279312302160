import React, { useEffect } from 'react';
import { useGTM } from '../../../../../../utils';
import { AdvancedOptions, ImageDataSetting, ModelTraining, TargetDevices } from '../../../../components';

export const SetProject: React.FC = () => {
  const { setPageToDataLayer } = useGTM();

  useEffect(() => {
    setPageToDataLayer('Retraining | Set Project');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ImageDataSetting />
      <TargetDevices />
      <ModelTraining />
      <AdvancedOptions />
    </>
  );
};
