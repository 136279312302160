import { Model } from '../lib';

export type ModelsAction = {
  type: 'ready' | 'remove' | 'add';
  payload: Model[];
};

export type ModelsState = Model[];

/* eslint-disable camelcase */
export const modelsReducer = (models: ModelsState, action: ModelsAction): ModelsState => {
  switch (action.type) {
    case 'ready':
      if (action.payload) {
        if (Array.isArray(action.payload)) {
          return action.payload.filter((item) => item.model_status_data.is_visible);
        }
      }

      return [];
    case 'remove':
      // eslint-disable-next-line no-case-declarations
      const { payload } = action;

      return models.filter((item, index) => item.project_id !== payload[index].project_id);
    case 'add':
      return [...JSON.parse(JSON.stringify(models)), { ...action.payload }];
    default:
      throw new Error(`Unhandled action :${action.type}`);
  }
};
