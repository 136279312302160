import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useFormState } from 'react-hook-form';

import { FormHeaderLabel, InputTextLabel, RadioButton, Select, TextInput } from '@netspresso/components';
import {
  INTELXEON,
  JETPACK_46,
  JETSON,
  NANO,
  PLACEHOLDER_OUTPUT_BATCH_SIZE,
  RASPBERRY_PI,
  THREEBPLUS,
  JETPACK_501,
} from '@netspresso/shared';
import {
  FRAMEWORK_VERSIONS,
  JETSON_VERSIONS,
  ONNX_TRT_VERSIONS,
  BATCH_SIZE,
  OUTPUT_MODEL_TYPE,
  OUTPUT_MODEL_VERSION,
  RASPBERRY_PI_VERSIONS,
  DEVICE,
  RASP_FRAMEWORK_VERSIONS,
  JETSON_FRAMEWORK_VERSIONS,
  INTELXEON_FRAMEWORK_VERSIONS,
  AGX_ORIN_VERSIONS,
  OUTPUT_BATCH_SIZE,
  NANO_VERSIONS,
  XAVIER_NX_VERSIONS,
  OUTPUT_DATA_TYPE,
} from '../../../../constants';

import { useConvertModelFormContext } from '../../../../context';
import {
  DEVICES,
  Devices,
  Frameworks,
  FRAMEWORKS,
  isDevices,
  isOutputDataTypes,
  isOutputModelTypes,
  OutputDataTypes,
  OUTPUT_DATA_TYPES,
  TASKS,
  Tasks,
} from '../../../../lib';
import {
  isJetson,
  isSemanticSegmentation,
  isRaspberryPi,
  isIntelXeon,
  isJetsonAgxOrin,
  isJetsonNano,
  isJetsonNx,
} from '../../../../utils';

export const TargetDevices: React.FC<{ task?: Tasks }> = ({ task = TASKS.OBJECT_DETECTION }) => {
  const { setValue, watch, control } = useConvertModelFormContext();
  const { errors } = useFormState({ control });

  const [device, setDevice] = useState<Devices>(DEVICES.JETSON);
  const [jetsonVersion, setJetsonVersion] = useState(NANO);
  const [raspberryVersion, setRaspberryVersion] = useState(THREEBPLUS);
  const [framework, setFramework] = useState<Frameworks>(FRAMEWORKS.TENSORRT);
  const [swversion, setSWversion] = useState(JETPACK_46);
  const [outputDataType, setOutputDataType] = useState<OutputDataTypes>(OUTPUT_DATA_TYPES.FP16);
  const [batchSize, setBatchSize] = useState(1);

  const watched = watch();

  useEffect(() => {
    if (watched[DEVICE] && watched[DEVICE].indexOf(JETSON) > -1) {
      setDevice(DEVICES.JETSON);
      setJetsonVersion(watched[DEVICE].substring(6));
    }

    if (watched[DEVICE] && watched[DEVICE].indexOf(RASPBERRY_PI) > -1) {
      setDevice(DEVICES.RASPBERRY_PI);
      setRaspberryVersion(watched[DEVICE].substring(11));
    }

    if (watched[DEVICE] && watched[DEVICE].indexOf(INTELXEON) > -1) {
      setDevice(DEVICES.INTEL_XEON);
    }

    if (watched[OUTPUT_DATA_TYPE]) {
      setOutputDataType(watched[OUTPUT_DATA_TYPE]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (device) {
      setValue(DEVICE, getDevice(device));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, setValue]);

  const onDeviceChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    if (!isDevices(value)) {
      return;
    }

    setDevice(value);
    setValue(DEVICE, getDevice(value));

    if (isRaspberryPi(value)) {
      setFramework(FRAMEWORKS.TENSORFLOW_LITE);
      setValue(OUTPUT_MODEL_TYPE, FRAMEWORKS.TENSORFLOW_LITE);
      setValue(OUTPUT_BATCH_SIZE, 1, { shouldValidate: true, shouldDirty: true });
    }

    if (isJetson(value)) {
      setFramework(FRAMEWORKS.TENSORRT);
      setValue(OUTPUT_MODEL_TYPE, FRAMEWORKS.TENSORRT);

      if (isSemanticSegmentation(task)) {
        // setValue(BATCH_SIZE, 1, { shouldValidate: true, shouldDirty: true });
        setValue(OUTPUT_BATCH_SIZE, 1, { shouldValidate: true, shouldDirty: true });
      }
    }

    if (isIntelXeon(value)) {
      setFramework(FRAMEWORKS.OPENVINO);
      setValue(OUTPUT_MODEL_TYPE, FRAMEWORKS.OPENVINO);
    }
  };

  const onOutputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    if (isOutputDataTypes(value)) {
      setOutputDataType(value);
      setValue(OUTPUT_DATA_TYPE, value);
    }
  };

  const getDevice = (deviceCode: Devices) => {
    let result = deviceCode;

    if (isJetson(deviceCode)) {
      const jetsonDevice = `${deviceCode}-${jetsonVersion}`;

      if (isDevices(jetsonDevice)) {
        result = jetsonDevice;
      }
    }

    if (isRaspberryPi(deviceCode)) {
      const raspberryDevice = `${deviceCode}${raspberryVersion}`;

      if (isDevices(raspberryDevice)) {
        result = raspberryDevice;
      }
    }

    return result;
  };

  const jetsonTrtVersionOptions = (deviceVersion: Devices) => {
    if (isJetsonAgxOrin(deviceVersion)) {
      return AGX_ORIN_VERSIONS;
    }

    if (isJetsonNano(deviceVersion)) {
      return NANO_VERSIONS;
    }

    if (isJetsonNx(deviceVersion)) {
      return XAVIER_NX_VERSIONS;
    }

    return ONNX_TRT_VERSIONS;
  };

  const onJetsonVersionChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;
    const jetsonDevice = `${JETSON}-${value}`;

    if (!isDevices(jetsonDevice)) {
      return;
    }

    setJetsonVersion(value);
    setValue(DEVICE, jetsonDevice);

    if (value === 'AGX-Orin') {
      setValue(OUTPUT_MODEL_VERSION, JETPACK_501);
    } else {
      setValue(OUTPUT_MODEL_VERSION, JETPACK_46);
    }
  };

  const onRaspberryVersionChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;
    const raspberryDevice = `${RASPBERRY_PI}${value}`;

    if (!isDevices(raspberryDevice)) {
      return;
    }

    setRaspberryVersion(value);
    setValue(DEVICE, raspberryDevice);
  };

  const onFrameworkChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    if (isOutputModelTypes(value)) {
      setFramework(value);
      setValue(OUTPUT_MODEL_TYPE, value);
    }
  };

  const onSWVersionChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target;

    setSWversion(value);
    setValue(OUTPUT_MODEL_VERSION, value);
  };

  const onBatchSizeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    const batch = parseInt(value, 10);

    setBatchSize(batch);
    // setValue(BATCH_SIZE, value, { shouldValidate: true, shouldDirty: true });
    setValue(OUTPUT_BATCH_SIZE, batch, { shouldValidate: true, shouldDirty: true });
  };

  const isBatchDiabled = () => {
    return (
      watched[OUTPUT_MODEL_TYPE] === FRAMEWORKS.TENSORFLOW_LITE ||
      (isSemanticSegmentation(task) && watched[DEVICE] !== INTELXEON)
    );
  };

  const modifiedFrameworks = () => {
    if (isRaspberryPi(device)) {
      return RASP_FRAMEWORK_VERSIONS;
    }

    if (isJetson(device)) {
      return JETSON_FRAMEWORK_VERSIONS;
    }

    if (isIntelXeon(device)) {
      return INTELXEON_FRAMEWORK_VERSIONS;
    }

    return FRAMEWORK_VERSIONS;
  };

  return (
    <>
      <section className="flex flex-row mb-4">
        <div className="w-[200px] mr-4">
          <FormHeaderLabel>Target device *</FormHeaderLabel>
          <div className="flex flex-col">
            <RadioButton
              groupName={DEVICE}
              value={DEVICES.JETSON}
              label={DEVICES.JETSON}
              classes="mb-3"
              isChecked={isJetson(device)}
              onChange={onDeviceChange}
            />
            <RadioButton
              groupName={DEVICE}
              value={DEVICES.RASPBERRY_PI}
              label={DEVICES.RASPBERRY_PI}
              classes="mb-3"
              isChecked={isRaspberryPi(device)}
              isDisabled={isSemanticSegmentation(task)}
              onChange={onDeviceChange}
            />
            <RadioButton
              groupName={DEVICE}
              value={DEVICES.INTEL_XEON}
              label={DEVICES.INTEL_XEON}
              classes="mb-3"
              isChecked={isIntelXeon(device)}
              onChange={onDeviceChange}
            />
            <RadioButton
              groupName={DEVICE}
              value={DEVICES.ARM_VIRTUAL_HARDWARE_ETHOS_U_SERIES}
              label="Arm Virtual Hardware (Available soon)"
              classes="mb-3"
              isDisabled
            />
          </div>
        </div>
        <div className={`${isJetson(device) ? 'block' : 'hidden'} w-1/6 pt-6`}>
          <Select value={jetsonVersion} options={JETSON_VERSIONS} onChange={onJetsonVersionChange} />
        </div>
        <div className={`${isRaspberryPi(device) ? 'block' : 'hidden'} w-1/12 pt-13`}>
          <Select value={raspberryVersion} options={RASPBERRY_PI_VERSIONS} onChange={onRaspberryVersionChange} />
        </div>
      </section>
      <section className="flex flex-row mb-4">
        <div className="w-1/6 mr-6">
          <FormHeaderLabel>Framework *</FormHeaderLabel>
          <Select
            value={watched[OUTPUT_MODEL_TYPE] || framework}
            options={modifiedFrameworks()}
            onChange={onFrameworkChange}
          />
        </div>
        <div
          className={`w-1/6 ${(watched[OUTPUT_MODEL_TYPE] || framework) === FRAMEWORKS.TENSORRT ? 'block' : 'hidden'}`}
        >
          <FormHeaderLabel>SW version *</FormHeaderLabel>
          <Select
            value={watched[OUTPUT_MODEL_VERSION] || swversion}
            options={jetsonTrtVersionOptions(watched[DEVICE])}
            onChange={onSWVersionChange}
          />
        </div>
      </section>
      <section className="mb-4">
        <FormHeaderLabel>Output datatype *</FormHeaderLabel>
        <div>
          <RadioButton
            groupName={OUTPUT_DATA_TYPE}
            value={OUTPUT_DATA_TYPES.FP32}
            label={OUTPUT_DATA_TYPES.FP32.toUpperCase()}
            classes="mr-6"
            isChecked={outputDataType === OUTPUT_DATA_TYPES.FP32}
            onChange={onOutputChange}
          />
          <RadioButton
            groupName={OUTPUT_DATA_TYPE}
            value={OUTPUT_DATA_TYPES.FP16}
            label={OUTPUT_DATA_TYPES.FP16.toUpperCase()}
            classes="mr-6"
            isChecked={outputDataType === OUTPUT_DATA_TYPES.FP16}
            onChange={onOutputChange}
          />
          <RadioButton
            groupName={OUTPUT_DATA_TYPE}
            value={OUTPUT_DATA_TYPES.INT8}
            label={OUTPUT_DATA_TYPES.INT8.toUpperCase()}
            classes="mr-6"
            isChecked={outputDataType === OUTPUT_DATA_TYPES.INT8}
            onChange={onOutputChange}
            isDisabled
          />
          <RadioButton
            groupName={OUTPUT_DATA_TYPE}
            value={OUTPUT_DATA_TYPES.INT4}
            label={OUTPUT_DATA_TYPES.INT4.toUpperCase()}
            isChecked={outputDataType === OUTPUT_DATA_TYPES.INT4}
            onChange={onOutputChange}
            isDisabled
          />
        </div>
      </section>
      <section className="mb-4">
        <InputTextLabel htmlFor={BATCH_SIZE}>Inference batch size *</InputTextLabel>
        <TextInput
          id={BATCH_SIZE}
          placeholder={PLACEHOLDER_OUTPUT_BATCH_SIZE}
          error={errors[OUTPUT_BATCH_SIZE] ? errors[OUTPUT_BATCH_SIZE].message : ''}
          value={watched[OUTPUT_BATCH_SIZE].toString() || batchSize.toString()}
          isDisable={isBatchDiabled()}
          width="w-1/6"
          onChange={onBatchSizeChange}
        />
      </section>
    </>
  );
};
