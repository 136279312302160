import { OptionType } from '@netspresso/components';
import {
  AGX_ORIN,
  FOURB,
  IMAGE_CLASSIFICATION,
  INSTANCE_SEGMENTATION,
  JetpackVersions,
  JETPACK_441,
  JETPACK_45,
  JETPACK_451,
  JETPACK_451_DLA,
  JETPACK_45_DLA,
  JETPACK_46,
  JETPACK_46_DLA,
  JETPACK_501,
  JETPACK_502,
  JetsonVersions,
  NANO,
  NX,
  OBJECT_DETECTION,
  OTHER,
  PANOPTIC_SEGMENTATION,
  RaspberryVersions,
  SEMANTIC_SEGMENTATION,
  TAO,
  TasksMap,
  THREEBPLUS,
  TWOB,
  TX2,
  XAVIER,
  ZEROW
} from '@netspresso/shared';
import { FrameworksMap, OPENVINO, TENSORFLOW_LITE, TENSORRT } from './frameworks';

export const JETSON_VERSIONS: OptionType[] = [
  { value: NANO, label: JetsonVersions[NANO] },
  { value: NX, label: JetsonVersions[NX] },
  { value: TX2, label: JetsonVersions[TX2] },
  { value: XAVIER, label: JetsonVersions[XAVIER] },
  { value: AGX_ORIN, label: JetsonVersions[AGX_ORIN] },
];

export const RASPBERRY_PI_VERSIONS: OptionType[] = [
  { value: ZEROW, label: RaspberryVersions[ZEROW], isDisabled: true },
  { value: TWOB, label: RaspberryVersions[TWOB], isDisabled: true },
  { value: THREEBPLUS, label: RaspberryVersions[THREEBPLUS] },
  { value: FOURB, label: RaspberryVersions[FOURB] },
];

export const FRAMEWORK_VERSIONS: OptionType[] = [
  { value: TENSORRT, label: FrameworksMap[TENSORRT] },
  { value: TENSORFLOW_LITE, label: FrameworksMap[TENSORFLOW_LITE] },
  { value: OPENVINO, label: FrameworksMap[OPENVINO] },
];

export const RASP_FRAMEWORK_VERSIONS: OptionType[] = [
  { value: TENSORRT, label: FrameworksMap[TENSORRT], isDisabled: true },
  { value: TENSORFLOW_LITE, label: FrameworksMap[TENSORFLOW_LITE] },
  { value: OPENVINO, label: FrameworksMap[OPENVINO], isDisabled: true },
];

export const JETSON_FRAMEWORK_VERSIONS: OptionType[] = [
  { value: TENSORRT, label: FrameworksMap[TENSORRT] },
  { value: TENSORFLOW_LITE, label: FrameworksMap[TENSORFLOW_LITE], isDisabled: true },
  { value: OPENVINO, label: FrameworksMap[OPENVINO], isDisabled: true },
];

export const TAO_FRAMEWORK_VERSIONS: OptionType[] = [
  { value: TENSORRT, label: FrameworksMap[TENSORRT] },
  { value: TAO, label: FrameworksMap[TAO] },
  { value: TENSORFLOW_LITE, label: FrameworksMap[TENSORFLOW_LITE], isDisabled: true },
  { value: OPENVINO, label: FrameworksMap[OPENVINO], isDisabled: true },
];

export const INTELXEON_FRAMEWORK_VERSIONS: OptionType[] = [
  { value: TENSORRT, label: FrameworksMap[TENSORRT], isDisabled: true },
  { value: TENSORFLOW_LITE, label: FrameworksMap[TENSORFLOW_LITE], isDisabled: true },
  { value: OPENVINO, label: FrameworksMap[OPENVINO] },
];

export const ONNX_TRT_VERSIONS: OptionType[] = [
  { value: JETPACK_441, label: JetpackVersions[JETPACK_441], isDisabled: true },
  { value: JETPACK_45, label: JetpackVersions[JETPACK_45], isDisabled: true },
  { value: JETPACK_45_DLA, label: JetpackVersions[JETPACK_45_DLA], isDisabled: true },
  { value: JETPACK_451, label: JetpackVersions[JETPACK_451], isDisabled: true },
  { value: JETPACK_451_DLA, label: JetpackVersions[JETPACK_451_DLA], isDisabled: true },
  { value: JETPACK_46, label: JetpackVersions[JETPACK_46] },
  { value: JETPACK_46_DLA, label: JetpackVersions[JETPACK_46_DLA], isDisabled: true },
  { value: JETPACK_501, label: JetpackVersions[JETPACK_501], isDisabled: true },
  { value: JETPACK_502, label: JetpackVersions[JETPACK_502], isDisabled: true },
];

export const XAVIER_NX_VERSIONS: OptionType[] = [
  { value: JETPACK_441, label: JetpackVersions[JETPACK_441], isDisabled: true },
  { value: JETPACK_45, label: JetpackVersions[JETPACK_45], isDisabled: true },
  { value: JETPACK_45_DLA, label: JetpackVersions[JETPACK_45_DLA], isDisabled: true },
  { value: JETPACK_451, label: JetpackVersions[JETPACK_451], isDisabled: true },
  { value: JETPACK_451_DLA, label: JetpackVersions[JETPACK_451_DLA], isDisabled: true },
  { value: JETPACK_46, label: JetpackVersions[JETPACK_46] },
  { value: JETPACK_46_DLA, label: JetpackVersions[JETPACK_46_DLA], isDisabled: true },
  { value: JETPACK_501, label: JetpackVersions[JETPACK_501], isDisabled: true },
  { value: JETPACK_502, label: JetpackVersions[JETPACK_502] },
];

export const NANO_VERSIONS: OptionType[] = [
  { value: JETPACK_441, label: JetpackVersions[JETPACK_441] },
  { value: JETPACK_45, label: JetpackVersions[JETPACK_45], isDisabled: true },
  { value: JETPACK_45_DLA, label: JetpackVersions[JETPACK_45_DLA], isDisabled: true },
  { value: JETPACK_451, label: JetpackVersions[JETPACK_451], isDisabled: true },
  { value: JETPACK_451_DLA, label: JetpackVersions[JETPACK_451_DLA], isDisabled: true },
  { value: JETPACK_46, label: JetpackVersions[JETPACK_46] },
  { value: JETPACK_46_DLA, label: JetpackVersions[JETPACK_46_DLA], isDisabled: true },
  { value: JETPACK_501, label: JetpackVersions[JETPACK_501], isDisabled: true },
  { value: JETPACK_502, label: JetpackVersions[JETPACK_502], isDisabled: true },
];

export const AGX_ORIN_VERSIONS: OptionType[] = [
  { value: JETPACK_441, label: JetpackVersions[JETPACK_441], isDisabled: true },
  { value: JETPACK_45, label: JetpackVersions[JETPACK_45], isDisabled: true },
  { value: JETPACK_45_DLA, label: JetpackVersions[JETPACK_45_DLA], isDisabled: true },
  { value: JETPACK_451, label: JetpackVersions[JETPACK_451], isDisabled: true },
  { value: JETPACK_451_DLA, label: JetpackVersions[JETPACK_451_DLA], isDisabled: true },
  { value: JETPACK_46, label: JetpackVersions[JETPACK_46], isDisabled: true },
  { value: JETPACK_46_DLA, label: JetpackVersions[JETPACK_46_DLA], isDisabled: true },
  { value: JETPACK_501, label: JetpackVersions[JETPACK_501] },
  { value: JETPACK_502, label: JetpackVersions[JETPACK_502], isDisabled: true },
];

export const BATCH_IMAGE_SIZES: OptionType[] = [
  { value: '128', label: '128 x 128' },
  { value: '256', label: '256 x 256' },
  { value: '480', label: '480 x 480' },
  { value: '640', label: '640 x 640' },
  { value: '1024', label: '1024 x 1024' },
];

export const ARM_BATCH_IMAGE_SIZES: OptionType[] = [
  { value: '96', label: '96 x 96' },
  { value: '128', label: '128 x 128' },
  { value: '160', label: '160 x 160' },
  { value: '192', label: '192 x 192' },
];

export const JETSON_ORIN_BATCH_IMAGE_SIZES: OptionType[] = [
  { value: '128', label: '128 x 128' },
  { value: '256', label: '256 x 256' },
  { value: '512', label: '512 x 512' },
  { value: '640', label: '640 x 640' },
  { value: '1024', label: '1024 x 1024' },
];

export const CLS_BATCH_IMAGE_SIZES: OptionType[] = [
  { value: '224', label: '224 x 224' },
  { value: '384', label: '384 x 384' },
  { value: '512', label: '512 x 512' },
];

export const SEG_BATCH_IMAGE_SIZES: OptionType[] = [
  { value: '512', label: '512 x 512' },
  { value: '768', label: '768 x 768' },
  { value: '1024', label: '1024 x 1024' },
];

export const SEG_LIMITED_BATCH_IMAGE_SIZES: OptionType[] = [
  { value: '512', label: '512 x 512' },
  { value: '768', label: '768 x 768' },
];

export const MODEL_TASKS: OptionType[] = [
  { value: '', label: 'Select a task' },
  { value: IMAGE_CLASSIFICATION, label: TasksMap[IMAGE_CLASSIFICATION] },
  { value: OBJECT_DETECTION, label: TasksMap[OBJECT_DETECTION] },
  { value: SEMANTIC_SEGMENTATION, label: TasksMap[SEMANTIC_SEGMENTATION] },
  { value: INSTANCE_SEGMENTATION, label: TasksMap[INSTANCE_SEGMENTATION] },
  { value: PANOPTIC_SEGMENTATION, label: TasksMap[PANOPTIC_SEGMENTATION] },
  { value: OTHER, label: TasksMap[OTHER] },
];

export const CLASSIFICATION_EVALUATION_METRICS: OptionType[] = [
  { value: '', label: 'Select a metric' },
  { value: 'Top-1 Accuracy', label: 'Top-1 Accuracy' },
  { value: 'Top-5 Accuracy', label: 'Top-5 Accuracy' },
  { value: 'Other', label: 'Other' },
];

export const OBJECT_DETECTION_EVALUATION_METRICS: OptionType[] = [
  { value: '', label: 'Select a metric' },
  { value: 'mAP[0.5]', label: 'mAP[0.5]' },
  { value: 'mAP[0.5:0.95]', label: 'mAP[0.5:0.95]' },
  { value: 'Other', label: 'Other' },
];

export const SEGMENTATION_EVALUATION_METRICS: OptionType[] = [
  { value: '', label: 'Select a metric' },
  { value: 'mIoU', label: 'mIoU' },
  { value: 'PA (Pixel Accuracy)', label: 'PA (Pixel Accuracy)' },
  { value: 'Other', label: 'Other' },
];

export const INS_SEGMENTATION_EVALUATION_METRICS: OptionType[] = [
  { value: '', label: 'Select a metric' },
  { value: 'AP(Average Precision)[IoU=.5:.05:.95]', label: 'AP(Average Precision)[IoU=.5:.05:.95]' },
  { value: 'AP(Average Precision)[IoU=0.50]', label: 'AP(Average Precision)[IoU=0.50]' },
  { value: 'AP(Average Precision)[IoU=0.75]', label: 'AP(Average Precision)[IoU=0.75]' },
  { value: 'Other', label: 'Other' },
];

export const PAN_SEGMENTATION_EVALUATION_METRICS: OptionType[] = [
  { value: '', label: 'Select a metric' },
  { value: 'PQ(Panoptic quality)', label: 'PQ(Panoptic quality)' },
  { value: 'PQ_st(PQ averaged over stuff classes)', label: 'PQ_st(PQ averaged over stuff classes)' },
  { value: 'PQ_th(PQ averaged over thing classes)', label: 'PQ_th(PQ averaged over thing classes)' },
  { value: 'Other', label: 'Other' },
];

// 0414 compressor 통합에 추가 (model edit에서 사용)
export const IMAGE_SEGMENTAION_EVALUATION_METRICS: OptionType[] = [
  { value: '', label: 'Select a metric' },
  { value: 'PA(Pixel Accuracy)', label: 'PA(Pixel Accuracy)' },
  { value: 'MPA(Mean Pixel Accuracy)', label: 'MPA(Mean Pixel Accuracy)' },
  { value: 'Other', label: 'Other' },
];
