import { IconButton } from '@netspresso/components';
import { isVoidFunction } from '@netspresso/shared';
import React from 'react';

interface ISearchbar {
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  onConfirm: React.MouseEventHandler<HTMLButtonElement> | VoidFunction;
  className?: string;
  isDisabled?: boolean;
}

export const Searchbar: React.FC<ISearchbar> = ({ className, setQuery, onConfirm, isDisabled = false }) => {
  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setQuery(value);
  };

  const onKeyDownHandler: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (isVoidFunction(onConfirm) && event.key === 'Enter') {
      onConfirm();
    }
  };

  return (
    <div role="searchbox" className={`w-1/2 flex flex-row ${className}`} tabIndex={0} onKeyDown={onKeyDownHandler}>
      <input
        className="border border-defaultGray rounded w-full px-4 py-1 placeholder-defaultGray-300 focus:outline-none focus:border-secondary mr-2 disabled:bg-lineGray"
        placeholder="Search model name"
        onChange={onInputChange}
        disabled={isDisabled}
      />
      <IconButton onClick={onConfirm} width="w-[36px]" height="h-[36px]" isDisabled={isDisabled}>
        search
      </IconButton>
    </div>
  );
};
