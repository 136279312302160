export const API_DATASET_V1 = '/api/v1/dataset';
export const API_PROJECT_V1 = '/api/v1/project';
export const API_MODEL_V1 = '/api/v1/model';
export const API_PACKAGE_V1 = '/api/v1/package';
export const API_CONVERT_V1 = '/api/v1/convert';
export const API_AUTH_V1 = '/api/v1/auth';
export const API_USER_V1 = '/api/v1/user';
export const API_AGENT_V1 = '/api/v1/agent';
export const API_RECOMMEND_MODEL_V1 = '/api/v1/lookuptable/recommend_model';

// compressor api 구분하기 위해 중간에 "compress"를 명시했음
export const API_COMPRESS_MODEL_V2 = '/api/v2/models';
export const API_COMPRESS_COMPRESSION_V2 = '/api/v2/compressions';
