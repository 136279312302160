import TagManager from 'react-gtm-module';
import { useAuth } from '../../context/hooks';

export const useGTM = () => {
  const { user } = useAuth();

  const setPageToDataLayer = (page: string) => {
    if (user) {
      TagManager.dataLayer({ dataLayer: { user_id: user.user_id, page } });
    }
  };

  return { setPageToDataLayer };
};
