export const RASPBERRY_PI = 'RaspberryPi';
export const RASPBERRY_PI_4B = 'RaspberryPi4B';
export const RASPBERRY_PI_3BPLUS = 'RaspberryPi3BPlus';
export const RASPBERRY_PI_2 = 'raspberrypi2';
export const RASPBERRY_PI_ZEROW = 'raspberrypizerow';
export const JETSON = 'Jetson';
export const JETSON_NANO = 'Jetson-Nano';
export const JETSON_NX = 'Jetson-Nx';
export const JETSON_TX2 = 'Jetson-Tx2';
export const JETSON_XAVIER = 'Jetson-Xavier';
export const JETSON_AGX_ORIN = 'Jetson-AGX-Orin';
export const INTELXEON = 'Intel-Xeon';
export const ARM_HARDWARE = 'Arm Virtual Hardware Ethos-U Series';

export const NANO = 'Nano';
export const NX = 'Nx';
export const TX2 = 'Tx2';
export const XAVIER = 'Xavier';
export const AGX_ORIN = 'AGX-Orin';

export const ZEROW = 'zerow';
export const TWOB = '2b';
export const THREEBPLUS = '3BPlus';
export const FOURB = '4B';

export const JetsonVersions = {
  [NANO]: 'Nano',
  [NX]: 'Xavier NX',
  [TX2]: 'TX2',
  [XAVIER]: 'AGX Xavier',
  [AGX_ORIN]: 'AGX Orin',
};

export const RaspberryVersions = {
  [ZEROW]: 'Zero W',
  [TWOB]: '2B',
  [THREEBPLUS]: '3B+',
  [FOURB]: '4B',
};
