import { QUICK_SEARCH, ADVANCED_SEARCH, RETRAINING } from '@netspresso/shared';

export const PROJECT_TYPES = [QUICK_SEARCH, ADVANCED_SEARCH, RETRAINING] as const;

export type ProjectTypes = (typeof PROJECT_TYPES)[number];

export const ProjectTypesMap: Record<ProjectTypes, string> = {
  [QUICK_SEARCH]: 'Quick Search',
  [ADVANCED_SEARCH]: 'Advanced Search',
  [RETRAINING]: 'Retraining',
};
