import * as yup from 'yup';
import { FIELD_NAME, FormErrorMessages } from '@netspresso/shared';
import { DISPLAY_NAME, DESCRIPTION } from '../constants';

export const SaveModelScheme = yup.object({
  [DISPLAY_NAME]: yup
    .string()
    .required(FormErrorMessages.isRequired)
    .min(2, FormErrorMessages.isNotMin(2))
    .max(20, FormErrorMessages.isNotMax(20))
    .matches(FIELD_NAME, FormErrorMessages.isWrongCharacters),
  [DESCRIPTION]: yup.string().max(100, FormErrorMessages.isNotMax(100)),
});

export type SaveModelType = yup.InferType<typeof SaveModelScheme>;

export const SaveModelsDefaultValues: SaveModelType = {
  [DISPLAY_NAME]: '',
  [DESCRIPTION]: '',
};
