import { TAO } from '@netspresso/shared';
import React, { useEffect } from 'react';
import { OUTPUT_MODEL_TYPE } from '../../../../../../constants';
import { useProjectsFormContext } from '../../../../../../context';
import { useGTM } from '../../../../../../utils';
import {
  AdvancedOptions,
  ImageDataSetting,
  ModelTraining,
  ProjectSetting,
  TargetDevices,
} from '../../../../components';

export const ProjectSettingStep: React.FC = () => {
  const {
    projectsForm: { watch },
  } = useProjectsFormContext();
  const { setPageToDataLayer } = useGTM();

  const watched = watch();

  useEffect(() => {
    setPageToDataLayer('Quick Search | Set Project');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ProjectSetting />
      <ImageDataSetting />
      <TargetDevices />
      <ModelTraining />
      {watched[OUTPUT_MODEL_TYPE] === TAO ? null : <AdvancedOptions />}
    </>
  );
};
