import React, { createContext, useContext, useReducer } from 'react';
import { LoaderAction, LoaderState, loaderReducer } from '../reducers';

type LoaderContextType = [LoaderState, React.Dispatch<LoaderAction>];

export const LoaderContext = createContext<LoaderContextType | null>(null);

const initialState = { isLoading: false };

export const LoaderContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(loaderReducer, initialState);

  return <LoaderContext.Provider value={[state, dispatch]}>{children}</LoaderContext.Provider>;
};

export const useLoaderContext = (): LoaderContextType => {
  const context = useContext(LoaderContext);

  if (!context) {
    throw new Error('useLoaderContext must be used within a LoaderContextProvider');
  }

  return context;
};
