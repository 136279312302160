import React from 'react';

type LabeledPerformanceDisplayProps = {
  className?: string;
  value: string;
  dataTestId?: string;
  isLast?: boolean;
} & React.PropsWithChildren;

export const LabeledPerformanceDisplay: React.FC<LabeledPerformanceDisplayProps> = ({
  children,
  className,
  value,
  dataTestId = 'model-info',
  isLast = false,
}) => {
  return (
    <div className={`w-1/6${isLast ? '' : ' border-r border-lineGray mr-6'}`}>
      <div className={`text-sm font-medium text-defaultGray ${className}`}>{children}</div>
      <div className="text-darkGray font-normal" data-testid={dataTestId}>
        {value}
      </div>
    </div>
  );
};
