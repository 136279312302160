import { NumInstanceType } from '../types';

export const isNumInstanceType = (value: unknown): value is NumInstanceType => {
  return (
    (value as NumInstanceType).class_name !== undefined &&
    (value as NumInstanceType).test !== undefined &&
    (value as NumInstanceType).total !== undefined &&
    (value as NumInstanceType).train !== undefined &&
    (value as NumInstanceType).val !== undefined
  );
};
