import React from 'react';

type ComposeProps = {
  providers: React.FC<React.PropsWithChildren>[];
} & React.PropsWithChildren;

export const Compose: React.FC<ComposeProps> = ({ children, providers = [] }) => {
  return (
    <>
      {providers.reduceRight((acc, Provider) => {
        return <Provider>{acc}</Provider>;
      }, children)}
    </>
  );
};
