import React, { useEffect, useState } from 'react';

type ToggleSwitchProps = {
  id: string;
  onToggle: (value: boolean) => void;
  value?: boolean;
  dataTestId?: string;
};

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  id,
  onToggle,
  value = false,
  dataTestId = 'toggle-switch',
}) => {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    onToggle(checked);
  }, [checked]);

  const onClickHandler = () => {
    setChecked(!checked);
  };

  return (
    <div className="relative inline-block align-middle text-left cursor-pointer select-none" onClick={onClickHandler}>
      <input id={id} name={id} type="checkbox" className="hidden" data-testid={dataTestId} />
      {checked ? (
        <div className="w-12 h-6 rounded-full flex items-center my-1 bg-secondary">
          <div className="w-[18px] h-[18px] rounded-full bg-white ml-auto mr-1"></div>
        </div>
      ) : (
        <div className="w-12 h-6 rounded-full flex items-center my-1 bg-defaultGray justify-items-start">
          <div className="w-[18px] h-[18px] rounded-full bg-white ml-1"></div>
        </div>
      )}
    </div>
  );
};
