/* eslint-disable react/no-danger */
import { SubmitButton } from '@netspresso/components';
import React from 'react';

type ErrorModalProps = {
  errorTitle: string;
  errorMessage: string;
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
  modelId?: string;
  errorLog?: string;
};

export const ModelErrorModal: React.FC<ErrorModalProps> = ({
  errorTitle,
  errorMessage,
  onSubmit,
  modelId = '',
  errorLog = '',
}) => {
  const linkRegex = /https?:\/\/[^\s)]+/;
  const link = errorMessage.match(linkRegex);
  const text = link ? errorMessage.split(link[0]) : errorMessage;

  return (
    <div className="flex flex-col justify-between bg-white rounded shadow-lg w-[600px] p-6 text-darkGray">
      <h3 className="text-xl font-title font-semibold mb-4 flex items-center gap-1">
        <span className="material-icons">error_outline</span>
        {errorTitle}
      </h3>
      <p className="text-sm pb-4">
        {typeof text !== 'string' && link ? (
          <>
            {text[0]}
            <a
              className="underline text-secondary hover:text-secondary-active"
              target="_blank"
              href={link[0]}
              rel="noreferrer"
            >
              {link[0]}
            </a>
            {text[1]}
          </>
        ) : (
          text
        )}
      </p>
      {modelId && <span className="text-xxs font-normal pb-4">* Model ID - {modelId}</span>}
      {errorLog && (
        <div className="py-4 w-full">
          <span className="text-sm font-medium">[Error Log]</span>
          <div className="w-full">
            <pre className="whitespace-pre-wrap break-words text-xxs h-12 overflow-y-auto">{errorLog}</pre>
          </div>
        </div>
      )}
      <div className="flex justify-end">
        <SubmitButton type="button" onClick={onSubmit}>
          OK
        </SubmitButton>
      </div>
    </div>
  );
};
