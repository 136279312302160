export const TORCH = 'torch';
export const TF = 'tf';
export const ONNX = 'onnx';
export const PYTORCH = 'pytorch';
export const TAO = 'etlt';
export const GRAPHMODULE_PT = 'graphmodule_pt';
export const TENSORFLOW_KERAS = 'tensorflow_keras';
export const JETPACK_441 = 'jetpack_4.4.1';
export const JETPACK_45 = 'jetpack_4.5';
export const JETPACK_45_DLA = 'jetpack_4.5_DLA';
export const JETPACK_451 = 'jetpack_4.5.1';
export const JETPACK_451_DLA = 'jetpack_4.5.1_DLA';
export const JETPACK_46 = 'jetpack_4.6';
export const JETPACK_46_DLA = 'jetpack_4.6_DLA';
export const JETPACK_501 = 'jetpack_5.0.1';
export const JETPACK_502 = 'jetpack_5.0.2';
export const TFLITE_RUNTIME_24 = 'tflite-runtime_2.4.0';
export const TFLITE_RUNTIME_291 = 'tflite-runtime_2.9.1';
export const SHUFFLENETV2PLUS_S = 'shufflenetv2plus_s';
export const SHUFFLENETV2PLUS_M = 'shufflenetv2plus_m';
export const SHUFFLENETV2PLUS_L = 'shufflenetv2plus_l';
export const MIXNET_S = 'mixnet_s';
export const MIXNET_M = 'mixnet_m';
export const MIXNET_L = 'mixnet_l';
export const SEGFORMER_B0 = 'segformer-b0';
export const SEGFORMER_B1 = 'segformer-b1';
export const SEGFORMER_B2 = 'segformer-b2';
export const SEGFORMER_B3 = 'segformer-b3';
export const SEGFORMER_B4 = 'segformer-b4';
export const SEGFORMER_B5 = 'segformer-b5';
export const YOLOFASTEST = 'yolo-fastest';
export const YOLOV5N = 'yolov5n';
export const YOLOV5S = 'yolov5s';
export const YOLOV5M = 'yolov5m';
export const YOLOV5L = 'yolov5l';
export const YOLOV5XL = 'yolov5xl';
export const YOLOV5N6 = 'yolov5n6';
export const YOLOV5S6 = 'yolov5s6';
export const YOLOV5M6 = 'yolov5m6';
export const YOLOV5L6 = 'yolov5l6';
export const UNET_RESNET = 'UNet (+ Resnet)';

export const LibraryVersions = {
  tensorflow_lite: ['python', 'numpy', 'tflite'],
  jetson: ['python', 'numpy', 'jetpack', 'tensorrt', 'cuda-runtime'],
  openvino: ['python', 'numpy', 'openvino-runtime'],
};

export const JetpackVersions = {
  [JETPACK_441]: 'JetPack 4.4.1',
  'jetpack_4.5': 'JetPack 4.5',
  'jetpack_4.5_DLA': 'JetPack 4.5 DLA',
  'jetpack_4.5.1': 'JetPack 4.5.1',
  'jetpack_4.5.1_DLA': 'JetPack 4.5.1 DLA',
  'jetpack_4.6': 'JetPack 4.6',
  'jetpack_4.6_DLA': 'JetPack 4.6 DLA',
  'jetpack_5.0.1': 'JetPack 5.0.1',
  [JETPACK_502]: 'JetPack 5.0.2',
};

export const TFLiteVersions = {
  'tflite-runtime_2.4.0': 'TensorFlow Lite 2.4.0',
  'tflite-runtime_2.9.1': 'TensorFlow Lite 2.9.1',
};
