import React from 'react';
import { DetailLabel, DetailValue } from '../TextStyle';

type LabeledValueDisplayProps = {
  label: string;
  dataTestId?: string;
  className?: string;
};

export const LabeledValueDisplay: React.FC<LabeledValueDisplayProps & React.PropsWithChildren> = ({
  label,
  children,
  dataTestId,
  className = '',
}) => {
  return (
    <div className={`${className}`}>
      <DetailLabel>{label}</DetailLabel>
      <DetailValue dataTestId={dataTestId}>{children}</DetailValue>
    </div>
  );
};
