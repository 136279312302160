import { OptionType } from '@netspresso/components';
import React from 'react';

type ModelSelectProps = {
  id?: string;
  className?: string;
  label: string;
  value?: string | number | readonly string[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  margin?: string;
  width?: string;
  valueProp?: string;
  labelProp?: string;
  options: OptionType[];
  isDisabled?: boolean;
};

export const ModelSelect: React.FC<ModelSelectProps> = ({
  id,
  className,
  label,
  value,
  onChange,
  margin = 'mr-2',
  width = 'w-full',
  valueProp = 'value',
  labelProp = 'label',
  options = [],
  isDisabled = false,
}) => {
  return (
    <select
      className={`border border-defaultGray text-darkGray rounded px-3 py-1 disabled:bg-lineGray disabled:text-componentGray ${width} ${margin}`}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      data-testid={id}
    >
      {options.length > 0 ? (
        options.map((item) => {
          return (
            <option key={item[valueProp]} value={item[valueProp]} disabled={item.isDisabled}>
              {item[labelProp]}
            </option>
          );
        })
      ) : (
        <option value="">No option to select</option>
      )}
    </select>
  );
};
