import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { SubmitErrorHandler, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputTextLabel, TextInput, TextArea, OutlineButton, SubmitButton } from '@netspresso/components';
import {
  FIELD_NAME,
  FormErrorMessages,
  PLACEHOLDER_DATASET_NAME,
  PLACEHOLDER_DATASET_MEMO,
  isEmpty,
  API_DATASET_V1,
  apiClient,
} from '@netspresso/shared';

import { DATASET_NAME, DESCRIPTION } from '../../../../../../constants';
import { useLoaderContext, useModalContext } from '../../../../../../context';
import {
  useNotificationContext,
  LEVEL_SUCCESS,
  Toast,
  LEVEL_DANGER,
  LEVEL_WARNING,
} from '../../../../../../components/Notifications';
import { LoaderActions, ModalActions } from '../../../../../../reducers';

const EditDatasetScheme = yup.object({
  [DATASET_NAME]: yup
    .string()
    .required(FormErrorMessages.isRequired)
    .min(2, FormErrorMessages.isNotMin(2))
    .max(20, FormErrorMessages.isNotMax(20))
    .matches(FIELD_NAME, FormErrorMessages.isWrongCharacters),
  [DESCRIPTION]: yup.string().max(100, FormErrorMessages.isNotMax(100)),
});

const EditDatasetDefaultValues = {
  [DATASET_NAME]: '',
  [DESCRIPTION]: '',
};

export type EditDatasetType = yup.InferType<typeof EditDatasetScheme>;

type EditDatasetDialogProps = {
  onComplete: VoidFunction;
  dialogName: string;
  dialogMemo: string;
  datasetId: string;
};

export const EditDatasetDialog: React.FC<EditDatasetDialogProps> = ({
  onComplete,
  dialogName,
  dialogMemo,
  datasetId,
}) => {
  const [datasetName, setDatasetName] = useState('');
  const [datasetDescription, setDatasetDescription] = useState('');
  const { handleSubmit, setValue, watch, control } = useForm({
    defaultValues: EditDatasetDefaultValues,
    mode: 'onBlur',
    resolver: yupResolver(EditDatasetScheme),
  });
  const { isDirty, errors } = useFormState({ control });
  const [, dispatchLoading] = useLoaderContext();
  const [, dispatchModal] = useModalContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();

  useEffect(() => {
    setValue(DATASET_NAME, dialogName, { shouldValidate: true, shouldDirty: true });
    setValue(DESCRIPTION, dialogMemo, { shouldValidate: true, shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const watched = watch();

  const isDisabled = () => {
    return !isDirty || !isEmpty(errors);
  };

  const onClickCancel: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setDatasetName(value);
    setValue(DATASET_NAME, value, { shouldValidate: true, shouldDirty: true });
  };

  const onDescriptionChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const { value } = event.target;

    setDatasetDescription(value);
    setValue(DESCRIPTION, value, { shouldValidate: true, shouldDirty: true });
  };

  const onSubmit: SubmitHandler<EditDatasetType> = async (data) => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      const res = await apiClient.patch(`${API_DATASET_V1}/${datasetId}`, data);

      if (res.status === 200) {
        dispatchLoading({ type: LoaderActions.Hide });
        showToast(
          <Toast
            content="You requested to edit dataset successfully"
            level={LEVEL_SUCCESS}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      showToast(
        <Toast
          content="Editing dataset request failed, please try again."
          level={LEVEL_DANGER}
          onClick={onClickToastHandler}
          hideToast={hideToast}
        />
      );
    } finally {
      onComplete();
      dispatchModal({ type: ModalActions.Hide });
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const onError: SubmitErrorHandler<EditDatasetType> = (datasetFormErrors) => {
    // eslint-disable-next-line no-console
    console.log(datasetFormErrors);
    showToast(
      <Toast
        content="Please review form fields."
        level={LEVEL_WARNING}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );
  };

  return (
    <div className="flex justify-center items-center h-screen w-full fixed left-0 top-0 bg-black bg-opacity-50 z-20">
      <div className="flex flex-col justify-between bg-white rounded shadow-lg w-[600px] p-6">
        <h3 className="text-xl text-gray-900 font-title font-semibold mb-4">Edit dataset</h3>
        <form className="w-full" onSubmit={handleSubmit(onSubmit, onError)}>
          <section className="mb-3" data-test="dataset-name-input">
            <InputTextLabel htmlFor={DATASET_NAME}>Dataset name *</InputTextLabel>
            <TextInput
              id={DATASET_NAME}
              placeholder={PLACEHOLDER_DATASET_NAME}
              error={errors[DATASET_NAME] ? errors[DATASET_NAME].message : ''}
              value={watched[DATASET_NAME] || datasetName}
              width="w-full"
              onChange={onNameChange}
            />
          </section>
          <section className="mb-8" data-test="dataset-memo-input">
            <InputTextLabel htmlFor={DESCRIPTION}>Memo</InputTextLabel>
            <TextArea
              id={DESCRIPTION}
              error={errors[DESCRIPTION] ? errors[DESCRIPTION].message : ''}
              placeholder={PLACEHOLDER_DATASET_MEMO}
              value={watched[DESCRIPTION] || datasetDescription}
              rows={3}
              width="w-full"
              onChange={onDescriptionChange}
            />
          </section>
          <div className="flex justify-end pt-2">
            <OutlineButton className="mr-3" onClick={onClickCancel}>
              Cancel
            </OutlineButton>
            <SubmitButton disabled={isDisabled()}>Confirm</SubmitButton>
          </div>
        </form>
      </div>
    </div>
  );
};
