export const MODEL_NAME = 'model_name';
export const MODEL_DESCRIPTION = 'model_description';
export const DATA_TYPE = 'data_type';
export const BATCH_SIZE = 'batch_size';
export const IMG_RESOLUTION = 'img_resolution';
export const TARGET_MODEL_TYPE = 'target_model_type';
export const TARGET_DEVICE = 'target_device';
export const TRIAL_UID = 'trial_uid';
export const PACKAGE_NAME = 'package_name';
export const PYTHON_VERSION = 'python_version';
export const PACKAGE_FORMAT = 'package_format';
export const MODEL_UID = 'model_uid';
export const DISPLAY_NAME = 'display_name';
export const FRAMEWORK = 'framework';
export const METRIC_UNIT = 'metric_unit';
export const METRIC_VALUE = 'metric_value';
export const ORIGINAL_METRIC_VALUE = 'original_metric_value';
export const INPUT_SHAPE_CHANNEL = 'input_shape_channel';
export const INPUT_SHAPE_HEIGHT = 'input_shape_height';
export const INPUT_SHAPE_WIDTH = 'input_shape_width';
export const BATCH = 'batch';
export const CHANNEL = 'channel';
export const DIMENSION = 'dimension';
export const INPUT_LAYERS = 'input_layers';
export const FILE_NAME = 'file_name';
