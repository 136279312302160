import { FormHeaderLabel, InputFile, RadioButton } from '@netspresso/components';
import React, { useState } from 'react';
import { useFormState } from 'react-hook-form';
import { FILE, FILE_NAME, FRAMEWORK } from '../../../../../../constants';
import { useUploadModelFormContext } from '../../../../../../context';
import { FRAMEWORKS, isUploadableFrameworks, UploadableModelTypes } from '../../../../../../lib';
import { acceptType, placeholderFile } from '../../utils';
import { ModelKeras } from '../ModelKeras';
import { ModelOnnx } from '../ModelOnnx';
import { ModelPyTorch } from '../ModelPyTorch';

type ModelFrameworkProps = {
  modelFramework: UploadableModelTypes;
  setModelFramework: React.Dispatch<React.SetStateAction<UploadableModelTypes>>;
};

export const ModelFramework: React.FC<ModelFrameworkProps> = ({ modelFramework, setModelFramework }) => {
  const { setValue, control } = useUploadModelFormContext();
  const { errors } = useFormState({ control });
  const [modelFile, setModelFile] = useState<File | null>(null);

  const onFrameworkChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    if (isUploadableFrameworks(value)) {
      setModelFramework(value);
      setValue(FRAMEWORK, value, { shouldValidate: true, shouldDirty: true });
    }
  };

  const onModelFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) {
      setModelFile(null);
      setValue(FILE, null, { shouldValidate: true, shouldDirty: true });
      setValue(FILE_NAME, '', { shouldValidate: true, shouldDirty: true });

      return;
    }

    const file = event.target.files[0];

    setModelFile(file);
    setValue(FILE, file, { shouldValidate: true, shouldDirty: true });
    setValue(FILE_NAME, file.name, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <>
      <section className="flex flex-row mb-4">
        <div className="w-full mr-4">
          <FormHeaderLabel>Framework *</FormHeaderLabel>
          <div className="flex flex-col">
            <RadioButton
              groupName={FRAMEWORK}
              value={FRAMEWORKS.ONNX}
              label="ONNX (.onnx)"
              classes="mb-3"
              isChecked={modelFramework === FRAMEWORKS.ONNX}
              onChange={onFrameworkChange}
            />
            <RadioButton
              groupName={FRAMEWORK}
              value={FRAMEWORKS.PYTORCH}
              label="PyTorch GraphModule (.pt)"
              classes="mb-3"
              isChecked={modelFramework === FRAMEWORKS.PYTORCH}
              onChange={onFrameworkChange}
            />
            <RadioButton
              groupName={FRAMEWORK}
              value={FRAMEWORKS.TENSORFLOW_KERAS}
              label="TensorFlow-Keras (.h5, .zip)"
              classes="mb-3"
              isChecked={modelFramework === FRAMEWORKS.TENSORFLOW_KERAS}
              onChange={onFrameworkChange}
            />
            <RadioButton
              groupName={FRAMEWORK}
              value={FRAMEWORKS.TENSORFLOW_LITE}
              label="TensorFlow Lite (.tflite)"
              classes="mb-3"
              isDisabled
              isChecked={modelFramework === FRAMEWORKS.TENSORFLOW_LITE}
              onChange={onFrameworkChange}
            />
            <RadioButton
              groupName={FRAMEWORK}
              value={FRAMEWORKS.TENSORRT}
              label="TensorRT (.trt)"
              classes="mb-3"
              isDisabled
              isChecked={modelFramework === FRAMEWORKS.TENSORRT}
              onChange={onFrameworkChange}
            />
            <RadioButton
              groupName={FRAMEWORK}
              value={FRAMEWORKS.OPENVINO}
              label="OpenVINO (.zip)"
              classes="mb-3"
              isDisabled
              isChecked={modelFramework === FRAMEWORKS.OPENVINO}
              onChange={onFrameworkChange}
            />
          </div>
        </div>
      </section>
      {modelFramework === FRAMEWORKS.ONNX && <ModelOnnx />}
      {modelFramework === FRAMEWORKS.TENSORFLOW_KERAS && <ModelKeras />}
      {modelFramework === FRAMEWORKS.PYTORCH && <ModelPyTorch />}
      <section className="flex flex-col mb-4 w-full">
        <span className="block font-semibold text-sm text-darkGray mb-2">Upload model file</span>
        <div className="w-full">
          <InputFile
            id={FILE}
            error={errors[FILE]?.message}
            accept={acceptType(modelFramework)}
            placeholder={placeholderFile(modelFramework)}
            localFile={modelFile}
            onChange={onModelFileChange}
          />
        </div>
      </section>
    </>
  );
};
