import React, { FunctionComponent, useEffect, useMemo } from 'react';

import SuccessIcon from '@netspresso/components/assets/icons/outline_check_circle.svg';
import DangerIcon from '@netspresso/components/assets/icons/outline_error.svg';
import InfoIcon from '@netspresso/components/assets/icons/outline_exclamation.svg';
import WarningIcon from '@netspresso/components/assets/icons/outline_warning.svg';

import { LEVEL_DANGER, LEVEL_INFO, LEVEL_SUCCESS, LEVEL_WARNING } from './constants';
import { LEVELS } from './types';

type ToastProps = {
  content: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  hideToast?: VoidFunction;
  level: LEVELS;
};

export const Toast: React.FC<ToastProps> = ({ content, onClick, hideToast, level = LEVEL_INFO }) => {
  useEffect(() => {
    if (content) {
      if (hideToast) {
        const timeoutToHide = setTimeout(hideToast, 3000);

        return () => clearTimeout(timeoutToHide);
      }
    }

    return () => null;
  }, [content, hideToast]);

  const {
    title,
    icon: Icon,
    colorClass,
  } = useMemo<{ icon: FunctionComponent; colorClass: string; title: string }>(
    () =>
      ({
        [LEVEL_INFO]: {
          title: 'Note',
          colorClass: 'border-primary-500',
          icon: InfoIcon,
        },
        [LEVEL_DANGER]: { title: 'Danger', colorClass: 'border-notaRed-500', icon: DangerIcon },
        [LEVEL_SUCCESS]: { title: 'Success', colorClass: 'border-notaGreen-500', icon: SuccessIcon },
        [LEVEL_WARNING]: { title: 'Warning', colorClass: 'border-notaYellow-500', icon: WarningIcon },
      }[level]),
    [level]
  );

  return (
    <div className="flex">
      <div className={`border-l-[5px] rounded-l-lg ${colorClass}`} />
      <div className="flex bg-white gap-3 p-4 rounded-r-lg">
        <Icon />
        <div className="w-full flex justify-between flex-col text-neutralBlack-500">
          <div className="font-semibold">{title}</div>
          <div className="text-xs whitespace-pre-wrap">{content}</div>
        </div>
        <div className="flex items-start">
          <button type="button" className="transition ease-in-out duration-300" onClick={onClick}>
            <span className="material-icons">close</span>
          </button>
        </div>
      </div>
    </div>
  );
};
