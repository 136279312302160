import React, { createContext, useContext, useState, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import { ProjectScheme, ProjectsDefaultValues, ProjectType } from '../schemes';
import { ProjectTaskType, TASKS } from '../lib';

type ProjectsFormContextType = {
  projectsForm: UseFormReturn<ProjectType>;
  setTask: React.Dispatch<React.SetStateAction<ProjectTaskType>>;
};

export const ProjectsFormContext = createContext<ProjectsFormContextType | null>(null);

export const ProjectsFormContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [task, setTask] = useState<ProjectTaskType>(TASKS.OBJECT_DETECTION);
  const projectsForm = useForm({
    defaultValues: useMemo(() => {
      return ProjectsDefaultValues(task);
    }, [task]),
    mode: 'onBlur',
    resolver: yupResolver(ProjectScheme),
  });

  return <ProjectsFormContext.Provider value={{ projectsForm, setTask }}>{children}</ProjectsFormContext.Provider>;
};

export const useProjectsFormContext = (): ProjectsFormContextType => {
  const context = useContext(ProjectsFormContext);

  if (!context) {
    throw new Error('useProjectsFormContext must be used within a ProjectsFormContextProvider');
  }

  return context;
};
