import React from 'react';

export const ModelKeras: React.FC = () => {
  return (
    <section className="flex items-start p-2 rounded bg-secondary-50 mb-6">
      <span className="material-icons mr-2 text-secondary">error_outline</span>
      <div className="text-m text-secondary">
        <h1 className="font-medium text-secondary-700">TensorFlow-Keras model</h1>
        <h2 className="mt-2">Model Compressor</h2>
        <ul className="w-full list-disc ml-4">
          <li className="text-xs text-secondary leading-[18px]">Supported version: TensorFlow 2.3.x ~ 2.8.x.</li>
          <li className="text-xs text-secondary leading-[18px]">
            Custom layer must not be included in Keras H5 (.h5) format.
          </li>
          <li className="text-xs text-secondary leading-[18px]">
            The model must contain not only weights but also the structure of the model (do not use save_weights).
          </li>
          <li className="text-xs text-secondary leading-[18px]">
            If there is a custom layer in the model, please upload TensorFlow SavedModel format (.zip).
          </li>
        </ul>
        <h2 className="mt-2">Model Launcher</h2>
        <ul className="w-full list-disc ml-4 mb-1">
          <li className="text-xs text-secondary leading-[18px]">
            TensorFlow-Keras to TensorFlow Lite converting will be available soon.
          </li>
        </ul>
        <img src="/images/tf-keras.png" alt="tensorflow-keras" />
      </div>
    </section>
  );
};
