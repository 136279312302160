import React, { createContext, useContext, useReducer } from 'react';
import { SideNavAction, sideNavReducer, SideNavState } from '../reducers';

type SideNavContextType = [SideNavState, React.Dispatch<SideNavAction>];

export const SideNavContext = createContext<SideNavContextType | null>(null);

const initialState = { isOpen: false, isExpand: true };

export const SideNavContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(sideNavReducer, initialState);

  return <SideNavContext.Provider value={[state, dispatch]}>{children}</SideNavContext.Provider>;
};

export const useSideNavContext = (): SideNavContextType => {
  const context = useContext(SideNavContext);

  if (!context) {
    throw new Error('useSideNavContext must be used within a SideNavContextProvider');
  }

  return context;
};
