import { HeaderText, LabeledValueDisplay } from '@netspresso/components';
import React from 'react';
import { NetworkLayerContextProvider } from '../../../../../../context';
import { Compressed, CompressModel } from '../../../../../../lib';
import { NetworkGraphTable } from '../../../../../Compression/components';

type CompressionDetailProps = {
  baseModel: CompressModel;
  compression: Compressed;
};

export const CompressionDetail: React.FC<CompressionDetailProps> = ({ baseModel, compression }) => {
  const layers = React.useMemo(() => {
    if (compression) {
      return compression.available_layers.map((availableLayer) => ({
        ...availableLayer,
        text: availableLayer.values.join(', '),
      }));
    }

    return [];
  }, [compression]);

  return (
    <section className="w-1/2 pt-8">
      <HeaderText className="mb-4">Compression Detail</HeaderText>
      {/* {compressedModel.compresssion_method.startsWith('PR_') && ( */}
      <LabeledValueDisplay label="Policy">{compression.options.policy}</LabeledValueDisplay>
      {/* )} */}
      <LabeledValueDisplay label="Details" className="pt-8">
        <div className="border broder-gray-300 rounded-lg">
          <NetworkLayerContextProvider>
            <NetworkGraphTable model={baseModel} compression={compression} layers={layers} disabled />
          </NetworkLayerContextProvider>
        </div>
      </LabeledValueDisplay>
    </section>
  );
};
