/* eslint-disable camelcase */
import { InfoContainer, InfoText, SubmitButton } from '@netspresso/components';
import { CONTACT_LINK, LOCAL_DATASET, featureFlags } from '@netspresso/shared';
import React, { useEffect, useState } from 'react';

import { LEVEL_DANGER, Toast, useNotificationContext } from '../../../../components';
import { AGENT_ID, DATASET_UID } from '../../../../constants';
import { useLoaderContext, useProjectsFormContext } from '../../../../context';
import { NotaServerType, UserAgentType } from '../../../../lib';
import { LoaderActions } from '../../../../reducers';
import { AgentService } from '../../../../services';
import { useGTM } from '../../../../utils';
import { NotaServer } from './components';
import { UserServer } from './components/UserServer';

export const SelectServer: React.FC = () => {
  const [trainingServer, setTrainingServer] = useState('');
  const [notaServer, setNotaServer] = useState<NotaServerType>({
    free_servers_num: 0,
    remained_num: 0,
    status: '',
    used_num: 1,
  });
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();
  const [userAgents, setUserAgents] = useState<UserAgentType[]>([]);
  const {
    projectsForm: { setValue, watch },
  } = useProjectsFormContext();
  const [, dispatchLoading] = useLoaderContext();
  const { setPageToDataLayer } = useGTM();

  const datasetId = watch(DATASET_UID);

  const fetchAgent = async () => {
    try {
      dispatchLoading({ type: LoaderActions.Show });

      if (!datasetId) {
        showToast(
          <Toast
            content="Dataset info is missing in this Project."
            level={LEVEL_DANGER}
            onClick={onClickToastHandler}
            hideToast={hideToast}
          />
        );

        return;
      }

      const res = await AgentService.getAgentInfoByDatasetId(datasetId);

      setNotaServer(res.data.nota_server);
      setUserAgents(res.data.user_server.filter((server) => server.status !== 'deleted'));
      dispatchLoading({ type: LoaderActions.Hide });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const serverStatusMessage = ({ remained_num, status, free_servers_num }: NotaServerType) => {
    if (status === 'running') {
      return 'Free server is alreday running the training project.';
    }

    if (remained_num <= 0) {
      return (
        <>
          Free NetsPresso Server usage has been exhausted. Please contact{' '}
          <a href={CONTACT_LINK} className="underline">
            netspresso@nota.ai
          </a>{' '}
          to use NetsPresso Server.
          <br />
          Connecting personal server will be available soon. You can use your own server to train the model.
        </>
      );
    }

    if (free_servers_num <= 0) {
      return 'There are no available servers. Please wait until the server is available.';
    }

    return null;
  };

  const updateServerStatus = () => {
    fetchAgent();
  };

  const onClickServer = (id: string) => {
    if (trainingServer !== id) {
      setTrainingServer(id);
      setValue(AGENT_ID, id);
    } else {
      setTrainingServer('');
      setValue(AGENT_ID, '');
    }
  };

  const isServerDisabled = ({ remained_num, status, free_servers_num }: NotaServerType) => {
    return [remained_num <= 0, status !== 'available', free_servers_num <= 0].some(Boolean);
  };

  const isUserServerDisabled = ({ status }: UserAgentType) => {
    return status !== 'available';
  };

  useEffect(() => {
    setPageToDataLayer('Select Training Server');
    fetchAgent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onManageResourcesClick: React.MouseEventHandler = (e) => {
    window.open(`${process.env.REACT_APP_PORTAL_URL}/account/resources`);
  };

  return (
    <>
      <div className="flex flex-row items-start">
        <h1 className="text-secondary-800 text-xl font-title font-bold mb-8">Select Training Server</h1>
        <button className="ml-2" type="button" onClick={updateServerStatus}>
          <span className="material-icons text-sub transform hover:-rotate-180 transition duration-500 ease-in-out">
            sync
          </span>
        </button>
      </div>
      {(notaServer.status === 'running' || notaServer.remained_num <= 0 || notaServer.free_servers_num <= 0) && (
        <InfoContainer infoType="danger" iconName="cancel" className="mb-6">
          <InfoText color="danger">{serverStatusMessage(notaServer)}</InfoText>
        </InfoContainer>
      )}
      <div className="flex flex-col lg:flex-row">
        <div className="w-full">
          <div className="flex justify-start flex-wrap">
            <section className="w-[576px]">
              <NotaServer
                notaServer={notaServer}
                selected={trainingServer}
                isDisabled={isServerDisabled(notaServer)}
                onClick={() => onClickServer('nota_server')}
              />
              {userAgents.length > 0 &&
                userAgents.map((server) => {
                  return (
                    <UserServer
                      key={server.agent_id}
                      userServer={server}
                      selected={trainingServer}
                      isDisabled={isUserServerDisabled(server)}
                      onClick={() => onClickServer(server.agent_id)}
                    />
                  );
                })}
              {featureFlags(LOCAL_DATASET) && (
                <div className="flex justify-end py-2.5">
                  <SubmitButton type="button" onClick={onManageResourcesClick}>
                    Manage resources
                  </SubmitButton>
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
